import React from "react";
import { Modal, Table, Spinner, Badge } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleArrowDown } from "@fortawesome/free-solid-svg-icons";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";

function InfluencerPopUp({ loading, data, show, handleClose }) {
  const customFontStyle = { fontSize: "11px", fontWeight: "500" };
  const customHeadingStyle = { fontSize: "11px" };

  return (
    <>
      <Offcanvas show={show} onHide={handleClose} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Details</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Table size="sm" hover>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan={2}>
                    <center>
                      <Spinner animation="border" />
                    </center>
                  </td>
                </tr>
              ) : (
                data &&
                data.map((data) => (
                  <>
                    <tr key={data._id}>
                      <th scope="col">
                        <small className="" style={customHeadingStyle}>
                          Campaign
                        </small>
                      </th>
                      <td style={customFontStyle}>
                        {data.campaign && data.campaign.name
                          ? data.campaign.name
                          : "---"}
                      </td>
                    </tr>
                    <tr>
                      <th scope="col">
                        <small className="" style={customHeadingStyle}>
                          Vendor
                        </small>
                      </th>
                      <td style={customFontStyle}>
                        {data.company ? data.company.name : "---"}
                      </td>
                    </tr>
                    <tr>
                      <th scope="col">
                        <small className="" style={customHeadingStyle}>
                          Platform
                        </small>
                      </th>
                      <td style={customFontStyle}>
                        {data.platform
                          ? data.platform.map((p) => p.name + " ")
                          : "---"}
                      </td>
                    </tr>
                    <tr>
                      <th scope="col">
                        <small className="" style={customHeadingStyle}>
                          Influencer
                        </small>
                      </th>
                      <td style={customFontStyle}>
                        {data.influencer && data.influencer.username
                          ? data.influencer.username
                          : "---"}
                      </td>
                    </tr>
                    <tr>
                      <th scope="col">
                        <small className="" style={customHeadingStyle}>
                          Deliverables
                        </small>
                      </th>
                      <td style={customFontStyle}>
                        {data.deliverables ? data.deliverables : "---"}
                      </td>
                    </tr>
                    <tr>
                      <th scope="col">
                        <small className="" style={customHeadingStyle}>
                          Comment
                        </small>
                      </th>
                      <td style={customFontStyle}>
                        {data.comment ? data.comment : "---"}
                      </td>
                    </tr>
                    <tr>
                      <th scope="col">
                        <small className="" style={customHeadingStyle}>
                          POC
                        </small>
                      </th>
                      <td style={customFontStyle}>
                        {data.createdby && data.createdby.fullName
                          ? data.createdby.fullName
                          : "---"}
                      </td>
                    </tr>
                    <tr>
                      <th scope="col">
                        <small className="" style={customHeadingStyle}>
                          Approved By
                        </small>
                      </th>
                      {data.approvedby ? (
                        <td style={customFontStyle}>
                          {data.approvedby.split(",")[0]
                            ? data.approvedby.split(",")[0]
                            : "---"}
                          &nbsp;
                          {data.approvedby.split(",")[1] ? (
                            <h6 className="d-inline">
                              <Badge bg="secondary">
                                {new Date(
                                  parseInt(data.approvedby.split(",")[1])
                                ).toLocaleDateString(undefined, {
                                  day: "numeric",
                                  month: "numeric",
                                  year: "numeric",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                })}
                              </Badge>
                            </h6>
                          ) : (
                            <></>
                          )}
                        </td>
                      ) : (
                        <td style={customFontStyle}>---</td>
                      )}
                    </tr>
                    <tr>
                      <th scope="col">
                        <small className="" style={customHeadingStyle}>
                          Total Amount
                        </small>
                      </th>
                      <td>
                        {data.cost ? (
                          <Badge bg="success">&#8377;{data.cost}</Badge>
                        ) : (
                          "---"
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th scope="col">
                        <small className="" style={customHeadingStyle}>
                          GST Amount
                        </small>
                      </th>
                      <td>
                        {data.gstAmount ? (
                          <Badge bg="success">&#8377;{data.gstAmount}</Badge>
                        ) : (
                          "---"
                        )}
                        {data.gstFile && (
                          <>
                            &nbsp;&nbsp;
                            <a
                              href={
                                process.env.REACT_APP_BASE_URL +
                                "/" +
                                data.gstFile
                              }
                              className="text-primary"
                              target={"_blank"}
                              title="Download GST File"
                              download
                            >
                              <img
                                src={
                                  process.env.REACT_APP_BASE_URL +
                                  "/" +
                                  data.gstFile
                                }
                                width={25}
                                height={25}
                                className="rounded-circle border border-danger border-2"
                              />
                            </a>
                          </>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th scope="col">
                        <small className="" style={customHeadingStyle}>
                          Payable Amount
                        </small>
                      </th>
                      <td>
                        {data.payableAmount ? (
                          <Badge
                            bg="warning"
                            style={{ fontSize: "12px", color: "black" }}
                          >
                            &#8377;{data.payableAmount}
                          </Badge>
                        ) : (
                          "---"
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th scope="col">
                        <small className="" style={customHeadingStyle}>
                          Invoice
                        </small>
                      </th>
                      <td style={customFontStyle}>
                        {data.invoice ? (
                          <a
                            href={
                              process.env.REACT_APP_BASE_URL +
                              "/" +
                              data.invoice
                            }
                            className="text-primary"
                            target={"_blank"}
                            title="Download Invoice"
                          >
                            {data.invoice}{" "}
                            <FontAwesomeIcon
                              icon={faCircleArrowDown}
                              size="xl"
                            />
                          </a>
                        ) : (
                          "---"
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th scope="col">
                        <small className="" style={customHeadingStyle}>
                          Email
                        </small>
                      </th>
                      <td style={customFontStyle}>
                        {data.influencer && data.influencer.email
                          ? data.influencer.email
                          : "---"}
                      </td>
                    </tr>
                    <tr>
                      <th scope="col">
                        <small className="" style={customHeadingStyle}>
                          Phone
                        </small>
                      </th>
                      <td style={customFontStyle}>
                        {data.influencer && data.influencer.number
                          ? data.influencer.number
                          : "---"}
                      </td>
                    </tr>
                    <tr>
                      <th scope="col">
                        <small className="" style={customHeadingStyle}>
                          DOB
                        </small>
                      </th>
                      <td style={customFontStyle}>
                        {data.influencer && data.influencer.dob
                          ? data.influencer.dob
                          : "---"}
                      </td>
                    </tr>
                    <tr>
                      <th scope="col">
                        <small className="" style={customHeadingStyle}>
                          State
                        </small>
                      </th>
                      <td style={customFontStyle}>
                        {data.influencer && data.influencer.state
                          ? data.influencer.state
                          : "---"}
                      </td>
                    </tr>
                    <tr>
                      <th scope="col">
                        <small className="" style={customHeadingStyle}>
                          Account Name
                        </small>
                      </th>
                      <td style={customFontStyle}>
                        {data.accountName ? data.accountName : "---"}
                        {/* {data.PaymentDetails[0] &&
                        data.PaymentDetails[0].details.length > 0
                          ? data.PaymentDetails[0].details.filter(
                              (detail) => detail.isActive === true
                            )[0].accountName
                          : "---"} */}
                      </td>
                    </tr>
                    <tr>
                      <th scope="col">
                        <small className="" style={customHeadingStyle}>
                          Account Number
                        </small>
                      </th>
                      <td style={customFontStyle}>
                        {data.accountNo ? data.accountNo : "---"}
                        {/* {data.PaymentDetails[0] &&
                        data.PaymentDetails[0].details.length > 0
                          ? data.PaymentDetails[0].details.filter(
                              (detail) => detail.isActive === true
                            )[0].accountNo
                          : "---"} */}
                      </td>
                    </tr>
                    <tr>
                      <th scope="col">
                        <small className="" style={customHeadingStyle}>
                          IFSC Code
                        </small>
                      </th>
                      <td style={customFontStyle}>
                        {data.ifscCode ? data.ifscCode : "---"}
                        {/* {data.PaymentDetails[0] &&
                        data.PaymentDetails[0].details.length > 0
                          ? data.PaymentDetails[0].details.filter(
                              (detail) => detail.isActive === true
                            )[0].ifscCode
                          : "---"} */}
                      </td>
                    </tr>
                    <tr>
                      <th scope="col">
                        <small className="" style={customHeadingStyle}>
                          Pan Card Number
                        </small>
                      </th>
                      <td style={customFontStyle}>
                        {data.pancard ? data.pancard : "---"}
                        {/* {data.PaymentDetails[0] &&
                        data.PaymentDetails[0].details.length > 0
                          ? data.PaymentDetails[0].details.filter(
                              (detail) => detail.isActive === true
                            )[0].pancard
                          : "---"} */}
                        &nbsp;
                        {data.pancardFile ? (
                          <a
                            href={
                              process.env.REACT_APP_BASE_URL +
                              "/" +
                              data.pancardFile
                            }
                            className="text-primary"
                            target={"_blank"}
                          >
                            <FontAwesomeIcon
                              icon={faCircleArrowDown}
                              size="xl"
                            />
                          </a>
                        ) : (
                          <span></span>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th scope="col">
                        <small className="" style={customHeadingStyle}>
                          Pancard Name
                        </small>
                      </th>
                      <td style={customFontStyle}>
                        {data.pancardName ? data.pancardName : "---"}
                        {/* {data.PaymentDetails[0] &&
                        data.PaymentDetails[0].details.length > 0
                          ? data.PaymentDetails[0].details.filter(
                              (detail) => detail.isActive === true
                            )[0].paytmNumber
                          : "---"} */}
                      </td>
                    </tr>
                    <tr>
                      <th scope="col">
                        <small className="" style={customHeadingStyle}>
                          Remarks
                        </small>
                      </th>
                      <td style={customFontStyle}>
                        {data.remarks && data.remarks}
                      </td>
                    </tr>
                  </>
                ))
              )}
            </tbody>
          </Table>
        </Offcanvas.Body>
      </Offcanvas>

      {/* <Modal show={isInfluencerPopupOpen} onHide={handleModalClose}>
        <Modal.Header closeButton onClick={handleModalClose}>
          <Modal.Title style={{ color: "#023430" }}>Influencer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          
        </Modal.Body>
      </Modal> */}
    </>
  );
}

export default InfluencerPopUp;
