import axios from "axios";
import constants from "../services/constants";
import Swal from "sweetalert2";
// axios.defaults.baseURL = "https://idiotic.pythonanywhere.com/api/";
axios.defaults.baseURL = constants.api;
const TOKEN_NAME = process.env.REACT_APP_TOKEN_NAME;

if (localStorage.getItem(TOKEN_NAME)){
  setToken(localStorage.getItem(TOKEN_NAME));
}
axios.defaults.headers.common["x-FY"] = localStorage.getItem("x-FY");
axios.interceptors.response.use(null, (error) => {
  
    if (error.status === 500)
      Swal.fire({
        icon: "error",
        title: `500 Internal Server Error

        Sorry, we encountered an unexpected error while processing your request. Please try again later.`,
        showConfirmButton: false,
        // timer: 1500,
      });
  return Promise.reject(error);
});

function setToken(token) {
  axios.defaults.headers.common["x-auth-token"] = token;  

}

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  setToken,
};
