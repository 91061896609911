import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import paymentlinkservice from "../../../services/paymentlinkservice";

function PaymentModal({ userParams }) {
  const btnClose = () => setVisible(false);
  //   const btnShow = () => setVisible(true);

  const [visible, setVisible] = useState(false);
  const [params, setParams] = useState({
    remarks: "",
  });

  const handleChange = (e) => {
    let { name, value } = e.target;

    setParams((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (obj) => {
    const id = userParams.id;
    const remarks = params.remarks;
    await paymentlinkservice.addRemarks(id, remarks);
    alert("Remarks Added Successfully");
    window.location.reload();
  };

  const Init = async () => {
    setVisible(true);
  };

  useEffect(() => {
    Init();
  }, []);

  return (
    <>
      <Modal show={visible} onHide={btnClose}>
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "#023430" }}>Add Remarks</Modal.Title>
        </Modal.Header>
        <form onSubmit={handleSubmit}>
          <Modal.Body>
            <h5 style={{ color: "#023430" }}>Remarks</h5>
            <textarea
              type="text"
              rows={5}
              placeholder="Add Remarks"
              className="w-100 p-2"
              value={params.remarks}
              name="remarks"
              onChange={handleChange}
              style={{ fontWeight: "500", resize: "none" }}
              spellCheck="false"
              required
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={btnClose}>
              Close
            </Button>
            <button className="btn btn-success" type="submit">
              Add
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
}

export default PaymentModal;
