import React from "react";
import AddUser from "../../components/adduser/addUser";

export default function adduser() {
  return (
    <>
      <AddUser />
    </>
  );
}
