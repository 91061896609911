import http from "./httpservices";

export async function getVendorCategories() {
  const { data } = await http.get("vendorcategory/");
  return data;
}

export async function addVendorCategory(params) {
  const { data } = await http.post("vendorcategory/", params);
  return data;
}

export default {
  getVendorCategories,
  addVendorCategory,
};
