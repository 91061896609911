import React, { useEffect, useState } from "react";
import _ from "lodash";
import Brand from "../../../common/select/brand";

function InfluencerForm({
  isClicked,
  form,
  gst,
  onSubmit,
  calcPercentage,
  handleVendorChangeForInfluencer,
  handleYoutubeUrl,
  handleGSTChange,
  handleChange,
  customStyle,
  customStyles,
  campaign,
  companyData,
  inputText,
  loading,
  userData,
  handleValidateUrl,
  showNumber,
  validateNumber,
  campaignDetails,
  handleCalcPercentage,
  setReloadPage,
}) {

  const [isCost, setCost] = useState(false);

  const getInit = async () => {
    if (userData.cost && userData.isGstPaid) setCost(true);
  };

  useEffect(() => {
    getInit();
  }, [userData.username]);

  return (
    <form onSubmit={onSubmit} ref={form}>
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-4 col-xl-6">
          <input
            type="text"
            className="form-control me-2"
            id="formGroupExampleInput"
            placeholder="Instagram Username*"
            name="username"
            value={campaignDetails.name}
            disabled
            style={customStyle}
          ></input>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-4 col-xl-6">
          <input
            type="url"
            className="form-control mb-2"
            placeholder="Youtube, Instagram, Telegram and Facebook Profile Links*"
            name="influencer"
            id="influencer_yt_id"
            value={inputText}
            style={customStyle}
            autoComplete="off"
            spellCheck="false"
            onChange={handleValidateUrl}
            autoFocus={true}
            required
          />
          {loading && <small className="d-block">Loading...</small>}
        </div>
      </div>
      <hr />
      <div className="row">
        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6">
          <input
            type="text"
            className="form-control me-2"
            id="formGroupExampleInput"
            placeholder="Instagram Username*"
            name="username"
            value={userData.username}
            disabled
            style={customStyle}
          ></input>
        </div>
        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6">
          <input
            type="date"
            className="form-control me-2"
            id="formGroupExampleInput"
            name="customDate"
            onChange={handleChange}
            value={userData.customDate}
            style={customStyle}
          ></input>
        </div>
      </div>
      <hr />
      <div className="row">
        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6">
          <Brand
            loading={false}
            userData={userData}
            brand={companyData}
            handleChange={handleVendorChangeForInfluencer}
            title={"Vendor"}
            isMulti={false}
            customStyles={customStyles}
            value={userData.company.name}
          />
        </div>
        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6">
          <input
            type="text"
            className="form-control me-2"
            id="formGroupExampleInput"
            placeholder="Comment (If Applicable)"
            name="comment"
            onChange={handleChange}
            style={customStyle}
          ></input>
        </div>
      </div>
      <hr />
      <div className="input-group">
        <textarea
          className="form-control"
          aria-label="With textarea"
          style={{ resize: "none", fontSize: "13px", height: "100px" }}
          placeholder="Deliverables*"
          name="deliverables"
          required
          value={userData.deliverables}
          onChange={handleChange}
        ></textarea>
      </div>
      <hr />
      <div
        className="form-check mb-2 d-flex justify-content-center"
        style={customStyle}
      >
        <input
          className="form-check-input"
          type="checkbox"
          id="formCheckGst"
          onClick={handleGSTChange}
          checked={userData.isGstPaid && userData.percentage < 100 ? true : undefined}
          disabled={userData.isGstPaid && userData.percentage < 100}
          style={{ cursor: (isCost && userData.percentage < 100) ? 'default' : 'pointer' }}
        />
        &nbsp;
        <label
          className="form-check-label"
          htmlFor="formCheckGst"
          style={{ cursor: (isCost && userData.percentage < 100) ? 'default' : 'pointer' }}
        >
          GST (If Applicable)
        </label>
      </div>
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <div className="d-flex justify-content-around">
            <input
              type="text"
              className="form-control me-2"
              id="formGroupExampleInput"
              placeholder={
                gst
                  ? 0
                  : `Total Amount* Your Limit is ${campaignDetails.paymentLimit} Rs`
              }
              min={0}
              name="cost"
              value={userData.cost}
              onChange={handleChange}
              onKeyUp={validateNumber}
              style={customStyle}
              disabled={isCost}
              required
            ></input>
            <input
              type="text"
              className="form-control me-2"
              id="formGroupExampleInput"
              placeholder={"GST Amount"}
              min={0}
              name="gstAmount"
              value={(!gst ? "" : userData.gstAmount) || (isCost ? parseInt(parseInt(userData.cost) * 0.18) : "")}
              onChange={handleChange}
              onKeyUp={validateNumber}
              style={customStyle}
              disabled
              required={gst ? true : false}
            ></input>
            <input
              type="text"
              className="form-control me-2"
              id="formGroupExampleInput2"
              placeholder="Payable Amount*"
              min={0}
              name="payableAmount"
              value={userData.payableAmount}
              onChange={handleChange}
              onKeyUp={validateNumber}
              style={customStyle}
              disabled
              required
            ></input>
            <select
              className="form-select w-25 border mx-auto text-center campaignScroll"
              aria-label="Default select example"
              style={customStyle}
              name="percentage"
              onChange={handleCalcPercentage}
              required
            >
              <option selected value="">
                %
              </option>
              {userData.username &&
                _.range(1, parseInt(userData.percentage) + 1).map(
                  (value, index) => (
                    <option value={value} key={index} style={{ color: localStorage.getItem('theme') === 'dark' ? 'lightgray' : "#212529", backgroundColor: localStorage.getItem('theme') === 'dark' ? '#212529' : "lightgray" }}>{`${value}%`}</option>
                  )
                )}
            </select>
          </div>
        </div>
      </div>
      <div className="text-center my-4">
        <button
          type="submit"
          className="btn btn-secondary col-xl-3 col-lg-4 col-md-4 col-sm-4 col-5 me-2"
          onClick={() => setReloadPage(true)}
        >
          Add Another
        </button>
        <button
          type="submit"
          className="btn btn-secondary col-xl-2 col-lg-4 col-md-4 col-sm-4 col-5"
          disabled={isClicked}
        >
          Submit
        </button>
        <small
          id="id_fail"
          style={{ display: "none", fontSize: "13px" }}
          className="text-danger my-4"
        >
          Failure...
        </small>
      </div>
    </form>
  );
}

export default InfluencerForm;
