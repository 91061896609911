import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import Register from "./components/register/register";
import Login from "./layouts/Index";
import Dashboard from "./components/dashboard/dashboard";
import Profile from "./components/profile/profile";
import Influencers from "./components/influencers/influencers";
import SearchCategories from "./layouts/SearchCategories";
import SearchInfluencer from "./layouts/SearchInfluencers";
import Influencer from "./components/common/influencer/influencer";
import Stats from "./layouts/Stats";
import Role from "./layouts/manage/Role";
import AddUser from "./layouts/manage/AddUser";
import PaymentLink from "./layouts/admin/paymentlink/Payments";
import PaymentDashboard from "./layouts/admin/paymentlink/PaymentDashboard";
import PaymentCampaignPending from "./layouts/admin/paymentlink/paymentCampaign/PaymentCampaignPending";
import PaymentCampaignCompleted from "./layouts/admin/paymentlink/paymentCampaign/PaymentCampaignCompleted";
import InfluencerPaymentInfo from "./layouts/admin/paymentinfo/PaymentInfo";
import CompanyPaymentInfo from "./layouts/admin/paymentinfo/CompanyInfo";
import PaymentConfirmation from "./layouts/admin/paymentconfirmation/PaymentConfirmation";
import SearchInfluencerByCity from "./layouts/SearchInfluencerByCity";
import EmailScraping from "./layouts/admin/emailScraping/EmailScraping";
import Hashtag from "./layouts/admin/emailScraping/hashTags/hashTagReport";
import CampaignInfo from "./layouts/admin/campaignDetails/CampaignInfo";
import CampaignReport from "./components/payment/campaignDetails/campaignReport";
import PaymentStatus from "./layouts/admin/paymentlink/PaymentStatus";
import Expense from "./layouts/admin/paymentlink/Expense";

function App() {
  return (
    <>
      <Router>
        <Switch>
          {/* <Route path="/register" exact component={Register} /> */}
          <Route
            path="/admin/payment/dashboard/home"
            exact
            component={PaymentDashboard}
          />
          <Route path="/dashboard" exact component={Dashboard} />
          {/* <Route path="/filter" exact component={Filter} /> */}
          {/* Filter Influencers */}
          <Route path="/filter/influencers" exact component={Influencers} />
          {/* End Filter Influencers */}
          <Route path="/categories/:name" exact component={SearchCategories} />
          <Route path="/city/:name" exact component={SearchInfluencerByCity} />
          <Route path="/influencers/:name" exact component={SearchInfluencer} />
          <Route path="/profile/:id/:isCompany" exact component={Profile} />
          <Route path="/influencer" exact component={Influencer} />
          {/* <Route path="/emailInfluencer" exact component={EmailInfluencer} /> */}
          <Route path="/admin/stats" exact component={Stats} />
          <Route path="/admin/manage/role" exact component={Role} />
          <Route path="/admin/emailscraping/" exact component={EmailScraping} />
          <Route
            path="/admin/hashtag/report/:tagname"
            exact
            component={Hashtag}
          />
          <Route path="/admin/manage/adduser" exact component={AddUser} />
          <Route path="/admin/payment/link" exact component={PaymentLink} />
          <Route path="/admin/expense/link" exact component={Expense} />
          <Route
            path="/admin/payment/dashboard/home"
            exact
            component={PaymentDashboard}
          />
          <Route
            path="/admin/payment/campaign"
            exact
            component={CampaignInfo}
          />
          <Route
            path="/admin/payment/campaign/report/:id"
            exact
            component={CampaignReport}
          />
          <Route
            path="/admin/payment/dashboard/campaigns/pending"
            exact
            component={PaymentCampaignPending}
          />
          <Route
            path="/admin/payment/dashboard/campaigns/completed"
            exact
            component={PaymentCampaignCompleted}
          />
          <Route
            path="/influencer/payment/:pathname"
            exact
            component={InfluencerPaymentInfo}
          />
          <Route
            path="/company/payment/:pathname"
            exact
            component={CompanyPaymentInfo}
          />
          <Route
            path="/admin/payment/confirmation"
            exact
            component={PaymentConfirmation}
          />
          <Route path="/admin/payment/status" exact component={PaymentStatus} />
          <Route path="/" exact component={Login} />
          <Route path="*" exact component={Login} />
        </Switch>
      </Router>
    </>
  );
}

export default App;
