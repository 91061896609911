import http from "./httpservices";

export async function getInfluencerCost(id) {
  
  const { data } = await http.post("influencercost/byid", id);
  return data;
}

export default {
    getInfluencerCost,
};
