import { ListGroup, ListGroupItem, Spinner, Badge } from "react-bootstrap";
import IdToDate from "../../../admin/common/IdToDate";
import LatestCampPlaceHolder from "../../../placeholders/latestCampPlaceHolder";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHandshakeSimple,
  faHandshakeSlash,
  faUser,
} from "@fortawesome/free-solid-svg-icons";

function RightSidebar({
  loading,
  campaigns,
  customStyle,
  handleCampaignDetails,
}) {
  return (
    <div className="col-xl-3 col-lg-4 col-mg-4 col-sm-4 mt-3" style={{height: "100vh"}} >
      <ListGroup as="ul">
        <ListGroup.Item
          as="li"
          className="d-flex justify-content-between align-items-start active fw-bold"
        >
          Latest Campaigns
        </ListGroup.Item>
        {loading ? (
          <LatestCampPlaceHolder />
        ) : (
          campaigns &&
          campaigns.map((campaign, index) => (
            <ListGroup.Item
              as="li"
              className="d-flex justify-content-between align-items-start"
              style={{ cursor: "pointer" }}
              onClick={() => handleCampaignDetails(campaign)}
            >
              <div className="ms-2 me-auto">
                <div className="fw-bold" style={{ fontSize: "14px" }}>
                  <FontAwesomeIcon icon={faHandshakeSimple} /> {campaign.name}
                </div>
                <div style={{ fontSize: "14px" }}>
                  <FontAwesomeIcon icon={faUser} />{" "}
                  {campaign.user && campaign.user[0]}
                </div>
              </div>
              <IdToDate id={campaign._id} />
            </ListGroup.Item>
          ))
        )}

        <li className="list-group-item list-group-item-action text-capitalize ">
          <button
            className="btn btn-warning btn-sm"
            type="button"
            onClick={() =>
              (window.location.href =
                "/admin/payment/dashboard/campaigns/pending")
            }
          >
            View More
          </button>
        </li>
      </ListGroup>
    </div>
  );
}

export default RightSidebar;
