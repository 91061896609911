import React from "react";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCloud,
  faArrowLeft,
  faUserGroup,
  faChalkboardUser,
  faFileInvoiceDollar,
  faTruckLoading,
  faRepeat,
  faSpinner,
  faCheck,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import { Spinner, Table, Tabs, Tab } from "react-bootstrap";
import SlideMenu from "../common/slideMenu";
import Footer from "../../common/footer/footer";
import Header from "../../common/header/header";
import IdToDate from "../../../admin/common/IdToDate";
import campaignservice from "../../../services/campaignservice";

export default function CampaignReport({ match }) {
  const history = useHistory();
  const [campaign, setCampaign] = useState([]);
  const [loading, setLoading] = useState(true);
  const [toggleTabs, setToggleTabs] = useState(false);
  const [posts, setPosts] = useState({});

  const showAddPost = async (id) => {
    const { value: text } = await Swal.fire({
      input: "textarea",
      title: "<h4>Add the links in below format</h4>",
      html: `<ul class="text-start">
      <li>https://instagram.com/p/CLlfKZYA2FA/</li>
      <li>https://www.instagram.com/reel/CLlfKZYA2FA/</li>
      <li>https://www.instagram.com/tv/CLlfKZYA2FA/</li>
      </ul>`,
      inputPlaceholder: "Enter Post Links...",
      inputAttributes: {
        "aria-label": "Enter Post Links...",
      },
      showCancelButton: true,
      confirmButtonText: "Submit",
      confirmButtonColor: "green",
    });

    if (text) {
      Swal.fire(text);
      const linkRegex =
        /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com|instagram\.com)\/.*(?:watch|shorts|reel|tv|p).*\/?$/;
      const links = text.trim().split("\n");
      let finalLinks;
      for (const link of links) {
        if (linkRegex.test(link)) {
          finalLinks = finalLinks ? finalLinks.concat("\n" + link) : link;
        } else {
          Swal.fire({
            position: "center",
            icon: "error",
            title: `${link} is not a valid link.`,
            showConfirmButton: true,
          });
          return;
        }
      }
      await campaignservice.updateCampaignPost({
        id: id,
        post: finalLinks,
      });
      window.location.reload();
    }
  };

  function determinePlatform(link) {
    if (link.includes("instagram")) {
      return "instagram";
    } else if (link.includes("youtube")) {
      return "youtube";
    } else {
      return "unknown";
    }
  }

  const handleClick = (key) => {
    if (key === "1") {
      setToggleTabs(false);
    } else {
      setToggleTabs(true);
    }
  };

  const getInit = async () => {
    const id = match.params["id"];
    const camp = await campaignservice.getCampaignById({ id: id });
    if (camp.posts?.length > 0) {
      const categorizedData = {};
      camp.posts.forEach((item) => {
        const platform = determinePlatform(item.link);

        if (!categorizedData[platform]) {
          categorizedData[platform] = [];
        }

        categorizedData[platform].push(item);
      });
      setPosts(categorizedData);
    }
    setCampaign(camp);
    setLoading(false);
  };

  useEffect(() => {
    getInit();
  }, []);

  return (
    <>
      <Header />
      <div className="container-fluid">
        <div className="row">
          <SlideMenu />
          <div
            className="col-xl-12 col-lg-12 col-md-12 col-sm-12 "
            style={{ marginBottom: "65px" }}
          >
            <nav className="navbar navbar-light ">
              <div>
                <a className="navbar-brand">
                  <FontAwesomeIcon
                    icon={faArrowLeft}
                    size={"sm"}
                    onClick={() => history.goBack()}
                    style={{ cursor: "pointer" }}
                  />
                </a>
                <small className="fs-5">{campaign.name}</small>
              </div>
              <button
                className="btn btn-sm btn-success"
                onClick={() => showAddPost(campaign._id)}
              >
                Add Post
              </button>
            </nav>
            <div className=" p-3">
              <h5 className="d-block">Campaign Overview</h5>
              <div className="d-flex">
                <div className="col p-3 me-1 ">
                  <div className="row">
                    <div className="col-2 my-auto">
                      <FontAwesomeIcon icon={faUserGroup} className="fs-1" />
                    </div>
                    <div className="col-10 d-grid gap-2">
                      <p className="fw-bold fs-5 m-0">10</p>
                      <h6 className="m-0">Influencers Live</h6>
                    </div>
                  </div>
                </div>
                <div className="col p-3 me-1 ">
                  <div className="row">
                    <div className="col-2 my-auto">
                      <FontAwesomeIcon icon={faCloud} className="fs-1" />
                    </div>
                    <div className="col-10 d-grid gap-2">
                      <p className="fw-bold fs-5 m-0">
                        {campaign && campaign.posts ? campaign.posts.length : 0}
                      </p>
                      <h6 className="m-0">Posts Live</h6>
                    </div>
                  </div>
                </div>
                <div className="col p-3 me-1 ">
                  <div className="row">
                    <div className="col-2 my-auto">
                      <FontAwesomeIcon
                        icon={faChalkboardUser}
                        className="fs-1"
                      />
                    </div>
                    <div className="col-10 d-grid gap-2">
                      <p className="fw-bold fs-5 m-0">
                        {campaign.totalPayment ? campaign.totalPayment : 0}
                      </p>
                      <h6 className="m-0">Campaign Budget</h6>
                    </div>
                  </div>
                </div>
                <div className="col p-3 ">
                  <div className="row">
                    <div className="col-2 my-auto">
                      <FontAwesomeIcon
                        icon={faFileInvoiceDollar}
                        className="fs-1"
                      />
                    </div>
                    <div className="col-10 d-grid gap-2">
                      <p className="fw-bold fs-5 m-0">
                        {campaign.paymentLimit && campaign.totalPayment
                          ? parseInt(campaign.totalPayment) -
                            parseInt(campaign.paymentLimit)
                          : 0}
                      </p>
                      <h6 className="m-0">Budget Spent</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br />
            {/* table */}
            <div
              className=" p-2 shadow rounded-3 w-75 mx-auto mt-2"
              style={{ fontSize: "12px" }}
            >
              {loading ? (
                <center>
                  <Spinner animation="border" />
                </center>
              ) : !campaign.posts ? (
                <center>
                  <b className="text-danger">No Posts</b>
                </center>
              ) : (
                <Tabs
                  defaultActiveKey={1}
                  id="uncontrolled-tab-example"
                  className="mb-3"
                  onSelect={handleClick}
                  style={{ fontSize: "14px" }}
                >
                  {Object.keys(posts).map((campaign, index) => (
                    <Tab eventKey={index + 1} title={Object.keys(posts)[index]}>
                      <Table hover size="sm">
                        <thead>
                          <tr>
                            <th scope="col">
                              <small className="">#</small>
                            </th>
                            <th scope="col">
                              <small className="">Date</small>
                            </th>
                            <th scope="col">
                              <small className="">Platform</small>
                            </th>
                            <th scope="col">
                              <small className="">Post</small>
                            </th>
                            <th scope="col">
                              <small className="">Likes</small>
                            </th>
                            <th scope="col">
                              <small className="">Views</small>
                            </th>
                            <th scope="col">
                              <small className="">Reshare</small>
                            </th>
                            <th scope="col">
                              <small className="">Comments</small>
                            </th>
                            <th scope="col">
                              <small className="">Last Update</small>
                            </th>
                          </tr>
                        </thead>
                        <tbody className="mx-auto my-0">
                          {loading ? (
                            <tr>
                              <td colSpan={8}>
                                <center>
                                  <Spinner animation="border" />
                                </center>
                              </td>
                            </tr>
                          ) : (
                            posts[campaign] &&
                            // campaign.posts &&
                            posts[campaign].map((post, index) => (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>
                                  <IdToDate id={match.params["id"]} />
                                </td>
                                <td>{post.platform ? post.platform : "NA"}</td>
                                <td>{post.link}</td>
                                <td>{post.like_count}</td>
                                <td>{post.views}</td>
                                <td>{post.reshare_count}</td>
                                <td>{post.comment_count}</td>
                                <td>
                                  {new Date(
                                    parseInt(post.timeStamp)
                                  ).toDateString()}
                                </td>
                                <td>
                                  {post.apiHit ? (
                                    <FontAwesomeIcon
                                      icon={faCheckCircle}
                                      color="green"
                                    />
                                  ) : (
                                    <FontAwesomeIcon
                                      icon={faSpinner}
                                      color="red"
                                      spin
                                    />
                                  )}
                                </td>
                              </tr>
                            ))
                          )}
                        </tbody>
                      </Table>
                    </Tab>
                  ))}
                </Tabs>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
