import http from "./httpservices";
// import auth from "./authService";

export async function getLimitedInfluencers() {
  const { data } = await http.get("influencer/showinfluencerlimit");
  return data;
}

export async function getInfluencerByUsername(username) {
  const { data } = await http.get(`influencer/profile/${username}`);
  return data;
}

export async function getInfluencerDetailById(id) {
  const { data } = await http.get(`influencer/profile/detail/${id}`);
  return data;
}

export async function getInfluencerOverviewById(params) {
  const { data } = await http.post("influencer/overview", params);
  return data;
}

export async function getSearchInfluencers(name) {
  const { data } = await http.get(`influencer/${name}`);
  return data;
}

export async function getInfluencersCount() {
  const { data } = await http.get(`influencer/influencerscount`);
  return data;
}

export async function getInfluencers(page = 1) {
  const { data } = await http.get(`influencer/?page=${page}`);
  return data;
}

export async function getAllInfluencers() {
  const { data } = await http.get("influencer/all");
  return data;
}

export async function findByUserName(search) {
  const { data } = await http.post("influencer/findbyusername", {
    username: search,
  });
  return data;
}

export async function addFilteredInfluencers(params) {
  const { data } = await http.post("/influencer/filter", params);
  return data;
}

export async function addNewInfluencers(params) {
  const { data } = await http.post("influencer/", params);
  return data;
}

export async function addInfluencerCost(params) {
  const { data } = await http.post("influencercost", params);
  return data;
}

export async function getInfluencerDataByUsername(value) {
  const { data } = await http.post("influencer/byusername", { value: value });
  return data;
}

export async function getInfluencerDetails(params) {
  const { data } = await http.post("influencer/detail/status", params);
  return data;
}

export async function getSearchInfluencer(params) {
  const { data } = await http.post("influencer/search", params);
  return data;
}

export async function getAllStatesAndCities() {
  const { data } = await http.post("influencer/statesandcities");
  return data;
}

export default {
  getInfluencers,
  getLimitedInfluencers,
  getSearchInfluencers,
  getInfluencerByUsername,
  getInfluencerDetailById,
  getInfluencerOverviewById,
  getInfluencerDataByUsername,
  getInfluencersCount,
  addNewInfluencers,
  addFilteredInfluencers,
  addInfluencerCost,
  getAllInfluencers,
  findByUserName,
  getInfluencerDetails,
  getSearchInfluencer,
  getAllStatesAndCities,
};
