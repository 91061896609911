import React from "react";
import _ from "lodash";
import { useState, useEffect } from "react";
import companyservice from "../../../../services/companyservice";
import Brand from "../../../common/select/brand";

function CompanyForm({
  isClicked,
  onSubmit,
  form,
  userData,
  gst,
  handleGSTChange,
  customStyle,
  handleChange,
  validateNumber,
  campaignDetails,
  handleVendorChange,
  handleCalcPercentage,
  paymentLinkToggle,
  customStyles,
  setReloadPage
}) {
  const [companyData, setCompanyData] = useState([]);
  const [isCost, setCost] = useState(false);

  const getInit = async () => {
    const companies = await companyservice.getCompanies();
    setCompanyData(companies);
  };

  const Init = async () => {
    if (userData.cost && userData.isGstPaid) setCost(true);
  };

  useEffect(() => {
    getInit();
  }, []);

  useEffect(() => {
    Init();
  }, [userData.company.name]);

  return (
    <form onSubmit={onSubmit} ref={form}>
      <div className="d-flex justify-content-between">
        <div className=" me-2 w-50">
          <Brand
            loading={false}
            userData={userData}
            brand={companyData}
            customStyles={customStyles}
            handleChange={handleVendorChange}
            title={"Vendor"}
            value={userData.company.name}
          />
        </div>
        <input
          type="text"
          className="form-control w-50"
          id="formGroupExampleInput"
          placeholder="Instagram Username*"
          name="username"
          value={campaignDetails.name}
          style={customStyle}
          disabled
        ></input>
      </div>
      <hr />
      <div className="input-group">
        <textarea
          className="form-control"
          aria-label="With textarea"
          style={{ resize: "none", fontSize: "13px", height: "100px" }}
          placeholder="Description*"
          name="description"
          onChange={handleChange}
          value={userData.description}
          required
        ></textarea>
      </div>
      <hr />
      <input
        type="text"
        className="form-control me-2"
        id="formGroupExampleInput2"
        placeholder="Type of Expenses (If Applicable)"
        name="expensesCategory"
        value={userData.expensesCategory}
        onChange={handleChange}
        style={customStyle}
      ></input>
      <hr />
      <div
        className="form-check mb-2 d-flex justify-content-center"
        style={customStyle}
      >
        <input
          className="form-check-input"
          type="checkbox"
          id="formCheckGst"
          onClick={handleGSTChange}
          checked={userData.isGstPaid && userData.percentage < 100 ? true : undefined}
          disabled={userData.isGstPaid && userData.percentage < 100}
          style={{ cursor: (isCost && userData.percentage < 100) ? 'default' : 'pointer' }}
        />
        &nbsp;
        <label
          className="form-check-label"
          htmlFor="formCheckGst"
          style={{ cursor: (isCost && userData.percentage < 100) ? 'default' : 'pointer' }}
        >
          GST (If Applicable)
        </label>
      </div>
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <div className="d-flex justify-content-around">
            <input
              type="text"
              className="form-control me-2"
              id="formGroupExampleInput"
              placeholder={
                gst
                  ? 0
                  : `Total Amount* Your Limit is ${campaignDetails.paymentLimit} Rs`
              }
              min={0}
              name="cost"
              value={userData.cost}
              onChange={handleChange}
              onKeyUp={validateNumber}
              style={customStyle}
              disabled={isCost}
              required={!gst ? true : false}
            ></input>
            <input
              type="text"
              className="form-control me-2"
              id="formGroupExampleInput"
              placeholder={gst ? "GST Amount*" : "GST Amount"}
              min={0}
              name="gstAmount"
              value={(!gst ? "" : userData.gstAmount) || (isCost ? parseInt(parseInt(userData.cost) * 0.18) : "")}
              onChange={handleChange}
              onKeyUp={validateNumber}
              style={customStyle}
              disabled
              required={gst ? true : false}
            ></input>
            <input
              type="text"
              className="form-control me-2"
              id="formGroupExampleInput2"
              placeholder="Payable Amount*"
              min={0}
              name="payableAmount"
              value={userData.payableAmount}
              onChange={handleChange}
              onKeyUp={validateNumber}
              style={customStyle}
              disabled
              required
            ></input>
            <select
              className="form-select w-25 border mx-auto text-center campaignScroll"
              aria-label="Default select example"
              style={customStyle}
              name="percentage"
              onChange={handleCalcPercentage}
              required
            >
              <option selected value="">
                %
              </option>
              {paymentLinkToggle &&
                _.range(1, parseInt(userData.percentage) + 1).map(
                  (value, index) => (
                    <option value={value} key={index} style={{ color: localStorage.getItem('theme') === 'dark' ? 'lightgray' : "#212529", backgroundColor: localStorage.getItem('theme') === 'dark' ? '#212529' : "lightgray" }}>{`${value}%`}</option>
                  )
                )}
            </select>
          </div>
        </div>
      </div>
      <div className="text-center my-4">
        <button
          type="submit"
          className="btn btn-secondary col-xl-3 col-lg-4 col-md-4 col-sm-4 col-5 me-2"
          onClick={() => setReloadPage(true)}
        >
          Add Another
        </button>
        <button
          type="submit"
          className="btn btn-secondary col-xl-2 col-lg-4 col-md-4 col-sm-4 col-5"
          disabled={isClicked}
        >
          Submit
        </button>
        <small
          id="id_fail"
          style={{ display: "none", fontSize: "13px" }}
          className="text-danger my-4"
        >
          Failure...
        </small>
      </div>
    </form>
  );
}

export default CompanyForm;
