import http from "./httpservices";


export async function getFacebookInfo(params) {
  const { data } = await http.post("fb/generate/payment/link", params);
  return data;
}

export default {
  FacebookInfo: getFacebookInfo,
};
