import http from "./httpservices";

export async function DashboardStats() {
  const { data } = await http.get("dashboard/");
  return data;
}

export default {
    DashboardStats,
};
