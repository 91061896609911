import React, { useEffect, useState } from "react";
import { Badge, Spinner, Table } from "react-bootstrap";
import Swal from "sweetalert2";
import bankservice from "../../../services/bankservice";
import paymentlinkservice from "../../../services/paymentlinkservice";
import stateservice from "../../../services/stateservice";
import BankPopup from "../../common/bankPopup/bankPopup";
import "../paymentInfo/css/paymentInfo.css";

function PaymentForm({
  loading,
  handleChange,
  paymentInfo,
  handlePaymentClick,
  togglePaymentOption,
  setTogglePaymentOption,
  userData,
  setUserData,
  handleBankDetailActive,
  isPancardVerify,
  loadingPancardVerify
}) {
  const borderStyle = { borderRadius: "0.5rem" };
  const customStyle = { fontSize: "13px" };
  const [file, setFile] = useState("");
  const [pancardFile, setPancardFile] = useState({});
  const [bankDetails, setBankDetails] = useState([]);
  const [statesData, setStatesData] = useState([]);
  const [influencerDetails, setInfluencerDetails] = useState([]);
  const [isEmpty, setIsEmpty] = useState(false);
  const [visible, setVisible] = useState(false);
  const [isInvoice, setInvoice] = useState(false);


  const handleUpload = (e) => {
    if (!paymentInfo.isGst) setInvoice(true);
    setFile(e.target.files[0]);
  };

  const handlePancardUpload = (e) => {
    setIsEmpty(true);
    setPancardFile(e.target.files[0]);
  };

  const handleAddBankDetails = async (e) => {
    e.preventDefault();
    Swal.fire({
      title: "Loading...",
      showConfirmButton: false,
      timer: 1500,
    });

    userData["influencer"] = paymentInfo.influencer._id;

    const response = await bankservice.addNewPaymentDetails(userData);

    if (response.status === 201) {
      Swal.fire({
        icon: "error",
        title: response.data,
        showConfirmButton: false,
        timer: 3000,
      });
      return;
    }

    if (isEmpty) {
      const pancardFormData = new FormData();
      pancardFormData.append("file", pancardFile);
      pancardFormData.append("id", paymentInfo._id);
      await paymentlinkservice.getUploadPancard(pancardFormData);
    }

    Swal.close();
    Swal.fire({
      icon: "success",
      title: "Success",
      showConfirmButton: false,
      timer: 1500,
    });

    window.location.reload();
  };

  const handlePermission = async () => {
    const response = await bankservice.influencerBankDetails({
      id: paymentInfo.influencer._id,
      isCompany: false,
    });

    if (response.status === 201) {
      Swal.fire({
        icon: "error",
        title: response.data,
        showConfirmButton: false,
        timer: 3000,
      });
      return;
    }

    let bankDetails = response.data;

    const result = await Swal.fire({
      // title: "Bank Details",
      icon: "info",
      html: `<table className="table table-bordered">
        <tr>
          <td>Account Name</td>
          <td>${bankDetails.accountName}</td>
        </tr>
          <tr>
          <td>Account Number</td>
          <td>${bankDetails.accountNo}</td>
        </tr>
          <tr>
          <td>IFSC Code</td>
          <td>${bankDetails.ifscCode}</td>
        </tr>
        <tr>
        <td>Pancard</td>
        <td>${bankDetails.pancard}</td>
      </table>
      <br>
      <small><b className="text-danger">Note</b>:Here is the final information regarding your Bank Account. Please edit your entry if necessary before we finalize it.</small>
      `,
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: "Submit",
      confirmButtonAriaLabel: "Thumbs up, great!",
      cancelButtonText: "Cancel",
      cancelButtonAriaLabel: "Thumbs down",
    });

    if (result.isConfirmed) {
      userData["influencer"] = paymentInfo.influencer._id;
      userData["paymentlink"] = paymentInfo._id;

      if (paymentInfo.influencer.email)
        userData["email"] = paymentInfo.influencer.email;

      if (paymentInfo.influencer.number)
        userData["number"] = paymentInfo.influencer.number;

      if (paymentInfo.influencer.dob)
        userData["dob"] = paymentInfo.influencer.dob;

      if (paymentInfo.influencer.state)
        userData["state"] = paymentInfo.influencer.state;

      try {
        Swal.fire({
          title: "Loading...",
          showConfirmButton: false,
          timer: 1500,
        });

        const response = await paymentlinkservice.addInfluencerData(userData);

        if (file) {
          const formData = new FormData();
          formData.append("file", file);
          formData.append("id", paymentInfo._id);
          await paymentlinkservice.getUploadInvoice(formData);
        }

        if (response.status === 201) {
          Swal.fire({
            icon: "error",
            title: response.data,
            showConfirmButton: false,
            timer: 3000,
          });
          return;
        }
        Swal.close();
        Swal.fire({
          icon: "success",
          title: "Success",
          showConfirmButton: false,
          timer: 1500,
        });
        window.location.reload();
      } catch (error) {
        document.getElementById("id_fail").style.display = "block";
        Swal.close();
        Swal.fire({
          icon: "error",
          title: "Error",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    handlePermission();
  };

  const getInit = async () => {
    const id = paymentInfo.influencer._id;
    const response = await bankservice.getAllInfluencerBankDetails(id);
    setBankDetails(response);
    if (response) {
      setUserData((prevState) => ({
        ...prevState,
        ["pancard"]: response.pancard,
      }));
    }

    const _influencerDetails = await paymentlinkservice.getInfluencerDetails({
      influencer: paymentInfo.influencer._id,
      campaign: paymentInfo.campaign._id,
      pathName: paymentInfo.pathName,
    });
    setInfluencerDetails(_influencerDetails);

    const statesResp = await stateservice.getStatesByName();
    setStatesData(statesResp);
  };

  const btnShow = () => {
    setVisible(true);
    if (togglePaymentOption) setTogglePaymentOption(false);
  };

  useEffect(() => {
    getInit();
  }, []);

  return (
    <div
      className="container-fluid"
      style={{ backgroundColor: "#E9EBEE", minHeight: "100vh", height: "auto" }}
    >
      <h4
        className="text-center py-4"
        style={{ color: "#023430", fontWeight: 900 }}
      >
        Influencer Payment
      </h4>
      <div className="row ">
        <div
          className="col-xl-12 col-lg-12 col-md-12 col-sm-12 mx-auto"
          style={{ ...borderStyle, width: "85%" }}
        >
          <form onSubmit={onSubmit} id="influence-payment-form-id">
            <div className="rounded-3 mb-5" id="tableSection">
              <Table>
                <thead>
                  <tr>
                    <th scope="col">
                      <small>Campaign</small>
                    </th>
                    <th scope="col">
                      <small>Platform</small>
                    </th>
                    <th scope="col">
                      <small>Influencer</small>
                    </th>
                    <th scope="col">
                      <small>Deliverables</small>
                    </th>
                    <th scope="col">
                      <small>POC</small>
                    </th>
                    <th scope="col">
                      <small>GST Amount</small>
                    </th>
                    <th scope="col">
                      <small>Total Amount</small>
                    </th>
                    <th scope="col">
                      <small>Payable Amount</small>
                    </th>
                    <th scope="col">
                      <small>Percentage</small>
                    </th>
                    <th scope="col">
                      <small>Status</small>
                    </th>
                  </tr>
                </thead>
                <tbody className="mx-auto my-0">
                  {loading ? (
                    <tr>
                      <td colSpan={8}>
                        <center>
                          <Spinner animation="border" />
                        </center>
                      </td>
                    </tr>
                  ) : (
                    influencerDetails &&
                    influencerDetails.map((influencer, index) => (
                      <tr key={index}>
                        <td style={customStyle}>{influencer.campaign.name}</td>
                        <td style={customStyle}>
                          <Badge bg="info">
                            {influencer.platform.map((p) => p.name + " ")}
                          </Badge>
                        </td>
                        <td style={customStyle}>
                          {influencer.influencer &&
                            influencer.influencer.username
                            ? influencer.influencer.username
                            : "NA"}
                        </td>
                        <td style={customStyle}>{influencer.deliverables}</td>
                        <td style={customStyle}>
                          {influencer.createdby &&
                            influencer.createdby.fullName}
                        </td>
                        <td style={customStyle}>
                          {influencer.gstAmount ? (
                            <Badge bg="success">
                              &#8377;{influencer.gstAmount}
                            </Badge>
                          ) : (
                            "---"
                          )}
                        </td>
                        <td style={customStyle}>
                          {influencer.cost ? (
                            <Badge bg="success">
                              &#8377;{influencer.cost}
                            </Badge>
                          ) : (
                            "---"
                          )}
                        </td>
                        <td style={customStyle}>
                          {influencer.payableAmount ? (
                            <Badge bg="warning" className="text-dark">
                              &#8377;{influencer.payableAmount}
                            </Badge>
                          ) : (
                            "---"
                          )}
                        </td>
                        <td style={customStyle}>{influencer.percentage}%</td>
                        <td style={customStyle}>
                          <Badge
                            bg={
                              influencer.status == "P" ? "primary" : "success"
                            }
                          >
                            {influencer.status === "P"
                              ? "Processing"
                              : "Success"}
                          </Badge>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </Table>
            </div>
            <div className="row">
              <div className="col-xl-4 left-data">
                <h6 className="text-center mb-4" style={{ color: "#606060" }}>
                  <span
                    className="rounded-pill px-4 py-2"
                    style={{ backgroundColor: "#023430", color: "white" }}
                  >
                    Personal
                  </span>
                </h6>
                <div style={{ backgroundColor: "white", padding: 10 }}>
                  <div className="row">
                    <div className="col">
                      <small>
                        <strong>Email*</strong>
                      </small>
                      {paymentInfo.influencer &&
                        paymentInfo.influencer.email ? (
                        <p style={customStyle}>
                          {paymentInfo.influencer.email}
                        </p>
                      ) : (
                        <input
                          type="email"
                          className="form-control"
                          id="user_email"
                          placeholder="Enter Email"
                          name="email"
                          value={userData.email}
                          onChange={handleChange}
                          style={customStyle}
                          required
                        ></input>
                      )}
                    </div>
                    <div className="col">
                      <small>
                        <strong>Phone*</strong>
                      </small>
                      {paymentInfo.influencer &&
                        paymentInfo.influencer.number ? (
                        <p style={customStyle}>
                          {paymentInfo.influencer.number}
                        </p>
                      ) : (
                        <input
                          type="text"
                          className="form-control"
                          id="user_phone"
                          placeholder="Enter Phone Number"
                          name="number"
                          value={userData.number}
                          style={customStyle}
                          onChange={handleChange}
                          maxLength="10"
                          pattern="[0-9]{10}"
                          title="Enter only Numerical Letters"
                          required
                        ></input>
                      )}
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col">
                      <small>
                        <strong>DOB*</strong>
                      </small>
                      {paymentInfo.influencer && paymentInfo.influencer.dob ? (
                        <p style={customStyle}>{paymentInfo.influencer.dob}</p>
                      ) : (
                        <input
                          type="date"
                          className="form-control "
                          id="user_dob"
                          name="dob"
                          value={userData.dob}
                          style={customStyle}
                          onChange={handleChange}
                          required
                        ></input>
                      )}
                    </div>
                    <div className="col">
                      <small>
                        <strong>State*</strong>
                      </small>
                      {paymentInfo.influencer &&
                        paymentInfo.influencer.state ? (
                        <p style={customStyle}>
                          {paymentInfo.influencer.state}
                        </p>
                      ) : (
                        <select
                          className="form-select w-100 border mx-auto scroll"
                          aria-label="Default select example"
                          onChange={handleChange}
                          style={customStyle}
                          name="state"
                          required
                        >
                          <option selected>Select State</option>
                          {statesData.map((s) => (
                            <option value={s.name} key={s.id}>
                              {s.name}
                            </option>
                          ))}
                        </select>
                      )}
                    </div>
                    <div>
                      {paymentInfo.isGst ? (
                        <>
                          <hr />
                          <div className="col">
                            <small>
                              <strong>Upload Invoice*</strong>
                            </small>
                            {paymentInfo.invoice ? (
                              <p style={customStyle}>{paymentInfo.invoice}</p>
                            ) : (
                              <input
                                type="file"
                                className="form-control"
                                id="formGroupExampleInput"
                                placeholder="Upload Your Invoice"
                                onChange={handleUpload}
                                style={customStyle}
                                title="Choose only '.pdf' file to Upload"
                                accept={".pdf"}
                                required
                              ></input>
                            )}
                          </div>
                          <hr />
                          <div className="row">
                            <div className="col">
                              <small>
                                <strong>Invoice Number*</strong>
                              </small>
                              {paymentInfo.invoiceNumber ? (
                                <p style={customStyle}>
                                  {paymentInfo.invoiceNumber}
                                </p>
                              ) : (
                                <input
                                  type="text"
                                  className="form-control"
                                  id="user_invoice_number"
                                  placeholder="Enter Invoice Number"
                                  name="invoiceNumber"
                                  value={userData.invoiceNumber}
                                  style={customStyle}
                                  onChange={handleChange}
                                  required
                                ></input>
                              )}
                            </div>
                            <div className="col">
                              <small>
                                <strong>Invoice Date*</strong>
                              </small>
                              {paymentInfo.invoiceDate ? (
                                <p style={customStyle}>
                                  {paymentInfo.invoiceDate}
                                </p>
                              ) : (
                                <input
                                  type="date"
                                  className="form-control"
                                  id="user_invoice_date"
                                  placeholder="Enter Invoice Date"
                                  name="invoiceDate"
                                  value={userData.invoiceDate}
                                  style={customStyle}
                                  onChange={handleChange}
                                  required
                                ></input>
                              )}
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <hr />
                          <div className="col">
                            <small>
                              <strong>Upload Invoice (Optional)</strong>
                            </small>
                            {paymentInfo.invoice ? (
                              <p style={customStyle}>{paymentInfo.invoice}</p>
                            ) : (
                              <input
                                type="file"
                                className="form-control"
                                id="formGroupExampleInput"
                                placeholder="Upload Your Invoice"
                                onChange={handleUpload}
                                style={customStyle}
                                title="Choose only '.pdf' file to Upload"
                                accept={".pdf"}
                                required={isInvoice}
                              ></input>
                            )}
                          </div>
                          <div className="row">
                            <div className="col">
                              <small>
                                <strong>Invoice Number (Optional)</strong>
                              </small>
                              {paymentInfo.invoiceNumber ? (
                                <p style={customStyle}>
                                  {paymentInfo.invoiceNumber}
                                </p>
                              ) : (
                                <input
                                  type="text"
                                  className="form-control"
                                  id="user_invoice_number"
                                  placeholder="Enter Invoice Number"
                                  name="invoiceNumber"
                                  value={userData.invoiceNumber}
                                  style={customStyle}
                                  onChange={handleChange}
                                  required={isInvoice}
                                ></input>
                              )}
                            </div>
                            <div className="col">
                              <small>
                                <strong>Invoice Date (Optional)</strong>
                              </small>
                              {paymentInfo.invoiceDate ? (
                                <p style={customStyle}>
                                  {paymentInfo.invoiceDate}
                                </p>
                              ) : (
                                <input
                                  type="date"
                                  className="form-control"
                                  id="user_invoice_date"
                                  placeholder="Enter Invoice Date"
                                  name="invoiceDate"
                                  value={userData.invoiceDate}
                                  style={customStyle}
                                  onChange={handleChange}
                                  required={isInvoice}
                                ></input>
                              )}
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-8 right-data text-center">
                <h6 className="text-center mb-4" style={{ color: "#606060" }}>
                  <span
                    className="rounded-pill px-4 py-2"
                    style={{ backgroundColor: "#023430", color: "white" }}
                  >
                    Bank
                  </span>
                </h6>
                <div className="container">
                  <div className="row">
                    {bankDetails ? (
                      bankDetails.details &&
                      bankDetails.details.map(
                        (detail, index) =>
                          detail.accountNo && (
                            <div
                              className={
                                detail.isActive
                                  ? "card  mx-auto col-xl-3 col-lg-4 col-md-6 col-sm-12 shadow-sm hoverStyle bankActive"
                                  : "card  mx-auto col-xl-3 col-lg-4 col-md-6 col-sm-12 shadow-sm hoverStyle"
                              }
                              id={detail.accountNo}
                              style={{ cursor: "pointer" }}
                              key={index}
                              onClick={() =>
                                handleBankDetailActive(
                                  bankDetails._id,
                                  detail.accountNo
                                )
                              }
                            >
                              <ul
                                className="list-group list-group-flush text-start"
                                style={customStyle}
                              >
                                <li className="list-group-item">
                                  <strong>Account Name</strong>
                                  <br />
                                  {detail.accountName
                                    ? detail.accountName
                                    : "NA"}
                                </li>
                                <li className="list-group-item">
                                  <strong>Account Number</strong>
                                  <br />
                                  {detail.accountNo ? detail.accountNo : "NA"}
                                </li>
                                <li className="list-group-item">
                                  <strong>IFSC Code</strong>
                                  <br />
                                  {detail.ifscCode ? detail.ifscCode : "NA"}
                                </li>
                                <li className="list-group-item">
                                  <strong>Pan Card</strong>
                                  <br />
                                  {detail.pancard ? detail.pancard : "NA"}
                                </li>
                              </ul>
                            </div>
                          )
                      )
                    ) : (
                      <div className="card">
                        <div className="card-body">
                          Sorry, It appears that we don't have your Bank
                          Details. Please click the below button to add your
                          details.
                        </div>
                      </div>
                    )}
                  </div>
                  <br />
                  <button
                    className="btn btn-success mt-3"
                    type="button"
                    onClick={btnShow}
                  >
                    Add Bank Details
                  </button>
                </div>
              </div>
            </div>
            <div className="text-center my-5">
              <button
                type="submit"
                className="btn btn-success w-25 rounded-pill"
                style={{ backgroundColor: "#023430", fontSize: "18px" }}
              >
                Confirm
              </button>
              <small
                id="id_fail"
                style={{ display: "none", fontSize: "13px" }}
                className="text-danger my-4"
              >
                Failure...
              </small>
            </div>
          </form>
          <BankPopup
            handlePaymentClick={handlePaymentClick}
            togglePaymentOption={togglePaymentOption}
            userData={userData}
            onSubmit={onSubmit}
            customStyle={customStyle}
            paymentInfo={paymentInfo}
            bankDetails={bankDetails}
            handleChange={handleChange}
            handleUpload={handleUpload}
            handlePancardUpload={handlePancardUpload}
            handleAddBankDetails={handleAddBankDetails}
            setVisible={setVisible}
            visible={visible}
            isPancardVerify={isPancardVerify}
            loadingPancardVerify={loadingPancardVerify}
          />
        </div>
      </div>
    </div>
  );
}

export default PaymentForm;
