import React from "react";
import { Placeholder } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleArrowDown } from "@fortawesome/free-solid-svg-icons";

function FinanceCampaignPlaceHolder(props) {
  const customHeadingStyle = { fontSize: "11px" };
  const CustomRowStyle = {
    fontSize: "11px",
    fontWeight: "500",
    cursor: "pointer",
  };
  return (
    <tbody className="mx-auto my-0">
      {_.range(1, 25).map((i) => (
        <tr key={i}>
          <td style={CustomRowStyle}>{i}</td>
          <td>
            <Placeholder as={Card.Text} animation="glow">
              <Placeholder xs={12} />
            </Placeholder>
          </td>
          <td>
            <Placeholder as={Card.Text} animation="glow">
              <Placeholder xs={10} />
            </Placeholder>
          </td>
          <td>
            <Placeholder as={Card.Text} animation="glow">
              <Placeholder xs={10} />
            </Placeholder>
          </td>
          <td style={{ fontSize: "10px" }} className="text-center">
            <FontAwesomeIcon icon={faCircleArrowDown} size="xl" className="" />
          </td>
        </tr>
      ))}
    </tbody>
  );
}

export default FinanceCampaignPlaceHolder;
