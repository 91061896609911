import React from "react";
import _ from "lodash";
import { useState, useEffect } from "react";
import companyservice from "../../../../services/companyservice";
import Brand from "../../../common/select/brand";

function ExpenseCompanyForm({
  onSubmit,
  form,
  userData,
  gst,
  handleGSTChange,
  customStyle,
  customStyles,
  handleChange,
  validateNumber,
  campaignDetails,
  handleVendorChange,
  handleCalcPercentage,
  paymentLinkToggle,
  handleInvoiceUpload,
  handleScreenshotUpload,
}) {
  const [companyData, setCompanyData] = useState([]);

  const getInit = async () => {
    const companies = await companyservice.getCompanies();
    setCompanyData(companies);
  };

  useEffect(() => {
    getInit();
  }, []);

  return (
    <form onSubmit={onSubmit} ref={form}>
      <div className="d-flex justify-content-between">
        <div className=" me-2 w-50">
          <Brand
            loading={false}
            userData={userData}
            brand={companyData}
            handleChange={handleVendorChange}
            title={"Vendor"}
            value={userData.company.name}
            customStyles={customStyles}
          />
        </div>
        <input
          type="text"
          className="form-control w-50 me-2"
          id="formGroupExampleInput"
          placeholder="Instagram Username*"
          name="username"
          value={campaignDetails.name}
          style={customStyle}
          disabled
        ></input>
      </div>
      <hr />
      <div className="input-group">
        <textarea
          className="form-control"
          aria-label="With textarea"
          style={{ resize: "none", fontSize: "13px", height: "100px" }}
          placeholder="Description*"
          name="description"
          onChange={handleChange}
          value={userData.description}
          required
        ></textarea>
      </div>
      <hr />
      <input
        type="text"
        className="form-control me-2"
        id="formGroupExampleInput2"
        placeholder="Type of Expenses (If Applicable)"
        name="expensesCategory"
        value={userData.expensesCategory}
        onChange={handleChange}
        style={customStyle}
      ></input>
      <hr />
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <div className="d-flex justify-content-around">
            <input
              type="text"
              className="form-control customRequired me-2"
              id="user_account_name"
              placeholder="Account Name*"
              name="accountName"
              value={userData.accountName}
              style={customStyle}
              onChange={handleChange}
              pattern="[a-zA-Z\s]*"
              title="Enter only Alphabet Characters"
              required={true}
            ></input>
            <input
              type="text"
              className="form-control customRequired me-2"
              id="user_account_number"
              placeholder="Account Number*"
              name="accountNo"
              value={userData.accountNo}
              style={customStyle}
              onChange={handleChange}
              maxLength="20"
              pattern="[0-9]*"
              title="Enter only Numerical Letters"
              required={true}
            ></input>
            <input
              type="text"
              className="form-control customRequired me-2"
              id="user_ifsc"
              placeholder="IFSC Code*"
              name="ifscCode"
              value={userData.ifscCode}
              style={customStyle}
              onChange={handleChange}
              maxLength="11"
              pattern="[A-Z]{4}0[A-Z0-9]{6}"
              title="Enter only '4' Uppercase Alphabet Characters, '0' Numerial Letter and '6' Uppercase Alphanumeric Letters"
              required={true}
            ></input>
            <input
              type="text"
              className="form-control customRequired"
              id="user_pancard"
              placeholder="Pan Card Number*"
              name="pancard"
              value={userData.pancard}
              style={customStyle}
              onChange={handleChange}
              maxLength="10"
              pattern="[A-Z]{5}[0-9]{4}[A-Z]{1}"
              title="Enter only '5' Uppercase Characters, '4' Numerical Letters and '1' Uppercase Character"
              required={true}
            ></input>
          </div>
        </div>
      </div>
      <hr />
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <div className="d-flex justify-content-around">
            <input
              type="text"
              className="form-control customRequired me-2"
              id="user_finance"
              placeholder="Finance (If Applicable)"
              name="finance"
              value={userData.finance}
              style={customStyle}
              onChange={handleChange}
              maxLength="20"
              pattern="[0-9]*"
              title="Enter only Numerical Letters"
            ></input>
            <input
              type="text"
              className="form-control customRequired me-2"
              id="user_tds"
              placeholder="TDS (If Applicable)"
              name="tds"
              value={userData.tds}
              style={customStyle}
              onChange={handleChange}
              maxLength="20"
              pattern="[0-9]*"
              title="Enter only Numerical Letters"
            ></input>
          </div>
        </div>
      </div>
      <hr />
      <div
        className="form-check mb-2 d-flex justify-content-center"
        style={customStyle}
      >
        <input
          className="form-check-input"
          type="checkbox"
          id="formCheckGst"
          onClick={handleGSTChange}
          checked={gst}
          style={{ cursor: "pointer" }}
        />
        &nbsp;
        <label
          className="form-check-label"
          htmlFor="formCheckGst"
          style={{ cursor: "pointer" }}
        >
          GST (If Applicable)
        </label>
      </div>
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <div className="d-flex justify-content-around">
            <input
              type="text"
              className="form-control me-2"
              id="formGroupExampleInput"
              placeholder={
                gst
                  ? 0
                  : `Total Amount* Your Limit is ${campaignDetails.paymentLimit} Rs`
              }
              min={0}
              name="cost"
              value={userData.cost}
              onChange={handleChange}
              onKeyUp={validateNumber}
              style={customStyle}
              required={!gst ? true : false}
            ></input>
            <input
              type="text"
              className="form-control me-2"
              id="formGroupExampleInput"
              placeholder={gst ? "GST Amount*" : "GST Amount"}
              min={0}
              name="gstAmount"
              value={!gst ? "" : userData.gstAmount}
              onChange={handleChange}
              onKeyUp={validateNumber}
              style={customStyle}
              disabled
              required={gst ? true : false}
            ></input>
            <input
              type="text"
              className="form-control me-2"
              id="formGroupExampleInput2"
              placeholder="Payable Amount*"
              min={0}
              name="payableAmount"
              value={userData.payableAmount}
              onChange={handleChange}
              onKeyUp={validateNumber}
              style={customStyle}
              disabled
              required
            ></input>
            <select
              className="form-select w-25 border mx-auto text-center campaignScroll"
              aria-label="Default select example"
              style={customStyle}
              name="percentage"
              onChange={handleCalcPercentage}
              required
            >
              <option selected value="">
                %
              </option>
              {paymentLinkToggle &&
                _.range(1, parseInt(userData.percentage) + 1).map(
                  (value, index) => (
                    <option value={value} key={index} style={{ color: localStorage.getItem('theme') === 'dark' ? 'lightgray' : "#212529", backgroundColor: localStorage.getItem('theme') === 'dark' ? '#212529' : "lightgray" }}>{`${value}%`}</option>
                  )
                )}
              {/* {_.range(1, 21).map((value) => (
                <option value={5 * value}>{`${5 * value}%`}</option>
              ))} */}
            </select>
          </div>
        </div>
      </div>
      <hr />
      <div className="row">
        <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4 pe-0">
          <small>
            <strong>Invoice (If Applicable)</strong>
          </small>
          <input
            type="file"
            className="form-control"
            id="formGroupExampleInput"
            placeholder="Upload Invoice (If Applicable)"
            onChange={handleInvoiceUpload}
            style={customStyle}
            title="Choose only '.pdf' file to Upload"
            accept={".pdf"}
          ></input>
        </div>
        <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4">
          <small>
            <strong>Screenshot (If Applicable)</strong>
          </small>
          <input
            type="file"
            className="form-control"
            id="formGroupExampleInput"
            placeholder="Upload Payment Screenshot (If Applicable)"
            onChange={handleScreenshotUpload}
            style={customStyle}
            title="Choose only '.pdf' file to Upload"
            accept={".pdf"}
          ></input>
        </div>
        <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4">
          <small>
            <strong>Private or LLP*</strong>
          </small>
          <br />
          <input
            className="form-check-input"
            type="radio"
            name="typeOfPayment"
            value="P"
            id="flexRadioDefault1"
            style={{ cursor: "pointer" }}
            onChange={handleChange}
            required
          />{" "}
          <label className="form-check-label" htmlFor="flexRadioDefault1">
            P
          </label>
          &nbsp;&nbsp;
          <input
            className="form-check-input"
            type="radio"
            name="typeOfPayment"
            value="L"
            id="flexRadioDefault2"
            style={{ cursor: "pointer" }}
            onChange={handleChange}
          />{" "}
          <label className="form-check-label" htmlFor="flexRadioDefault2">
            L
          </label>
        </div>
      </div>
      <div className="text-center my-4">
        <button
          type="submit"
          className="btn btn-secondary col-xl-4 col-lg-4 col-md-4 col-sm-4 col-5">
          Submit
        </button>
        <small
          id="id_fail"
          style={{ display: "none", fontSize: "13px" }}
          className="text-danger my-4"
        >
          Failure...
        </small>
      </div>
    </form>
  );
}

export default ExpenseCompanyForm;
