import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { faClone, faEye, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Badge, Spinner, Table } from "react-bootstrap";
import IdToDate from "../../admin/common/IdToDate";
import linkclubservice from "../../services/linkclubservice";
import jwtDecode from "jwt-decode";
import paymentlinkservice from "../../services/paymentlinkservice";
const CryptoJS = require("crypto-js");

function ShowInfluencerData({
  loading,
  isCompany,
  details,
  indexStart,
  customStyle,
  customButtonStyle,
  profileDetails,
  setProfileDetails
}) {
  const [view, setView] = useState(false);
  const [addStatus, setAddStatus] = useState(false);
  const [viewData, setViewData] = useState([]);
  const [checkBoxLists, setCheckBoxLists] = useState([]);
  const [initialDetails, setInitialDetails] = useState([]);
  const [initialProfileState, setInitialProfileState] = useState([]);
  const [checkedItems, setCheckedItems] = useState({});
  const [amount, setAmount] = useState(0);

  isCompany = isCompany.replaceAll("xXx", "/");
  const bytes = CryptoJS.AES.decrypt(isCompany, "123");
  isCompany = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

  const admin = jwtDecode(
    localStorage.getItem(process.env.REACT_APP_TOKEN_NAME)
  ).isAdmin;

  const handleCheckboxClick = (e, Amount, itemId) => {
    const isChecked = e.target.checked;
    let linkAmount = parseInt(Amount);

    setCheckedItems({
      ...checkedItems,
      [itemId]: isChecked
    });

    if (isChecked) {
      setAmount(amount + linkAmount);
    } else {
      setAmount(amount - linkAmount);
    }

    const selectedItems = Object.values(checkedItems).filter((value) => value);
    setAddStatus(selectedItems.length > 0);
  };

  const handleCopyClick = (pathName, isCompany) => {
    const link = `${window.location.protocol}//${window.location.host}/${isCompany ? "company" : "influencer"
      }/payment/${pathName}`;
    navigator.clipboard.writeText(link);
  };

  async function handleDecline(id, payableAmount, rowId) {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-danger me-2",
        cancelButton: "btn btn-success me-2",
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          swalWithBootstrapButtons.fire(
            "Deleted!",
            "Your file has been deleted.",
            "success"
          );

          const elm = document.getElementById(rowId);
          elm.style.display = "none";

          await paymentlinkservice.Declined({
            id: id,
            payableAmount: payableAmount,
          });
        }
      });
  }

  const handleSelectAll = (event) => {
    const elms = document.getElementsByName("checkboxName");
    const newCheckedItems = {};
    let amount = 0;
    const checked = event.target.checked;

    for (let index = 0; index < elms.length; index++) {
      elms[index].checked = checked;
      const itemId = elms[index].id;
      newCheckedItems[itemId] = checked;
      if (checked) {
        amount += parseInt(elms[index].dataset.amount);
      }
    }

    setCheckedItems(newCheckedItems); // Update the checkedItems state
    setAddStatus(checked);
    setAmount(amount);
  };

  const handleLinkClub = async () => {
    const elms = document.getElementsByName("checkboxName");
    let ids = [];
    for (let index = 0; index < elms.length; index++) {
      if (elms[index].checked) {
        ids.push(elms[index].id);
      }
    }

    Swal.fire({
      title: "Are you sure to Club the links?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes"
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Would you like to club links with or without GST?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: "No",
          confirmButtonText: "Yes",
        }).then(async (result) => {
          if (result.isConfirmed) {
            await linkclubservice.LinkClubs({
              createdby: jwtDecode(
                localStorage.getItem(process.env.REACT_APP_TOKEN_NAME)
              )._id,
              ids: ids,
              isGst: true,
            });
            Swal.fire("Links Club with GST!", "success");
          } else {
            await linkclubservice.LinkClubs({
              createdby: jwtDecode(
                localStorage.getItem(process.env.REACT_APP_TOKEN_NAME)
              )._id,
              ids: ids,
            });
          }
          Swal.fire({
            position: "top-center",
            icon: "success",
            title: "Done Successfully!",
            showConfirmButton: false,
            timer: 2000
          });
          window.location.reload();
        });
      }
    });

  };

  const handleView = async (links) => {
    const response = await paymentlinkservice.getClubLinks({
      linkChild: links,
    });
    setViewData(response);
    setView(true);
  };

  const handleChange = (value, name) => {
    if (value) {
      const regex = new RegExp(value, "i");
      let searchResults;
      if (name === 'campaign')
        searchResults = details.filter(item => regex.test(item.campaign.name));
      else
        searchResults = details.filter(item => regex.test(item.createdby.fullName));

      const updatedDetails = [...searchResults]; // New details you want to set

      const updatedData = profileDetails.map(item => {
        if (item._id === 'P') {
          // If the item has _id 'P', update its details property
          return { ...item, details: updatedDetails };
        }
        return item; // For other items, return them as is
      });

      setProfileDetails(updatedData);
    }
    else setProfileDetails(initialProfileState);
  };

  useEffect(() => {
    setInitialDetails(details);
    setInitialProfileState(profileDetails);
  }, []);

  return (
    <>
      {addStatus && (
        <div className="text-end">
          <span className="btn-light me-3" id="selectLinkCount">
            {amount}
          </span>

          <button
            type="button"
            className="btn btn-primary"
            onClick={handleLinkClub}
          >
            Link Club
          </button>
        </div>
      )}
      <div
        className="p-2 border border-1 rounded-3 w-100 mx-auto mt-2"
        id="tableSection"
        style={{ fontSize: "12px" }}
      >
        <Table hover size="sm">
          <thead>
            <tr>
              {admin && details.length > 1 ? (
                <th scope="col">
                  <input
                    type="checkbox"
                    title="Select All"
                    style={{ cursor: "pointer" }}
                    className="form-check-input"
                    id="selectAll"
                    onChange={handleSelectAll}
                  />
                </th>
              ) : (
                <th></th>
              )}
              <th scope="col">
                <small className="">Date</small>
              </th>
              <th scope="col">
                <small className="">Payment</small>
              </th>
              <th scope="col" style={{ width: "10%" }}>
                <small>
                  Campaign
                </small>
              </th>
              <th scope="col">
                <small className="">Platform</small>
              </th>
              <th scope="col">
                <small className="">Influencer</small>
              </th>
              <th scope="col" style={{ width: "10%" }}>
                <small className="">
                  POC
                </small>
              </th>
              <th scope="col">
                <small className="">Total Amount</small>
              </th>
              <th scope="col">
                <small className="">GST Amount</small>
              </th>
              <th scope="col">
                <small className="">Percentage</small>
              </th>
              <th scope="col">
                <small className="">Payable Amount</small>
              </th>
              <th scope="col">
                <small className="">Remarks</small>
              </th>
              <th scope="col">
                <small className="">Action</small>
              </th>
            </tr>
          </thead>
          <tbody className="mx-auto my-0">
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td>{
                initialDetails && initialDetails.find((detail) => detail.status === "P") &&
                initialDetails.find((detail) => detail.isClub === false) &&
                <input className="form-control form-control-sm" type="text" placeholder="Search Campaign..." onChange={(e) => handleChange(e.target.value, "campaign")} aria-label=".form-control-sm example"></input>
              }</td>
              <td></td>
              <td></td>
              <td>
                {
                  initialDetails && initialDetails.find((detail) => detail.status === "P") &&
                  initialDetails.find((detail) => detail.isClub === false) &&
                  <input className="form-control form-control-sm" type="text" placeholder="Search POC..." onChange={(e) => handleChange(e.target.value, "poc")} aria-label=".form-control-sm example"></input>
                }
              </td>
              <td></td>
              <td></td>
              <td></td>
              <td>
              </td>
              <td></td>
              <td></td>
            </tr>
            {loading ? (
              <tr>
                <td colSpan={13}>
                  <center>
                    <Spinner animation="border" />
                  </center>
                </td>
              </tr>
            ) : details.length === 0 ? (
              <tr>
                <td colSpan={13}>
                  <center className="text-danger">
                    No Data
                  </center>
                </td>
              </tr>
            ) : (
              details &&
              details.map((detail, index) => (
                <tr key={index} id={`${detail._id}-${index}`}>
                  {admin &&
                    detail.status === "P" &&
                    detail.isClub === false ? (
                    <td>
                      <input
                        type="checkbox"
                        className="form-check-input multipleStatus"
                        id={detail._id}
                        name="checkboxName"
                        style={{ cursor: "pointer" }}
                        title="Select"
                        onChange={(e) =>
                          handleCheckboxClick(
                            e,
                            detail.payableAmount,
                            detail._id,
                          )
                        }
                        checked={checkedItems[detail._id] || false}
                        data-amount={detail.payableAmount}
                      />
                    </td>
                  ) : (
                    <td></td>
                  )}
                  <td style={customStyle}>
                    <IdToDate id={detail._id} />
                  </td>
                  <td style={customStyle}>
                    <Badge bg={detail.isCompany ? "primary" : "info"}>
                      {detail.isCompany ? "Vendor" : "Influencer"}
                    </Badge>
                  </td>
                  <td
                    style={customStyle}
                    title={detail.campaign ? detail.campaign.name : "---"}
                  >
                    {detail.campaign
                      ? detail.campaign.name.substring(0, 10) + "..."
                      : "---"}
                  </td>
                  <td style={customStyle} className="">
                    {detail.platform
                      ? detail.platform.map((p) => p.name + " ")
                      : "---"}
                  </td>
                  <td style={customStyle} className="">
                    {detail.influencer ? detail.influencer.username : "---"}
                  </td>
                  <td style={customStyle} className="">
                    {detail.createdby.fullName}
                  </td>
                  <td>
                    {detail.cost ? (
                      <Badge bg="success" style={{ fontSize: "12px" }}>
                        &#8377;{detail.cost}
                      </Badge>
                    ) : (
                      "---"
                    )}
                  </td>
                  <td>
                    {detail.gstAmount ? (
                      <Badge bg="success" style={{ fontSize: "12px" }}>
                        &#8377;{detail.gstAmount}
                      </Badge>
                    ) : (
                      "---"
                    )}
                  </td>
                  <td style={customStyle}>{detail.percentage}%</td>
                  <td>
                    {detail.payableAmount ? (
                      <Badge
                        bg="warning"
                        style={{ fontSize: "12px", color: "black" }}
                      >
                        &#8377;{detail.payableAmount}
                      </Badge>
                    ) : (
                      "---"
                    )}
                  </td>
                  {detail.payment_Status ? (
                    <td style={customStyle}>
                      <b>{detail.payment_Status}</b>
                    </td>
                  ) : (
                    <td style={customStyle}>---</td>
                  )}
                  <td>
                    {detail.paymentScreenshot ? (
                      <a
                        href={
                          process.env.REACT_APP_BASE_URL +
                          "/" +
                          detail.paymentScreenshot
                        }
                        className="text-primary"
                        target={"_blank"}
                        title="Download Screenshot"
                        download
                      >
                        <img
                          src={
                            process.env.REACT_APP_BASE_URL +
                            "/" +
                            detail.paymentScreenshot
                          }
                          width={25}
                          height={25}
                          className="rounded-circle border border-danger border-2"
                        />
                      </a>
                    ) : detail.status === "P" ? (
                      <>
                        <FontAwesomeIcon
                          icon={faClone}
                          onClick={() =>
                            handleCopyClick(detail.pathName, detail.isCompany)
                          }
                          style={{ cursor: "pointer", fontSize: "15px", marginRight: '10px' }}
                          title="Copy Link"
                        />
                        <FontAwesomeIcon icon={faTrash} style={{ fontSize: '15px', cursor: 'pointer', marginRight: '10px' }} className="text-danger" title="Delete" onClick={() => handleDecline(detail._id, detail.payableAmount, `${detail._id}-${index}`)} />
                      </>
                    ) : (
                      "---"
                    )}
                    {detail.isClub === true ? (
                      <>
                        &nbsp;
                        <FontAwesomeIcon
                          icon={faEye}
                          onClick={() => handleView(detail.linkChild)}
                          style={{ cursor: "pointer", fontSize: "15px" }}
                          title="View Links"
                          className="text-primary"
                        />
                      </>
                    ) : (
                      <></>
                    )}
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </Table>
      </div>
      {/* view data */}
      {view && (
        <div
          className="p-2 border border-1 rounded-3 w-100 mx-auto mt-2"
          id="tableSection"
          style={{ fontSize: "12px" }}
        >
          {loading ? (
            <center>
              <Spinner animation="border" />
            </center>
          ) : viewData.length === 0 ? (
            <center>
              <b className="text-danger">No Data</b>
            </center>
          ) : (
            <Table hover size="sm">
              <thead>
                <tr>
                  <th scope="col">
                    <small className="">Date</small>
                  </th>
                  <th scope="col">
                    <small className="">Payment</small>
                  </th>
                  <th scope="col">
                    <small className="">Campaign</small>
                  </th>
                  <th scope="col">
                    <small className="">Platform</small>
                  </th>
                  <th scope="col">
                    <small className="">Influencer</small>
                  </th>
                  <th scope="col">
                    <small className="">POC</small>
                  </th>
                  <th scope="col">
                    <small className="">Total Amount</small>
                  </th>
                  <th scope="col">
                    <small className="">GST Amount</small>
                  </th>
                  <th scope="col">
                    <small className="">Percentage</small>
                  </th>
                  <th scope="col">
                    <small className="">Payable Amount</small>
                  </th>
                  <th scope="col">
                    <small className="">Remarks</small>
                  </th>
                  <th scope="col">
                    <small className="">Action</small>
                  </th>
                </tr>
              </thead>
              <tbody className="mx-auto my-0">
                {loading ? (
                  <tr>
                    <td colSpan={12}>
                      <center>
                        <Spinner animation="border" />
                      </center>
                    </td>
                  </tr>
                ) : (
                  viewData &&
                  viewData.map((link, index) => (
                    <tr key={index} id={index}>
                      <td style={customStyle}>
                        <IdToDate id={link._id} />
                      </td>
                      <td style={customStyle}>
                        <Badge bg={link.isCompany ? "primary" : "info"}>
                          {link.isCompany ? "Vendor" : "Influencer"}
                        </Badge>
                      </td>
                      <td
                        style={customStyle}
                        title={link.campaign ? link.campaign.name : "---"}
                      >
                        {link.campaign
                          ? link.campaign.name.substring(0, 10) + "..."
                          : "---"}
                      </td>
                      <td style={customStyle} className="">
                        {link.platform
                          ? link.platform.map((p) => p.name + " ")
                          : "---"}
                      </td>
                      <td style={customStyle} className="">
                        {link.influencer ? link.influencer.username : "---"}
                      </td>
                      <td style={customStyle} className="">
                        {link.createdby.fullName}
                      </td>
                      <td>
                        {link.cost ? (
                          <Badge bg="success" style={{ fontSize: "12px" }}>
                            &#8377;{link.cost}
                          </Badge>
                        ) : (
                          "---"
                        )}
                      </td>
                      <td>
                        {link.gstAmount ? (
                          <Badge bg="success" style={{ fontSize: "12px" }}>
                            &#8377;{link.gstAmount}
                          </Badge>
                        ) : (
                          "---"
                        )}
                      </td>
                      <td style={customStyle} className="text-center">
                        {link.percentage}%
                      </td>
                      <td>
                        {link.payableAmount ? (
                          <Badge
                            bg="warning"
                            style={{ fontSize: "12px", color: "black" }}
                          >
                            &#8377;{link.payableAmount}
                          </Badge>
                        ) : (
                          "---"
                        )}
                      </td>
                      {link.payment_Status ? (
                        <td style={customStyle}>
                          <b>{link.payment_Status}</b>
                        </td>
                      ) : (
                        <td style={customStyle} className="text-center">
                          ---
                        </td>
                      )}
                      <td className="text-center">
                        {link.paymentScreenshot ? (
                          <a
                            href={
                              process.env.REACT_APP_BASE_URL +
                              "/" +
                              link.paymentScreenshot
                            }
                            className="text-primary"
                            target={"_blank"}
                            title="Download Screenshot"
                            download
                          >
                            <img
                              src={
                                process.env.REACT_APP_BASE_URL +
                                "/" +
                                link.paymentScreenshot
                              }
                              width={25}
                              height={25}
                              className="rounded-circle border border-danger border-2"
                            />
                          </a>
                        ) : (
                          "---"
                        )}
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </Table>
          )}
        </div>
      )}
    </>
  );
}

export default ShowInfluencerData;
