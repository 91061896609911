import { faCircleInfo, faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import Offcanvas from "react-bootstrap/Offcanvas";

function CompanyList({ vendors }) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button variant="danger" onClick={handleShow}>
        <FontAwesomeIcon
          style={{
            cursor: "pointer",
          }}
          className="align-self-center fs-5"
          title="View Vendors"
          icon={faCircleInfo}
        />
      </Button>
      <Offcanvas show={show} onHide={handleClose} placement={"end"}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Vendors</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <ul className="list-group list-group-flush">
            {vendors &&
              vendors.map((vendor) => (
                <li
                  className="list-group-item"
                  key={vendor._id}
                  style={{ fontSize: "13px" }}
                >
                  {vendor.name}
                </li>
              ))}
          </ul>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default CompanyList;
