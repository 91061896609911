import React from "react";
import {
    faCircleCheck,
    faClone,
    faLink,
    faPenToSquare,
    faTrash,
} from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import jwtDecode from "jwt-decode";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import paymentlinkservice from "../../../services/paymentlinkservice";

const PaymentActions = ({ cell, row, rowIndex }) => {
    const handleCopyClick = (pathName, isCompany) => {
        const link = `${window.location.protocol}//${window.location.host}/${isCompany ? "company" : "influencer"}/payment/${pathName}`;
        navigator.clipboard.writeText(link);
        // document.getElementById(params).style.backgroundColor = "#D3E3FD";
    };

    const handleLink = async (rowId) => {
        let isAdmin = jwtDecode(localStorage.getItem(process.env.REACT_APP_TOKEN_NAME)).isAdmin;

        if (isAdmin) {
            await paymentlinkservice.generateNewPaymentLink({ _id: rowId });
            Swal.fire({
                position: "center",
                icon: "success",
                title: "New Payment Link Created Successfully!!",
                showConfirmButton: false,
                timer: 2500,
            });
        }
        window.location.reload();
    };

    const handleApprove = async (rowId, rowIndex) => {
        let isAdmin = jwtDecode(localStorage.getItem(process.env.REACT_APP_TOKEN_NAME)).isAdmin;
        let userId = jwtDecode(localStorage.getItem(process.env.REACT_APP_TOKEN_NAME))._id;

        if (isAdmin) {
            const elm = document.getElementById(rowId + "_status");
            const elm_one = document.getElementById(rowId + "_action");
            elm_one.innerHTML = "---";
            elm.innerHTML = "WAITING";

            elm.classList.remove("bg-warning");
            elm.classList.add("bg-info");

            await paymentlinkservice.getApprovedData(rowId, userId);

            Swal.fire({
                position: "center",
                icon: "success",
                title: "Approved",
                showConfirmButton: false,
                timer: 2500,
            });
        }
    };

    const handleDecline = async (id, payableAmount, rowIndex) => {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: "btn btn-danger me-2",
                cancelButton: "btn btn-success me-2",
            },
            buttonsStyling: false,
        });

        swalWithBootstrapButtons
            .fire({
                title: "Are you sure?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Yes, delete it!",
                cancelButtonText: "No, cancel!",
                reverseButtons: true,
            })
            .then(async (result) => {
                if (result.isConfirmed) {
                    swalWithBootstrapButtons.fire("Deleted!", "Your file has been deleted.", "success");
                    const elm = document.getElementsByClassName("tableRow");
                    elm[rowIndex].style.display = "none";
                    await paymentlinkservice.Declined({
                        id: id,
                        payableAmount: payableAmount,
                    });
                }
            });
    };

    const handleAction = (row, rowIndex) => {
        const customButtonStyle = { fontSize: "20px", cursor: "pointer" };
        if (row.status === "P") {
            return (
                <>
                    <FontAwesomeIcon
                        onClick={() => handleDecline(row._id, row.payableAmount, rowIndex)}
                        icon={faTrash}
                        color={"red"}
                        style={{ cursor: "pointer", fontSize: "15px" }}
                        title="Delete"
                    />
                    &nbsp; &nbsp;
                    <FontAwesomeIcon
                        icon={faClone}
                        onClick={() => handleCopyClick(row.pathName, row.isCompany)}
                        style={{ cursor: "pointer", fontSize: "15px" }}
                        title="Copy Link"
                    />
                </>
            );
        } else if (row.status === "A") {
            return (
                <div id={`${row._id}_action`}>
                    {jwtDecode(localStorage.getItem(process.env.REACT_APP_TOKEN_NAME)).isAdmin && (
                        <>
                            <FontAwesomeIcon
                                onClick={() => handleDecline(row._id, row.payableAmount, rowIndex)}
                                icon={faTrash}
                                color={"red"}
                                style={{ cursor: "pointer", fontSize: "15px" }}
                                title="Delete"
                            />
                            &nbsp;&nbsp;
                            <FontAwesomeIcon
                                icon={faCircleCheck}
                                style={{ ...customButtonStyle, fontSize: "18px" }}
                                className="text-success"
                                title="Approve"
                                onClick={() => handleApprove(row._id, rowIndex)}
                            />
                        </>
                    )}
                </div>
            );
        } else if (row.status === "F" && row.isClub === true && row.isNewLink === false) {
            return (
                <div id={`${row._id}_action`}>
                    {jwtDecode(localStorage.getItem(process.env.REACT_APP_TOKEN_NAME)).isAdmin && (
                        <>
                            <FontAwesomeIcon
                                onClick={() => handleLink(row._id)}
                                icon={faLink}
                                style={{ cursor: "pointer" }}
                                title="Create a New Payment Link"
                            />
                        </>
                    )}
                </div>
            );
        } else {
            return <span style={{ fontSize: "12px" }}>NA</span>;
        }
    };

    return (
        <>
            {cell ? (
                <a href={process.env.REACT_APP_BASE_URL + "/" + cell} className="text-primary" target={"_blank"} title="Download Payment Screenshot" download>
                    <img src={process.env.REACT_APP_BASE_URL + "/" + cell} width={25} height={25} className="rounded-circle border border-danger border-2" />
                </a>
            ) : (
                handleAction(row, rowIndex)
            )}
            &nbsp;
            {row.gstScreenshot !== "" && (
                <a href={process.env.REACT_APP_BASE_URL + "/" + row.gstScreenshot} className="text-primary" target={"_blank"} title="Download GST Screenshot" download>
                    <img src={process.env.REACT_APP_BASE_URL + "/" + row.gstScreenshot} width={25} height={25} className="rounded-circle border border-danger border-2" />
                </a>
            )}
        </>
    );
};

export default PaymentActions;
