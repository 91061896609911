import React, { useState, useEffect } from "react";
import "../../dashboard/css/dashboard.css";
import categoryservice from "../../../services/categoryservice";
import CategoryList from "../../common/categoryList/categoryList";
import influencerservice from "../../../services/influencerservice";
import InfluencerList from "../../common/influencerList/influencerList";
import { Spinner } from "react-bootstrap";

export const Content = ({
  inputText,
  handleInfluencerName,
  ShowNumberBox,
  HideNumberBox,
}) => {
  const [categoryContent, setCategoryContent] = useState([]);
  const [influencerContent, setInfluencerContent] = useState([]);
  const [loading, setLoading] = useState(true);
  const [foundInfluencers, setFoundInfluencers] = useState([]);
  const [foundCategories, setFoundCategories] = useState([]);

  const getInit = async () => {
    const responseInfluencerContent =
      await influencerservice.getLimitedInfluencers();
    setInfluencerContent(responseInfluencerContent.slice(1, 15));
    setFoundInfluencers(responseInfluencerContent.slice(1, 15));
    setLoading(false);
  };

  useEffect(() => {
    getInit();
  }, []);

  useEffect(async () => {
    if (inputText !== "") {
      setLoading(true);
      const res = await influencerservice.findByUserName(inputText);
      if (res.length === 0) {
        ShowNumberBox();
        handleInfluencerName(inputText, inputText, false);
      } else {
        HideNumberBox();
        setFoundInfluencers(res);
        // handleInfluencerName(inputText, inputText, false);
      }

      setLoading(false);
      // return;
    } else {
      setFoundInfluencers(foundInfluencers.slice(1, 15));
      // return;
    }
  }, [inputText]);

  return (
    <>
      <div
        className=" p-3 rounded-bottom"
        id="searchedItem"
        // style={{ height: "304px", overflowY: "scroll" }}
      >
        {loading ? (
          <>
            <h5 className="text-center my-5">
              Loading... Influencers Profiles
            </h5>
            <center>
              <Spinner animation="border" />
            </center>
          </>
        ) : (
          <>
            <div className="influencer text-start">
              <ul className="list-group list-group-flush rounded-3">
                {foundInfluencers &&
                  foundInfluencers.length > 0 &&
                  foundInfluencers.map((i, index) => (
                    <li key={index} className="list-group-item border-bottom-0">
                      <a
                        style={{ fontSize: "13px", cursor: "pointer" }}
                        onClick={() =>
                          handleInfluencerName(i.username, i._id, true)
                        }
                      >
                        {i.username}
                      </a>
                    </li>
                  ))}
              </ul>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Content;
