import React, { useEffect, useState } from "react";
import Header from "../common/header/header";
import AdminCard from "../common/adminCard/adminCard";
import adminservice from "../../services/adminservice";
import { Spinner, Tab, Tabs } from "react-bootstrap";
import StatsSildeMenu from "./statsSlideMenu/statsSlideMenu";
import Footer from "../common/footer/footer";

export default function Stats() {
  const [influencerCount, setInfluencerCount] = useState([]);
  const [loading, setLoading] = useState(true);
  const [title, setTitle] = useState("All");

  const Init = async () => {
    const resp = await adminservice.getInfluencerByDay();
    setInfluencerCount(resp);
    setLoading(false);
  };

  const handleClick = async (id, name) => {
    setTitle(name);
    setLoading(true);
    const response = await adminservice.getUserInfluencersCount(id);
    setInfluencerCount(response);
    setLoading(false);
  };

  useEffect(() => {
    Init();
  }, []);

  return (
    <>
      <Header title={"Daily Stats"} />
      <div className="container-fluid">
        <div className="row">
          <StatsSildeMenu handleClick={handleClick} />
          <div className="pt-3 col-xl-10 ">
            <Tabs
              defaultActiveKey="all"
              transition={false}
              id="noanim-tab-example"
              className="mb-3"
              style={{ fontSize: "14px" }}
            >
              <Tab eventKey="all" title={title} className="text-capitalize">
                {loading ? (
                  <center>
                    <Spinner animation="border" />
                  </center>
                ) : influencerCount.length === 0 ? (
                  <h6 className="text-danger text-center">No Data</h6>
                ) : (
                  <div className="row row-cols-1 row-cols-md-3 g-4">
                    <AdminCard influencerCount={influencerCount} />
                  </div>
                )}
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
      <Footer />
    </>
  );
}
