import { useEffect, useRef, useState } from "react";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleArrowLeft,
  faCircleArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import userservice from "../../../services/userservice";

export default function ManageAddUser({ id }) {
  const borderStyle = { borderRadius: "0.5rem" };
  const customStyle = { fontSize: "13px" };
  const form = useRef(null);

  const [allUsers, setAllUsers] = useState([]);
  const [subUsers, setSubUsers] = useState([]);
  const [manageUsers, setManageUsers] = useState([]);
  const [removeUsers, setRemoveUsers] = useState([]);

  const filterRemoveUser = (mainList, subList) => {
    return mainList.filter((user) => !subList.includes(user._id));
  };

  const filterAddUser = (mainList, subList) => {
    return mainList.filter((user) => subList.includes(user._id));
  };

  const handleAddClick = (id) => {
    const list = document.getElementById(id);
    list.classList.add("listActive");
    const addUsers = [...manageUsers, id];
    const uniqueUsers = [...new Set(addUsers)]; // to get unique array
    setManageUsers(uniqueUsers);
  };

  const handleRemoveClick = (userObj) => {
    const list = document.getElementById(userObj._id);
    list.classList.add("listActive");
    const user = [...removeUsers, userObj];
    const uniqueUsers = [...new Set(user)];
    setRemoveUsers(uniqueUsers);
  };

  const handleRemove = () => {
    setSubUsers(subUsers.filter((user) => !removeUsers.includes(user)));
    setAllUsers([...removeUsers, ...allUsers]);
    setManageUsers(filterAddUser(manageUsers, subUsers));
  };

  const handleAdd = () => {
    setAllUsers(filterRemoveUser(allUsers, manageUsers));
    const filterResult = filterAddUser(allUsers, manageUsers);
    const finalSubUsers = [...subUsers, ...filterResult];
    setSubUsers(finalSubUsers);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      Swal.fire({
        title: "Loading...",
        showConfirmButton: false,
        timer: 1500,
      });
      try {
        const users = [...subUsers];
        await userservice.updateManageUsers(id, users);
      } catch (error) {}
      Swal.close();
      Swal.fire({
        icon: "success",
        title: "Success",
        showConfirmButton: false,
        timer: 1500,
      });
      document.getElementById("id_fail").style.display = "none";
    } catch (error) {
      document.getElementById("id_fail").style.display = "block";
      Swal.close();
      Swal.fire({
        icon: "error",
        title: "Error",
        showConfirmButton: false,
        timer: 1500,
      });
    }
    window.location.reload();
  };

  const getInit = async () => {
    const allSubUsers = await userservice.getAllSubUsers(id);
    setSubUsers(allSubUsers);
    const manageUsers = await userservice.getManageUsers(id);
    setManageUsers(manageUsers);
    const allUsers = await userservice.getStatsUsers();
    setAllUsers(filterRemoveUser(allUsers, manageUsers));
  };

  useEffect(() => {
    getInit();
  }, []);
  return (
    <>
      <div className="col-xl-9 mx-auto ">
        <div
          className=" shadow px-5 py-4 w-75 mx-auto my-auto formWidth"
          style={borderStyle}
        >
          <form onSubmit={onSubmit} ref={form}>
            <br />
            <div className="mb-3 d-flex justify-content-between">
              <div
                className="border border-secondary rounded overflow-auto manageScroll"
                style={{ width: "40%", height: "400px" }}
              >
                <ul className="list-group list-group-flush" style={customStyle}>
                  {allUsers.map((user) => (
                    <li
                      className="list-group-item"
                      style={{ cursor: "pointer" }}
                      id={user._id}
                      key={user._id}
                      value={user._id}
                      onClick={() => handleAddClick(user._id)}
                    >
                      {user.fullName}
                    </li>
                  ))}
                </ul>
              </div>
              <div className="my-auto">
                <FontAwesomeIcon
                  onClick={handleAdd}
                  title="Add User"
                  className="text-primary d-block mb-2"
                  icon={faCircleArrowRight}
                  style={{ cursor: "pointer" }}
                />
                <FontAwesomeIcon
                  onClick={handleRemove}
                  title="Remove User"
                  className="text-primary d-block"
                  icon={faCircleArrowLeft}
                  style={{ cursor: "pointer" }}
                />
              </div>
              <div
                className="border border-secondary rounded overflow-auto manageScroll"
                style={{ width: "40%", height: "400px" }}
              >
                <ul className="list-group list-group-flush" style={customStyle}>
                  {subUsers.length > 0 ? (
                    subUsers.map((manageUser) => (
                      <li
                        style={{ cursor: "pointer" }}
                        className="list-group-item"
                        id={manageUser._id}
                        key={manageUser._id}
                        value={manageUser._id}
                        onClick={() => handleRemoveClick(manageUser)}
                      >
                        {manageUser.fullName}
                      </li>
                    ))
                  ) : (
                    <li className="list-group-item">No Sub Users</li>
                  )}
                </ul>
              </div>
            </div>
            <div className="text-center my-4">
              <button
                type="submit"
                className="btn btn-secondary w-50 "
                style={{ backgroundColor: "#031327f2" }}
              >
                Submit
              </button>
              <small
                id="id_fail"
                style={{ display: "none", fontSize: "13px" }}
                className="text-danger my-4"
              >
                Failure...
              </small>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
