import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";

function SuccessMessage(props) {
  return (
    <>
      <br />
      <div className="row">
        <div className="col-xl-3"></div>
        <div className="col-xl-6 shadow p-3">
          <div className="card border-success">
            <h1 className="text-center text-success">
              <FontAwesomeIcon className="pt-5" icon={faCircleCheck} />
            </h1>
            <div className="card-body">
              <h2 className="text-center card-title mb-4 text-success">Success</h2>
              <p className="text-center card-text">
                Your information submitted has been successfully saved. For
                further proceedings, you will be notified of the outcome. For
                more details visit idioticmedia.
              </p>
            </div>
          </div>
        </div>
        <div className="col-xl-3"></div>
      </div>
    </>
  );
}

export default SuccessMessage;
