import React from "react";
import { useState } from "react";
import youtubeServices from "../../../../services/youtubeservice";

function InputYoutubeChannelUrl({
  youtubeData,
  handleYoutubeChange,
  setYoutubeData,
  customStyle,
  setYoutubeResponse,
}) {
  const [loading, setLoading] = useState(false);

  const validURL = (str) => {
    var pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator
    return !!pattern.test(str);
  };

  const handleValidateChannelUrl = async (e) => {
    setLoading(true);
    let { name, value } = e.target;
    setYoutubeData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    if (validURL(value)) {
      const resp = await youtubeServices.getChannelDetails({ url: value });

      if (resp.success) {
        setYoutubeData((prevState) => ({
          ...prevState,
          ...resp,
        }));
      }
    }
    setLoading(false);
  };

  return (
    <>
      <input
        type="url"
        className="form-control mb-2"
        id="formGroupExampleInput6"
        placeholder="Youtube Channel Url"
        name="url"
        value={youtubeData.url}
        onChange={handleValidateChannelUrl}
        style={customStyle}
      ></input>
      {loading && <small className="text-danger">Loading...</small>}
    </>
  );
}

export default InputYoutubeChannelUrl;
