import http from "./httpservices";

export async function UserDetails(params) {
  const response = await http.post("socialmedia/influencer/payment/details", params);

  return response;
}

export default {
  UserDetails,
};
