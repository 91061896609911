import React from "react";
import Header from "../../../components/common/header/header";
import PaymentConf from "../../../components/payment/paymentConfirmation/paymentConf";
import Footer from "../../../components/common/footer/footer";

function PaymentConfirmation() {
  return (
    <>
      <Header title="Finance Dashboard" />
      <div className="container-fluid ">
        <div className="row">
          <PaymentConf />
        </div>
      </div>
      <Footer />
    </>
  );
}

export default PaymentConfirmation;
