import http from "./httpservices";

export async function getAllPlatform() {
  const { data } = await http.get("platform/");
  return data;
}

export default {
  getAllPlatform,
};
