function emailFormatter(cell, row) {
  return (
    <>
      <div>{cell ? (<span>
        {cell.split(",")[0].split("@")[0]}
        <p>@{cell.split(",")[0].split("@")[1]}</p>
      </span>) : "NA"}</div>
    </>
  );
}
export default emailFormatter;
