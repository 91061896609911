import http from "./httpservices";

export async function CalculatePercentage(params) {
  const response = await http.post("vendor/calculate/percentage", params);
  return response;
}

export default {
  CalculatePercentage,
};
