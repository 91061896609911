import React from "react";
import "../../manage/css/manage.css";
import Pending from "../../../../components/common/campaignCard/campaigns/pending";
import Filters from "./Filters";

export default function CampaignPending({
  loading,
  handleChange,
  months,
  campaigns,
  setCampaigns,
  handleMonthFilter,
  customStyle,
  CustomRowStyle,
  borderRadiusStyle,
  filter,
}) {
  return (
    <>
      <div className="d-flex justify-content-between">
        <div
          className="fw-bold fs-5 align-middle"
          // onClick={handleCampaignModalOpener}
          style={{
            cursor: "pointer",
          }}
        >
          {/* {role["role"] !== "Operation" && (
            <FontAwesomeIcon icon={faPlusCircle} title="Add Campaign" />
          )}{" "} */}
          Campaigns ({campaigns.length})
        </div>
        <input
          type="search"
          aria-label="Search"
          className="form-control w-50 mx-auto"
          placeholder="Search Campaign or Brand"
          name="search"
          onChange={(e) => handleChange(e.target.value)}
          autoComplete="off"
        />
        <div>
          <div style={{ cursor: "pointer" }} className="fw-bold fs-6">
            <Filters
              months={months}
              filter={filter}
              handleMonthFilter={handleMonthFilter}
            />
          </div>
        </div>
      </div>
      <br />
      <></>
      <div className="row row-cols-1 row-cols-md-3 g-4 mb-5">
        <Pending
          loading={loading}
          customStyle={customStyle}
          borderRadiusStyle={borderRadiusStyle}
          campaigns={campaigns}
          setCampaigns={setCampaigns}
        />
      </div>
    </>
  );
}
