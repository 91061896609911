import React, { useEffect, useRef, useState } from "react";
import paymentservice from "../../../services/paymentlinkservice";
import jwtDecode from "jwt-decode";
import campaignservice from "../../../services/campaignservice";
import "../paymentLink/css/paymentLink.css";
import Swal from "sweetalert2";
import ExpenseCompanyForm from "./form/expenseCompanyForm";
import companyservice from "../../../services/companyservice";
import paymentlinkservice from "../../../services/paymentlinkservice";
import bankservice from "../../../services/bankservice";

const customStyles = {
  control: (base, state) => ({
    ...base,
    background: localStorage.getItem('theme') === 'dark' ? '#212529' : 'light',
    color: state.isActive ? 'white' : '#212529',
    borderColor: localStorage.getItem('theme') === 'dark' ? '#495057' : 'light',
  }),
  option: (base, state) => ({
    ...base,
    backgroundColor: state.isSelected || localStorage.getItem('theme') === 'dark' ? '#212529' : 'light', // Option background color
    color: state.isSelected || localStorage.getItem('theme') === 'dark' ? 'light' : '#212529', // Option text color
  }),
};

export default function ExpenseLink({ campaignDetails }) {
  const customBorderStyle = {
    borderRadius: "0.5rem",
    marginTop: "1rem",
    marginBottom: "6rem",
  };
  const customStyle = { fontSize: "13px" };
  const form = useRef(null);

  const [userData, setUserData] = useState({
    company: {
      name: "",
    },
    percentage: 100,
  });
  const [_percentage, _setPercentage] = useState("");
  const [campaign, setCampaign] = useState([]);
  const [company, setCompany] = useState([]);
  const [gst, setGst] = useState(false);
  const [invoiceFile, setInvoiceFile] = useState("");
  const [screenshotFile, setScreenshotFile] = useState("");

  const handleInvoiceUpload = (e) => {
    setInvoiceFile(e.target.files[0]);
  };

  const handleScreenshotUpload = (e) => {
    setScreenshotFile(e.target.files[0]);
  };

  const handleGSTChange = () => {
    if (!userData.cost) {
      Swal.fire({
        icon: "error",
        title: "Please Enter Total Amount",
        showConfirmButton: false,
        timer: 500,
      });
      return;
    }

    if (!gst) {
      // gst value set late because setgst call after logic
      setUserData((prevState) => ({
        ...prevState,
        ["gstAmount"]: parseInt(parseInt(userData.cost) * 0.18),
      }));
    } else {
      setUserData((prevState) => ({
        ...prevState,
        ["gstAmount"]: "",
      }));
    }
    setGst(!gst);
  };
  const validateNumber = (e) => {
    let result = e.target;
    result.value = result.value.replace(/[^0-9]+/, "");
  };

  function calcPercentage(percentage, cost) {
    const payableAmount = parseInt(cost * (percentage / 100));

    setUserData((prevState) => ({
      ...prevState,
      ["payableAmount"]: payableAmount,
    }));
  }

  const handleVendorChange = async (e) => {
    // let params = `${e.value}@@${e.label}`;
    if (e) {
      let bankDetails = await bankservice.getAllCompanyBankDetails(e.value);
      if (e.value && bankDetails) {
        bankDetails = bankDetails.details.filter((detail) => detail.isActive);
        setUserData((prevState) => ({
          ...prevState,
          ["company"]: e.value !== null && e.value,
          ["company.name"]: e.label,
          ["companyName"]: e.label,
          ['accountName']: bankDetails[0].accountName,
          ['accountNo']: bankDetails[0].accountNo,
          ['ifscCode']: bankDetails[0].ifscCode,
          ['pancard']: bankDetails[0].pancard
        }));
      } else {
        setUserData((prevState) => ({
          ...prevState,
          ["company"]: e.value !== null && e.value,
          ["company.name"]: e.label,
          ["companyName"]: e.label,
        }));
      }
    }
    else
      window.location.reload();
  };

  const handleCalcPercentage = async (e) => {
    let { name, value } = e.target;
    if (userData.cost !== "") {
      calcPercentage(value, userData.cost);
    }
    _setPercentage(value);
  };

  const handleChange = async (e) => {
    let { name, value } = e.target;
    if (name === "percentage") {
      return;
    }

    if (name === "cost") {
      if (parseInt(value) > parseInt(campaignDetails.paymentLimit)) {
        Swal.fire({
          icon: "error",
          title: `Enter amount within limit ${campaignDetails.paymentLimit}`,
          showConfirmButton: false,
          timer: 1500,
        });
        setUserData((prevState) => ({
          ...prevState,
          ["cost"]: "",
        }));
        return;
      }
    }

    setUserData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    let finalObj = { ...userData };

    if (finalObj.hasOwnProperty("customDate")) {
      finalObj.customDate = new Date(finalObj.customDate).getTime();
    } else {
      finalObj.customDate = new Date().getTime();
    }

    if (finalObj["payableAmount"] > parseInt(campaignDetails.paymentLimit)) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `Please Enter Amount within Limit! Your limit is ${campaignDetails.paymentLimit}`,
      });

      return;
    }

    delete finalObj["username"];
    delete finalObj["company"]["name"];
    finalObj["paymentLimit"] = campaignDetails.paymentLimit;

    finalObj["createdby"] = jwtDecode(
      localStorage.getItem(process.env.REACT_APP_TOKEN_NAME)
    )._id;

    if (campaignDetails.platform) {
      let platform = [];
      campaignDetails.platform.map((p) => {
        platform.push(p._id);
      });
      finalObj["platform"] = platform;
    }
    // finalObj["platform"] = campaignDetails.platformId;
    finalObj["campaign"] = campaignDetails.id;
    if (_percentage) finalObj["percentage"] = _percentage;

    if (gst) {
      finalObj["isGst"] = true;
      finalObj["isGstPay"] = false;
    } else {
      if (finalObj["gstAmount"] !== "") delete finalObj["gstAmount"];
    }

    try {
      let stringObj = finalObj.description;
      finalObj["description"] = stringObj.replace(/(\r\n|\n|,|\r)/gm, " ");
      finalObj["isCompany"] = true;
      const resp = await paymentservice.generateExpenseLink(finalObj);

      if (invoiceFile) {
        const formData = new FormData();
        formData.append("file", invoiceFile);
        formData.append("id", resp._id);
        await paymentlinkservice.getUploadInvoice(formData);
      }

      if (screenshotFile) {
        const formData = new FormData();
        formData.append("file", screenshotFile);
        formData.append("id", resp._id);
        await paymentlinkservice.getUploadScreenshot(formData);
      }

      window.location.href = "/admin/payment/status";
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
    }
  };

  const getInit = async () => {
    const campaigns = await campaignservice.getCampaigns();
    setCampaign(campaigns);
    const companies = await companyservice.getCompanies();
    setCompany(companies);
  };

  useEffect(() => {
    getInit();
  }, []);

  return (
    <>
      <div
        className="col-xl-12 col-lg-12 col-md-12 col-sm-12"
        style={{ height: '100vh' }}
      >
        <div
          className=" shadow p-3 w-75 mx-auto formWidth"
          style={customBorderStyle}
        >
          <ExpenseCompanyForm
            onSubmit={onSubmit}
            form={form}
            gst={gst}
            userData={userData}
            customStyle={customStyle}
            customStyles={customStyles}
            handleGSTChange={handleGSTChange}
            handleChange={handleChange}
            validateNumber={validateNumber}
            campaign={campaign}
            campaignDetails={campaignDetails}
            handleVendorChange={handleVendorChange}
            handleCalcPercentage={handleCalcPercentage}
            paymentLinkToggle={true}
            handleInvoiceUpload={handleInvoiceUpload}
            handleScreenshotUpload={handleScreenshotUpload}
          />
        </div>
      </div>
    </>
  );
}
