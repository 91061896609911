import http from "./httpservices";

const endPoint = "fetch/influencers";

export async function fetchInfluencers() {
  const { data } = await http.get(endPoint);
  return data;
}

export async function fetchStatesAndCities() {
  const { data } = await http.post(`${endPoint}/statesandcities`);
  return data;
}

export async function searchInfluencer(fullName) {
  const { data } = await http.post(`${endPoint}/fullName`, {
    fullName: fullName,
  });
  return data;
}

export async function searchInfluencerByState(state) {
  const { data } = await http.post(`${endPoint}/state`, {
    state: state,
  });
  return data;
}

export async function searchInfluencerByCategory(category) {
  const { data } = await http.post(`${endPoint}/category`, {
    category: category,
  });
  return data;
}

export async function filterBio(params) {
  const { data } = await http.post(`${endPoint}/bio`, params);
  return data;
}

export async function applyFilters(params) {
  const { data } = await http.post(`${endPoint}/filters`, params);
  return data;
}

export default {
  fetchInfluencers,
  fetchStatesAndCities,
  searchInfluencer,
  searchInfluencerByState,
  searchInfluencerByCategory,
  filterBio,
  applyFilters,
};
