import React, { useEffect, useState } from "react";
import SuccessMessage from "./successmessage";
import CompanyForm from "./companyForm";
import Swal from "sweetalert2";
import bankservice from "../../../services/bankservice";
import pancardservice from "../../../services/pancardservice";

export default function CompanyInfo({ companyInfo }) {
  const [loading, setLoading] = useState(true);
  const [togglePaymentOption, setTogglePaymentOption] = useState(false);
  const [userData, setUserData] = useState({
    pancardName: "",
    accountName: "",
    accountNo: "",
    ifscCode: "",
    pancard: "",
    paytmNumber: "",
    invoiceNumber: "",
    invoiceDate: "",
  });

  const [isPancardVerify, setIsPancardVerify] = useState(false);
  const [loadingPancardVerify, setLoadingPancardVerify] = useState(false);
  const [tempVerifyPancard, setTempVerifyPancard] = useState("");

  const handlePaymentClick = () => {
    setTogglePaymentOption(!togglePaymentOption);
  };

  const handleBankDetailActive = async (id, number) => {
    Swal.fire({
      title: "Loading...",
      showConfirmButton: false,
      timer: 1500,
    });
    await bankservice.activeBankDetails({ id: id, number: number });
    let elm = document.getElementsByClassName("bankActive");
    if (elm.length !== 0) elm[0].classList.remove("bankActive");
    let activeElm = document.getElementById(number);
    activeElm.classList.add("bankActive");
  };

  const onPaste = (e) => {
    e.preventDefault();
  };

  const handleChange = async (e) => {
    const { name, value } = e.target;

    if (value.length < 10 && name === "pancard") {
      setIsPancardVerify(false);
      setUserData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
      return;
    }

    if (name === "pancard" && value.length === 10 && parseInt(companyInfo.payableAmount) > 5000) {
      if (["AAKCC1898H", "AAKFC4548H", "XXXXX1234X"].includes(value)) {
        setIsPancardVerify(false);
        setLoadingPancardVerify(false);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Invalid PAN Card number. Please enter a valid PAN Card number.",
        });

        setUserData((prevState) => ({
          ...prevState,
          pancardName: "",
          pancard: value,
        }));
        return;
      }

      setLoadingPancardVerify(true);

      if (value === tempVerifyPancard) {
        setLoadingPancardVerify(false);
        setIsPancardVerify(true);
        setUserData((prevState) => ({
          ...prevState,
          pancard: value,
        }));
        return;
      }

      try {
        const response = await pancardservice.getPancardDetails({
          number: value,
        });
        const { isValid, name: pancardName } = response.data;

        if (isValid) {
          setTempVerifyPancard(value);
          setIsPancardVerify(true);
          setUserData((prevState) => ({
            ...prevState,
            pancard: value,
            pancardName,
          }));
        } else {
          setIsPancardVerify(false);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Invalid PAN Card number. Please enter a valid PAN Card number.",
          });

          setUserData((prevState) => ({
            ...prevState,
            pancard: value,
            pancardName: "",
          }));
        }
      } catch (error) {
        setIsPancardVerify(false);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "An error occurred while verifying the PAN Card number. Please try again.",
        });

        setUserData((prevState) => ({
          ...prevState,
          pancard: value,
          pancardName: "",
        }));
      } finally {
        setLoadingPancardVerify(false);
      }
      return;
    }

    setUserData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    setIsPancardVerify(true);
  };

  const getInit = async () => {
    if (companyInfo !== null) setLoading(false);
  };

  useEffect(() => {
    getInit();
  }, []);

  return (
    <>
      {companyInfo.isOpen && companyInfo.isCompany && (
        <CompanyForm
          loading={loading}
          handleChange={handleChange}
          companyInfo={companyInfo}
          handlePaymentClick={handlePaymentClick}
          togglePaymentOption={togglePaymentOption}
          setTogglePaymentOption={setTogglePaymentOption}
          userData={userData}
          setUserData={setUserData}
          onPaste={onPaste}
          handleBankDetailActive={handleBankDetailActive}
          isPancardVerify={isPancardVerify}
          loadingPancardVerify={loadingPancardVerify}
        />
      )}
      {companyInfo.isOpen === false && <SuccessMessage />}
    </>
  );
}
