import React from "react";
import { Badge, Placeholder, Table } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleArrowDown } from "@fortawesome/free-solid-svg-icons";

function FinancePlaceHolder(props) {
  const customHeadingStyle = { fontSize: "11px" };
  const CustomRowStyle = {
    fontSize: "11px",
    fontWeight: "500",
    cursor: "pointer",
  };
  return (
    <>
      {_.range(1, 25).map((i) => (
        <tr key={i}>
          <td>
            <input
              type="checkbox"
              className="form-check-input multipleStatus"
              disabled
              name=""
              id=""
            />
          </td>
          <td style={CustomRowStyle}>{i}</td>
          <td>
            <Badge bg="dark" style={{ fontSize: "10px" }}>
              00/00/0000
            </Badge>
            <div style={{ width: "90px" }}></div>
          </td>
          <td>
            <Placeholder as={Card.Text} animation="glow">
              <Placeholder xs={8} />
            </Placeholder>
          </td>
          <td>
            <Badge bg="success" style={{ fontSize: "10px" }}>
              {`\u20B9 0000`}
            </Badge>
          </td>
          <td>
            <Badge bg="warning" style={{ fontSize: "10px", color: "black" }}>
              {`\u20B9 0000`}
            </Badge>
          </td>
          <td>
            <Placeholder as={Card.Text} animation="glow">
              <Placeholder xs={8} />
            </Placeholder>
          </td>
          <td>
            <Badge bg="warning" style={{ fontSize: "10px", color: "black" }}>
              {`\u20B9 0000`}
            </Badge>
          </td>
          <td>
            <Placeholder as={Card.Text} animation="glow">
              <Placeholder xs={8} />
            </Placeholder>
          </td>
          <td style={{ fontSize: "10px" }}>
            <FontAwesomeIcon icon={faCircleArrowDown} size="xl" className="" />
          </td>
          <td>---</td>
          <td>
            <Placeholder as={Card.Text} animation="glow">
              <Placeholder xs={7} />
            </Placeholder>
          </td>
          <td>
            <Placeholder as={Card.Text} animation="glow">
              <Placeholder xs={12} />
            </Placeholder>
          </td>
          <td>
            <Placeholder as={Card.Text} animation="glow">
              <Placeholder xs={8} />
            </Placeholder>
          </td>
          <td>
            <Badge pill style={{ fontSize: "10px" }} className="text-center">
              XXXX
            </Badge>
          </td>
          <td>
            <Placeholder as={Card.Text} animation="glow">
              <Placeholder xs={8} />
            </Placeholder>
          </td>
          <td>
            <Placeholder as={Card.Text} animation="glow">
              <Placeholder xs={8} />
            </Placeholder>
          </td>
          <td>
            <Placeholder as={Card.Text} animation="glow">
              <Placeholder xs={8} />
            </Placeholder>
          </td>
        </tr>
      ))}
    </>
  );
}

export default FinancePlaceHolder;
