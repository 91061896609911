import React from "react";
import _ from "lodash";
import { Badge } from "react-bootstrap";
import Placeholder from "react-bootstrap/Placeholder";

function latestCampPlaceHolder(props) {
  const customStyle = { fontSize: "13px", fontWeight: "500" };
  return (
    <>
      {_.range(1, 15).map((r) => (
        <ul className="list-group list-group-flush" key={r}>
          <li
            className="list-group-item list-group-item-action text-capitalize "
            style={{ ...customStyle, cursor: "pointer" }}
          >
            <div className="d-flex ">
              {r})&nbsp;
              <div>
                <Placeholder
                  as={"div"}
                  animation="glow"
                  style={{ width: "100px" }}
                >
                  <Placeholder xs={12} />
                </Placeholder>
              </div>
            </div>
            <div className="d-flex mt-1">
              <Badge bg="dark" style={{ fontSize: "12px" }}>
                00/00/0000
              </Badge>
              &nbsp;-&nbsp;
              <div className="text-dark">
                <Placeholder
                  as={"div"}
                  animation="glow"
                  style={{ width: "100px" }}
                >
                  <Placeholder xs={10} />
                </Placeholder>
              </div>
            </div>
          </li>
        </ul>
      ))}
    </>
  );
}

export default latestCampPlaceHolder;
