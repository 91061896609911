import React from "react";
import { Table } from "react-bootstrap";
import "../manage/css/manage.css";
import CampaignCard from "../../../components/common/campaignCard/campaignCard";
import StatusCard from "../../../components/common/campaignCard/statusCard";
import PocPlaceHolder from "../../../placeholders/pocPlaceHolder";

export default function Dashboard({
  role,
  stats,
  vendors,
  brands,
  loading,
  widthStyle,
  customStyle,
  borderRadiusStyle,
}) {
  return (
    <>
      <div className="col-xl-9 col-lg-9 col-mg-9 col-sm-9 pt-3">
        <div className="row row-cols-1 row-cols-md-3 g-4">
          <CampaignCard
            borderRadiusStyle={borderRadiusStyle}
            stats={stats}
            vendors={vendors}
            brands={brands}
            role={role}
          />
        </div>
        <br />
        <br />
        <div className="row row-cols-1 row-cols-md-3 g-2">
          <StatusCard
            widthStyle={widthStyle}
            borderRadiusStyle={borderRadiusStyle}
            stats={stats}
          />
        </div>
        <br />
        <br />
        {role["role"] !== "Operation" && (
          <div className="w-100 mx-auto mt-2" style={borderRadiusStyle}>
            <Table hover size="lg" bordered>
              <thead>
                <tr>
                  <th scope="col">
                    <small className="">#</small>
                  </th>
                  <th scope="col">
                    <small className="">POC</small>
                  </th>
                  <th scope="col">
                    <small className="">Success</small>
                  </th>
                  <th scope="col">
                    <small className="">Pending</small>
                  </th>
                  <th scope="col">
                    <small className="">Applied</small>
                  </th>
                  <th scope="col">
                    <small className="">Failed</small>
                  </th>
                </tr>
              </thead>
              <tbody className="mx-auto my-0">
                {loading ? (
                  <PocPlaceHolder />
                ) : stats.subordinates.length === 0 ? (
                  <tr>
                    <td colSpan={6} className="text-danger">
                      <center>
                        <b>No Data</b>
                      </center>
                    </td>
                  </tr>
                ) : (
                  stats.subordinates.map((p, index) => (
                    <tr key={index}>
                      <td style={customStyle}>{index + 1}</td>
                      <td style={customStyle}>{p.name.toUpperCase()}</td>
                      <td style={customStyle}>{p.success}</td>
                      <td style={customStyle}>{p.pending}</td>
                      <td style={customStyle}>{p.applied}</td>
                      <td style={customStyle}>{p.failed}</td>
                    </tr>
                  ))
                )}
              </tbody>
            </Table>
          </div>
        )}
      </div>
    </>
  );
}
