import React, { useEffect, useState } from "react";
import { Badge, Spinner, Table } from "react-bootstrap";
import Swal from "sweetalert2";
import bankservice from "../../../services/bankservice";
import paymentlinkservice from "../../../services/paymentlinkservice";
import BankPopup from "../../common/bankPopup/bankPopup";
import _ from "lodash";

function CompanyForm({
  loading,
  handleChange,
  companyInfo,
  togglePaymentOption,
  setTogglePaymentOption,
  handlePaymentClick,
  userData,
  setUserData,
  handleBankDetailActive,
  isPancardVerify,
  loadingPancardVerify,
}) {
  const borderStyle = { borderRadius: "0.5rem" };
  const customStyle = { fontSize: "13px" };
  const [file, setFile] = useState("");
  const [pancardFile, setPancardFile] = useState({});
  const [bankDetails, setBankDetails] = useState({});
  const [vendorDetails, setVendorDetails] = useState([]);
  const [isEmpty, setIsEmpty] = useState(false);
  const [isInvoiceEmpty, setIsInvoiceEmpty] = useState(false);
  const [visible, setVisible] = useState(false);
  const [isInvoice, setInvoice] = useState(false);

  const handleUpload = (e) => {
    setIsInvoiceEmpty(true);
    if (!companyInfo.isGst) setInvoice(true);
    setFile(e.target.files[0]);
  };

  const handlePancardUpload = (e) => {
    setIsEmpty(true);
    setPancardFile(e.target.files[0]);
  };

  const handleAddBankDetails = async (e) => {
    e.preventDefault();
    Swal.fire({
      title: "Loading...",
      showConfirmButton: false,
      timer: 1500,
    });
    userData["company"] = companyInfo.company;

    let response = await bankservice.addNewPaymentDetails(userData);

    if (response.status === 201) {
      Swal.fire({
        icon: "error",
        title: response.data,
        showConfirmButton: false,
        timer: 3000,
      });
      return;
    }

    if (isEmpty) {
      const pancardFormData = new FormData();
      pancardFormData.append("file", pancardFile);
      pancardFormData.append("id", companyInfo._id);
      await paymentlinkservice.getUploadPancard(pancardFormData);
    }

    Swal.close();
    Swal.fire({
      icon: "success",
      title: "Success",
      showConfirmButton: false,
      timer: 1500,
    });

    window.location.reload();
  };

  const handlePermission = async (e) => {
    e.preventDefault();
    const response = await bankservice.influencerBankDetails({
      id: companyInfo.company,
      isCompany: true,
    });

    if (response.status === 201) {
      Swal.fire({
        icon: "error",
        title: response.data,
        showConfirmButton: false,
        timer: 3000,
      });
      return;
    }
    let bankDetails = response.data;
    Swal.fire({
      // title: "Bank Details",
      icon: "info",
      html: `<table className="table table-bordered">
        <tr>
          <td>Account Name</td>
          <td>${bankDetails.accountName}</td>
        </tr>
          <tr>
          <td>Account Number</td>
          <td>${bankDetails.accountNo}</td>
        </tr>
          <tr>
          <td>IFSC Code</td>
          <td>${bankDetails.ifscCode}</td>
        </tr>
        <tr>
        <td>Pancard</td>
        <td>${bankDetails.pancard}</td>
      </table>
      <br>
      <small><b className="text-danger">Note</b>:Here is the final information regarding your Bank Account. Please edit your entry if necessary before we finalize it.</small>
      `,
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: "Submit",
      confirmButtonAriaLabel: "Thumbs up, great!",
      cancelButtonText: "Cancel",
      cancelButtonAriaLabel: "Thumbs down",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */

      if (result.isConfirmed) {
        onSubmit();
      }
    });
  };

  const onSubmit = async () => {
    try {
      Swal.fire({
        title: "Loading...",
        showConfirmButton: false,
        timer: 1500,
      });
      const response = await paymentlinkservice.addCompanyData({
        paymentlink: companyInfo._id,
        company: companyInfo.company,
        invoiceDate: userData.invoiceDate,
        invoiceNumber: userData.invoiceNumber,
      });

      if (file) {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("id", companyInfo._id);
        await paymentlinkservice.getUploadInvoice(formData);
      }

      if (response.status === 201) {
        Swal.fire({
          icon: "error",
          title: response.data,
          showConfirmButton: false,
          timer: 3000,
        });

        return;
      }

      Swal.close();
      Swal.fire({
        icon: "success",
        title: "Success",
        showConfirmButton: false,
        timer: 1500,
      });
      window.location.reload();
    } catch (error) {
      document.getElementById("id_fail").style.display = "block";
      Swal.close();
      Swal.fire({
        icon: "error",
        title: "Error",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  const getInit = async () => {
    const id = companyInfo.company;
    const response = await bankservice.getAllCompanyBankDetails(id);
    setBankDetails(response);
    if (response) {
      setUserData((prevState) => ({
        ...prevState,
        ["pancard"]: response.pancard,
      }));
    }
    const _vendorDetails = await paymentlinkservice.getVendorDetails({
      company: companyInfo.company,
      campaign: companyInfo.campaign._id,
      pathName: companyInfo.pathName,
    });
    setVendorDetails(_vendorDetails);
  };

  const btnShow = () => {
    setVisible(true);
    if (togglePaymentOption) setTogglePaymentOption(false);
  };

  useEffect(() => {
    getInit();
  }, []);

  return (
    <div
      className="container-fluid"
      style={{ backgroundColor: "#E9EBEE", minHeight: "100vh", height: "auto" }}
    >
      <h4 className="text-center py-4" style={{ color: "#606060" }}>
        Vendor Payment
      </h4>
      <div className="row pb-2">
        <div
          className="col-xl-12 col-lg-12 col-md-12 col-sm-12 shadow p-3  mx-auto"
          style={{ ...borderStyle, width: "85%" }}
        >
          <form onSubmit={handlePermission}>
            <div className="rounded-3 mb-5" id="tableSection">
              <Table hover size="sm">
                <thead>
                  <tr>
                    <th scope="col">
                      <small>Vendor</small>
                    </th>
                    <th scope="col">
                      <small>Campaign</small>
                    </th>
                    <th scope="col">
                      <small>Description</small>
                    </th>
                    <th scope="col">
                      <small>POC</small>
                    </th>
                    {companyInfo.isGst && (
                      <>
                        <th scope="col">
                          <small>GST Amount</small>
                        </th>
                      </>
                    )}
                    <th scope="col">
                      <small>Total Amount</small>
                    </th>
                    <th scope="col">
                      <small>Payable Amount</small>
                    </th>
                    <th scope="col">
                      <small>Percentage</small>
                    </th>
                    <th scope="col">
                      <small>Status</small>
                    </th>
                  </tr>
                </thead>
                <tbody className="mx-auto my-0">
                  {loading ? (
                    <tr>
                      <td colSpan={companyInfo.isGst ? 9 : 8}>
                        <center>
                          <Spinner animation="border" />
                        </center>
                      </td>
                    </tr>
                  ) : (
                    vendorDetails &&
                    vendorDetails.map((vendor, index) => (
                      <tr key={index}>
                        <td style={customStyle}>
                          {vendor.company ? vendor.company.name : "---"}
                        </td>
                        <td style={customStyle}>
                          {vendor.campaign ? vendor.campaign.name : "---"}
                        </td>
                        <td style={customStyle}>
                          {vendor.description
                            ? vendor.description
                            : vendor.deliverables}
                        </td>
                        <td style={customStyle}>
                          {vendor.createdby && vendor.createdby.fullName}
                        </td>
                        {vendor.isGst && (
                          <>
                            <td style={customStyle}>
                              {vendor.gstAmount ? (
                                <Badge bg="success">
                                  &#8377;{vendor.gstAmount}
                                </Badge>
                              ) : (
                                "---"
                              )}
                            </td>
                          </>
                        )}
                        <td style={customStyle}>
                          {vendor.cost ? (
                            <Badge bg="success">&#8377;{vendor.cost}</Badge>
                          ) : (
                            "---"
                          )}
                        </td>
                        <td style={customStyle}>
                          {vendor.payableAmount ? (
                            <Badge bg="warning" className="text-dark">
                              &#8377;{vendor.payableAmount}
                            </Badge>
                          ) : (
                            "---"
                          )}
                        </td>
                        <td style={customStyle}>
                          {vendor.percentage ? vendor.percentage + "%" : "---"}
                        </td>
                        <td style={customStyle}>
                          {vendor.payment_Status ? (
                            vendor.payment_Status
                          ) : (
                            <Badge>Processing</Badge>
                          )}
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </Table>
            </div>
            <div className="row mx-auto">
              <div className="col-xl-12 right-data">
                <h6 className="text-center mb-4" style={{ color: "#606060" }}>
                  <span
                    className="rounded-pill px-4 py-2"
                    style={{ backgroundColor: "#C2E7FF" }}
                  >
                    Bank
                  </span>
                </h6>
                <div className="container text-center">
                  <div className="row">
                    {bankDetails ? (
                      bankDetails.details &&
                      bankDetails.details.map(
                        (detail, index) =>
                          detail.accountNo && (
                            <div
                              className={
                                detail.isActive
                                  ? "card mx-auto col-xl-3 col-lg-4 col-md-6 col-sm-12 mt-2 mx-auto shadow-sm hoverStyle bankActive"
                                  : "card mx-auto col-xl-3 col-lg-4 col-md-6 col-sm-12 mt-2 mx-auto shadow-sm hoverStyle"
                              }
                              id={detail.accountNo}
                              style={{ cursor: "pointer" }}
                              key={index}
                              onClick={() =>
                                handleBankDetailActive(
                                  bankDetails._id,
                                  detail.accountNo
                                )
                              }
                            >
                              <ul
                                className="list-group list-group-flush text-start"
                                style={customStyle}
                              >
                                <li className="list-group-item">
                                  <strong>Account Name</strong>
                                  <br />
                                  {detail.accountName
                                    ? detail.accountName
                                    : "NA"}
                                </li>
                                <li className="list-group-item">
                                  <strong>Account Number</strong>
                                  <br />
                                  {detail.accountNo ? detail.accountNo : "NA"}
                                </li>
                                <li className="list-group-item">
                                  <strong>IFSC Code</strong>
                                  <br />
                                  {detail.ifscCode ? detail.ifscCode : "NA"}
                                </li>
                                <li className="list-group-item">
                                  <strong>Pan Card</strong>
                                  <br />
                                  {detail.pancard ? detail.pancard : "NA"}
                                </li>
                                {/* <li className="list-group-item">
                                <strong>Paytm</strong>
                                <br />
                                {detail.paytmNumber ? detail.paytmNumber : "NA"}
                              </li> */}
                              </ul>
                            </div>
                          )
                      )
                    ) : (
                      <div className="card col-xl-12 col-lg-12 col-md-12 col-sm-12">
                        <div className="card-body">
                          Sorry, It appears that we don't have your Bank
                          Details. Please click the below button to add your
                          details.
                        </div>
                      </div>
                    )}
                  </div>
                  <br />
                  <button
                    className="btn btn-success mt-3 mx-auto"
                    type="button"
                    onClick={btnShow}
                  >
                    Add Bank Details
                  </button>
                </div>
                <br />
                <br />
                <div className="w-50 mx-auto">
                  {companyInfo.isGst ? (
                    <>
                      <hr />
                      <div className="col">
                        <small>
                          <strong>Upload Invoice*</strong>
                        </small>
                        {companyInfo.invoice ? (
                          <p style={customStyle}>{companyInfo.invoice}</p>
                        ) : (
                          <input
                            type="file"
                            className="form-control"
                            id="formGroupExampleInput"
                            placeholder="Upload Your Invoice"
                            onChange={handleUpload}
                            style={customStyle}
                            title="Choose only '.pdf' file to Upload"
                            accept={".pdf"}
                            required
                          ></input>
                        )}
                      </div>
                      <div className="row">
                        <div className="col">
                          <small>
                            <strong>Invoice Number*</strong>
                          </small>
                          {companyInfo.invoiceNumber ? (
                            <p style={customStyle}>
                              {companyInfo.invoiceNumber}
                            </p>
                          ) : (
                            <input
                              type="text"
                              className="form-control"
                              id="user_invoice_number"
                              placeholder="Enter Invoice Number"
                              name="invoiceNumber"
                              value={userData.invoiceNumber}
                              style={customStyle}
                              onChange={handleChange}
                              required
                            ></input>
                          )}
                        </div>
                        <div className="col">
                          <small>
                            <strong>Invoice Date*</strong>
                          </small>
                          {companyInfo.invoiceDate ? (
                            <p style={customStyle}>{companyInfo.invoiceDate}</p>
                          ) : (
                            <input
                              type="date"
                              className="form-control"
                              id="user_invoice_date"
                              placeholder="Enter Invoice Date"
                              name="invoiceDate"
                              value={userData.invoiceDate}
                              style={customStyle}
                              onChange={handleChange}
                              required
                            ></input>
                          )}
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <hr />
                      <div className="col">
                        <small>
                          <strong>Upload Invoice (Optional)</strong>
                        </small>
                        {companyInfo.invoice ? (
                          <p style={customStyle}>{companyInfo.invoice}</p>
                        ) : (
                          <input
                            type="file"
                            className="form-control"
                            id="formGroupExampleInput"
                            placeholder="Upload Your Invoice"
                            onChange={handleUpload}
                            style={customStyle}
                            title="Choose only '.pdf' file to Upload"
                            accept={".pdf"}
                            required={isInvoice}
                          ></input>
                        )}
                      </div>
                      <div className="row">
                        <div className="col">
                          <small>
                            <strong>Invoice Number (Optional)</strong>
                          </small>
                          {companyInfo.invoiceNumber ? (
                            <p style={customStyle}>
                              {companyInfo.invoiceNumber}
                            </p>
                          ) : (
                            <input
                              type="text"
                              className="form-control"
                              id="user_invoice_number"
                              placeholder="Enter Invoice Number"
                              name="invoiceNumber"
                              value={userData.invoiceNumber}
                              style={customStyle}
                              onChange={handleChange}
                              required={isInvoice}
                            ></input>
                          )}
                        </div>
                        <div className="col">
                          <small>
                            <strong>Invoice Date (Optional)</strong>
                          </small>
                          {companyInfo.invoiceDate ? (
                            <p style={customStyle}>{companyInfo.invoiceDate}</p>
                          ) : (
                            <input
                              type="date"
                              className="form-control"
                              id="user_invoice_date"
                              placeholder="Enter Invoice Date"
                              name="invoiceDate"
                              value={userData.invoiceDate}
                              style={customStyle}
                              onChange={handleChange}
                              required={isInvoice}
                            ></input>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="text-center my-5">
              <button
                type="submit"
                className="btn btn-success w-25 rounded-pill"
                style={{ backgroundColor: "#023430", fontSize: "18px" }}
              >
                Confirm
              </button>
              <small
                id="id_fail"
                style={{ display: "none", fontSize: "13px" }}
                className="text-danger my-4"
              >
                Failure...
              </small>
            </div>
          </form>
          <BankPopup
            paymentInfo={companyInfo}
            handlePaymentClick={handlePaymentClick}
            togglePaymentOption={togglePaymentOption}
            userData={userData}
            customStyle={customStyle}
            bankDetails={bankDetails}
            handleChange={handleChange}
            handleUpload={handleUpload}
            handlePancardUpload={handlePancardUpload}
            handleAddBankDetails={handleAddBankDetails}
            setVisible={setVisible}
            visible={visible}
            isPancardVerify={isPancardVerify}
            loadingPancardVerify={loadingPancardVerify}
          />
        </div>
      </div>
    </div>
  );
}

export default CompanyForm;
