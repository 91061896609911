import React from "react";
import { Spinner, Badge, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faB,
  faCircleArrowDown,
  faCircleArrowUp,
  faCircleCheck,
  faCircleExclamation,
  faCircleXmark,
  faFilter,
  faL,
  faP,
} from "@fortawesome/free-solid-svg-icons";
import PaymentModal from "../paymentModal";
import IdToDate from "../../../../admin/common/IdToDate";

function PaymentCompanyList({
  customHeadingStyle,
  CustomFontStyle,
  CustomRowStyle,
  customBadgeStyle,
  paymentCompanyData,
  financialCompanyPayment,
  loading,
  handleGstPay,
  handleCheckboxClick,
  handleSelectAll,
  filterCompanyStatus,
  handleCompanyView,
  remarksModal,
  userParams,
  handleSubmit,
  handleRadioButton,
  handleDelete,
  handleFail,
  handleUpload,
  uploadGstScreenshot,
  handleSearchChange,
  initialPaymentCompanyData
}) {

  let TotalAmount = 0;
  let TotalPayableAmount = 0;

  paymentCompanyData.map((p) => {
    TotalAmount += p.cost;
    TotalPayableAmount += parseInt(p.payableAmount);
  })

  const renderFinancialPayment = (id) => {
    let filterFinancialPayment = financialCompanyPayment.filter(
      (item) => item._id === id
    );
    if (filterFinancialPayment.length > 0)
      return parseInt(filterFinancialPayment[0]["TotalCost"]);

    return null;
  };

  const handleFinancialPayment = (id) => {
    let financialPay = null;
    if (id) financialPay = renderFinancialPayment(id);

    return financialPay ? (
      <Badge bg="info" style={{ fontSize: "12px", color: "black" }}>
        &#8377;{financialPay}
      </Badge>
    ) : (
      "---"
    );
  };

  const handleTDSAmount = (amount, pancard) => {
    let finalResult = null;
    if (pancard.charAt(3) === "P" || pancard.charAt(3) === "H") {
      finalResult = `${amount * 0.01} (1%)`;
    } else if (pancard.charAt(3) === "F" || pancard.charAt(3) === "C" || pancard.charAt(3) === "T") {
      finalResult = `${amount * 0.02} (2%)`;
    }
    return finalResult;
  };

  const processedPancards = new Set();

  const handleTDS = (amount, pancard, percentage, isGst, fpayment) => {
    let finalResult = null;

    // Check if the PAN card is already processed
    if (processedPancards.has(pancard) && (percentage < 100) && isGst) {
      finalResult = null
    }
    else {
      if (fpayment >= 100000) {
        finalResult = handleTDSAmount(amount, pancard);
      }

      if (amount >= 30000) {
        finalResult = handleTDSAmount(amount, pancard);
      }
    }

    if (finalResult) {
      processedPancards.add(pancard);
    }

    return finalResult ? (
      <Badge bg="warning" style={{ fontSize: "12px", color: "black" }}>
        &#8377; {finalResult}
      </Badge>
    ) : (
      "---"
    );
  };

  return (
    <>
      <div
        className=" px-2 pt-2 pb-4 shadow rounded-3 ms-2 overflow-auto paymentConf scroll"
        id="tableSection"
        style={{ height: "100vh" }}
      >
        <Table hover size="sm">
          <thead>
            <tr>
              {paymentCompanyData.length > 0 &&
                (filterCompanyStatus === "" ||
                  filterCompanyStatus.split("-")[0] === "New") ? (
                <th scope="col">
                  <input
                    type="checkbox"
                    title="Select All"
                    style={{ cursor: "pointer" }}
                    className="form-check-input"
                    id="vendorSelectAll"
                    onClick={() => handleSelectAll("vendorSelectAll", true, "")}
                  />
                </th>
              ) : paymentCompanyData.length > 0 &&
                filterCompanyStatus.split("-")[0] === "Under Process" ? (
                <th scope="col">
                  <input
                    type="checkbox"
                    title="Select All"
                    style={{ cursor: "pointer" }}
                    className="form-check-input"
                    id="vendorSelectAll"
                    onClick={() =>
                      handleSelectAll("vendorSelectAll", true, "Under Process")
                    }
                  />
                </th>
              ) : (
                <th></th>
              )}
              <th scope="col">
                <small className="" style={customHeadingStyle}>
                  #
                </small>
              </th>
              <th scope="col">
                <small className="" style={customHeadingStyle}>
                  Date
                </small>
              </th>
              <th scope="col">
                <small className="" style={customHeadingStyle}>
                  Vendor
                </small>
              </th>
              <th scope="col">
                <small className="" style={customHeadingStyle}>
                  Campaign
                </small>
              </th>
              <th scope="col">
                <small className="" style={customHeadingStyle}>
                  Total
                </small>
              </th>
              <th scope="col">
                <small className="" style={customHeadingStyle}>
                  TDS
                </small>
              </th>
              <th scope="col">
                <small className="" style={customHeadingStyle}>
                  GST
                </small>
              </th>
              <th scope="col">
                <small className="" style={customHeadingStyle}>
                  Percentage
                </small>
              </th>
              <th scope="col">
                <small className="" style={customHeadingStyle}>
                  Payable
                </small>
              </th>
              <th scope="col">
                <small className="" style={customHeadingStyle}>
                  Financial
                </small>
              </th>
              <th scope="col">
                <small className="" style={customHeadingStyle}>
                  POC
                </small>
              </th>
              <th scope="col">
                <small className="" style={customHeadingStyle}>
                  Invoice
                </small>
              </th>
              <th scope="col">
                <small className="" style={customHeadingStyle}>
                  GST
                </small>
              </th>
              <th scope="col">
                <small className="" style={customHeadingStyle}>
                  Status
                </small>
              </th>
              <th scope="col" className="text-center">
                <small className="" style={customHeadingStyle}>
                  Type
                </small>
              </th>
              <th scope="col" className="text-center">
                <small className="" style={customHeadingStyle}>
                  Mode
                </small>
              </th>
              <th scope="col" className="text-center">
                <small className="" style={customHeadingStyle}>
                  Action
                </small>
              </th>
            </tr>
          </thead>
          <tbody className="mx-auto my-0">
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td style={{ width: "10%" }}>
                <input className="form-control form-control-sm " type="text" placeholder="Vendor... " onChange={(e) => handleSearchChange("vendor", e.target.value, initialPaymentCompanyData[0].isCompany)} aria-label=".form-control-sm example" />
              </td>
              <td style={{ width: "10%" }}>
                <input className="form-control form-control-sm " type="text" placeholder="Campaign... " onChange={(e) => handleSearchChange("campaign", e.target.value, initialPaymentCompanyData[0].isCompany)} aria-label=".form-control-sm example" />
              </td>
              <td><Badge bg="success">&#8377;{TotalAmount}</Badge></td>
              <td></td>
              <td></td>
              <td></td>
              <td><Badge bg="warning" style={{color: "black"}}>&#8377;{TotalPayableAmount}</Badge></td>
              <td></td>
              <td style={{ width: "10%" }}>
                <input className="form-control form-control-sm " type="text" placeholder="POC... " onChange={(e) => handleSearchChange("poc", e.target.value, initialPaymentCompanyData[0].isCompany)} aria-label=".form-control-sm example" />
              </td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            {loading ? (
              <tr>
                <td colSpan={18}>
                  <center>
                    <Spinner animation="border" />
                  </center>
                </td>
              </tr>
            ) : paymentCompanyData.length === 0 ? (
              <tr>
                <td colSpan={18}>
                  <center>
                    <b className="text-danger">No Data</b>
                  </center>
                </td>
              </tr>
            ) : (
              paymentCompanyData &&
              paymentCompanyData.map((i, index) => (
                <tr
                  key={i._id}
                  className="tableRow align-baseline"
                  id={`${index}-paymentCompanyData`}
                  style={{
                    backgroundColor: i.invoice ? "#d2f4ea" : "none",
                  }}
                >
                  {i.payment_Status === "" ? (
                    <td>
                      <input
                        type="checkbox"
                        className="form-check-input multipleStatus"
                        title="Select"
                        style={{ cursor: "pointer" }}
                        id={i._id}
                        onClick={() =>
                          handleCheckboxClick(i._id, "Under Process")
                        }
                      />
                    </td>
                  ) : filterCompanyStatus.split("-")[0] === "Under Process" ? (
                    <td>
                      <input
                        className="form-check-input multipleStatus"
                        type="checkbox"
                        id={i._id}
                        style={{ cursor: "pointer" }}
                        title="Select"
                        onClick={() => handleCheckboxClick(i._id, "Done")}
                      />
                    </td>
                  ) : (
                    <td></td>
                  )}
                  <td
                    style={CustomRowStyle}
                    onClick={() =>
                      handleCompanyView(i._id, `${index}-paymentCompanyData`)
                    }
                  >
                    {index + 1}
                  </td>
                  <td
                    style={customBadgeStyle}
                    onClick={() =>
                      handleCompanyView(i._id, `${index}-paymentCompanyData`)
                    }
                  >
                    <IdToDate id={i._id} />
                  </td>
                  {i.company ? (
                    <td
                      style={CustomRowStyle}
                      onClick={() =>
                        handleCompanyView(i._id, `${index}-paymentCompanyData`)
                      }
                      title={i.company.name}
                    >
                      {i.company.name.substring(0, 5) + "..."}
                    </td>
                  ) : (
                    <td>---</td>
                  )}
                  <td
                    style={CustomRowStyle}
                    onClick={() =>
                      handleCompanyView(i._id, `${index}-paymentCompanyData`)
                    }
                  >
                    {i.campaign ? i.campaign.name : "---"}
                  </td>
                  <td
                    style={customBadgeStyle}
                    onClick={() =>
                      handleCompanyView(i._id, `${index}-paymentCompanyData`)
                    }
                    className="text-center"
                  >
                    <span className="edit">
                      {i.cost ? (
                        <Badge bg="success">&#8377;{i.cost}</Badge>
                      ) : (
                        "---"
                      )}
                    </span>
                  </td>
                  <td
                    style={customBadgeStyle}
                    onClick={() =>
                      handleCompanyView(i._id, `${index}-paymentCompanyData`)
                    }
                    className="text-center"
                  >
                    <span className="edit">
                      {handleTDS(
                        parseInt(i.isGst ? i.cost : i.payableAmount),
                        i.pancard,
                        i.percentage,
                        i.isGst,
                        renderFinancialPayment(i.pancard)
                      )}
                    </span>
                  </td>
                  <td
                    style={customBadgeStyle}
                    onClick={() =>
                      handleCompanyView(i._id, `${index}-paymentCompanyData`)
                    }
                    className="text-center"
                  >
                    <span className="edit">
                      {i.gstAmount ? (
                        <Badge bg="success">&#8377;{i.gstAmount}</Badge>
                      ) : (
                        "---"
                      )}
                    </span>
                  </td>
                  <td
                    style={CustomRowStyle}
                    onClick={() =>
                      handleCompanyView(i._id, `${index}-paymentCompanyData`)
                    }
                    className="text-center"
                  >
                    {i.percentage ? i.percentage + "%" : "---"}
                  </td>
                  <td
                    style={customBadgeStyle}
                    onClick={() =>
                      handleCompanyView(i._id, `${index}-paymentCompanyData`)
                    }
                    className="text-center"
                  >
                    <span className="edit">
                      {i.payableAmount ? (
                        <Badge
                          bg="warning"
                          style={{ fontSize: "12px", color: "black" }}
                        >
                          &#8377;{i.payableAmount}
                        </Badge>
                      ) : (
                        "---"
                      )}
                    </span>
                  </td>
                  <td style={CustomRowStyle} className="text-center">
                    {handleFinancialPayment(i.pancard)}
                  </td>
                  <td
                    style={CustomRowStyle}
                    onClick={() =>
                      handleCompanyView(i._id, `${index}-paymentCompanyData`)
                    }
                  >
                    {i.createdby && i.createdby.fullName
                      ? i.createdby.fullName
                      : "---"}
                  </td>
                  <td style={CustomFontStyle} className="text-center">
                    {i.invoice ? (
                      <a
                        href={process.env.REACT_APP_BASE_URL + "/" + i.invoice}
                        className="text-primary"
                        target={"_blank"}
                        title="Download Invoice"
                      >
                        <FontAwesomeIcon icon={faCircleArrowDown} size="xl" />
                      </a>
                    ) : (
                      <FontAwesomeIcon
                        icon={faCircleArrowDown}
                        size="xl"
                        className=""
                      />
                    )}
                  </td>
                  <td style={CustomFontStyle} className="text-center">
                    {i.gstFile ? (
                      <a
                        href={process.env.REACT_APP_BASE_URL + "/" + i.gstFile}
                        className="text-primary"
                        target={"_blank"}
                        title="Download GST File"
                        download
                      >
                        <img
                          src={process.env.REACT_APP_BASE_URL + "/" + i.gstFile}
                          width={25}
                          height={25}
                          className="rounded-circle border border-danger border-2"
                        />
                      </a>
                    ) : (
                      "---"
                    )}
                  </td>
                  {i.payment_Status && !i.payment_Status.split(",") ? (
                    <td
                      style={CustomRowStyle}
                      onClick={() =>
                        handleCompanyView(i._id, `${index}-paymentCompanyData`)
                      }
                      id={`${index}-paymentCompanyData_status`}
                    >
                      {i.payment_Status}
                    </td>
                  ) : i.payment_Status && i.payment_Status.split(",") ? (
                    <td
                      style={CustomRowStyle}
                      onClick={() =>
                        handleCompanyView(i._id, `${index}-paymentCompanyData`)
                      }
                      id={`${index}-paymentCompanyData_status`}
                    >
                      {i.payment_Status.split(",")[0]}
                      <br />
                      {i.payment_Status.split(",")[1]}
                    </td>
                  ) : (
                    <td>---</td>
                  )}
                  <td className="text-center">
                    {i.typeOfPayment === "P" ? (
                      <FontAwesomeIcon icon={faP} />
                    ) : i.typeOfPayment === "L" ||
                      (i.paytmNumber !== "" && i.accountNo === "") ? (
                      <FontAwesomeIcon icon={faL} />
                    ) : i.status !== "R" ? (
                      <div>
                        <input
                          className="form-check-input"
                          type="radio"
                          name={`${"typeOfPayment"}_${index}`}
                          id="flexRadioDefault1"
                          style={{ cursor: "pointer" }}
                          onClick={() => handleRadioButton(i._id, "P")}
                        />{" "}
                        <label
                          className="form-check-label"
                          htmlFor="flexRadioDefault1"
                        >
                          P
                        </label>
                        &nbsp;&nbsp;
                        <input
                          className="form-check-input"
                          type="radio"
                          name={`${"typeOfPayment"}_${index}`}
                          id="flexRadioDefault2"
                          style={{ cursor: "pointer" }}
                          onClick={() => handleRadioButton(i._id, "L")}
                        />{" "}
                        <label
                          className="form-check-label"
                          htmlFor="flexRadioDefault2"
                        >
                          L
                        </label>
                      </div>
                    ) : (
                      <></>
                    )}
                  </td>
                  <td style={CustomFontStyle} className="text-center">
                    {i.accountNo ? (
                      <Badge bg="success">Bank</Badge>
                    ) : (
                      <Badge>Paytm</Badge>
                    )}
                  </td>
                  <td className="align-middle">
                    {i.payment_Status === "" && i.status !== "R" ? (
                      <>
                        <FontAwesomeIcon
                          onClick={() =>
                            handleSubmit(
                              i._id,
                              true,
                              `${index}-paymentCompanyData_status`,
                              "Under Process",
                              i.isGstRequested
                            )
                          }
                          icon={faCircleCheck}
                          style={{
                            cursor: "pointer",
                            marginRight: "10px",
                            fontSize: "20px",
                          }}
                          className="text-success"
                          title="Approve"
                        />
                        <FontAwesomeIcon
                          onClick={() => handleDelete(i._id, i.isCompany)}
                          icon={faCircleXmark}
                          style={{
                            cursor: "pointer",
                            fontSize: "20px",
                          }}
                          className="text-danger"
                          title="Delete"
                        />
                        {i.isGst && (
                          <>
                            &nbsp;&nbsp;
                            <input
                              type="checkbox"
                              className="form-check-input isGstPay"
                              style={{ cursor: "pointer" }}
                              title="GST Pay"
                              id={`${i._id}-gstPay`}
                              onClick={() =>
                                handleGstPay(`${i._id}-gstPay`, i.isGstPay)
                              }
                            />
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        {i.status !== "R" && i.status !== "F" ? (
                          <>
                            <FontAwesomeIcon
                              onClick={() =>
                                handleSubmit(
                                  i._id,
                                  true,
                                  `${index}-paymentCompanyData_status`,
                                  "Done",
                                  i.isGstRequested
                                )
                              }
                              icon={faCircleCheck}
                              style={{
                                cursor: "pointer",
                                marginRight: "10px",
                                fontSize: "20px",
                              }}
                              className="text-success"
                              title="Approve"
                            />
                            <FontAwesomeIcon
                              onClick={() =>
                                handleFail(i._id, i.isCompany, "Failed")
                              }
                              icon={faCircleExclamation}
                              style={{
                                cursor: "pointer",
                                fontSize: "20px",
                                marginRight: "10px",
                              }}
                              className="text-danger"
                              title="Fail"
                            />
                          </>
                        ) : (
                          <></>
                        )}
                        {i.paymentScreenshot ? (
                          <a
                            href={
                              process.env.REACT_APP_BASE_URL +
                              "/" +
                              i.paymentScreenshot
                            }
                            className="text-primary"
                            target={"_blank"}
                            title="Download Screenshot"
                            download
                          >
                            <img
                              src={
                                process.env.REACT_APP_BASE_URL +
                                "/" +
                                i.paymentScreenshot
                              }
                              width={25}
                              height={25}
                              style={{ marginRight: "10px" }}
                              className="rounded-circle border border-danger border-2"
                            />
                          </a>
                        ) : i.status !== "R" ? (
                          <FontAwesomeIcon
                            onClick={() => handleUpload(i._id)}
                            icon={faCircleArrowUp}
                            style={{
                              cursor: "pointer",
                              fontSize: "20px",
                              marginRight: "10px",
                            }}
                            className="text-primary"
                            title="Upload Payment Screenshot"
                          />
                        ) : (
                          <></>
                        )}
                        {i.gstScreenshot ? (
                          <a
                            href={
                              process.env.REACT_APP_BASE_URL +
                              "/" +
                              i.gstScreenshot
                            }
                            className="text-primary"
                            target={"_blank"}
                            title="Download Screenshot"
                            download
                          >
                            <img
                              src={
                                process.env.REACT_APP_BASE_URL +
                                "/" +
                                i.gstScreenshot
                              }
                              width={25}
                              height={25}
                              className="rounded-circle border border-danger border-2"
                            />
                          </a>
                        ) : i.isGstRequested && i.status !== "R" ? (
                          <>
                            <FontAwesomeIcon
                              onClick={() => uploadGstScreenshot(i._id)}
                              icon={faCircleArrowUp}
                              style={{
                                cursor: "pointer",
                                fontSize: "20px",
                              }}
                              className="text-primary"
                              title="Upload GST Screenshot"
                            />
                          </>
                        ) : (
                          <></>
                        )}
                      </>
                    )}
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </Table>
      </div >
      {remarksModal && <PaymentModal userParams={userParams} />
      }
    </>
  );
}

export default PaymentCompanyList;
