import { Badge } from "react-bootstrap";

function categoryFormatter(cell, row) {
  return (
    <>
      <div>
        {cell && cell.length > 0
          ? typeof cell === "string"
            ? cell.split(",").map((category, index) => (
                <>
                  <Badge key={index}>{category}</Badge> <br />
                </>
              ))
            : cell.map((category, index) => (
                <>
                  <Badge key={index}>{category.value}</Badge>
                  <br />
                </>
              ))
          : "NA"}
      </div>
    </>
  );
}
export default categoryFormatter;
