import React, { useState, useEffect } from "react";
import jwtDecode from "jwt-decode";
import { useHistory } from "react-router-dom";
import Header from "../../../components/common/header/header";
import SildeMenu from "../../../components/payment/common/slideMenu";
import Dashboard from "../../../admin/payments/manage/dashboard";
import dashboardService from "../../../services/dashboardService";
import useRole from "../../../hooks/useRole";
import campaignservice from "../../../services/campaignservice";
import RightSidebar from "../../../components/payment/common/rightSidebar";
import companyservice from "../../../services/companyservice";
import brandservice from "../../../services/brandservice";
import Footer from "../../../components/common/footer/footer";

function PaymentDashboard() {
  const customStyle = { fontSize: "13px", fontWeight: "500" };
  const colorStyle = { color: "#148A9C" };
  const widthStyle = { width: "19%" };
  const borderRadiusStyle = { borderRadius: "20px" };
  const [loading, setLoading] = useState(true);
  const [campaigns, setCampaigns] = useState([]);
  const [brands, setBrands] = useState([]);
  const [vendors, setVendors] = useState([]);

  const [stats, setStats] = useState({
    stats: {
      totalCampaign: "Loading...",
      totalLink: "Loading...",
      totalSubordinates: "Loading...",
      totalPending: "Loading...",
      totalApplied: "Loading...",
      totalFailed: "Loading...",
      totalRejected: "Loading...",
      totalSuccess: "Loading...",
      totalCompany: "Loading...",
      totalBrand: "Loading...",
    },
    subordinates: [
      {
        name: "Loading...",
        pending: "Loading...",
        applied: "Loading...",
        failed: "Loading...",
      },
    ],
  });

  const history = useHistory();
  const role = useRole();
  const id = jwtDecode(
    localStorage.getItem(process.env.REACT_APP_TOKEN_NAME)
  )._id;

  const handleCampaignDetails = (camp) => {
    history.push({
      pathname: "/admin/payment/campaign",
      state: {
        id: camp._id,
        name: camp.name,
        category: camp.category,
        deliverables: camp.deliverables,
        platform: camp.platform,
        // platformName: camp.platform.name,
        // platformId: camp.platform._id,
        brandName: camp.brandName.length > 0 ? camp.brandName[0] : "NA",
        paymentBudget: camp.totalPayment ? camp.totalPayment : "NA",
        paymentLimit: camp.paymentLimit ? camp.paymentLimit : "NA",
        createdby: camp.user ? camp.user : "NA",
      },
    });
  };

  const getInit = async () => {
    const dashboardStats = await dashboardService.DashboardStats();
    setStats(dashboardStats);
    const campaigns = await campaignservice.FetchLatestsCampaignsByStatus({
      id: id,
    });
    setCampaigns(campaigns);
    const vendors = await companyservice.getCompanies();
    setVendors(vendors);
    const brands = await brandservice.getAllBrands();
    setBrands(brands);

    setLoading(false);
  };

  useEffect(() => {
    getInit();
  }, []);

  return (
    <>
      <Header />
      <div className="container-fluid">
        <div className="row">
          <SildeMenu />
          <Dashboard
            role={role}
            stats={stats}
            vendors={vendors}
            brands={brands}
            loading={loading}
            widthStyle={widthStyle}
            colorStyle={colorStyle}
            customStyle={customStyle}
            borderRadiusStyle={borderRadiusStyle}
          />
          <RightSidebar
            loading={loading}
            campaigns={campaigns}
            customStyle={customStyle}
            handleCampaignDetails={handleCampaignDetails}
          />
        </div>
      </div>
      <Footer />
    </>
  );
}

export default PaymentDashboard;
