export default function AdminCard({ influencerCount }) {
  return (
    <>
      {influencerCount.map(
        (influencer) =>
          influencer._id !== null && (
            <div className="col-xl-3" key={influencer._id}>
              <div className="card h-100 shadow">
                <div className="card-body">
                  <h6 className="card-title">{influencer._id}</h6>
                  <p className="card-text">{influencer.count}</p>
                </div>
              </div>
            </div>
          )
      )}
    </>
  );
}
