import React from "react";
import { Badge } from "react-bootstrap";

function IdToDate({ id }) {
  return (
    <Badge bg="secondary" style={{ fontSize: "12px" }}>
      {new Date(parseInt(id.substring(0, 8), 16) * 1000).toLocaleDateString()}
    </Badge>
  );
}

export default IdToDate;
