import {
  faArrowsRotate,
  faCheckDouble,
  faFileCircleExclamation,
  faFileExcel,
  faHandsClapping,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Card from "react-bootstrap/Card";

export default function StatusCard({ stats }) {
  const fontStyle = { fontSize: "14px" };
  return (
    <>
      <div className="col-xl-4 mb-2">
        <Card
          style={{ cursor: "pointer" }}
          onClick={() => (window.location.href = "/admin/payment/status")}
        >
          <Card.Body>
            <div className="d-flex align-items-center justify-content-between">
              <div>
                <h6 className="card-title" style={{ color: "#148A9C" }}>
                  {stats.stats.totalPending}
                </h6>
                <p className="card-text  fw-bold" style={fontStyle}>
                  Pending
                </p>
              </div>
              <div>
                <p className="fs-5" style={{ color: "#148A9C" }}>
                  <FontAwesomeIcon icon={faArrowsRotate} />
                </p>
              </div>
            </div>
          </Card.Body>
        </Card>
      </div>
      <div className="col-xl-4 mb-2">
        <Card
          style={{ cursor: "pointer" }}
          onClick={() => (window.location.href = "/admin/payment/status")}
        >
          <Card.Body>
            <div className="d-flex align-items-center justify-content-between">
              <div>
                <h6 className="card-title" style={{ color: "#0D6EFD" }}>
                  {stats.stats.totalApplied}
                </h6>
                <p className="card-text  fw-bold" style={fontStyle}>
                  Applied
                </p>
              </div>
              <div>
                <p className="fs-5" style={{ color: "#0D6EFD" }}>
                  <FontAwesomeIcon icon={faHandsClapping} />
                </p>
              </div>
            </div>
          </Card.Body>
        </Card>
      </div>
      <div className="col-xl-4 mb-2">
        <Card
          style={{ cursor: "pointer" }}
          onClick={() => (window.location.href = "/admin/payment/status")}
        >
          <Card.Body>
            <div className="d-flex align-items-center justify-content-between">
              <div>
                <h6 className="card-title" style={{ color: "#35B669" }}>
                  {stats.stats.totalSuccess}
                </h6>
                <p className="card-text  fw-bold" style={fontStyle}>
                  Success
                </p>
              </div>
              <div>
                <p className="fs-5" style={{ color: "#35B669" }}>
                  <FontAwesomeIcon icon={faCheckDouble} />
                </p>
              </div>
            </div>
          </Card.Body>
        </Card>
      </div>
      <div className="col-xl-4">
        <Card
          style={{ cursor: "pointer" }}
          onClick={() => (window.location.href = "/admin/payment/status")}
        >
          <Card.Body>
            <div className="d-flex align-items-center justify-content-between">
              <div>
                <h6 className="card-title" style={{ color: "#4650DD" }}>
                  {stats.stats.totalFailed}
                </h6>
                <p className="card-text  fw-bold" style={fontStyle}>
                  Failed
                </p>
              </div>
              <div>
                <p className="fs-5" style={{ color: "#4650DD" }}>
                  <FontAwesomeIcon icon={faFileCircleExclamation} />
                </p>
              </div>
            </div>
          </Card.Body>
        </Card>
      </div>
      <div className="col-xl-4">
        <Card
          style={{ cursor: "pointer" }}
          onClick={() => (window.location.href = "/admin/payment/status")}
        >
          <Card.Body>
            <div className="d-flex align-items-center justify-content-between">
              <div>
                <h6 className="card-title" style={{ color: "#DC3545" }}>
                  {stats.stats.totalRejected}
                </h6>
                <p className="card-text  fw-bold" style={fontStyle}>
                  Rejected
                </p>
              </div>
              <div>
                <p className="fs-5" style={{ color: "#DC3545" }}>
                  <FontAwesomeIcon icon={faFileExcel} />
                </p>
              </div>
            </div>
          </Card.Body>
        </Card>
      </div>
    </>
  );
}
