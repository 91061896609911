import http from "./httpservices";

export async function getLimitedCategories() {
  const { data } = await http.get("category/showcategorylimit");
  return data;
}

export async function getSearchCategories(name) {
  const { data } = await http.get(`category/${name}`);
  return data;
}

export async function getCategoriesCount() {
  const { data } = await http.get("category/categoriescount");
  return data;
}

export async function getCategories() {
  const { data } = await http.get("category/");
  return data;
}

export async function addCategory(value) {
  const { data } = await http.post("category/", value);
  return data;
}

export async function findByName(search) {
  const { data } = await http.post('category/findbyname', {"name": search});
  return data;
}



export default {
    getCategories,
    getLimitedCategories,
    getSearchCategories,
    getCategoriesCount,
    findByName,
    addCategory
};
