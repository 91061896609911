import React, { useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import {
  faFilter,
  faBars,
  faHouseChimney,
  faSun,
  faMoon,
  faSignal,
  faSignOut,
  faEnvelopeCircleCheck,
  faFileInvoiceDollar,
  faUserPlus,
  faPeopleRoof,
  faPlug,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./css/header.css";
import { Button, Container, Nav, Navbar } from "react-bootstrap";
import useRole from "../../../hooks/useRole";
import useAuthAllow from "../../../hooks/useAuthAllow";
import { useEffect } from "react";
import jwtDecode from "jwt-decode";
import userservice from "../../../services/userservice";
import SideMenuCanvas from "./SideMenuCanvas";
import { faStackpath } from "@fortawesome/free-brands-svg-icons";

import ButtonGroup from "react-bootstrap/ButtonGroup";
import DropdownButton from "react-bootstrap/DropdownButton";

export default function Header({ title = "Media Payout Dashboard" }) {
  const [show, setShow] = useState(false);
  const [colorTheme, setColorTheme] = useState("dark");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const fy = `${new Date().getFullYear()}-${new Date().getFullYear() + 1}`;

  const [selectedFY, setSelectedFY] = useState(
    localStorage.getItem("x-FY") ? localStorage.getItem("x-FY") : "All"
  );

  localStorage.setItem("x-FY", selectedFY);

  const role = useRole();
  const authAccess = useAuthAllow();
  const activeName =
    window.location.href.split("/")[window.location.href.split("/").length - 1];

  if (
    localStorage.getItem(process.env.REACT_APP_TOKEN_NAME) === null ||
    localStorage.getItem(process.env.REACT_APP_TOKEN_NAME) === ""
  ) {
    window.location.href = "/";
  }
  if (!window.location.href.indexOf("categories") > 0) {
    if (role["role"] === "Admin") {
    } else if (window.location.href.indexOf("profile") > 0) {
    } else if (!authAccess[role["role"]].includes(activeName)) {
      window.location.href = "/";
    }
  }

  const handleLogout = () => {
    localStorage.removeItem("username");
    localStorage.removeItem(process.env.REACT_APP_TOKEN_NAME);
    window.location.href = "/";
  };

  const Init = async () => {
    const userID = jwtDecode(
      localStorage.getItem(process.env.REACT_APP_TOKEN_NAME)
    )._id;
    let respone = await userservice.checkActiveUser({ id: userID });

    if (!respone.isActive) {
      localStorage.removeItem(process.env.REACT_APP_TOKEN_NAME);
      window.location.href = "/";
    }
  };

  useEffect(() => {
    Init();
    if (!localStorage.getItem("theme")) localStorage.setItem("theme", "dark");
    document
      .querySelector("body")
      .setAttribute("data-bs-theme", localStorage.getItem("theme"));
  }, []);

  const handleThemeChange = () => {
    const colorSet = localStorage.getItem("theme") == "dark" ? "light" : "dark";
    document.querySelector("body").setAttribute("data-bs-theme", colorSet);
    localStorage.setItem("theme", colorSet);
    setColorTheme(colorSet);
  };

  const variant = "warning";

  const handleChangeFY = (fy) => {
    setSelectedFY(fy);
    window.location.reload();
  };

  return (
    <>
      <Navbar expand="sm" className="bg-body-tertiary">
        <Container fluid={true}>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav style={{ marginRight: "15%" }}>
              <Button onClick={handleShow} size="sm">
                <FontAwesomeIcon icon={faBars} />
              </Button>
              {/* <Nav.Link
                className={activeName === "dashboard" && "active"}
                href="/admin/payment/dashboard/home"
              >
                <FontAwesomeIcon icon={faHouseChimney} />
              </Nav.Link> */}

              <DropdownButton
                as={ButtonGroup}
                key={variant}
                id={`dropdown-variants-${variant}`}
                variant={variant.toLowerCase()}
                title={`Current FY ${selectedFY}`}
                size="sm"
                className="ms-2"
                style={{ color: "white" }}
              >
                <Dropdown.Item
                  eventKey="1"
                  onClick={() => handleChangeFY("All")}
                >
                  All
                </Dropdown.Item>
                <Dropdown.Item
                  eventKey="2"
                  onClick={() => handleChangeFY("2024-2025")}
                >
                  2024-2025
                </Dropdown.Item>
                <Dropdown.Item
                  eventKey="3"
                  onClick={() => handleChangeFY("2023-2024")}
                >
                  2023-2024
                </Dropdown.Item>
                <Dropdown.Item
                  eventKey="4"
                  onClick={() => handleChangeFY("2022-2023")}
                >
                  2022-2023
                </Dropdown.Item>
              </DropdownButton>
            </Nav>

            <Navbar.Brand
              className="mx-auto"
              style={{ cursor: "pointer" }}
              // onClick={() =>
              //   (window.location.href = "/admin/payment/dashboard/home")
              // }
            >
              <span style={{ fontWeight: "bold" }}>{title}</span>
            </Navbar.Brand>
            <Nav>
              <Nav.Link>
                <Button variant="light" onClick={handleThemeChange} size="sm">
                  <FontAwesomeIcon
                    icon={colorTheme == "dark" ? faSun : faMoon}
                  />
                </Button>
              </Nav.Link>
            </Nav>
            <Nav>
              <Dropdown>
                <Dropdown.Toggle variant="success" className="dropItem py-1">
                  {localStorage.getItem("username")}
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropItemMenu">
                  {
                    <>
                      {role["role"] === "Admin" &&
                        role["isSuperUser"] === true && (
                          <>
                            <Dropdown.Item
                              href="/admin/stats"
                              className="dropItem"
                            >
                              <FontAwesomeIcon
                                icon={faSignal}
                                className="me-1"
                              />
                              <small>Daily Stats</small>
                            </Dropdown.Item>
                            <Dropdown.Item
                              href="/admin/manage/role"
                              className="dropItem"
                            >
                              <FontAwesomeIcon
                                icon={faPeopleRoof}
                                className="me-1"
                              />
                              <small>Manage Role</small>
                            </Dropdown.Item>
                            <Dropdown.Item
                              href="/admin/emailscraping"
                              className="dropItem"
                            >
                              <FontAwesomeIcon
                                icon={faEnvelopeCircleCheck}
                                className="me-1"
                              />
                              <small>Email Scraping</small>
                            </Dropdown.Item>
                            <Dropdown.Item
                              href="/admin/manage/adduser"
                              className="dropItem"
                            >
                              <FontAwesomeIcon
                                icon={faUserPlus}
                                className="me-1"
                              />
                              <small>Add Sub User</small>
                            </Dropdown.Item>
                          </>
                        )}
                    </>
                  }
                  {
                    <>
                      {role["role"] === "Admin" && (
                        <>
                          <Dropdown.Item
                            href="/admin/payment/dashboard/home"
                            className="dropItem"
                          >
                            <FontAwesomeIcon
                              icon={faFileInvoiceDollar}
                              className="me-1"
                            />
                            <small>Payment</small>
                          </Dropdown.Item>
                        </>
                      )}
                    </>
                  }
                  {role["role"] === "Operation" && (
                    <Dropdown.Item
                      href="/admin/payment/link"
                      className="dropItem"
                    >
                      <FontAwesomeIcon icon={faSignal} className="me-1" />
                      <small>Payment</small>
                    </Dropdown.Item>
                  )}
                  {role["role"] === "Finance" && (
                    <Dropdown.Item
                      href="/admin/payment/confirmation"
                      className="dropItem"
                    >
                      <FontAwesomeIcon
                        icon={faFileInvoiceDollar}
                        className="me-1"
                      />
                      <small>Payment</small>
                    </Dropdown.Item>
                  )}
                  <Dropdown.Item
                    className={
                      activeName === "influencer dropItem" && "active dropItem"
                    }
                    href="/influencer"
                  >
                    <FontAwesomeIcon icon={faPlus} className="me-1" />
                    <small>Add Influencer</small>
                  </Dropdown.Item>

                  <Dropdown.Item onClick={handleLogout} className="dropItem">
                    <FontAwesomeIcon icon={faSignOut} className="me-1" />
                    <small>Logout</small>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <SideMenuCanvas show={show} handleClose={handleClose} />
    </>
  );
}
