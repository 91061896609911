import http from "./httpservices";

export async function getGroups() {
  const { data } = await http.get("/group/");
  return data;
}

export default {
  getGroups,
};
