import React, { useEffect, useState } from "react";
import PaymentDetails from "../../../components/payment/paymentInfo/paymentInfo";
import paymentservice from "../../../services/paymentlinkservice";

function PaymentInfo({ match }) {
  const [paymentInfo, setPaymentInfo] = useState({});

  const getInit = async () => {
    const linkInfo = await paymentservice.getPaymentLink({
      pathName: match["params"]["pathname"],
    });
    if (!linkInfo) {
      alert("not a valid url");
      return;
    }
    setPaymentInfo(linkInfo);
  };

  useEffect(() => {
    getInit();
  }, []);

  return <PaymentDetails paymentInfo={paymentInfo} />;
}

export default PaymentInfo;
