import React from "react";
import Login from "../components/login/login";

export default function Index() {
  if (
    (localStorage.getItem(process.env.REACT_APP_TOKEN_NAME) !== null) &
    (localStorage.getItem(process.env.REACT_APP_TOKEN_NAME) !== "")
  )
    window.location.href = "/admin/payment/dashboard/home";
  else {
    return (
      <div className="container-fluid m-0 h-100">
        <div className="row">
          <div
            className="col-xl-12 col-lg-6 px-5 py-5 text-center h-100"
            id="section-2"
          >
            <div className="my-0 mx-auto loginPage">
              {/* <div className="d-flex justify-content-center"> */}
              <h2 className="text-danger">Influencers-Portal</h2>
              {/* </div> */}
              {/* <figure className="d-flex justify-content-center">
                <img
                  className="w-25"
                  src="https://terareach.s3-ap-southeast-1.amazonaws.com/white-labeled-brands/qoruz_red.png"
                  alt=""
                />
              </figure> */}
              <p className="fw-bold">Welcome Back!</p>
              <Login />
            </div>
          </div>
          {/* <div
            className="col-xl-5 col-lg-6 bg-success px-5 py-5 h-100"
            id="section-1"
          >
            <h2 className="text-white">
              Transform the way you do influencer marketing.
            </h2>
            <br />
            <p className="text-white fs-5">
              Real influence can do wonders for your brand, but it takes a lot to
              earn real influence.Qoruz helps you simplify influencer marketing
              for your brand, and delivers impact at scale.Talk to us to see how
              we can help.
            </p>
            <br />
            <figure className="d-flex justify-content-center">
              <img
                className="login-image"
                src="https://app.qoruz.com/2db5bebf9585b8cd31c99f42edbb8b29.svg"
                alt=""
              />
            </figure>
            <br />
          </div> */}
        </div>
      </div>
    );
  }
}
