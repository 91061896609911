import React, { useState } from "react";
import jwtDecode from "jwt-decode";
import { Badge, Offcanvas, Pagination, Spinner, Table } from "react-bootstrap";
import IdToDate from "../../../admin/common/IdToDate";
import Footer from "../../../components/common/footer/footer";
import Header from "../../../components/common/header/header";
import TablePlaceHolder from "../../../placeholders/tablePlaceHolder";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { useFetchAllPayments } from "../../../react-query/hooks/useFetchAllPayments";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleArrowDown,
  faDownload,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import paymentlinkservice from "../../../services/paymentlinkservice";
import PaymentActions from "./PaymentActions";
import InfluencerCompanyLink from "./InfluencerCompanyLink";
import GSTAmountFormatter from "./GSTAmountFormatter";
import Dropdown from "react-bootstrap/Dropdown";

function PaymentStatus(props) {
  const customHeadingStyle = { fontSize: "11px" };
  const customFontStyle = { fontSize: "11px", fontWeight: "500" };
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [viewData, setViewData] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [filters, setFilters] = useState({ status: "", all: "" });
  const [finalSearchQuery, setFinalSearchQuery] = useState("");

  const [currentStatus, setCurrentStatus] = useState("All");

  let TotalAmount = 0;
  let TotalPayableAmount = 0;

  const handleShow = (row) => {
    setShow(true);
    setViewData(row);
  };

  const badgeColor = (status) => {
    let color = "info";
    switch (status) {
      case "S":
        color = "success";
        break;
      case "A":
        color = "warning";
        break;
      case "P":
        color = "primary";
        break;
      case "F":
        color = "secondary";
        break;
      case "R":
        color = "danger";
        break;
    }
    return color;
  };

  const selectOptions = {
    S: "SUCCESS",
    A: "APPLIED",
    P: "PENDING",
    PP: "PARTIAL",
    F: "FAILED",
    W: "WAITING",
    R: "REJECTED",
  };

  const handleSearchChange = (e) => {
    if (e.target.value.length === 0) {
      removeFilter("all");
    }

    setCurrentPage(1);
    setSearchQuery(e.target.value);
    if (e.target.value.length > 3) {
      addFilter("all", e.target.value);
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleCurrentStatus = (status) => {
    setCurrentStatus(status);
    if (status !== "All") {
      addFilter("status", status);
    } else {
      removeFilter("status");
    }
  };
  const addFilter = (key, value) => {
    setFilters((prevFilters) => {
      const newFilters = prevFilters
        ? { ...prevFilters, [key]: value }
        : { [key]: value };
      updateSearchQuery(newFilters);
      return newFilters;
    });
  };

  const removeFilter = (key) => {
    setFilters((prevFilters) => {
      if (!prevFilters) return null;
      const { [key]: removed, ...newFilters } = prevFilters;
      updateSearchQuery(newFilters);
      return Object.keys(newFilters).length > 0 ? newFilters : null;
    });
  };

  const updateSearchQuery = (filters) => {
    const query = filters
      ? Object.entries(filters)
          .map(([key, value]) => `${key}=${value}`)
          .join("&")
      : "";
    setFinalSearchQuery(query);
  };

  const {
    isLoading,
    isError,
    data: products,
    error,
  } = useFetchAllPayments({
    id: jwtDecode(localStorage.getItem(process.env.REACT_APP_TOKEN_NAME))._id,
    page: currentPage,
    search: finalSearchQuery,
  });

  const StatusData = [
    {
      name: "All",
      variant: "",
      action: "",
    },
    {
      name: "Pending",
      variant: "primary",
      action: "",
    },
    {
      name: "Applied",
      variant: "warning",
      action: "",
    },
    {
      name: "Waiting",
      variant: "info",
      action: "",
    },
    {
      name: "Failed",
      variant: "secondary",
      action: "",
    },
    {
      name: "Rejected",
      variant: "danger",
      action: "",
    },
    {
      name: "Success",
      variant: "success",
      action: "",
    },
  ];

  products &&
    products.data.data.map((row) => {
      TotalAmount += row.cost;
      TotalPayableAmount += parseInt(row.payableAmount);
    });

  return (
    <>
      <Header />
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="d-flex justify-content-between my-3">
              <Badge bg="light" className="text-dark align-self-end">
                {isLoading ? "XXXX" : products.data.totalItems}
              </Badge>

              <input
                type="search"
                aria-label="Search"
                className="form-control form-control-sm"
                placeholder="Search Campaign || Influencer || Pancard || Amount"
                autoComplete="off"
                style={{ width: "30%" }}
                name="search"
                value={searchQuery}
                onChange={handleSearchChange}
              />
              <Dropdown>
                <Dropdown.Toggle
                  variant={
                    currentStatus === "All"
                      ? ""
                      : badgeColor(currentStatus.charAt(0))
                  }
                  id="dropdown-basic"
                  size="sm"
                >
                  Status {currentStatus}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {StatusData.map((s, index) => (
                    <Dropdown.Item onClick={() => handleCurrentStatus(s.name)}>
                      {s.name}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
            {isLoading ? (
              <TablePlaceHolder />
            ) : products.data.data.length === 0 ? (
              <center className="my-4 text-danger">No Data</center>
            ) : (
              <table className="table table-sm table-striped">
                <thead>
                  <tr>
                    <th scope="col" style={customHeadingStyle}></th>
                    <th scope="col" style={customHeadingStyle}>
                      #
                    </th>
                    <th scope="col" style={customHeadingStyle}>
                      Date
                    </th>
                    <th scope="col" style={customHeadingStyle}>
                      Pay
                    </th>
                    <th scope="col" style={customHeadingStyle}>
                      Camp
                    </th>
                    <th scope="col" style={customHeadingStyle}>
                      Platform
                    </th>
                    <th scope="col" style={customHeadingStyle}>
                      Inf/Ven
                    </th>
                    <th scope="col" style={customHeadingStyle}>
                      Comment
                    </th>
                    <th scope="col" style={customHeadingStyle}>
                      POC
                    </th>
                    <th scope="col" style={customHeadingStyle}>
                      Amount{" "}
                      <Badge bg="success" style={{ fontSize: "12px" }}>
                        &#8377;{TotalAmount}
                      </Badge>
                    </th>
                    <th scope="col" style={customHeadingStyle}>
                      GST
                    </th>
                    <th scope="col" style={customHeadingStyle}>
                      %
                    </th>
                    <th scope="col" style={customHeadingStyle}>
                      Payable{" "}
                      <Badge
                        bg="warning"
                        style={{ fontSize: "12px", color: "black" }}
                      >
                        &#8377;{TotalPayableAmount}
                      </Badge>
                    </th>
                    <th scope="col" style={customHeadingStyle}>
                      Remarks
                    </th>
                    <th scope="col" style={customHeadingStyle}>
                      Status
                    </th>
                    <th scope="col" style={customHeadingStyle}>
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {products &&
                    products.data.data.map((row, index) => (
                      <tr key={index} className="tableRow">
                        <td style={customFontStyle}>
                          <FontAwesomeIcon
                            onClick={() => handleShow(row)}
                            icon={faEye}
                            title="View Details"
                            style={{ fontSize: "12px", cursor: "pointer" }}
                          />
                        </td>
                        <td style={customFontStyle}>{index + 1}</td>
                        <td style={customFontStyle}>
                          <IdToDate id={row._id} />
                        </td>
                        <td style={customFontStyle}>
                          {row.company && row.company.name ? (
                            <Badge bg="danger" style={{ fontSize: "11px" }}>
                              VEN
                            </Badge>
                          ) : (
                            <Badge bg="primary" style={{ fontSize: "11px" }}>
                              INF
                            </Badge>
                          )}
                        </td>
                        <td style={customFontStyle}>
                          {row.campaign && row.campaign.name
                            ? row.campaign.name
                            : "---"}
                        </td>
                        <td style={customFontStyle}>
                          {row.platform
                            ? row.platform.map((p) => p.name + " ")
                            : "---"}
                        </td>
                        <td style={customFontStyle}>
                          <InfluencerCompanyLink
                            cell={row.influencer?.username}
                            row={row}
                          />
                        </td>
                        <td style={customFontStyle}>
                          {row.comment ? row.comment : ""}
                        </td>
                        <td style={customFontStyle}>
                          {row.createdby && row.createdby.fullName
                            ? row.createdby.fullName
                            : "---"}
                        </td>
                        <td style={customFontStyle}>
                          {row.cost ? (
                            <Badge bg="success" style={{ fontSize: "12px" }}>
                              &#8377;{row.cost}
                            </Badge>
                          ) : (
                            ""
                          )}
                        </td>
                        <td style={customFontStyle}>
                          <GSTAmountFormatter cell={row.gstAmount} row={row} />
                        </td>
                        <td style={customFontStyle}>{row.percentage}%</td>
                        <td style={customFontStyle}>
                          {row.payableAmount ? (
                            <Badge
                              bg="warning"
                              style={{ fontSize: "12px", color: "black" }}
                            >
                              &#8377;{row.payableAmount}
                            </Badge>
                          ) : (
                            "NA"
                          )}
                        </td>
                        <td style={customFontStyle}>
                          {row.remarks ? row.remarks : ""}
                        </td>
                        <td style={customFontStyle}>
                          {row.status ? (
                            <Badge
                              pill
                              id={`${row._id}_status`}
                              bg={badgeColor(row.status)}
                              style={{ fontSize: "10px" }}
                            >
                              {selectOptions[row.status]}
                            </Badge>
                          ) : (
                            ""
                          )}
                        </td>
                        <td style={customFontStyle}>
                          <PaymentActions
                            cell={row.paymentScreenshot}
                            row={row}
                            rowIndex={index}
                          />
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            )}
          </div>
          {/* pagination */}
          <Pagination className="justify-content-center">
            <Pagination.Prev
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            />
            {[...Array(isLoading ? totalPages : products.data.totalPages)].map(
              (_, index) => {
                // Determine the range of pages to display based on the current page
                const startPage = Math.max(1, currentPage - 2);
                const endPage = Math.min(
                  isLoading ? totalPages : products.data.totalPages,
                  startPage + 4
                );

                // Only render the pagination item if it falls within the range
                if (index + 1 >= startPage && index + 1 <= endPage) {
                  return (
                    <Pagination.Item
                      key={index + 1}
                      active={index + 1 === currentPage}
                      onClick={() => handlePageChange(index + 1)}
                    >
                      {index + 1}
                    </Pagination.Item>
                  );
                }
              }
            )}
            <Pagination.Next
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={
                currentPage ===
                (isLoading ? totalPages : products.data.totalPages)
              }
            />
          </Pagination>
        </div>
      </div>
      <Footer />

      {/* offcanvas details */}
      <Offcanvas show={show} onHide={handleClose} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Details</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Table hover size="sm">
            <tbody className="mx-auto my-0">
              {isLoading ? (
                <tr>
                  <td colSpan={6}>
                    <center>
                      <Spinner animation="border" />
                    </center>
                  </td>
                </tr>
              ) : (
                viewData && (
                  <>
                    <tr>
                      <th scope="col">
                        <small className="" style={customHeadingStyle}>
                          Vendor
                        </small>
                      </th>
                      <td style={customFontStyle}>
                        {viewData.company ? viewData.company.name : "---"}
                      </td>
                    </tr>
                    <tr>
                      <th scope="col">
                        <small className="" style={customHeadingStyle}>
                          Influencer
                        </small>
                      </th>
                      <td style={customFontStyle}>
                        {viewData.influencer
                          ? viewData.influencer.username
                          : "---"}
                      </td>
                    </tr>
                    <tr>
                      <th scope="col">
                        <small className="" style={customHeadingStyle}>
                          Campaign
                        </small>
                      </th>
                      <td style={customFontStyle}>
                        {viewData.campaign && viewData.campaign.name
                          ? viewData.campaign.name
                          : "---"}
                      </td>
                    </tr>
                    <tr>
                      <th scope="col">
                        <small className="" style={customHeadingStyle}>
                          Description
                        </small>
                      </th>
                      <td style={customFontStyle}>
                        {viewData.description ? viewData.description : "---"}
                      </td>
                    </tr>
                    <tr>
                      <th scope="col">
                        <small className="" style={customHeadingStyle}>
                          Comment
                        </small>
                      </th>
                      <td style={customFontStyle}>
                        {viewData.deliverables ? viewData.deliverables : "---"}
                      </td>
                    </tr>
                    <tr>
                      <th scope="col">
                        <small className="" style={customHeadingStyle}>
                          Type of Expenses
                        </small>
                      </th>
                      <td style={customFontStyle}>
                        {viewData.expensesCategory
                          ? viewData.expensesCategory
                          : "---"}
                      </td>
                    </tr>
                    <tr>
                      <th scope="col">
                        <small className="" style={customHeadingStyle}>
                          Total Amount
                        </small>
                      </th>
                      <td>
                        {viewData.cost ? (
                          <Badge bg="success">&#8377;{viewData.cost}</Badge>
                        ) : (
                          "---"
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th scope="col">
                        <small className="" style={customHeadingStyle}>
                          GST Amount
                        </small>
                      </th>
                      <td>
                        {viewData.gstAmount ? (
                          <Badge bg="success">
                            &#8377;{viewData.gstAmount}
                          </Badge>
                        ) : (
                          "---"
                        )}
                        {viewData.gstFile && (
                          <>
                            &nbsp;&nbsp;
                            <a
                              href={
                                process.env.REACT_APP_BASE_URL +
                                "/" +
                                viewData.gstFile
                              }
                              className="text-primary"
                              target={"_blank"}
                              title="Download GST File"
                              download
                            >
                              <img
                                src={
                                  process.env.REACT_APP_BASE_URL +
                                  "/" +
                                  viewData.gstFile
                                }
                                width={25}
                                height={25}
                                className="rounded-circle border border-danger border-2"
                              />
                            </a>
                          </>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th scope="col">
                        <small className="" style={customHeadingStyle}>
                          Payable Amount
                        </small>
                      </th>
                      <td>
                        {viewData.payableAmount ? (
                          <Badge
                            bg="warning"
                            style={{ fontSize: "12px", color: "black" }}
                          >
                            &#8377;{viewData.payableAmount}
                          </Badge>
                        ) : (
                          "---"
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th scope="col">
                        <small className="" style={customHeadingStyle}>
                          POC
                        </small>
                      </th>
                      <td style={customFontStyle}>
                        {viewData.createdby && viewData.createdby.fullName
                          ? viewData.createdby.fullName
                          : "---"}
                      </td>
                    </tr>
                    <tr>
                      <th scope="col">
                        <small className="" style={customHeadingStyle}>
                          Approved By
                        </small>
                      </th>
                      {viewData.approvedby ? (
                        <td style={customFontStyle}>
                          {viewData.approvedby.split(",")[0]
                            ? viewData.approvedby.split(",")[0]
                            : "---"}
                          &nbsp;
                          {viewData.approvedby.split(",")[1] ? (
                            <h6 className="d-inline">
                              <Badge bg="secondary">
                                {new Date(
                                  parseInt(viewData.approvedby.split(",")[1])
                                ).toLocaleDateString(undefined, {
                                  day: "numeric",
                                  month: "numeric",
                                  year: "numeric",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                })}
                              </Badge>
                            </h6>
                          ) : (
                            <></>
                          )}
                        </td>
                      ) : (
                        <td style={customFontStyle}>---</td>
                      )}
                    </tr>
                    <tr>
                      <th scope="col">
                        <small className="" style={customHeadingStyle}>
                          Invoice
                        </small>
                      </th>
                      <td style={customFontStyle}>
                        {viewData.invoice ? (
                          <a
                            href={
                              process.env.REACT_APP_BASE_URL +
                              "/" +
                              viewData.invoice
                            }
                            className="text-primary"
                            target={"_blank"}
                            title="Download Invoice"
                          >
                            {viewData.invoice} &nbsp;
                            <FontAwesomeIcon
                              icon={faCircleArrowDown}
                              size="xl"
                            />
                          </a>
                        ) : (
                          "---"
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th scope="col">
                        <small className="" style={customHeadingStyle}>
                          Account Name
                        </small>
                      </th>
                      <td style={customFontStyle}>
                        {viewData.accountName ? viewData.accountName : "---"}
                      </td>
                    </tr>
                    <tr>
                      <th scope="col">
                        <small className="" style={customHeadingStyle}>
                          Account Number
                        </small>
                      </th>
                      <td style={customFontStyle}>
                        {viewData.accountNo ? viewData.accountNo : "---"}
                      </td>
                    </tr>
                    <tr>
                      <th scope="col">
                        <small className="" style={customHeadingStyle}>
                          IFSC Code
                        </small>
                      </th>
                      <td style={customFontStyle}>
                        {viewData.ifscCode ? viewData.ifscCode : "---"}
                      </td>
                    </tr>
                    <tr>
                      <th scope="col">
                        <small className="" style={customHeadingStyle}>
                          Pan Card Number
                        </small>
                      </th>
                      <td style={customFontStyle}>
                        {viewData.pancard ? viewData.pancard : "---"}
                        &nbsp;
                        {viewData.pancardFile ? (
                          <a
                            href={
                              process.env.REACT_APP_BASE_URL +
                              "/" +
                              viewData.pancardFile
                            }
                            className="text-primary"
                            target={"_blank"}
                            title="Download Pan Card"
                          >
                            <FontAwesomeIcon
                              icon={faCircleArrowDown}
                              size="xl"
                            />
                          </a>
                        ) : (
                          <span></span>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th scope="col">
                        <small className="" style={customHeadingStyle}>
                          Pancard Name
                        </small>
                      </th>
                      <td style={customFontStyle}>
                        {viewData.pancardName ? viewData.pancardName : "---"}
                      </td>
                    </tr>
                  </>
                )
              )}
            </tbody>
          </Table>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default PaymentStatus;
