import React from "react";
import "../influencer/css/influencer.css";
import Select from "react-select/creatable";
import { useEffect } from "react";
import { useState } from "react";
import makeAnimated from "react-select/animated";

const makeAnimatedComp = makeAnimated();

const Category = ({
  loading,
  customStyle,
  customStyles,
  userData,
  category,
  handleChange,
  handleNewCategoryChange,
}) => {
  const [options, setOptions] = useState([{ value: "", label: "" }]);

  const handleInputChange = (inputValue, actionMeta) => {
    handleNewCategoryChange(inputValue);

    if (actionMeta.action === "menu-close") {
    }
  };

  useEffect(() => {
    let opt = [];
    category.map((c) => {
      opt.push({ value: c.name, label: c.name });
    });
    setOptions(opt);
  }, [category]);

  return (
    <div className="col" style={customStyle}>
      <Select
        styles={customStyles}
        className="scroll mb-2"
        components={makeAnimatedComp}
        placeholder="Category"
        isMulti
        onChange={handleChange}
        options={options}
        isClearable={true}
        isSearchable={true}
        isLoading={loading}
        value={userData.categoryName}
        onInputChange={handleInputChange}
      // defaultInputValue={["name", "name2"]}
      // defaultMenuIsOpen={["Art"]}
      // defaultValue={"Art"}
      // closeMenuOnSelect={false}
      />
    </div>
  );
};

export default Category;
