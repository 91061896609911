import React, { useEffect, useState } from "react";
import Header from "../common/header/header";
import "./css/influencers.css";
import FilterSlideMenu from "../common/filterSlideMenu/FilterSlideMenu";
import platformservice from "../../services/platformservice";
import categoryservice from "../../services/categoryservice";
import filterInfluencerService from "../../services/filterInfluencerservice";

import InfluencerDataTable from "./CustomTable/InfluencerDataTable";
import Swal from "sweetalert2";
import Footer from "../common/footer/footer";

const Influencers = () => {
  const cursorPointer = { cursor: "pointer" };
  const globalFontStyle = { fontSize: "13px" };
  const fixedHeight = { height: "140px" };

  const [loading, setLoading] = useState(true);

  const [profiles, setProfiles] = useState([]);
  const [count, setCount] = useState(0);
  const [platforms, setPlatforms] = useState([]);
  const [categories, setCategories] = useState([]);
  const [statesAndCities, setStatesAndCities] = useState([]);

  const [showFilters, setShowfilters] = useState(null);

  const handleClearFilter = () => {
    setShowfilters([]);
  };

  const handleApplyFilter = (name) => {
    if (showFilters !== null) {
      if (showFilters.includes(name)) {
        setShowfilters((prevState) =>
          prevState.filter((item) => item !== name)
        );
        return;
      }
      setShowfilters((prevState) => [...prevState, name]);
    } else {
      setShowfilters([name]);
    }
  };

  const handleFollowersFilter = (min, max) => {
    if (parseInt(min) >= parseInt(max)) {
      Swal.fire({
        icon: "error",
        title: "Not a valid range",
        showConfirmButton: false,
        timer: 3000,
      });
      return;
    }

    let range = `${min}-${max}`;

    if (showFilters !== null) {
      setShowfilters((prevState) => [...prevState, range]);
    } else {
      setShowfilters([range]);
    }
  };

  const getInit = async () => {
    setLoading(true);
    const responseInfluencer = await filterInfluencerService.fetchInfluencers();
    setProfiles(responseInfluencer["data"]);
    setCount(responseInfluencer["count"]);
    setLoading(false);
  };

  useEffect(() => {
    getInit();
  }, []);

  return (
    <>
      <Header title={"Influencers Filter (BETA)"} />
      <div className="container-fulid">
        <div className="row mx-0">
          {/* <FilterSlideMenu
            loading={loading}
            platforms={platforms}
            categories={categories}
            statesAndCities={statesAndCities}
            cursorPointer={cursorPointer}
            globalFontStyle={globalFontStyle}
            fixedHeight={fixedHeight}
            showFilters={showFilters}
            handleClearFilter={handleClearFilter}
            handleApplyFilter={handleApplyFilter}
            handleFollowersFilter={handleFollowersFilter}
          /> */}
          <InfluencerDataTable
            loading={loading}
            profiles={profiles}
            count={count}
            setProfiles={setProfiles}
            setCount={setCount}
            setLoading={setLoading}
          />
        </div>
      </div>
      <br />
      <br />
      <Footer />
    </>
  );
};

export default Influencers;
