import { useEffect, useRef, useState } from "react";
import Swal from "sweetalert2";
import groupservice from "../../../services/groupservice";
import userservice from "../../../services/userservice";

export default function ManageRole({ id, currentRole }) {
  const borderStyle = { borderRadius: "0.5rem" };
  const customStyle = { fontSize: "13px" };
  const form = useRef(null);

  const [userDetails, setUserDetails] = useState({
    role: "",
  });
  const [groups, setGroups] = useState([]);

  const handleChange = (e) => {
    let { name, value } = e.target;
    setUserDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      Swal.fire({
        title: "Loading...",
        showConfirmButton: false,
        timer: 1500,
      });
      try {
        await userservice.getChangeRole(id, userDetails.role);
      } catch (error) {}
      Swal.close();
      Swal.fire({
        icon: "success",
        title: "Success",
        showConfirmButton: false,
        timer: 1500,
      });
      document.getElementById("id_fail").style.display = "none";
    } catch (error) {
      document.getElementById("id_fail").style.display = "block";
      Swal.close();
      Swal.fire({
        icon: "error",
        title: "Error",
        showConfirmButton: false,
        timer: 1500,
      });
    }
    window.location.reload();
  };

  const getInit = async () => {
    const response = await groupservice.getGroups();
    setGroups(response);
  };

  useEffect(() => {
    getInit();
  }, []);
  return (
    <>
      <div className="col-xl-9 mx-auto ">
        <div
          className=" shadow px-5 py-4 w-75 mx-auto my-auto formWidth"
          style={borderStyle}
        >
          <form onSubmit={onSubmit} ref={form}>
            <div className="mb-3 row">
              <label htmlFor="staticRole" className="col-sm-4 col-form-label">
                <strong>Current Role</strong>
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  readonly
                  className="form-control-plaintext"
                  id="statticRole"
                  value={currentRole}
                />
              </div>
            </div>
            <div className="mb-3 row">
              <label htmlFor="changeRole" className="col-sm-4 col-form-label">
                <strong>Change Role*</strong>
              </label>
              <div className="col-sm-8">
                <select
                  className="form-select w-100 border mx-auto rolesScroll"
                  aria-label="Default select example"
                  style={customStyle}
                  name="role"
                  onChange={handleChange}
                  required
                >
                  <option selected value="">
                    Select
                  </option>
                  {groups.map((group) => (
                    <option key={group._id} value={group._id}>
                      {group.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="text-center my-4">
              <button
                type="submit"
                className="btn btn-secondary w-50 "
                style={{ backgroundColor: "#031327f2" }}
              >
                Submit
              </button>
              <small
                id="id_fail"
                style={{ display: "none", fontSize: "13px" }}
                className="text-danger my-4"
              >
                Failure...
              </small>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
