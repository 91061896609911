import React, { useEffect } from "react";
import axios from "axios";
export default function Card({ name, bio, path, image }) {
  const profileUrl = `/profile/${name}`;

  return (
    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3">
      <a href={profileUrl} target={"_blank"} className="text-decoration-none">
        <div className="card h-100 shadow">
          <img src={image} className="card-img-top" alt="..." />
          <div className="card-body">
            <h6 className="card-title text-start">
              <a target={"_blank"} href={"https://www.instagram.com/" + name}>
                &#x00040;{name}
              </a>
            </h6>
            <p className="card-text " style={{ fontSize: "15px" }}>
              {bio}
            </p>
          </div>
        </div>
      </a>
    </div>
  );
}
