import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinusCircle, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import "../influencer/css/influencer.css";

const Manager = ({
  userData,
  customStyle,
  validateText,
  validateNumber,
  components,
  setComponents,
}) => {

  const handleAddComponent = () => {
    if (components.length >= 1) {
      var elements = document.getElementsByName("isActive");
      for (var i = 0; i < elements.length; i++) {
        if (components[i].isActive) {
          elements[i].setAttribute("checked", "");
        }
      }
    }
    setComponents([
      ...components,
      { agency: "", manager_name: "", manager_number: "", isActive: false },
    ]);
  };

  const handleRemoveComponent = (index) => {
    const list = [...components];
    list.splice(index, 1);
    if (list.length === 1) list[0].isActive = true;
    setComponents(list);
  };

  const handleComponentChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...components];
    list[index][name] = value;

    if (list.length === 1) list[0]["isActive"] = true;
    else if (list.length > 1) {
      var elements = document.getElementsByName("isActive");
      for (var i = 0; i < elements.length; i++) {
        if (elements[i].checked) list[i]["isActive"] = true;
        else list[i]["isActive"] = false;
      }
    }

    setComponents(list);
  };

  return (
    <>
      {components &&
        components.map((component, index) => (
          <>
            <div key={index} className="row mb-4">
              <div className="col equalWidth">
                <input
                  type="text"
                  className="form-control mb-2"
                  id={component.id}
                  placeholder="Agency Name"
                  name="agency"
                  value={component.agency ? component.agency : null}
                  onChange={(e) => handleComponentChange(e, index)}
                  onKeyUp={validateText}
                  style={customStyle}
                ></input>
              </div>
              <div className="col equalWidth">
                <input
                  type="text"
                  className="form-control mb-2"
                  id={component.id}
                  placeholder="Manager Name"
                  name="manager_name"
                  value={component.manager_name ? component.manager_name : null}
                  onChange={(e) => handleComponentChange(e, index)}
                  onKeyUp={validateText}
                  style={customStyle}
                ></input>
              </div>
              <div className="col equalWidth">
                <input
                  type="text"
                  className="form-control mb-2"
                  id={component.id}
                  placeholder="Manager's Phone Number"
                  name="manager_number"
                  minLength={10}
                  maxLength={10}
                  value={
                    component.manager_number ? component.manager_number : null
                  }
                  onChange={(e) => handleComponentChange(e, index)}
                  onKeyUp={validateNumber}
                  style={customStyle}
                ></input>
              </div>
              {components.length > 1 && (
                <div className="buttonWidth">
                  <input
                    className="form-check-input mx-auto mt-1"
                    type="radio"
                    value={true}
                    checked={userData.manager && userData.manager.isActive}
                    id={component.id}
                    name="isActive"
                    onClick={(e) => handleComponentChange(e, index)}
                    style={{ cursor: "pointer" }}
                    title="Active"
                  />
                </div>
              )}
              {components.length > 1 && (
                <div className="buttonWidth">
                  <FontAwesomeIcon
                    className="fs-5 mx-auto mt-1 text-danger"
                    style={{ cursor: "pointer" }}
                    icon={faMinusCircle}
                    onClick={() => handleRemoveComponent(index)}
                    title="Remove"
                  />
                </div>
              )}
            </div>
            <div className="row">
              <div className="col"></div>
              <div className="col"></div>
              <div className="col"></div>
              {components.length - 1 === index && (
                <div className="addButton" style={{ width: "5%" }}>
                  <FontAwesomeIcon
                    icon={faPlusCircle}
                    className="fs-5 mx-auto mt-1"
                    style={{ cursor: "pointer" }}
                    onClick={handleAddComponent}
                    title="Add"
                  />
                </div>
              )}
            </div>
          </>
        ))}
    </>
  );
};

export default Manager;
