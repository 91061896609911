import React from "react";
import { Spinner, Badge, Table, Offcanvas } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleArrowDown,
  faDownload,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import IdToDate from "../../../../admin/common/IdToDate";

function CompanyPaymentsDetails({
  customStyle,
  customHeadingStyle,
  CustomFontStyle,
  customBadgeStyle,
  view,
  viewData,
  show,
  handleClose,
  paymentData,
  handleView,
  handleClubLinksExport,
  loading = false,
}) {

  return (
    <>
      <Offcanvas show={show} onHide={handleClose} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Details ({paymentData && paymentData[0] && paymentData[0].status ? paymentData[0].status : "---"})</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Table hover size="sm">
            <tbody className="mx-auto my-0">
              {loading ? (
                <tr>
                  <td colSpan={6}>
                    <center>
                      <Spinner animation="border" />
                    </center>
                  </td>
                </tr>
              ) : (
                paymentData &&
                paymentData.map((t, index) => (
                  <>
                    <tr key={index} id={t._id}>
                      <th scope="col">
                        <small className="" style={customHeadingStyle}>
                          Vendor
                        </small>
                      </th>
                      <td style={CustomFontStyle}>
                        {t.company ? t.company.name : "---"}
                      </td>
                    </tr>
                    <tr key={index} id={t._id}>
                      <th scope="col">
                        <small className="" style={customHeadingStyle}>
                          Campaign
                        </small>
                      </th>
                      <td style={CustomFontStyle}>
                        {t.campaign && t.campaign.name ? t.campaign.name : "---"}
                      </td>
                    </tr>
                    <tr>
                      <th scope="col">
                        <small className="" style={customHeadingStyle}>
                          Description
                        </small>
                      </th>
                      <td style={CustomFontStyle}>
                        {t.description ? t.description : "---"}
                      </td>
                    </tr>
                    <tr>
                      <th scope="col">
                        <small className="" style={customHeadingStyle}>
                          Comment
                        </small>
                      </th>
                      <td style={CustomFontStyle}>
                        {t.deliverables ? t.deliverables : "---"}
                      </td>
                    </tr>
                    <tr>
                      <th scope="col">
                        <small className="" style={customHeadingStyle}>
                          Type of Expenses
                        </small>
                      </th>
                      <td style={CustomFontStyle}>
                        {t.expensesCategory ? t.expensesCategory : "---"}
                      </td>
                    </tr>
                    <tr>
                      <th scope="col">
                        <small className="" style={customHeadingStyle}>
                          Total Amount
                        </small>
                      </th>
                      <td>
                        {t.cost ? (
                          <Badge bg="success">&#8377;{t.cost}</Badge>
                        ) : (
                          "---"
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th scope="col">
                        <small className="" style={customHeadingStyle}>
                          GST Amount
                        </small>
                      </th>
                      <td>
                        {t.gstAmount ? (
                          <Badge bg="success">&#8377;{t.gstAmount}</Badge>
                        ) : (
                          "---"
                        )}
                        {t.gstFile && (
                          <>
                            &nbsp;&nbsp;
                            <a
                              href={
                                process.env.REACT_APP_BASE_URL + "/" + t.gstFile
                              }
                              className="text-primary"
                              target={"_blank"}
                              title="Download GST File"
                              download
                            >
                              <img
                                src={
                                  process.env.REACT_APP_BASE_URL + "/" + t.gstFile
                                }
                                width={25}
                                height={25}
                                className="rounded-circle border border-danger border-2"
                              />
                            </a>
                          </>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th scope="col">
                        <small className="" style={customHeadingStyle}>
                          Payable Amount
                        </small>
                      </th>
                      <td>
                        {t.payableAmount ? (
                          <Badge
                            bg="warning"
                            style={{ fontSize: "12px", color: "black" }}
                          >
                            &#8377;{t.payableAmount}
                          </Badge>
                        ) : (
                          "---"
                        )}
                        &nbsp;
                        {t.isClub ? (
                          <>
                            <FontAwesomeIcon
                              icon={faEye}
                              onClick={() => handleView(t.linkChild)}
                              style={{ cursor: "pointer", fontSize: "15px" }}
                              title="View Links"
                              className="text-primary"
                            />
                            &nbsp;
                            <FontAwesomeIcon
                              icon={faDownload}
                              onClick={() =>
                                handleClubLinksExport(t.linkChild, true)
                              }
                              style={{ cursor: "pointer", fontSize: "15px" }}
                              title="Export Links"
                              className="text-primary"
                            />
                          </>
                        ) : (
                          ""
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th scope="col">
                        <small className="" style={customHeadingStyle}>
                          POC
                        </small>
                      </th>
                      <td style={CustomFontStyle}>
                        {t.createdby && t.createdby.fullName
                          ? t.createdby.fullName
                          : "---"}
                      </td>
                    </tr>
                    <tr>
                      <th scope="col">
                        <small className="" style={customHeadingStyle}>
                          Approved By
                        </small>
                      </th>
                      {t.approvedby ? (
                        <td style={CustomFontStyle}>
                          {t.approvedby.split(",")[0]
                            ? t.approvedby.split(",")[0]
                            : "---"}
                          &nbsp;
                          {t.approvedby.split(",")[1] ? (
                            <h6 className="d-inline">
                              <Badge bg="secondary">
                                {new Date(
                                  parseInt(t.approvedby.split(",")[1])
                                ).toLocaleDateString(undefined, {
                                  day: "numeric",
                                  month: "numeric",
                                  year: "numeric",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                })}
                              </Badge>
                            </h6>
                          ) : (
                            <></>
                          )}
                        </td>
                      ) : (
                        <td style={CustomFontStyle}>---</td>
                      )}
                    </tr>
                    <tr>
                      <th scope="col">
                        <small className="" style={customHeadingStyle}>
                          Invoice
                        </small>
                      </th>
                      <td style={CustomFontStyle}>
                        {t.invoice ? (
                          <a
                            href={
                              process.env.REACT_APP_BASE_URL + "/" + t.invoice
                            }
                            className="text-primary"
                            target={"_blank"}
                            title="Download Invoice"
                          >
                            <FontAwesomeIcon icon={faCircleArrowDown} size="xl" />
                          </a>
                        ) : (
                          "---"
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th scope="col">
                        <small className="" style={customHeadingStyle}>
                          Account Name
                        </small>
                      </th>
                      <td style={CustomFontStyle}>
                        {t.accountName ? t.accountName : "---"}
                      </td>
                    </tr>
                    <tr>
                      <th scope="col">
                        <small className="" style={customHeadingStyle}>
                          Account Number
                        </small>
                      </th>
                      <td style={CustomFontStyle}>
                        {t.accountNo ? t.accountNo : "---"}
                      </td>
                    </tr>
                    <tr>
                      <th scope="col">
                        <small className="" style={customHeadingStyle}>
                          IFSC Code
                        </small>
                      </th>
                      <td style={CustomFontStyle}>
                        {t.ifscCode ? t.ifscCode : "---"}
                      </td>
                    </tr>
                    <tr>
                      <th scope="col">
                        <small className="" style={customHeadingStyle}>
                          Pan Card Number
                        </small>
                      </th>
                      <td style={CustomFontStyle}>
                        {t.pancard ? t.pancard : "---"}
                        &nbsp;
                        {t.pancardFile ? (
                          <a
                            href={
                              process.env.REACT_APP_BASE_URL + "/" + t.pancardFile
                            }
                            className="text-primary"
                            target={"_blank"}
                            title="Download Pan Card"
                          >
                            <FontAwesomeIcon icon={faCircleArrowDown} size="xl" />
                          </a>
                        ) : (
                          <span></span>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th scope="col">
                        <small className="" style={customHeadingStyle}>
                          Pancard Name
                        </small>
                      </th>
                      <td style={CustomFontStyle}>
                        {t.pancardName ? t.pancardName : "---"}
                      </td>
                    </tr>
                  </>
                ))
              )}
            </tbody>
          </Table>
        </Offcanvas.Body>
      </Offcanvas>
      {/* view data */}
      {view && (
        <div
          className=" p-2 mb-2 mt-2 shadow rounded-3 ms-2 overflow-auto"
          id="tableSection"
          style={{ fontSize: "12px" }}
        >
          {loading ? (
            <center>
              <Spinner animation="border" />
            </center>
          ) : viewData.length === 0 ? (
            <center>
              <b className="text-danger">No Data</b>
            </center>
          ) : (
            <Table hover size="sm">
              <thead>
                <tr>
                  <th scope="col">
                    <small className="">Date</small>
                  </th>
                  <th scope="col">
                    <small className="">Payment</small>
                  </th>
                  <th scope="col">
                    <small className="">Campaign</small>
                  </th>
                  <th scope="col">
                    <small className="">Platform</small>
                  </th>
                  <th scope="col">
                    <small className="">Influencer</small>
                  </th>
                  <th scope="col">
                    <small className="">POC</small>
                  </th>
                  <th scope="col">
                    <small className="">Total Amount</small>
                  </th>
                  <th scope="col">
                    <small className="">GST Amount</small>
                  </th>
                  <th scope="col">
                    <small className="">Percentage</small>
                  </th>
                  <th scope="col">
                    <small className="">Payable Amount</small>
                  </th>
                  <th scope="col">
                    <small className="">Remarks</small>
                  </th>
                  <th scope="col">
                    <small className="">Action</small>
                  </th>
                </tr>
              </thead>
              <tbody className="mx-auto my-0">
                {loading ? (
                  <tr>
                    <td colSpan={12}>
                      <center>
                        <Spinner animation="border" />
                      </center>
                    </td>
                  </tr>
                ) : (
                  viewData &&
                  viewData.map((link, index) => (
                    <tr key={index} id={index}>
                      <td style={customStyle}>
                        <IdToDate id={link._id} />
                      </td>
                      <td style={customStyle}>
                        <Badge bg={link.isCompany ? "primary" : "info"}>
                          {link.isCompany ? "Vendor" : "Influencer"}
                        </Badge>
                      </td>
                      <td
                        style={customStyle}
                        title={link.campaign ? link.campaign.name : "---"}
                      >
                        {link.campaign
                          ? link.campaign.name.substring(0, 10) + "..."
                          : "---"}
                      </td>
                      <td style={customStyle} className="">
                        {link.platform
                          ? link.platform.map((p) => p.name + " ")
                          : "---"}
                      </td>
                      <td style={customStyle} className="">
                        {link.influencer ? link.influencer.username : "---"}
                      </td>
                      <td style={customStyle} className="">
                        {link.createdby.fullName}
                      </td>
                      <td>
                        {link.cost ? (
                          <Badge bg="success" style={{ fontSize: "12px" }}>
                            &#8377;{link.cost}
                          </Badge>
                        ) : (
                          "---"
                        )}
                      </td>
                      <td>
                        {link.gstAmount ? (
                          <Badge bg="success" style={{ fontSize: "12px" }}>
                            &#8377;{link.gstAmount}
                          </Badge>
                        ) : (
                          "---"
                        )}
                      </td>
                      <td style={customStyle} className="text-center">
                        {link.percentage}%
                      </td>
                      <td>
                        {link.payableAmount ? (
                          <Badge
                            bg="warning"
                            style={{ fontSize: "12px", color: "black" }}
                          >
                            &#8377;{link.payableAmount}
                          </Badge>
                        ) : (
                          "---"
                        )}
                      </td>
                      {link.payment_Status ? (
                        <td style={customStyle}>
                          <b>{link.payment_Status}</b>
                        </td>
                      ) : (
                        <td style={customStyle} className="text-center">
                          ---
                        </td>
                      )}
                      <td className="text-center">
                        {link.paymentScreenshot ? (
                          <a
                            href={
                              process.env.REACT_APP_BASE_URL +
                              "/" +
                              link.paymentScreenshot
                            }
                            className="text-primary"
                            target={"_blank"}
                            title="Download Screenshot"
                            download
                          >
                            <img
                              src={
                                process.env.REACT_APP_BASE_URL +
                                "/" +
                                link.paymentScreenshot
                              }
                              width={25}
                              height={25}
                              className="rounded-circle border border-danger border-2"
                            />
                          </a>
                        ) : (
                          "---"
                        )}
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </Table>
          )}
        </div>
      )}
    </>
  );
}

export default CompanyPaymentsDetails;
