import { faCircleArrowDown, faFilter } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Spinner, Table } from "react-bootstrap";
import FinanceCampaignPlaceHolder from "../../../../placeholders/financeCampaignPlaceHolder";

function InfluencerPayment({
  customHeadingStyle,
  loading,
  campaigns,
  CustomRowStyle,
  handleShow,
  handleDownload,
  CustomFontStyle,
}) {
  return (
    <>
      <Table hover size="sm">
        <thead>
          <tr>
            <th scope="col">
              <small className="" style={customHeadingStyle}>
                #
              </small>
            </th>
            <th scope="col">
              <small className="" style={customHeadingStyle}>
                Campaign
              </small>
            </th>
            <th scope="col">
              <small className="" style={customHeadingStyle}>
                Count
              </small>
            </th>
            <th scope="col">
              <small className="" style={customHeadingStyle}>
                Platform
              </small>
            </th>
            <th scope="col">
              <small className="" style={customHeadingStyle}>
                Download
              </small>
            </th>
          </tr>
        </thead>
        {loading ? (
          <FinanceCampaignPlaceHolder />
        ) : campaigns.length === 0 ? (
          <tr>
            <td colSpan={5}>
              {" "}
              <center>
                <b className="text-danger">No Data</b>
              </center>{" "}
            </td>
          </tr>
        ) : (
          <tbody className="mx-auto my-0">
            {campaigns &&
              campaigns.map((p, index) => (
                <tr key={p._id} id={index} className="align-middle">
                  <td
                    style={CustomRowStyle}
                    className=""
                    onClick={() => handleShow(index, p._id)}
                  >
                    {index + 1}
                  </td>
                  <td
                    style={CustomRowStyle}
                    className=""
                    onClick={() => handleShow(index, p._id)}
                  >
                    {p.campaignName[0]}
                  </td>
                  <td
                    style={CustomRowStyle}
                    className=""
                    onClick={() => handleShow(index, p._id)}
                  >
                    {p.count}
                  </td>
                  <td
                    style={CustomRowStyle}
                    className=""
                    onClick={() => handleShow(index, p._id)}
                  >
                    {p.platformName[0]}
                  </td>
                  <td style={CustomFontStyle} className="text-center">
                    <FontAwesomeIcon
                      icon={faCircleArrowDown}
                      title="Download User Details"
                      className="text-primary"
                      style={{ fontSize: "15px", cursor: "pointer" }}
                      onClick={() =>
                        handleDownload(p._id, p.campaignName[0], false)
                      }
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        )}
      </Table>
    </>
  );
}

export default InfluencerPayment;
