import React, { useState } from "react";
import influencerservice from "../../../../services/influencerservice";
import instagramservice from "../../../../services/instagramservice";
import influencerCostsService from "../../../../services/influencercostsservice";
import Swal from "sweetalert2";

function InputInstagramUrl({
  setUserData,
  customStyle,
  userData,
  setInputText,
  setYoutubeData,
  components,
  setComponents,
  setCostData,

}) {
  const [loading, setLoading] = useState(false);

  const validURL = (str) => {
    var pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator
    return !!pattern.test(str);
  };

  const handleValidateUrl = async (e) => {
    let { name, value } = e.target;
    setUserData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    if (validURL(value)) {
      setLoading(true);
      const resp = await instagramservice.getUserDetails({ url: value });
      if (resp.status !== 200){
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: resp.data,
          timer: 3000
        });
        return;
      }

      
      const influencer = await influencerservice.getInfluencerDataByUsername(
        value.split("/")[3].split("?")[0]
      );
      
      if (influencer) {
        setUserData(influencer);
  
        if (influencer.manager && influencer.manager.length !== 0) {
          setComponents(influencer.manager);
        } else {
          setComponents(components);
        }
  
        if (influencer.manager && influencer.manager.length !== 0) {
          var elements = document.getElementsByName("isActive");
          for (var i = 0; i < elements.length; i++) {
            if (influencer.manager[i].isActive) {
              elements[i].setAttribute("checked", "");
            }
          }
        }
        if (influencer.youtubeData) {
          setYoutubeData(influencer.youtubeData);
        }
  
        const influencerCost = await influencerCostsService.getInfluencerCost({
          id: influencer["_id"],
        });
        if (influencerCost.length > 0) {
          setCostData(influencerCost[0]);
        }
      }

      if (resp["data"].success) {
        setUserData((prevState) => ({
          ...resp["data"],
        }));
        setInputText(resp["data"].username.toLowerCase());
      }
      if (influencer) {
        setUserData((prevState) => ({
          ...influencer,
        }));

        if (influencer.youtubeData) {
          setYoutubeData((prevState) => ({
            ...prevState,
            ...influencer.youtubeData,
          }));
        }
      }
      setUserData((prevState) => ({
        ...prevState,
        ["country"]: "India",
      }));
    }
    setLoading(false);
  };

  return (
    <>
      <input
        type="url"
        className="form-control mb-2"
        id="formGroupExampleInput5"
        placeholder="Instagram Url*"
        name="instagramUrl"
        autoFocus={true}
        
        value={
          userData && userData.username && userData.instagramUrl
            ? userData.instagramUrl
            : null
        }
        onChange={handleValidateUrl}
        style={customStyle}
        required 
      ></input>
      {loading && <small className="text-danger">Loading...</small>}
    </>
  );
}

export default InputInstagramUrl;
