import http from "./httpservices";

export async function getAllBrands() {
  const { data } = await http.get("brand/");
  return data;
}

export async function Brand(params) {
  const { data } = await http.post("brand/", params);
  return data;
}

export default {
  getAllBrands,
  Brand,
};
