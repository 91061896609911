import { faCreativeCommonsBy } from "@fortawesome/free-brands-svg-icons";
import {
  faCirclePlus,
  faListCheck,
  faPenSquare,
  faRupee,
  faShareNodes,
  faTrash,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert, Card, Spinner } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import campaignservice from "../../../../services/campaignservice";
import Swal from "sweetalert2";
import jwtDecode from "jwt-decode";
import IdToDate from "../../../../admin/common/IdToDate";
import CampPlaceHolder from "../../../../placeholders/campPlaceHolder";

export default function Pending({
  loading,
  customStyle,
  borderRadiusStyle,
  campaigns,
  setCampaigns,
}) {
  const admin = jwtDecode(
    localStorage.getItem(process.env.REACT_APP_TOKEN_NAME)
  ).isAdmin;
  const superUser = jwtDecode(
    localStorage.getItem(process.env.REACT_APP_TOKEN_NAME)
  ).isSuperUser;
  const backgroundColorStyle = { color: "#3C44BC", backgroundColor: "#9398E6" };
  const history = useHistory();

  const CategoryFormatting = (categories) => {
    let categoryString = "";
    categories.map((c) => (categoryString += c.value + ", "));
    return categoryString.substring(0, categoryString.length - 2);
  };

  const handleDelete = async (id, campaignName) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-danger me-2",
        cancelButton: "btn btn-success me-2",
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          let response = await campaignservice.deleteCampaign({
            campaignId: id,
            campaignName: campaignName,
          });

          if (response.status === 201) {
            Swal.fire({
              icon: "error",
              title: response.data,
              showConfirmButton: false,
              timer: 3000,
            });
            return;
          }
          setCampaigns(campaigns.filter((campaign) => campaign._id !== id));
          swalWithBootstrapButtons.fire(
            "Deleted!",
            "Your file has been deleted.",
            "success"
          );
        }
      });
  };

  const handleLinkGenerate = (camp) => {
    if (camp.paymentLimit === "0") {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Payment Limit Exceeded",
        showConfirmButton: false,
        timer: 2000,
      });
      return;
    }

    history.push({
      pathname: `/admin/${camp.isOfficeExpense ? "expense" : "payment"}/link`,
      state: {
        id: camp._id,
        name: camp.name,
        platform: camp.platform,
        paymentLimit: camp.paymentLimit,
      },
    });
  };

  const editCampaign = async (id) => {
    const { value: totalPayment } = await Swal.fire({
      title: "Increase the Budget",
      input: "text",
      confirmButtonText: "Add",
      showCancelButton: true,
      inputPlaceholder: "Amount",
      inputValidator: (value) => {
        if (!value) {
          return "You need to write something!";
        }
      },
    });

    if (totalPayment) {
      await campaignservice.updateCampaignBudget({
        id: id,
        totalPayment: totalPayment,
      });
      await Swal.fire({
        position: "center",
        icon: "success",
        title: "Budget Updated Successfully!",
        showConfirmButton: false,
        timer: 1500,
      });
      window.location.reload();
    }
  };

  const handleCampaignDetails = (camp) => {
    history.push({
      pathname: "/admin/payment/campaign",
      state: {
        id: camp._id,
        name: camp.name,
        category: camp.category,
        deliverables: camp.deliverables,
        platform: camp.platform,
        // platformName: camp.platform.name,
        // platformId: camp.platform._id,
        brandName: camp.brand ? camp.brand : "NA",
        paymentBudget: camp.totalPayment ? camp.totalPayment : "NA",
        paymentLimit: camp.paymentLimit ? camp.paymentLimit : "NA",
        createdby: camp.createdby ? camp.createdby : "NA",
        isOfficeExpense: camp.isOfficeExpense,
      },
    });
  };

  const NoDataAlert = (
    <Alert variant="danger">
      <Alert.Heading>
        <center>No Campaign Data</center>
      </Alert.Heading>
    </Alert>
  );

  return (
    <>
      {loading ? (
        <CampPlaceHolder />
      ) : campaigns.length > 0 ? (
        campaigns.map((campaign, index) => (
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12" key={index}>
            <div
              className={
                campaign.isOfficeExpense
                  ? localStorage.getItem("theme") === "dark"
                    ? "card shadow p-3 bg-white text-dark"
                    : "card shadow p-3 bg-dark text-white"
                  : "card shadow p-3"
              }
              style={borderRadiusStyle}
            >
              <div className="card-body">
                <div className="d-flex justify-content-between">
                  <div
                    className="d-flex"
                    style={{ cursor: "pointer" }}
                    onClick={() => handleCampaignDetails(campaign)}
                  >
                    <FontAwesomeIcon
                      icon={faListCheck}
                      className="p-3 rounded-pill"
                      style={backgroundColorStyle}
                    />
                    &nbsp;&nbsp;
                    <small className="align-self-center mt-1">
                      {campaign.name ? campaign.name : "NA"}

                      <br />
                      <p style={{ ...customStyle, color: "#3C44BC" }}>
                        {campaign.category && campaign.category.length > 0
                          ? CategoryFormatting(campaign.category)
                          : "NA"}
                      </p>
                    </small>
                  </div>
                  <div>
                    <IdToDate id={campaign._id} />
                    &nbsp;
                    <FontAwesomeIcon
                      icon={faCirclePlus}
                      style={{
                        ...customStyle,
                        backgroundColor: "#3C44BC",
                        color: "#fff",
                      }}
                      className="btn rounded-pill"
                      title="Create"
                      onClick={() => handleLinkGenerate(campaign)}
                    />
                    &nbsp;
                    {superUser && (
                      <>
                        <FontAwesomeIcon
                          icon={faPenSquare}
                          style={{
                            ...customStyle,
                            backgroundColor: "#0FAC81",
                          }}
                          className="btn rounded-pill"
                          title="Edit"
                          onClick={() => editCampaign(campaign._id)}
                        />
                        &nbsp;
                      </>
                    )}
                    {admin && (
                      <FontAwesomeIcon
                        icon={faTrash}
                        style={customStyle}
                        className="btn btn-danger rounded-pill"
                        title="Delete"
                        onClick={() =>
                          handleDelete(campaign._id, campaign.name)
                        }
                      />
                    )}
                  </div>
                </div>
                <p
                  className="mt-2 mb-4"
                  style={{ ...customStyle, marginLeft: "10%" }}
                >
                  {campaign.deliverables
                    ? `${campaign.deliverables.substring(0, 80) + "..."}`
                    : "NA"}
                </p>
                <div className="d-flex justify-content-between">
                  <small className="align-self-end" style={customStyle}>
                    <FontAwesomeIcon
                      icon={faCreativeCommonsBy}
                      style={{ ...customStyle, color: "#3C44BC" }}
                    />
                    &nbsp;
                    {campaign.createdby ? campaign.createdby : "NA"}
                  </small>
                  <div>
                    <div className="d-inline-block me-3">
                      <div className="h6 mb-1 text-center" style={customStyle}>
                        {campaign.totalPayment ? campaign.totalPayment : "NA"}{" "}
                        <FontAwesomeIcon
                          icon={faRupee}
                          style={{ ...customStyle, color: "#3C44BC" }}
                        />
                      </div>
                      <small style={customStyle}>Budget </small>
                    </div>
                    <div className="d-inline-block me-3">
                      <div className="h6 mb-1 text-center" style={customStyle}>
                        {campaign.paymentLimit ? campaign.paymentLimit : "NA"}{" "}
                        <FontAwesomeIcon
                          icon={faRupee}
                          style={{ ...customStyle, color: "#3C44BC" }}
                        />
                      </div>
                      <small className="text-danger" style={customStyle}>
                        Limit{" "}
                      </small>
                    </div>
                    <div className="d-inline-block me-3">
                      <div className="h6 mb-1 text-center" style={customStyle}>
                        {campaign.platform
                          ? campaign.platform.map((p) => p.name + " ")
                          : "NA"}{" "}
                        <FontAwesomeIcon
                          icon={faShareNodes}
                          style={{ ...customStyle, color: "#3C44BC" }}
                        />
                      </div>
                      <small style={customStyle}>Platform</small>
                    </div>
                    <div className="d-inline-block">
                      <div className="h6 mb-1 text-center" style={customStyle}>
                        <span
                        >
                          {campaign.brand ? campaign.brand : "NA"}{" "}
                        </span>
                        <FontAwesomeIcon
                          icon={faUsers}
                          style={{ ...customStyle, color: "#3C44BC" }}
                        />
                      </div>
                      <small style={customStyle}>Brand</small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))
      ) : (
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
          {NoDataAlert}
        </div>
      )}
    </>
  );
}
