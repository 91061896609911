import http from "./httpservices";

export async function getCampaigns() {
  const { data } = await http.get("campaign/");
  return data;
}

export async function getCampaignsByStatus(id) {
  const { data } = await http.post("campaign/pending", id);
  return data;
}

export async function FetchLatestsCampaignsByStatus(id) {
  const { data } = await http.post("campaign/latest", id);
  return data;
}

export async function getCampaignById(params) {
  const { data } = await http.post("campaign/id", params);
  return data;
}

export async function getAllCampaigns() {
  const { data } = await http.get("campaign/allcampaign");
  return data;
}

export async function getEditCampaigns(params) {
  const { data } = await http.put("campaign/", params);
  return data;
}

export async function updateCampaign(params) {
  const { data } = await http.post("campaign/updatecampaign", params);
  return data;
}

export async function addCampaign(params) {
  const response = await http.post("campaign/", params);

  return response;
}

export async function deleteCampaign(params) {
  const response = await http.post("campaign/delete", params);
  return response;
}

export async function exportCampaign(id, influencers, exportColumn) {
  const { data } = await http.post("campaign/exportcampaign", {
    id: id,
    influencers: influencers,
    exportColumn: exportColumn,
  });
  return data;
}

export async function getUploadFile(formData) {
  const { data } = await http.post("campaign/upload", formData);
  return data;
}

export async function CampaignDetailStatus(params) {
  const { data } = await http.post("campaign/detail/status", params);
  return data;
}

export async function getSearchCampaign(params) {
  const { data } = await http.post("/campaign/search", params);
  return data;
}

export async function getCampaignsbyMonth(params) {
  const { data } = await http.post("/campaign/month/filter", params);
  return data;
}

export async function updateCampaignBudget(params) {
  const { data } = await http.post("/campaign/edit/budget", params);
  return data;
}

export async function updateCampaignPost(params) {
  const { data } = await http.post("/campaign/addpost", params);
  return data;
}

export default {
  getCampaigns,
  getCampaignsByStatus,
  getCampaignById,
  updateCampaign,
  getEditCampaigns,
  addCampaign,
  getAllCampaigns,
  exportCampaign,
  deleteCampaign,
  getUploadFile,
  CampaignDetailStatus,
  getSearchCampaign,
  getCampaignsbyMonth,
  updateCampaignBudget,
  FetchLatestsCampaignsByStatus,
  updateCampaignPost
};
