import _ from "lodash";
import {
  faCirclePlus,
  faListCheck,
  faPenSquare,
  faRupee,
  faShareNodes,
  faTrash,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { faCreativeCommonsBy } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import Placeholder from "react-bootstrap/Placeholder";
import Card from "react-bootstrap/Card";
import { Badge } from "react-bootstrap";
import jwtDecode from "jwt-decode";

function campPlaceHolder(props) {
  const customStyle = { fontSize: "13px" };
  const borderRadiusStyle = { borderRadius: "20px" };
  const backgroundColorStyle = { color: "#3C44BC", backgroundColor: "#9398E6" };

  const superUser = jwtDecode(
    localStorage.getItem(process.env.REACT_APP_TOKEN_NAME)
  ).isSuperUser;

  return (
    <>
      {_.range(1, 7).map((r) => (
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-2" key={r}>
          <div className="card shadow p-3 border-0" style={borderRadiusStyle}>
            <div className="card-body">
              <div className="d-flex">
                <FontAwesomeIcon
                  icon={faListCheck}
                  className="p-3 rounded-pill"
                  style={backgroundColorStyle}
                />
                &nbsp;&nbsp;&nbsp;
                <div>
                  <Placeholder
                    as={"div"}
                    animation="glow"
                    style={{ width: "100px" }}
                  >
                    <Placeholder xs={12} />
                  </Placeholder>
                  <Placeholder
                    as={"div"}
                    animation="glow"
                    style={{ width: "100px" }}
                  >
                    <Placeholder xs={12} />
                  </Placeholder>
                </div>
                <div className="ms-auto">
                  <Badge bg="dark" style={{ fontSize: "12px" }}>
                    00/0/0000
                  </Badge>
                  &nbsp;
                  <FontAwesomeIcon
                    icon={faCirclePlus}
                    style={{
                      ...customStyle,
                      backgroundColor: "#3C44BC",
                      color: "#fff",
                    }}
                    className="btn rounded-pill"
                    title="Create"
                  />
                  &nbsp;
                  {superUser && (
                    <>
                      <FontAwesomeIcon
                        icon={faPenSquare}
                        style={{
                          ...customStyle,
                          backgroundColor: "#0FAC81",
                          color: "#fff",
                        }}
                        className="btn rounded-pill"
                        title="Edit"
                      />
                      &nbsp;
                    </>
                  )}
                  {
                    <FontAwesomeIcon
                      icon={faTrash}
                      style={customStyle}
                      className="btn btn-danger rounded-pill"
                      title="Delete"
                    />
                  }
                </div>
              </div>
              <p
                className="text-muted mt-4 mb-4"
                style={{ ...customStyle, marginLeft: "10%" }}
              >
                <Placeholder as={Card.Text} animation="glow">
                  <Placeholder xs={4} />
                </Placeholder>
              </p>
              <div className="d-flex justify-content-between">
                <div className="d-flex">
                  &nbsp;
                  <div className="align-self-end">
                    <Placeholder
                      as={"div"}
                      animation="glow"
                      style={{ width: "100px" }}
                    >
                      <Placeholder xs={12} />
                    </Placeholder>
                  </div>
                </div>
                <div>
                  <div className="d-inline-block me-3">
                    <div className="h6 mb-1 text-center" style={customStyle}>
                      <Placeholder as="div" animation="glow">
                        <Placeholder xs={12} />
                      </Placeholder>
                    </div>
                    <small className="text-muted" style={customStyle}>
                      Budget{" "}
                    </small>
                  </div>
                  <div className="d-inline-block me-3">
                    <div className="h6 mb-1 text-center" style={customStyle}>
                      <Placeholder as="div" animation="glow">
                        <Placeholder xs={12} />
                      </Placeholder>
                    </div>
                    <small className="text-danger" style={customStyle}>
                      Limit{" "}
                    </small>
                  </div>
                  <div className="d-inline-block me-3">
                    <div className="h6 mb-1 text-center" style={customStyle}>
                      <Placeholder as="div" animation="glow">
                        <Placeholder xs={12} />
                      </Placeholder>
                    </div>
                    <small className="text-muted" style={customStyle}>
                      Platform
                    </small>
                  </div>
                  <div className="d-inline-block">
                    <div className="h6 mb-1 text-center" style={customStyle}>
                      <Placeholder as="div" animation="glow">
                        <Placeholder xs={12} />
                      </Placeholder>
                    </div>
                    <small className="text-muted" style={customStyle}>
                      Brand
                    </small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
}

export default campPlaceHolder;
