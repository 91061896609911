import React, { useEffect, useRef, useState } from "react";
import Header from "../header/header";
import countryservice from "../../../services/countryservice";
import cityservice from "../../../services/cityservice";
import stateservice from "../../../services/stateservice";
import categoryservice from "../../../services/categoryservice";
import Option from "../option/option";
import influencerservice from "../../../services/influencerservice";
import Swal from "sweetalert2";
import jwtDecode from "jwt-decode";
import Content from "../../payment/paymentLink/content";
import "../influencer/css/influencer.css";
import Manager from "./manager";
import Gender from "./gender";
import InputYoutubeChannelUrl from "./common/InputYoutubeChannelUrl";
import influencerCostsService from "../../../services/influencercostsservice";
import InputInstagramUrl from "./common/InputInstagramUrl";
import Category from "../select/category";
import Footer from "../footer/footer";

const customStyles = {
  control: (base, state) => ({
    ...base,
    background: localStorage.getItem('theme') === 'dark' ? '#212529' : 'light',
    color: state.isActive ? 'white' : '#212529',
    borderColor: localStorage.getItem('theme') === 'dark' ? '#495057' : 'light',
  }),
  option: (base, state) => ({
    ...base,
    backgroundColor: state.isSelected || localStorage.getItem('theme') === 'dark' ? '#212529' : 'light', // Option background color
    color: state.isSelected || localStorage.getItem('theme') === 'dark' ? 'light' : '#212529', // Option text color
  }),
};

const Influencer = () => {
  const borderStyle = { borderRadius: "0.5rem" };
  const customStyle = { fontSize: "13px" };
  const form = useRef(null);

  const [newCategory, setNewCategory] = useState("");
  const [inputText, setInputText] = useState("");
  const [searchContent, setSearchContent] = useState(false);
  const [showNumber, setShowNumber] = useState(false);
  const [loading, setLoading] = useState(true);
  const [category, setCategory] = useState([]);
  const [country, setCountry] = useState([]);
  const [state, setState] = useState([]);
  const [city, setCity] = useState([]);
  const [cursor, setCursor] = useState(null);
  const ref = useRef(null);

  const [costData, setCostData] = useState({
    staticCost: "",
    videoCost: "",
    storyCost: "",
    integratedCost: "",
    dedicatedCost: "",
    shortsCost: "",
    date: "",
  });

  const [youtubeData, setYoutubeData] = useState({
    youtubeUrl: "",
    channel: "",
    statistics: {},
  });

  const [userData, setUserData] = useState({
    country: "India",
    state: "",
    city: "",
    name: "",
    email: "",
    username: "",
    gender: "",
    instagramUrl: "",
    instaFollowers: "",
    categoryName: [],
    manager: "",
    number: "",
    youtubeData: {},
    blog: {
      blogTitle: "",
      blogUrl: ""
    },
    date: new Date(),
  });

  const [components, setComponents] = useState([
    { agency: "", manager_name: "", manager_number: "", isActive: true },
  ]);

  const openSearch = () => {
    setSearchContent(!searchContent);
  };

  const handleNewCategoryChange = (value) => {
    setNewCategory(value);
  };

  const validateText = (e) => {
    let result = e.target;
    result.value = result.value.replace(/[^a-zA-Z" "]+/, "");
  };

  const validateNumber = (e) => {
    let result = e.target;
    result.value = result.value.replace(/[^0-9]+/, "");
  };

  const handleCategoryChange = (e) => {
    setUserData((prevState) => ({
      ...prevState,
      ["categoryName"]: e,
    }));
  };

  const handleGenderChange = (e) => {
    setUserData((prevState) => ({
      ...prevState,
      ["gender"]: e.value,
    }));
  };

  const handleYoutubeChange = (e) => {
    let { name, value } = e.target;

    setYoutubeData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleChange = (e) => {
    let { name, value } = e.target;

    if (name === "category_name" && e.target.checked) {
      if (userData.category_name) {
        value += `,${userData.category_name}`;
      }
    }

    if (name === "blogTitle" || name === "blogUrl") {
      setUserData((prevUserData) => ({
        ...prevUserData,
        blog: {
          ...prevUserData.blog,
          [name]: value,
        },
      }));
      return;
    }

    setUserData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleInfluencerName = async (value, id) => {
    setInputText(value);
    setSearchContent(false);
    setUserData((prevState) => ({
      ...prevState,
      ["username"]: value,
    }));

    const response = await influencerservice.getInfluencerDataByUsername(value);

    if (response) {
      setUserData(response);

      if (response.manager && response.manager.length !== 0) {
        setComponents(response.manager);
      } else {
        setComponents(components);
      }

      if (response.manager && response.manager.length !== 0) {
        var elements = document.getElementsByName("isActive");
        for (var i = 0; i < elements.length; i++) {
          if (response.manager[i].isActive) {
            elements[i].setAttribute("checked", "");
          }
        }
      }
      if (response.youtubeData) {
        setYoutubeData(response.youtubeData);
      }

      const influencerCost = await influencerCostsService.getInfluencerCost({
        id: response["_id"],
      });
      if (influencerCost.length > 0) {
        setCostData(influencerCost[0]);
      }
    }
  };

  const ShowNumberBox = () => {
    setShowNumber(true);
  };

  const HideNumberBox = () => {
    setShowNumber(false);
  };

  const inputHandler = (e) => {
    const lowerCase = e.target.value.toLowerCase();
    setInputText(lowerCase);
    setCursor(e.target.selectionStart);
    clearState();
    form.current.reset();

    if (userData.username) {
      const categoryElements = document.getElementsByName("category_name");
      for (var j = 0; j < categoryElements.length; j++) {
        if (categoryElements[j].checked) {
          categoryElements[j].checked = false;
        }
      }

      const managerElements = document.getElementsByName("isActive");
      for (var j = 0; j < managerElements.length; j++) {
        if (managerElements[j].checked) {
          managerElements[j].checked = false;
        }
      }
    }

    setUserData((prevState) => ({
      ...prevState,
      ["username"]: lowerCase,
    }));
  };

  const handleCostChange = (e) => {
    let { name, value } = e.target;
    setCostData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      Swal.fire({
        title: "Loading...",
        showConfirmButton: false,
        timer: 1500,
      });
      userData["createdBy"] = jwtDecode(
        localStorage.getItem(process.env.REACT_APP_TOKEN_NAME)
      )._id;
      userData["isAddedPortal"] = true;

      let flag = false;

      for (let i = 0; i < components.length; i++) {
        if (
          components[i].manager_name === "" &&
          components[i].agency === "" &&
          components[i].manager_number !== ""
        ) {
          alert("Please fill manager name and Number!");
          return;
        }
      }

      let manager_data = components.filter(
        (component) => component.manager_number !== ""
      );

      for (let i = 0; i < manager_data.length; i++) {
        if (manager_data[i].isActive || manager_data[i].manager_name === "") {
          flag = true;
          break;
        }
      }

      if (!flag && manager_data.length >= 1) {
        alert("Please select the active Manager!");
        return;
      }

      userData["manager"] = manager_data;
      userData["youtubeData"] = youtubeData;

      try {
        const resp = await influencerservice.addNewInfluencers(userData);
        if (
          Object.values(costData).filter((a) => a !== "").length > 0 &&
          resp
        ) {
          costData["influencer"] = resp;
          await influencerservice.addInfluencerCost(costData);
        }
      } catch (error) { }

      Swal.close();
      Swal.fire({
        icon: "success",
        title: "Success",
        showConfirmButton: false,
        timer: 1500,
      });
      document.getElementById("id_fail").style.display = "none";
    } catch (error) {
      document.getElementById("id_fail").style.display = "block";
      Swal.close();
      Swal.fire({
        icon: "error",
        title: "Error",
        showConfirmButton: false,
        timer: 1500,
      });
    }
    window.location.reload();
  };

  //reset the form
  const clearState = () => {
    setUserData({});
    setCostData({});
    setComponents([
      { agency: "", manager_name: "", manager_number: "", isActive: false },
    ]);
  };

  const getStatesByCountryId = async (value) => {
    const id = value.split("_")[0];
    const name = value.split("_")[1];
    setUserData((prevState) => ({
      ...prevState,
      ["country"]: name,
    }));

    const states = await stateservice.getStatesById(id);
    setState(states);
  };

  const getCityByStateId = async (value) => {
    const id = value.split("_")[0];
    const name = value.split("_")[1];
    setUserData((prevState) => ({
      ...prevState,
      ["state"]: name,
    }));

    const cities = await cityservice.getCityById(id);
    setCity(cities);
  };

  const getInit = async () => {
    const responseCountry = await countryservice.getCountries();
    setCountry(responseCountry);

    const responseCategory = await categoryservice.getCategories();
    setCategory(responseCategory);
    setLoading(false);
  };

  useEffect(() => {
    getInit();
    getStatesByCountryId("101_India");
    const input = ref.current;
    if (input) input.setSelectionRange(cursor, cursor);
  }, [ref, cursor]);

  return (
    <>
      <Header title={"Add Influencer"} />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12 col-md-9 col-lg-9 mx-auto">
            <form
              onSubmit={onSubmit}
              className={"py-4 needs-validation"}
              id="user_form"
              autoComplete="off"
              ref={form}
            >
              <div className="row">
                <div className="col-xl-12 shadow p-3" style={borderStyle}>
                  <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4">
                      {userData && userData.username && userData.country ? (
                        <h6>
                          <select
                            className="form-select w-100 border mx-auto text-center scroll"
                            aria-label="Default select example"
                            name="country"
                            onChange={(e) =>
                              getStatesByCountryId(e.target.value)
                            }
                            style={customStyle}
                          >
                            <option selected>{userData.country}</option>
                            {country.map((c) => (
                              <Option
                                key={c.id}
                                name={c.name}
                                value={c.id + "_" + c.name}
                              />
                            ))}
                          </select>
                        </h6>
                      ) : (
                        <h6>
                          <select
                            className="form-select w-100 border mx-auto text-center scroll"
                            aria-label="Default select example"
                            name="country"
                            onChange={(e) =>
                              getStatesByCountryId(e.target.value)
                            }
                            style={customStyle}
                          >
                            <option selected>Country</option>
                            {country.map((c) => (
                              <Option
                                key={c.id}
                                name={c.name}
                                value={c.id + "_" + c.name}
                              />
                            ))}
                          </select>
                        </h6>
                      )}
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4">
                      {userData && userData.username && userData.state ? (
                        <h6>
                          <select
                            className="form-select w-100 border mx-auto text-center scroll"
                            aria-label="Default select example"
                            name="states"
                            onChange={(e) => getCityByStateId(e.target.value)}
                            style={customStyle}
                          >
                            <option selected>{userData.state}</option>
                            {state.length < 1 && (
                              <Option value={0} name={"Select the Country"} />
                            )}
                            {state.map((s) => (
                              <Option
                                key={s.id}
                                name={s.name}
                                value={s.id + "_" + s.name}
                              />
                            ))}
                          </select>
                        </h6>
                      ) : (
                        <h6>
                          <select
                            className="form-select w-100 border mx-auto text-center scroll"
                            aria-label="Default select example"
                            name="states"
                            onChange={(e) => getCityByStateId(e.target.value)}
                            style={customStyle}
                          >
                            <option selected>State</option>
                            {state.length < 1 && (
                              <Option value={0} name={"Select the Country"} />
                            )}
                            {state.map((s) => (
                              <Option
                                key={s.id}
                                name={s.name}
                                value={s.id + "_" + s.name}
                              />
                            ))}
                          </select>
                        </h6>
                      )}
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4">
                      {userData && userData.username && userData.city ? (
                        <h6>
                          <select
                            className="form-select w-100 border mx-auto text-center scroll"
                            aria-label="Default select example"
                            name="city"
                            onChange={handleChange}
                            style={customStyle}
                          >
                            <option selected>{userData.city}</option>
                            {city.length < 1 && (
                              <Option value={0} name={"Select the State"} />
                            )}
                            {city.map((c) => (
                              <Option key={c.id} name={c.name} value={c.city} />
                            ))}
                          </select>
                        </h6>
                      ) : (
                        <h6>
                          <select
                            className="form-select w-100 border mx-auto text-center scroll"
                            aria-label="Default select example"
                            name="city"
                            onChange={handleChange}
                            style={customStyle}
                          >
                            <option selected>City</option>
                            {city.length < 1 && (
                              <Option value={0} name={"Select the State"} />
                            )}
                            {city.map((c) => (
                              <Option key={c.id} name={c.name} value={c.city} />
                            ))}
                          </select>
                        </h6>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4">
                      <input
                        type="text"
                        className="form-control mb-2"
                        id="formGroupExampleInput2"
                        placeholder="Full Name"
                        value={
                          userData && userData.username && userData.name
                            ? userData.name
                            : null
                        }
                        name="name"
                        onChange={handleChange}
                        onKeyUp={validateText}
                        style={customStyle}
                        disabled
                      ></input>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4">
                      <input
                        type="text"
                        className="form-control mb-2"
                        id="formGroupExampleInput3"
                        placeholder="Email"
                        name="email"
                        value={
                          userData && userData.username && userData.email
                            ? userData.email
                            : null
                        }
                        onChange={handleChange}
                        style={customStyle}
                      ></input>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4">
                      <input
                        type="text"
                        className="form-control"
                        id="formGroupExampleInput4"
                        placeholder="Phone Number"
                        name="number"
                        minLength={10}
                        value={
                          userData && userData.username && userData.number
                            ? userData.number
                            : null
                        }
                        onChange={handleChange}
                        // onKeyUp={validateNumber}
                        style={customStyle}
                      ></input>
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4">
                      <input
                        type="text"
                        className="form-control mb-2"
                        id="formGroupExampleInput1"
                        placeholder="Instagram Username*"
                        ref={ref}
                        name="username"
                        value={inputText}
                        onChange={inputHandler}
                        onClick={() => openSearch()}
                        onKeyUp={() => openSearch()}
                        style={customStyle}
                        maxLength={70}
                        spellCheck="false"
                        autoFocus
                        required
                        disabled
                      ></input>
                      {searchContent && (
                        <Content
                          inputText={inputText}
                          handleInfluencerName={handleInfluencerName}
                          ShowNumberBox={ShowNumberBox}
                          HideNumberBox={HideNumberBox}
                        />
                      )}
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4">
                      <InputInstagramUrl
                        customStyle={customStyle}
                        userData={userData}
                        setUserData={setUserData}
                        handleChange={handleChange}
                        setInputText={setInputText}
                        setYoutubeData={setYoutubeData}
                        components={components}
                        setComponents={setComponents}
                        setCostData={setCostData}
                      />
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4">
                      <input
                        type="text"
                        className="form-control mb-2"
                        id="formGroupExampleInput7"
                        placeholder="Instagram Followers"
                        name="instaFollowers"
                        value={
                          userData &&
                            userData.username &&
                            userData.instaFollowers
                            ? userData.instaFollowers
                            : null
                        }
                        onChange={handleChange}
                        onKeyUp={validateNumber}
                        style={customStyle}
                        disabled
                      ></input>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4">
                      <input
                        type="text"
                        className="form-control mb-2"
                        id="formGroupExampleInput8"
                        placeholder="Instagram Static Cost"
                        name="staticCost"
                        value={
                          userData &&
                            userData.username &&
                            costData &&
                            costData.staticCost
                            ? costData.staticCost
                            : null
                        }
                        onChange={handleCostChange}
                        onKeyUp={validateNumber}
                        style={customStyle}
                      ></input>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4">
                      <input
                        type="text"
                        className="form-control mb-2"
                        id="formGroupExampleInput9"
                        placeholder="Instagram Video Cost"
                        name="videoCost"
                        value={
                          userData &&
                            userData.username &&
                            costData &&
                            costData.videoCost
                            ? costData.videoCost
                            : null
                        }
                        onChange={handleCostChange}
                        onKeyUp={validateNumber}
                        style={customStyle}
                      ></input>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4">
                      <input
                        type="text"
                        className="form-control"
                        id="formGroupExampleInput10"
                        placeholder="Instagram Story Cost"
                        name="storyCost"
                        value={
                          userData &&
                            userData.username &&
                            costData &&
                            costData.storyCost
                            ? costData.storyCost
                            : null
                        }
                        onChange={handleCostChange}
                        onKeyUp={validateNumber}
                        style={customStyle}
                      ></input>
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4">
                      <input
                        type="text"
                        className="form-control mb-2"
                        id="formGroupExampleInput5"
                        placeholder="Youtube Channel Name"
                        name="title"
                        value={youtubeData.title}
                        onChange={handleYoutubeChange}
                        style={customStyle}
                        disabled
                      ></input>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4">
                      <InputYoutubeChannelUrl
                        youtubeData={youtubeData}
                        handleYoutubeChange={handleYoutubeChange}
                        customStyle={customStyle}
                        setYoutubeData={setYoutubeData}
                      />
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4">
                      <input
                        type="text"
                        className="form-control mb-2"
                        id="formGroupExampleInput7"
                        placeholder="Subscriber"
                        name="subscriber"
                        value={youtubeData.statistics?.subscriberCount}
                        onChange={handleYoutubeChange}
                        style={customStyle}
                        disabled
                      ></input>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4">
                      <input
                        type="text"
                        className="form-control mb-2"
                        id="formGroupExampleInput5"
                        placeholder="Integrated Cost"
                        name="integratedCost"
                        value={costData.integratedCost}
                        onChange={handleCostChange}
                        style={customStyle}
                      ></input>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4">
                      <input
                        type="text"
                        className="form-control mb-2"
                        id="formGroupExampleInput6"
                        placeholder="Dedicated Cost"
                        name="dedicatedCost"
                        value={costData.dedicatedCost}
                        onChange={handleCostChange}
                        style={customStyle}
                      ></input>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4">
                      <input
                        type="text"
                        className="form-control"
                        id="formGroupExampleInput7"
                        placeholder="Shorts Cost"
                        name="shortsCost"
                        value={costData.shortsCost}
                        onChange={handleCostChange}
                        onKeyUp={validateNumber}
                        style={customStyle}
                      ></input>
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                      <input
                        type="text"
                        className="form-control mb-2"
                        id="formGroupExampleInputBlogTitle"
                        placeholder="Blog Title"
                        name={"blogTitle"}
                        value={
                          userData && userData.username && userData.blog && userData.blog.blogTitle
                            ? userData.blog.blogTitle
                            : null
                        }
                        onChange={handleChange}
                        onKeyUp={validateText}
                        style={customStyle}
                        // required={userData.blog && userData.blog.blogUrl && userData.blog.blogUrl ? true : false}
                      ></input>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6" >
                      <input
                        type="url"
                        className="form-control mb-2"
                        id="formGroupExampleInputBlogUrl"
                        placeholder="Blog Url"
                        name={"blogUrl"}
                        value={
                          userData && userData.username && userData.blog && userData.blog.blogUrl
                            ? userData.blog.blogUrl
                            : null
                        }
                        onChange={handleChange}
                        style={customStyle}
                        required={userData.blog && userData.blog.blogTitle && userData.blog.blogTitle ? true : false}
                      ></input>
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4">
                      <Category
                        handleChange={handleCategoryChange}
                        userData={userData}
                        loading={loading}
                        category={category}
                        customStyle={customStyle}
                        customStyles={customStyles}
                        handleNewCategoryChange={handleNewCategoryChange}
                      />
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4" >
                      <Gender
                        userData={userData}
                        customStyle={customStyle}
                        customStyles={customStyles}
                        handleChange={handleGenderChange}
                      />
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4">
                      <div className="">
                        <input
                          type="date"
                          className="form-control "
                          id="formGroupExampleInput12"
                          placeholder="Date of Commercial"
                          name="date"
                          value={
                            userData &&
                              userData.username &&
                              costData &&
                              costData.date
                              ? costData.date
                              : null
                          }
                          onChange={handleCostChange}
                          style={customStyle}
                        ></input>
                      </div>
                    </div>
                  </div>
                  <br />
                  <Manager
                    userData={userData}
                    validateText={validateText}
                    customStyle={customStyle}
                    validateNumber={validateNumber}
                    components={components}
                    setComponents={setComponents}
                  />
                  <hr />
                  <div className="text-center">
                    <button
                      type="submit"
                      className="btn btn-secondary"
                      style={{ width: "32%" }}
                    >
                      Submit
                    </button>
                    <small
                      id="id_fail"
                      style={{ display: "none", fontSize: "13px" }}
                      className="text-danger my-4"
                    >
                      Failure...
                    </small>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Influencer;
