import http from "./httpservices";

export async function LinkClubs(params) {
  const { data } = await http.post("link/club", params);
  return data;
}

export default {
  LinkClubs,
};
