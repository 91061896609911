import { Spinner } from "react-bootstrap";

export default function Completed({ loading, customStyle, campaigns }) {
  const CategoryFormatting = (categories) => {
    let categoryString = "";
    categories.map((c) => (categoryString += c.value + ", "));
    return categoryString.substring(0, categoryString.length - 2);
  };

  return (
    <>
      {loading ? (
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
          <center>
            <Spinner animation="border" />
          </center>
        </div>
      ) : (
        campaigns &&
        campaigns.map((campaign, index) => (
          <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 mb-1" key={index}>
            <div className="card h-100">
              <div className="card-header shadow-sm ">
                <h6>
                  {campaign.brand ? campaign.brand.name : "NA"} |{" "}
                  {campaign.platform ? campaign.platform.name : "NA"}
                </h6>
              </div>
              <div className="card-body">
                <h6 className="text-center mb-4" style={customStyle}>
                  {campaign ? campaign.name : "NA"}
                </h6>
                {campaign.totalPayment ? (
                  <p className="mb-3 fw-bold" style={customStyle}>
                    &#8377;{campaign.totalPayment}
                  </p>
                ) : (
                  <p style={customStyle}>NA</p>
                )}
                <div className="row">
                  <div className="col-auto">
                    <p className="text-muted text-sm" style={customStyle}>
                      {campaign.deliverables ? campaign.deliverables : "NA"}
                    </p>
                  </div>
                  <div className="col text-end text-sm" style={customStyle}>
                    {campaign.category && campaign.category.length > 0
                      ? CategoryFormatting(campaign.category)
                      : "NA"}
                  </div>
                </div>
              </div>
              <div className="card-footer  d-flex justify-content-between">
                <p className="align-self-center" style={customStyle}>
                  {campaign.createdBy ? campaign.createdBy.fullName : "NA"}
                </p>
                <button
                  className="btn"
                  style={{
                    ...customStyle,
                    backgroundColor: "#D1ECF1",
                    color: "#148A9C",
                  }}
                >
                  Action
                </button>
              </div>
            </div>
          </div>
        ))
      )}
    </>
  );
}
