import http from "./httpservices";

export async function getHashTags() {
  const { data } = await http.get("/hashtags");
  return data;
}

export async function getHashTagByTagName(params) {
  const { data } = await http.post("/hashtags/tagname", params);
  return data;
}

export default {
  getHashTags,
  getHashTagByTagName
};
