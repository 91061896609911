import React, { useEffect, useRef, useState } from "react";
import userservice from "../../../services/userservice";
import paymentservice from "../../../services/paymentlinkservice";
import jwtDecode from "jwt-decode";
import campaignservice from "../../../services/campaignservice";
import "../paymentLink/css/paymentLink.css";
import Swal from "sweetalert2";
import instagramservice from "../../../services/instagramservice";
import CompanyForm from "./form/companyForm";
import InfluencerForm from "./form/influencerForm";
import Alert from "react-bootstrap/Alert";
import companyservice from "../../../services/companyservice";
import youtubeservices from "../../../services/youtubeservice";

import paymentlinkservice from "../../../services/paymentlinkservice";
import vendorservice from "../../../services/vendorservice";
import telegramservice from "../../../services/telegramservice";
import facebookservice from "../../../services/facebookservice";
import socialmediaservice from "../../../services/socialmediaservice";

const customStyles = {
  control: (base, state) => ({
    ...base,
    background: localStorage.getItem("theme") === "dark" ? "#212529" : "light",
    color: state.isActive ? "white" : "#212529",
    borderColor: localStorage.getItem("theme") === "dark" ? "#495057" : "light",
  }),
  option: (base, state) => ({
    ...base,
    backgroundColor:
      state.isSelected || localStorage.getItem("theme") === "dark"
        ? "#212529"
        : "light", // Option background color
    color:
      state.isSelected || localStorage.getItem("theme") === "dark"
        ? "light"
        : "#212529", // Option text color
  }),
};

export default function PaymentLink({ campaignDetails }) {
  const customBorderStyle = {
    borderRadius: "0.5rem",
    marginTop: "1rem",
    marginBottom: "6rem",
  };
  const customPaymentStyle = {
    fontSize: "13px",
    marginTop: "2rem",
  };
  const customStyle = { fontSize: "13px" };
  const form = useRef(null);

  const superUser = jwtDecode(
    localStorage.getItem(process.env.REACT_APP_TOKEN_NAME)
  ).isSuperUser;

  const [userData, setUserData] = useState({
    company: {
      name: "",
    },
  });
  const [campaign, setCampaign] = useState([]);
  const [company, setCompany] = useState([]);
  const [showNumber, setShowNumber] = useState(false);
  const [gst, setGst] = useState(false);

  const [inputText, setInputText] = useState("");
  const [_percentage, _setPercentage] = useState("");
  const [searchContent, setSearchContent] = useState(false);
  const [paymentLinkToggle, setPaymentLinkToggle] = useState(false);
  const [reloadPage, setReloadPage] = useState(false);

  const handleGSTChange = () => {
    if (!userData.cost) {
      Swal.fire({
        icon: "error",
        title: "Please Enter Total Amount",
        showConfirmButton: false,
        timer: 500,
      });
      return;
    }

    if (!gst) {
      // gst value set late because setgst call after logic
      setUserData((prevState) => ({
        ...prevState,
        ["gstAmount"]: parseInt(parseInt(userData.cost) * 0.18),
      }));
    } else {
      setUserData((prevState) => ({
        ...prevState,
        ["gstAmount"]: "",
      }));
    }
    setGst(!gst);
  };

  const validateNumber = (e) => {
    let result = e.target;
    result.value = result.value.replace(/[^0-9]+/, "");
  };

  function calcPercentage(percentage, cost) {
    const payableAmount = parseInt(cost * (percentage / 100));

    setUserData((prevState) => ({
      ...prevState,
      ["payableAmount"]: payableAmount,
    }));
  }

  const handleVendorChangeForInfluencer = async (e) => {
    try {
      setUserData((prevState) => ({
        ...prevState,
        ["company"]: e ? e.value : "",
        ["company.name"]: e ? e.label : "",
      }));
    } catch (error) {}
  };

  const handleVendorChange = async (e) => {
    let params = `${e.value}@@${e.label}`;
    let response = await CalculateCompanyPercentage(params);

    if (response.status === 201) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: response.data,
        timer: 3000,
      });
      setUserData((prevState) => ({
        ...prevState,
        ["company.name"]: "",
      }));
      setLoading(false);
      return;
    }

    response = response.data;

    setUserData((prevState) => ({
      ...prevState,
      ["company"]: e.value,
      ["company.name"]: e.label,
      ["companyName"]: e.label,
      ["percentage"]: response.percentage,
      ["cost"]: response.totalCost,
      ["description"]: response.description,
      ["isGstPaid"]: response.isGstPaid,
    }));
  };

  async function CalculateCompanyPercentage(company) {
    const response = await vendorservice.CalculatePercentage({
      company: company,
      campaign: campaignDetails.id,
    });

    return response;
  }

  const handleCalcPercentage = async (e) => {
    let { name, value } = e.target;
    if (userData.cost !== "") {
      calcPercentage(value, userData.cost);
    }
    _setPercentage(value);
  };

  const handleChange = async (e) => {
    let { name, value } = e.target;
    if (name === "percentage") {
      return;
    }

    if (name === "cost") {
      if (parseInt(value) > parseInt(campaignDetails.paymentLimit)) {
        Swal.fire({
          icon: "error",
          title: `Enter amount within limit ${campaignDetails.paymentLimit}`,
          showConfirmButton: false,
          timer: 1500,
        });
        setUserData((prevState) => ({
          ...prevState,
          ["cost"]: "",
        }));
        return;
      }
    }

    setUserData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSwitch = () => {
    setPaymentLinkToggle(!paymentLinkToggle);
    if (gst) setGst(false);
  };

  const handleInfluencerName = (value, id, flag) => {
    if (flag) setInputText(value);

    setSearchContent(false);
    setUserData((prevState) => ({
      ...prevState,
      ["influencer"]: id,
    }));
  };

  function isValidSocialMediaUrl(path) {
    let url = path.toLowerCase();
    const regexes = {
      instagram: /^https?:\/\/(?:www\.)?instagram\.com\/([a-zA-Z0-9_.]+)\/?$/,
      youtube: /^https?:\/\/(?:www\.)?youtube\.com\/([a-zA-Z0-9.@_]+)\/?$/,
      telegram:
        /^(https?:\/\/(?:www\.)?(?:t\.me|telegram\.me)\/([a-zA-Z0-9_@.]+)\/?|https?:\/\/twitter\.com\/([a-zA-Z0-9_]+)\/?)$/,
      facebook: /^https?:\/\/(?:www\.)?facebook\.com\/([a-zA-Z0-9._@]+)\/?$/,
    };
    //twitter.com/_m_c_q
    https: for (const [platform, regex] of Object.entries(regexes)) {
      let match = url.match(regex);

      if (regex.test(url)) {
        return [platform, match[1]];
      }
    }

    return [false];
  }

  const handleSocialMediaUrl = async () => {
    const { value: url } = await Swal.fire({
      input: "url",
      inputLabel: "Youtube, Facebook, Telegram",
      inputPlaceholder: "Enter the Social-Media Link (Profile or Channel Link)",
      confirmButtonText: "Submit",
    });

    if (url) {
      const result = await Swal.fire({
        title: `Entered URL: ${url}`,
        showCancelButton: true,
        confirmButtonText: "Submit",
      });

      if (result.isConfirmed) {
        Swal.fire({ title: "loading", showConfirmButton: false });
        const validUrlResponse = isValidSocialMediaUrl(url);
        let response = "";
        switch (validUrlResponse[0]) {
          case "Invalid":
            Swal.fire({
              position: "center",
              icon: "error",
              title: "Not a Valid Link",
              showConfirmButton: false,
              timer: 1500,
            });
            return;
          case "Facebook":
            response = await facebookservice.FacebookInfo({ url: url });
            break;
          case "YouTube":
            response = await youtubeservices.YTChannelInfo({ url: url });
            break;
          case "Telegram":
            response = await telegramservice.TelegramInfo({ url: url });
            break;
        }

        setUserData((prevState) => ({
          ...prevState,
          ["username"]: response.name,
          ["influencer"]: response._id,
        }));

        setInputText(`https://www.instagram.com/${response.username}`);
        document
          .getElementById("influencer_yt_id")
          .setAttribute("disabled", true);
        Swal.close();
      }
    }
  };

  const [isClicked, setClicked] = useState(false);

  const onSubmit = async (e) => {
    e.preventDefault();
    setClicked(true);

    let finalObj = { ...userData };

    if (finalObj.hasOwnProperty("customDate")) {
      finalObj.customDate = new Date(finalObj.customDate).getTime();
    } else {
      finalObj.customDate = new Date().getTime();
    }

    if (
      ![
        "6667fab9e91c6336aff2f156",
        "6667fa72e91c6336aff2f0d6",
        "6667fa02e91c6336aff2f0af",
        "6667f993e91c6336aff2f03c",
      ].includes(campaignDetails.id)
    ) {
      if (finalObj["payableAmount"] > parseInt(campaignDetails.paymentLimit)) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `Please Enter Amount within Limit! Your limit is ${campaignDetails.paymentLimit}`,
        });

        return;
      }
    }

    delete finalObj["username"];
    delete finalObj["company"]["name"];
    finalObj["paymentLimit"] = campaignDetails.paymentLimit;

    finalObj["createdby"] = jwtDecode(
      localStorage.getItem(process.env.REACT_APP_TOKEN_NAME)
    )._id;

    if (campaignDetails.platform) {
      let platform = [];
      campaignDetails.platform.map((p) => {
        platform.push(p._id);
      });
      finalObj["platform"] = platform;
    }

    finalObj["campaign"] = campaignDetails.id;
    if (_percentage) finalObj["percentage"] = _percentage;

    if (gst) {
      finalObj["isGst"] = true;
      finalObj["isGstPay"] = false;
    } else {
      if (finalObj["gstAmount"] !== "") delete finalObj["gstAmount"];
    }

    if (finalObj["isGstPaid"]) {
      finalObj["gstAmount"] = parseInt(parseInt(userData.cost) * 0.18);
      finalObj["isGst"] = true;
      finalObj["isGstPay"] = false;
    }

    if (!paymentLinkToggle) {
      try {
        if (Object.keys(finalObj["company"]).length > 0)
          finalObj["isCompany"] = true;
        await paymentservice.generatePaymentLink(finalObj);
        if (reloadPage) window.location.reload();
        else window.location.href = "/admin/payment/status";
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      }
    } else {
      try {
        let stringObj = finalObj.description;
        finalObj["description"] = stringObj.replace(/(\r\n|\n|,|\r)/gm, " ");
        finalObj["isCompany"] = true;
        await paymentservice.generateCompanyLink(finalObj);
        if (reloadPage) {
          window.location.reload();
        } else window.location.href = "/admin/payment/status";
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      }
    }
  };

  const getInit = async () => {
    const campaigns = await campaignservice.getCampaigns();
    setCampaign(campaigns);
    const companies = await companyservice.getCompanies();
    setCompany(companies);
  };

  const [loading, setLoading] = useState(false);

  const handleValidateUrl = async (e) => {
    setLoading(true);
    setInputText(e.target.value);
    let { name, value } = e.target;
    setUserData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    const validUrl = isValidSocialMediaUrl(value);
    if (!validUrl[0]) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Not a Valid Instagram URL",
      });
      setInputText("");
      setLoading(false);
      return;
    }

    const resp = await socialmediaservice.UserDetails({
      url: value,
      campaignId: campaignDetails.id,
    });

    if (resp.status === 201) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: resp.data,
        timer: 3000,
      });
      setInputText("");
      setLoading(false);
      return;
    }

    const respData = resp.data;

    if (respData) {
      setUserData((prevState) => ({
        ...prevState,
        username: respData["username"],
        influencer: respData["_id"],
        percentage: respData["percentage"],
        cost: respData["totalCost"],
        deliverables: respData["deliverables"],
        isGstPaid: respData["isGstPaid"],
      }));
    }

    setLoading(false);
  };

  useEffect(() => {
    getInit();
  }, []);

  return (
    <>
      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
        <div
          className="form-check mx-auto w-75 d-flex justify-content-center"
          style={customPaymentStyle}
        >
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              id="flexSwitchCheckDefault"
              onClick={handleSwitch}
              style={{ cursor: "pointer" }}
            />
          </div>
          <span className={!paymentLinkToggle ? "fw-bold" : ""}>
            Influencer&nbsp;
          </span>
          <span>/&nbsp;</span>
          <span className={paymentLinkToggle ? "fw-bold" : ""}>Vendor</span>
        </div>
        {paymentLinkToggle ? (
          <div
            className=" shadow p-3 w-75 mx-auto formWidth border border-1"
            style={customBorderStyle}
          >
            <CompanyForm
              isClicked={isClicked}
              onSubmit={onSubmit}
              form={form}
              gst={gst}
              setReloadPage={setReloadPage}
              userData={userData}
              customStyle={customStyle}
              customStyles={customStyles}
              handleGSTChange={handleGSTChange}
              handleChange={handleChange}
              validateNumber={validateNumber}
              campaign={campaign}
              campaignDetails={campaignDetails}
              handleVendorChange={handleVendorChange}
              handleCalcPercentage={handleCalcPercentage}
              paymentLinkToggle={paymentLinkToggle}
            />
          </div>
        ) : (
          <div
            className=" shadow p-3 w-75 mx-auto formWidth border border-1"
            style={customBorderStyle}
          >
            <InfluencerForm
              isClicked={isClicked}
              form={form}
              gst={gst}
              onSubmit={onSubmit}
              setReloadPage={setReloadPage}
              calcPercentage={calcPercentage}
              handleYoutubeUrl={handleSocialMediaUrl}
              handleGSTChange={handleGSTChange}
              handleChange={handleChange}
              handleVendorChangeForInfluencer={handleVendorChangeForInfluencer}
              customStyles={customStyles}
              customStyle={customStyle}
              campaign={campaign}
              companyData={company}
              inputText={inputText}
              loading={loading}
              userData={userData}
              handleValidateUrl={handleValidateUrl}
              showNumber={showNumber}
              validateNumber={validateNumber}
              paymentLinkToggle={paymentLinkToggle}
              campaignDetails={campaignDetails}
              handleCalcPercentage={handleCalcPercentage}
              handleVendorChange={handleVendorChange}
            />
          </div>
        )}
      </div>
    </>
  );
}
