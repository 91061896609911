import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import Card from "../components/common/card/card";
import Header from "../components/common/header/header";
import "../components/categories/css/categories.css";
import removeMediaFromUrl from "../hooks/useRemoveMediaFromUrl";
import cityservice from "../services/cityservice";
import Footer from "../components/common/footer/footer";

const SearchInfluencerByCity = ({ match }) => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [categoryName, setCategoryName] = useState("");

  const getInit = async (name) => {
    const response = await cityservice.getInfluencerByCity(name);
    setCategories(response);
    setLoading(false);
  };

  useEffect(() => {
    getInit(match.params["name"]);
    setCategoryName(match.params["name"]);
  }, []);

  return (
    <>
      <Header title={"Search By City"} />
      {loading ? (
        <>
          <h3 className="text-center my-5">
            Loading... {categoryName} Influencers{" "}
          </h3>
          <center>
            <Spinner animation="border" />
          </center>
        </>
      ) : (
        <>
          <h3 className="text-center my-5">
            {categoryName} ({categories.length})
          </h3>
          <div className="container mt-3">
            <div className="row row-cols-1 row-cols-md-3 g-4">
              {categories.map((category, index) => (
                <Card
                  name={category.username}
                  bio={category.biography}
                  key={index}
                  path={category.path}
                  image={removeMediaFromUrl(category.profile_pic_url_hd)}
                />
              ))}
            </div>
          </div>
          <br />
        </>
      )}
      <Footer />
    </>
  );
};

export default SearchInfluencerByCity;
