import http from "./httpservices";

export async function getPocUsers() {
  const { data } = await http.get("/user/poc");
  return data;
}

export async function getAllSubUsers(id) {
  const { data } = await http.post("/user/allsubusers", { id: id });
  return data;
}

export async function getManageUsers(id) {
  const { data } = await http.post("/user/manageusers", { id: id });
  return data;
}

export async function getStatsUsers() {
  const { data } = await http.get("/user/statsuser");
  return data;
}

export async function getSearchUser(name) {
  const { data } = await http.post("/user/searchuser", { name: name });
  return data;
}

export async function getCurrentRole(id) {
  const { data } = await http.post("/user/currentrole", { id: id });
  return data;
}

export async function getChangeRole(id, role) {
  const { data } = await http.post("/user/changerole", { id: id, role: role });
  return data;
}

export async function updateManageUsers(id, users) {
  const { data } = await http.post("/user/updatemanageusers", {id: id, users: users});
  return data;
}

export async function checkActiveUser(params) {
  const { data } = await http.post("/user/checkActiveUser", params);
  return data;
}

export default {
  getPocUsers,
  getStatsUsers,
  getAllSubUsers,
  getManageUsers,
  getSearchUser,
  getCurrentRole,
  getChangeRole,
  updateManageUsers,
  checkActiveUser
};
