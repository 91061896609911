import http from "./httpservices";

export async function getCountries() {
  const { data } = await http.get("country/");
  return data;
}

export default {
    getCountries,
};
