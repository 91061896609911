import React, { useState } from "react";
import { Badge, Spinner } from "react-bootstrap";

function FilterSlideMenu({
  loading,
  platforms,
  categories,
  statesAndCities,
  cursorPointer,
  globalFontStyle,
  fixedHeight,
  showFilters,
  handleClearFilter,
  handleApplyFilter,
  handleFollowersFilter,
}) {
  return (
    <div
      className="col-xl-2 col-lg-2 col-md-2 col-sm-2 "
      style={globalFontStyle}
    >
      <br />
      <div className="d-grid gap-3">
        <div className="p-2  rounded shadow">
          <div className="d-flex justify-content-between">
            <span className="fw-bold">Filter Result</span>
            <small
              className="text-danger"
              style={cursorPointer}
              onClick={handleClearFilter}
            >
              Clear All
            </small>
          </div>
          <div>
            {showFilters &&
              showFilters.map((filter, index) => (
                <Badge className="me-2 mt-2" key={index}>
                  {filter} <span aria-hidden="true">&times;</span>
                </Badge>
              ))}
          </div>
        </div>
        <div className="p-2  rounded shadow">
          <span className="fw-bold">Gender</span>
          <div className="ps-2 pt-2">
            <div className="form-check">
              <input
                className="form-check-input"
                style={cursorPointer}
                type="radio"
                name="gender"
                id="gender1"
              />
              <label
                className="form-check-label"
                style={cursorPointer}
                htmlFor="gender1"
              >
                Male
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                style={cursorPointer}
                type="radio"
                name="gender"
                id="gender2"
              />
              <label
                className="form-check-label"
                style={cursorPointer}
                htmlFor="gender2"
              >
                Female
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                style={cursorPointer}
                type="radio"
                name="gender"
                id="gender3"
              />
              <label
                className="form-check-label"
                style={cursorPointer}
                htmlFor="gender3"
              >
                Other
              </label>
            </div>
          </div>
        </div>
        <div className="p-2  rounded shadow">
          <span className="fw-bold">Platform</span>
          <div className="ps-2 pt-2 overflow-auto scroll" style={fixedHeight}>
            {loading ? (
              <center>
                <Spinner animation="border" />
              </center>
            ) : (
              platforms &&
              platforms.map((platform, index) => (
                <div className="form-check" key={platform._id}>
                  <input
                    className="form-check-input"
                    style={cursorPointer}
                    type="checkbox"
                    name="platform"
                    id={`${platform}_${index}`}
                    onClick={() => handleApplyFilter(platform.name)}
                  />
                  <label
                    className="form-check-label"
                    style={cursorPointer}
                    htmlFor={`${platform}_${index}`}
                  >
                    {platform.name}
                  </label>
                </div>
              ))
            )}
          </div>
        </div>
        <div className="p-2  rounded shadow">
          <span className="fw-bold">Category</span>
          <div className="ps-2 pt-2 overflow-auto scroll" style={fixedHeight}>
            {loading ? (
              <center>
                <Spinner animation="border" />
              </center>
            ) : (
              categories &&
              categories.map((category, index) => (
                <div className="form-check" key={index}>
                  <input
                    className="form-check-input"
                    style={cursorPointer}
                    type="checkbox"
                    name="category"
                    id={`${category}_${index}`}
                    onClick={() => handleApplyFilter(category.name)}
                  />
                  <label
                    className="form-check-label"
                    style={cursorPointer}
                    htmlFor={`${category}_${index}`}
                  >
                    {category.name}
                  </label>
                </div>
              ))
            )}
          </div>
        </div>
        <div className="p-2  rounded shadow">
          <span className="fw-bold">State</span>
          <div className="ps-2 pt-2 overflow-auto scroll" style={fixedHeight}>
            {loading ? (
              <center>
                <Spinner animation="border" />
              </center>
            ) : (
              statesAndCities &&
              statesAndCities.state &&
              statesAndCities.state.map(
                (state, index) =>
                  state !== "" &&
                  state !== null && (
                    <div className="form-check" key={index}>
                      <input
                        className="form-check-input"
                        style={cursorPointer}
                        type="checkbox"
                        name="state"
                        id={`${state}_${index}`}
                        onClick={() => handleApplyFilter(state)}
                      />
                      <label
                        className="form-check-label"
                        style={cursorPointer}
                        htmlFor={`${state}_${index}`}
                      >
                        {state}
                      </label>
                    </div>
                  )
              )
            )}
          </div>
        </div>
        {/* <div className="p-2  rounded shadow">
          <span className="fw-bold">City</span>
          <div className="ps-2 pt-2 overflow-auto scroll" style={fixedHeight}>
            {loading ? (
              <center>
                <Spinner animation="border" />
              </center>
            ) : (
              statesAndCities &&
              statesAndCities.city &&
              statesAndCities.city.map(
                (city, index) =>
                  city !== "" &&
                  city !== null && (
                    <div className="form-check" key={index}>
                      <input
                        className="form-check-input"
                        style={cursorPointer}
                        type="checkbox"
                        name="city"
                        id={`${city}_${index}`}
                        onClick={() => handleApplyFilter(city)}
                      />
                      <label
                        className="form-check-label"
                        style={cursorPointer}
                        htmlFor={`${city}_${index}`}
                      >
                        {city}
                      </label>
                    </div>
                  )
              )
            )}
          </div>
        </div> */}
        <div className="p-2  rounded shadow">
          <span className="fw-bold">Followers</span>
          <div
            className="ps-2 pt-2 overflow-auto scroll"
            style={{ height: "150px" }}
          >
            <div className="form-check">
              <input
                className="form-check-input"
                style={cursorPointer}
                type="checkbox"
                name="followers"
                onClick={() => handleApplyFilter("5000-10000")}
              />
              <label
                className="form-check-label"
                style={cursorPointer}
                htmlFor="followers1"
              >
                5000 - 10000
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                style={cursorPointer}
                type="checkbox"
                name="followers"
                onClick={() => handleApplyFilter("10000-30000")}
              />
              <label
                className="form-check-label"
                style={cursorPointer}
                htmlFor="followers1"
              >
                10000 - 30000
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                style={cursorPointer}
                type="checkbox"
                name="followers"
                onClick={() => handleApplyFilter("30000-40000")}
              />
              <label
                className="form-check-label"
                style={cursorPointer}
                htmlFor="followers1"
              >
                30000 - 40000
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                style={cursorPointer}
                type="checkbox"
                name="followers"
                onClick={() => handleApplyFilter("40000-80000")}
              />
              <label
                className="form-check-label"
                style={cursorPointer}
                htmlFor="followers1"
              >
                40000 - 80000
              </label>
            </div>
            <div className="d-flex justify-content-between input-group-sm">
              <input
                type="text"
                className="form-control me-2"
                id="followersMinRange"
                placeholder="Min"
                name="followersMin"
                // value={0}
                // value={}
                // onChange={handleChange}
                pattern="[0-9]*"
                title="Enter only Numerical Letters"
              ></input>
              <input
                type="text"
                className="form-control me-2"
                id="followersMaxRange"
                placeholder="Max"
                name="followersMax"
                // value=""
                // onChange={handleChange}
                pattern="[0-9]*"
                title="Enter only Numerical Letters"
              ></input>
              <button
                className="btn btn-primary"
                onClick={() =>
                  handleFollowersFilter(
                    document.getElementById("followersMinRange").value,
                    document.getElementById("followersMaxRange").value
                  )
                }
              >
                Apply
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FilterSlideMenu;
