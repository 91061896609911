import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";

export default function Footer() {
  return (
    <>
      {/* <footer
        className="text-center text-lg-start fixed-bottom"
        style={{ backgroundColor: "#0D1224" }}
      >
        <div className="text-center p-3 text-white">
          © {new Date().getFullYear()} Copyright: &nbsp;
          <a
            className="text-white"
            style={{ textDecoration: "none" }}
            href="https://idiotic.media/"
          >
            Idiotic.Media
          </a>
        </div>
      </footer> */}
    </>
  );
}
