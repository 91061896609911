import React from "react";
import _ from "lodash";
import Placeholder from "react-bootstrap/Placeholder";

function pocPlaceHolder(props) {
  return (
    <>
      {_.range(1, 6).map((r) => (
        <tr key={r}>
          <td>{r}</td>
          <td>
            <Placeholder as={"div"} animation="glow" style={{ width: "100px" }}>
              <Placeholder xs={12} />
            </Placeholder>
          </td>
          <td>
            <Placeholder as={"div"} animation="glow" style={{ width: "100px" }}>
              <Placeholder xs={12} />
            </Placeholder>
          </td>
          <td>
            <Placeholder as={"div"} animation="glow" style={{ width: "100px" }}>
              <Placeholder xs={12} />
            </Placeholder>
          </td>
          <td>
            <Placeholder as={"div"} animation="glow" style={{ width: "100px" }}>
              <Placeholder xs={12} />
            </Placeholder>
          </td>
          <td>
            <Placeholder as={"div"} animation="glow" style={{ width: "100px" }}>
              <Placeholder xs={12} />
            </Placeholder>
          </td>
        </tr>
      ))}
    </>
  );
}

export default pocPlaceHolder;
