import http from "./httpservices";

export async function getInfluencerByDay() {
  const { data } = await http.get("idiotic/sortby/day");
  return data;
}

// export async function getHimanshuInfluencers() {
//   const { data } = await http.post("idiotic/createdby", {id: "62c0233da5a6a373307e6232"});
//   return data;
// }

// export async function getSurbhiInfluencers() {
//   const { data } = await http.post("idiotic/createdby", {id: "62a307af098c981001d2a524"});
//   return data;
// }

export async function getUserInfluencersCount(id) {
  const { data } = await http.post("idiotic/createdby", { id: id });
  return data;
}

export default {
  getInfluencerByDay,
  getUserInfluencersCount,
};
