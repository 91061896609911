import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import platformservice from "../../../services/platformservice";
import campaignservice from "../../../services/campaignservice";
import Swal from "sweetalert2";
import jwtDecode from "jwt-decode";
import brandservice from "../../../services/brandservice";
import categoryservice from "../../../services/categoryservice";
import Category from "../select/category";
import Brand from "../select/brand";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd } from "@fortawesome/free-solid-svg-icons";

const customStyles = {
  control: (base, state) => ({
    ...base,
    background: localStorage.getItem('theme') === 'dark' ? '#212529' : 'light',
    color: state.isActive ? 'white' : '#212529',
    borderColor: localStorage.getItem('theme') === 'dark' ? '#495057' : 'light',
  }),
  option: (base, state) => ({
    ...base,
    backgroundColor: state.isSelected || localStorage.getItem('theme') === 'dark' ? '#212529' : 'light', // Option background color
    color: state.isSelected || localStorage.getItem('theme') === 'dark' ? 'light' : '#212529', // Option text color
  }),
};

function Campaign() {
  const customStyle = { fontSize: "13px" };

  const btnClose = () => setVisible(false);
  const btnShow = () => setVisible(true);

  const [loading, setLoading] = useState(true);
  const [visible, setVisible] = useState(false);
  const [platforms, setPlatforms] = useState([]);
  const [brands, setBrands] = useState([]);
  const [categories, setCategories] = useState([]);
  const [newCategory, setNewCategory] = useState("");
  const [newBrand, setNewBrand] = useState("");
  const [file, setFile] = useState("");

  const [params, setParams] = useState({
    name: "",
    totalPayment: "",
    deliverables: "",
    brand: [],
    platform: [],
    category: [],
    isOfficeExpense: false,
  });
  const isSuperUser = jwtDecode(
    localStorage.getItem(process.env.REACT_APP_TOKEN_NAME)
  ).isSuperUser;

  const handleUpload = (e) => {
    setFile(e.target.files[0]);
  };

  const handleChange = (e) => {
    let { name, value } = e.target;

    if (name === "isOfficeExpense") {
      setParams((prevState) => ({
        ...prevState,
        [name]: value === "on" ? true : false,
      }));
    } else {
      setParams((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };


  const handleCategoryChange = (e) => {
    setParams((prevState) => ({
      ...prevState,
      ["category"]: e,
    }));
  };

  const handleBrandChange = (e) => {
    setParams((prevState) => ({
      ...prevState,
      ["brand"]: e,
    }));
  };

  const handlePlatformChange = (e) => {
    var platforms = [...params.platform];
    const value = e.target.value
    const index = platforms.findIndex(data => data === value);
    if (index > -1) {
      platforms = [...platforms.slice(0, index), ...platforms.slice(index + 1)]
    } else {
      platforms.push(value);
    }
    setParams((prevState) => ({
      ...prevState,
      ["platform"]: platforms,
    }));
  };


  const handleNewCategoryChange = (value) => {
    setNewCategory(value);
  };

  const handleNewBrandChange = (value) => {
    setNewBrand(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    params["createdby"] = jwtDecode(
      localStorage.getItem(process.env.REACT_APP_TOKEN_NAME)
    )._id;

    params["brand"] = params["brand"]["value"];
    const response = await campaignservice.addCampaign(params);
    if (response.status === 201) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: response.data,
        showConfirmButton: false,
        timer: 2000,
      });
      return;
    }

    if (file) {
      Swal.fire({
        title: "File is uploading Please wait...",
        showConfirmButton: false,
        timer: 1500,
      });

      const formData = new FormData();
      formData.append("file", file);
      formData.append("id", response.data._id);
      formData.append("createdby", params["createdby"]);
      await campaignservice.getUploadFile(formData);
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Campaign Created Successfully!",
        showConfirmButton: false,
        timer: 2500,
      });
    } else {
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Campaign Created Successfully!",
        showConfirmButton: false,
        timer: 2500,
      });
    }
    window.location.href = "/admin/payment/dashboard/campaigns/pending";
  };

  const Init = async () => {
    const platforms = await platformservice.getAllPlatform();
    setPlatforms(platforms);
    const brands = await brandservice.getAllBrands();
    setBrands(brands);
    const categories = await categoryservice.getCategories();
    setCategories(categories);
    setLoading(false);
  };

  useEffect(() => {
    Init();
  }, []);

  return (
    <>
      <li className="nav-item" style={{ cursor: "pointer" }}>
        <a
          className="nav-link link-light rounded"
          aria-current="page"
          onClick={btnShow}
          id={"campaignModalOpener"}
        >
          <FontAwesomeIcon icon={faAdd} className="me-1 align-self-center" />
          Campaign
        </a>
      </li>
      <Modal show={visible} onHide={btnClose}>
        <Modal.Header closeButton>
          <Modal.Title >Add Campaign</Modal.Title>
        </Modal.Header>
        <form onSubmit={handleSubmit}>
          <Modal.Body>
            <Brand
              loading={loading}
              customStyle={customStyle}
              customStyles={customStyles}
              userData={params}
              brand={brands}
              handleChange={handleBrandChange}
              handleNewBrandChange={handleNewBrandChange}
              value={params.brand}
            />
          </Modal.Body>
          <Modal.Body>
            <Category
              loading={loading}
              customStyle={customStyle}
              customStyles={customStyles}
              userData={params}
              category={categories}
              handleChange={handleCategoryChange}
              handleNewCategoryChange={handleNewCategoryChange}
            />
          </Modal.Body>
          <Modal.Body>
            <input
              type="text"
              className="form-control"
              placeholder="Campaign*"
              name="name"
              value={params.name}
              style={customStyle}
              onChange={handleChange}
              required
            />
          </Modal.Body>
          <Modal.Body>
            <input
              type="text"
              className="form-control"
              placeholder="Budget*"
              name="totalPayment"
              value={params.totalPayment}
              style={customStyle}
              onChange={handleChange}
              required
            />
          </Modal.Body>
          <Modal.Body>
            <textarea
              className="form-control"
              aria-label="With textarea"
              style={{ resize: "none", fontSize: "13px", height: "100px" }}
              placeholder="Deliverables"
              name="deliverables"
              value={params.deliverables}
              onChange={handleChange}
            ></textarea>
          </Modal.Body>
          <Modal.Body>
            <small>
              <strong>Upload File (If Applicable)</strong>
            </small>
            <input
              type="file"
              className="form-control"
              id="formGroupExampleInput"
              placeholder="Upload Your File"
              onChange={handleUpload}
              style={customStyle}
              title="Choose only '.csv' file to Upload"
              accept={".csv"}
            ></input>
          </Modal.Body>
          <Modal.Body>
            <h6>Platform</h6>
            <div className="row mx-auto">
              {platforms.map((platform, index) => (
                <div
                  className="col-3 form-check mt-2 ms-2"
                  style={{ width: "20%" }}
                  key={index}
                >
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id={platform.name}
                    name="platform"
                    value={platform._id}
                    onChange={handlePlatformChange}
                    required={params.platform.length > 0 ? false : true}
                  />
                  <label className="form-check-label" htmlFor={platform.name}>
                    <span
                      style={{
                        ...customStyle,
                        cursor: "pointer",
                      }}
                    >
                      {platform.name}
                    </span>
                  </label>
                </div>
              ))}
            </div>
          </Modal.Body>
          {isSuperUser && (
            <Modal.Body>
              <div class="form-check form-switch">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="flexSwitchCheckDefault"
                  name={"isOfficeExpense"}
                  onChange={handleChange}
                />
                <label class="form-check-label" for="flexSwitchCheckDefault">
                  Office Expense
                </label>
              </div>
            </Modal.Body>
          )}
          <Modal.Footer>
            <Button variant="danger" onClick={btnClose}>
              Close
            </Button>
            <Button className="btn btn-success" type="submit">
              Add
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
}

export default Campaign;
