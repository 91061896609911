import React, { useEffect, useState } from "react";
import PaymentDetails from "../../../components/payment/paymentInfo/companyInfo";
import paymentservice from "../../../services/paymentlinkservice";

function CompanyInfo({ match }) {
  const [companyInfo, setCompanyInfo] = useState({});

  const getInit = async () => {
    const linkInfo = await paymentservice.getCompanyPaymentLink({
      pathName: match["params"]["pathname"],
    });
    if (!linkInfo) {
      alert("not a valid url");
      return;
    }
    setCompanyInfo(linkInfo);
  };

  useEffect(() => {
    getInit();
  }, []);

  return <PaymentDetails companyInfo={companyInfo} />;
}

export default CompanyInfo;
