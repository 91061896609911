import React, { useState, useEffect } from "react";
import Header from "../../../../components/common/header/header";
import SildeMenu from "../../../../components/payment/common/slideMenu";
import campaignservice from "../../../../services/campaignservice";
import CampaignCompleted from "../../../../admin/payments/manage/campaigns/campaignCompleted";
import Footer from "../../../../components/common/footer/footer";

function PaymentCampaignCompleted() {
  const customStyle = { fontSize: "13px", fontWeight: "500" };
  const [loading, setLoading] = useState(true);
  const [campaigns, setCampaigns] = useState([]);

  const getInit = async () => {
    setLoading(true);
    const campaigns = await campaignservice.getCampaigns();
    setCampaigns(campaigns);
    setLoading(false);
  };

  useEffect(() => {
    getInit();
  }, []);

  return (
    <>
      <Header />
      <div className="container-fluid">
        <div className="row" style={{ minHeight: "100vh", height: "auto" }}>
          <SildeMenu />
          <CampaignCompleted
            loading={loading}
            customStyle={customStyle}
            campaigns={campaigns}
          />
        </div>
      </div>
      <Footer />
    </>
  );
}

export default PaymentCampaignCompleted;
