import { faClone, faEye, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Badge, Spinner, Table } from "react-bootstrap";
import IdToDate from "../../../admin/common/IdToDate";
import InfluencerPopUp from "../../common/contentPopup/InfluencerPopup";
import { useState } from "react";

function ShowCampaignData({
  data,
  loading,
  viewData,
  handleView,
  toggleView,
  setToggleView,
  customStyle,
  status,
  handleCopyClick,
  handleDecline,
}) {
  const handleClose = () => setToggleView(false);

  // const [totalAmount, setTotalAmount] = useState(0)
  // const [totalPayableAmount, setTotalPayableAmount] = useState(0)

  let totalAmount = 0;
  let totalPayableAmount = 0;

  data.map((p) => {
    totalAmount += p.cost;
    totalPayableAmount += parseInt(p.payableAmount);
  });

  return (
    <>
      {loading ? (
        <center>
          <Spinner animation="border" />
        </center>
      ) : data.length === 0 ? (
        <center>
          <b className="text-danger">No Data</b>
        </center>
      ) : (
        <Table hover size="sm">
          <thead>
            <tr>
              <th scope="col">
                <small className="">Date</small>
              </th>
              <th scope="col">
                <small className="">Payment</small>
              </th>
              <th scope="col">
                <small className="">Campaign</small>
              </th>
              <th scope="col">
                <small className="">Platform</small>
              </th>
              <th scope="col">
                <small className="">Influencer</small>
              </th>
              <th scope="col">
                <small className="">POC</small>
              </th>
              <th scope="col">
                <small className="">Total Amount <Badge bg="success" style={{fontSize: 14}} >&#8377;{totalAmount}</Badge></small>
              </th>
              <th scope="col">
                <small className="">GST Amount</small>
              </th>
              <th scope="col">
                <small className="">Percentage</small>
              </th>
              <th scope="col">
                <small className="">Payable Amount  <Badge bg="warning" style={{fontSize: 14, color: "black"}} >&#8377;{totalPayableAmount}</Badge></small>
              </th>
              <th scope="col">
                <small className="">Remarks</small>
              </th>
              <th scope="col">
                <small className="">Action</small>
              </th>
              <th scope="col">
                <small className=""></small>
              </th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan={12}>
                  <center>
                    <Spinner animation="border" />
                  </center>
                </td>
              </tr>
            ) : (
              data &&
              data.map((p, index) => (
                <tr
                  key={index}
                  id={`${p._id}-${index}`}
                  style={{ cursor: "pointer" }}
                >
                  <td onClick={() => handleView(p._id, `${p._id}-${index}`)}>
                    <IdToDate id={p._id} />
                  </td>
                  <td>
                    <Badge bg={p.isCompany ? "primary" : "info"}>
                      {p.isCompany ? "Vendor" : "Influencer"}
                    </Badge>
                  </td>
                  <td
                    style={customStyle}
                    onClick={() => handleView(p._id, `${p._id}-${index}`)}
                    title={p.campaign ? p.campaign.name : "---"}
                  >
                    {p.campaign
                      ? p.campaign.name.substring(0, 10) + "..."
                      : "---"}
                  </td>
                  <td
                    style={customStyle}
                    onClick={() => handleView(p._id, `${p._id}-${index}`)}
                  >
                    {p.platform ? p.platform.map((p) => p.name + " ") : "---"}
                  </td>
                  <td
                    style={customStyle}
                    onClick={() => handleView(p._id, `${p._id}-${index}`)}
                  >
                    {p.influencer ? p.influencer.username : "---"}
                  </td>
                  <td
                    style={customStyle}
                    onClick={() => handleView(p._id, `${p._id}-${index}`)}
                  >
                    {p.createdby.fullName}
                  </td>
                  <td onClick={() => handleView(p._id, `${p._id}-${index}`)}>
                    {p.cost ? (
                      <Badge bg="success" style={{ fontSize: "12px" }}>
                        &#8377;{p.cost}
                      </Badge>
                    ) : (
                      "---"
                    )}
                  </td>
                  <td onClick={() => handleView(p._id, `${p._id}-${index}`)}>
                    {p.gstAmount ? (
                      <Badge bg="success" style={{ fontSize: "12px" }}>
                        &#8377;{p.gstAmount}
                      </Badge>
                    ) : (
                      "---"
                    )}
                  </td>
                  <td
                    style={customStyle}
                    className="text-center"
                    onClick={() => handleView(p._id, `${p._id}-${index}`)}
                  >
                    {p.percentage}%
                  </td>
                  <td onClick={() => handleView(p._id, `${p._id}-${index}`)}>
                    {p.payableAmount ? (
                      <Badge
                        bg="warning"
                        style={{ fontSize: "12px", color: "black" }}
                      >
                        &#8377;{p.payableAmount}
                      </Badge>
                    ) : (
                      "---"
                    )}
                  </td>
                  {p.payment_Status ? (
                    <td
                      style={customStyle}
                      onClick={() => handleView(p._id, `${p._id}-${index}`)}
                    >
                      <b>{p.payment_Status}</b>
                    </td>
                  ) : (
                    <td
                      style={customStyle}
                      className="text-center"
                      onClick={() => handleView(p._id, `${p._id}-${index}`)}
                    >
                      ---
                    </td>
                  )}
                  <td style={customStyle} className="text-center">
                    <FontAwesomeIcon
                      icon={faEye}
                      style={{
                        fontSize: "15px",
                        cursor: "pointer",
                        marginRight: "10px",
                      }}
                      className="text-primary"
                      title="View"
                      onClick={() => handleView(p._id, `${p._id}-${index}`)}
                    />
                    {status === "P" && (
                      <>
                        <FontAwesomeIcon
                          icon={faTrash}
                          style={{
                            fontSize: "15px",
                            cursor: "pointer",
                            marginRight: "10px",
                          }}
                          className="text-danger"
                          title="Delete"
                          onClick={() =>
                            handleDecline(
                              p._id,
                              p.payableAmount,
                              `${p._id}-${index}`
                            )
                          }
                        />
                        <FontAwesomeIcon
                          icon={faClone}
                          onClick={() =>
                            handleCopyClick(p.pathName, p.isCompany)
                          }
                          style={{ cursor: "pointer", fontSize: "15px" }}
                          title="Copy Link"
                        />
                      </>
                    )}
                  </td>
                  <td className="text-center">
                    {p.paymentScreenshot ? (
                      <a
                        href={
                          process.env.REACT_APP_BASE_URL +
                          "/" +
                          p.paymentScreenshot
                        }
                        className="text-primary"
                        target={"_blank"}
                        title="Download Screenshot"
                        download
                      >
                        <img
                          src={
                            process.env.REACT_APP_BASE_URL +
                            "/" +
                            p.paymentScreenshot
                          }
                          width={25}
                          height={25}
                          className="rounded-circle border border-danger border-2"
                        />
                      </a>
                    ) : (
                      "---"
                    )}
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </Table>
      )}

      {toggleView && (
        <InfluencerPopUp
          loading={loading}
          data={viewData}
          show={toggleView}
          handleClose={handleClose}
        />
      )}
    </>
  );
}

export default ShowCampaignData;
