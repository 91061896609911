import React from 'react';
import Role from '../../components/role/role';

export default function role() {
  return (
    <>
      <Role />
    </>
  )
}
