import React from "react";
import "./css/profile.css";
import { Spinner, Tab, Tabs } from "react-bootstrap";
import { BrowserRouter as Router } from "react-router-dom";
import ShowInfluencerData from "./showInfluencerData";
import ShowOverviewData from "./showOverviewData";

export default function mainContent({
  loading,
  isCompany,
  ShowLabel,
  handleClick,
  overview,
  profileDetails,
  handleCampaignDetails,
  influencer,
  vendor,
  setProfileDetails,
}) {

  return (
    <Router>
      <div className="col-xl-12 d-flex flex-column pt-4" style={{ marginBottom: '4%' }} id="maincontent">
        {loading ? (
          <center>
            <Spinner animation="border" />
          </center>
        ) : (
          <>
            <h5>{vendor ? vendor : influencer.username ? influencer.username : "NA"}</h5>
            <Tabs
              defaultActiveKey={1}
              id="uncontrolled-tab-example"
              onSelect={handleClick}
              style={{ fontSize: "14px" }}
            >
              <Tab eventKey={1} title="Overview">
                <div className="row row-cols-1 row-cols-md-3 g-4 mt-1">
                  <ShowOverviewData
                    overview={overview}
                    handleCampaignDetails={handleCampaignDetails}
                    influencer={influencer}
                  />
                </div>
              </Tab>
              {profileDetails.length > 0 &&
                profileDetails.map((profile, index) => (
                  <Tab
                    eventKey={index + 2}
                    title={ShowLabel(profile._id)}
                    key={index}>
                    <ShowInfluencerData
                      loading={loading}
                      isCompany={isCompany}
                      indexStart={index + 1}
                      details={profile.details}
                      profileDetails={profileDetails}
                      setProfileDetails={setProfileDetails}
                      customStyle={{ fontSize: "11px", fontWeight: "500" }}
                      customButtonStyle={{ fontSize: "20px", cursor: "pointer" }}
                    />
                  </Tab>
                ))}
            </Tabs>
          </>
        )}
      </div>
    </Router>
  );
}
