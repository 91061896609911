import React from "react";
import "../../manage/css/manage.css";
import Completed from "../../../../components/common/campaignCard/campaigns/completed";

export default function CampaignCompleted({ loading, customStyle, campaigns }) {
  return (
    <>
      <div className="col-xl-10 col-lg-10 col-mg-10 col-sm-10  pt-3 px-4">
        <div className="row row-cols-1 row-cols-md-3 g-4">
          <Completed
            loading={loading}
            customStyle={customStyle}
            campaigns={campaigns}
          />
        </div>
      </div>
    </>
  );
}
