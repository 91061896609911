import React from "react";
import { Link } from "react-router-dom";
import CryptoJS from "crypto-js";

const InfluencerCompanyLink = ({ cell, row }) => {
  let ciphertext = CryptoJS.AES.encrypt(
    JSON.stringify(row.isCompany),
    "123"
  ).toString();
  ciphertext = ciphertext.replaceAll("/", "xXx");

  return (
    <>
      <div className="my-1">
        <Link
          to={{
            pathname: `${process.env.REACT_APP_PROFILE_URL}${
              row.isCompany
                ? row.company
                  ? row.company._id
                  : ""
                : row.influencer && row.influencer._id
            }/${ciphertext}`,
          }}
          target={"_blank"}
          style={{ textDecoration: "none", fontSize: "11px" }}
          className={`p-1 rounded-1 fw-bold text-white ${
            row.isCompany ? "bg-danger " : "bg-primary"
          }`}
        >
          {row.isCompany ? (row.company ? row.company.name : "NA") : cell}
        </Link>
      </div>
    </>
  );
};

export default InfluencerCompanyLink;
