import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleArrowDown, faFilter } from "@fortawesome/free-solid-svg-icons";
import "../paymentConfirmation/css/paymentConfirmation.css";
import paymentservice from "../../../services/paymentlinkservice";
import InfluencerPaymentsDetails from "./components/InfluencerPaymentsDetails";
import CompanyPaymentsDetails from "./components/CompanyPaymentsDetails";
import PaymentInfluencerList from "./components/PaymentInfluencerList";
import PaymentCompanyList from "./components/PaymentCompanyList";
import Swal from "sweetalert2";
import InfluencerPayment from "./components/InfluencerPayment";
import CompanyPayment from "./components/CompanyPayment";
import { Badge, Tab, Tabs } from "react-bootstrap";
import paymentlinkservice from "../../../services/paymentlinkservice";

export default function PaymentConf() {
  const CustomFontStyle = { fontSize: "11px", fontWeight: "500" };
  const CustomRowStyle = {
    fontSize: "11px",
    fontWeight: "500",
    cursor: "pointer",
  };
  const customHeadingStyle = { fontSize: "11px" };
  const customBadgeStyle = { cursor: "pointer" };
  const customRowInvoiceStyle = { backgroundColor: "red" };

  const [showPaymentDetails, setShowPaymentDetails] = useState(false);
  const [showCompanyPaymentDetails, setShowCompanyPaymentDetails] =
    useState(false);
  const [toggleCompanyList, setToggleCompanyList] = useState(false);
  const [remarksModal, setRemarksModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [detailLoading, setDetailLoading] = useState(true);
  const [toggleTabs, setToggleTabs] = useState(false);
  const [downloadInfluencer, setDownloadInfluencer] = useState(false);
  const [downloadCompany, setDownloadCompany] = useState(false);
  const [influencerCount, setInfluencerCount] = useState(0);
  const [companyCount, setCompanyCount] = useState(0);
  const [view, setView] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [vendorAddStatus, setVendorAddStatus] = useState(false);
  const [influencerAddStatus, setInfluencerAddStatus] = useState(false);
  const [influencerSelectAll, setInfluencerSelectAll] = useState(false);
  const [downloadInfluencerStatus, setDownloadInfluencerStatus] = useState("");
  const [downloadCompanyStatus, setDownloadCompanyStatus] = useState("");

  const [paymentCompanyData, setPaymentCompanyData] = useState([]);
  const [initialPaymentCompanyData, setInitialPaymentCompanyData] = useState([]);
  const [paymentInfluencerStatusData, setPaymentInfluencerStatusData] =
    useState([]);
  const [initialPaymentInfluencerStatusData, setInitialPaymentInfluencerStatusData] =
    useState([]);
  const [viewData, setViewData] = useState([]);
  const [pocUsers, setPocUsers] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  const [paymentData, setPaymentData] = useState([]);
  const [initialPaymentData, setInitialPaymentData] = useState([]);
  const [paymentDetails, setPaymentDetails] = useState([]);
  const [checkBoxVendorLists, setCheckBoxVendorLists] = useState([]);
  const [checkBoxInfluencerLists, setCheckBoxInfluencerLists] = useState([]);
  const [paymentCompanyStatusData, setPaymentCompanyStatusData] = useState([]);
  const [initialPaymentCompanyStatusData, setInitialPaymentCompanyStatusData] = useState([]);

  const [activePaymentIndex, setActivePaymentIndex] = useState(-1);
  const [searchCampaign, setSearchCampaign] = useState("");
  const [searchCompany, setSearchCompany] = useState("");
  const [filterInfluencerStatus, setFilterInfluencerStatus] = useState("");
  const [filterCompanyStatus, setFilterCompanyStatus] = useState("");
  const [influencerStatus, setInfluencerStatus] = useState("");
  const [companyStatus, setCompanyStatus] = useState("");
  const [influencerGstRequestedCount, setInfluecerGstRequestedCount] =
    useState(0);
  const [vendorGstRequestedCount, setVendorGstRequestedCount] = useState(0);
  const [financialPayment, setFinancialPayment] = useState(0);
  const [financialCompanyPayment, setFinancialCompanyPayment] = useState(0);
  const [userParams, setUserParams] = useState({
    id: "",
    status: "",
  });
  const [paymentStatus, setPaymentStatus] = useState({
    payment_Status: "",
  });

  let payment_Status = {};

  const handleFilterSearchChange = async (name, value, isCompany) => {
    if (value) {
      // Escape special characters in the value
      const escapedValue = value.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
      const regex = new RegExp(escapedValue, "i");
      let searchResults;
      let data;

      if (isCompany) data = [...initialPaymentCompanyStatusData];
      else data = [...initialPaymentInfluencerStatusData];

      searchResults = data.filter(item => regex.test(item));

      if (isCompany) setPaymentCompanyStatusData(searchResults);
      else setPaymentInfluencerStatusData(searchResults);

    } else {
      if (isCompany) setPaymentCompanyStatusData(initialPaymentCompanyStatusData);
      else setPaymentInfluencerStatusData(initialPaymentInfluencerStatusData);
    }
  }

  const handleSearchChange = async (name, value, isCompany) => {
    if (value) {
      const regex = new RegExp(value, "i");
      let searchResults;
      let data;

      if (isCompany) data = [...paymentCompanyData];
      else data = [...paymentData];

      if (name === 'campaign')
        searchResults = data.filter(item => regex.test(item.campaign.name));
      else if (name === 'influencer')
        searchResults = data.filter(item => regex.test(item.influencer.username));
      else if (name === 'vendor')
        searchResults = data.filter(item => regex.test(item.company.name));
      else
        searchResults = data.filter(item => regex.test(item.createdby.fullName));

      if (isCompany) setPaymentCompanyData(searchResults);
      else setPaymentData(searchResults);

    }
    else {
      setPaymentData(initialPaymentData);
      setPaymentCompanyData(initialPaymentCompanyData);
    }
  }

  const handleGstRequest = async (isCompany) => {
    setDetailLoading(true);
    if (isCompany) {
      const response = await paymentservice.getGstRequest({
        isCompany: isCompany,
      });
      setVendorGstRequestedCount(response.length);
      setPaymentCompanyData(response);
    } else {
      const response = await paymentservice.getGstRequest({
        isCompany: isCompany,
      });
      setInfluecerGstRequestedCount(response.length);
      setPaymentData(response);
    }
    setDetailLoading(false);
  };

  const handleSelectAll = async (id, isCompany, status) => {
    const ids = await paymentservice.getAllSelectedIds({
      isCompany: isCompany,
      payment_Status: status,
    });

    const element = document.getElementById(id);

    if (ids.length === 0) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "No New Payments",
        showConfirmButton: false,
        timer: 2000,
      });
      element.checked = false;
      return;
    }

    let elements = document.getElementsByClassName("multipleStatus");

    if (isCompany) {
      if (element.checked) {
        for (var i = 0; i < elements.length; i++) {
          elements[i].checked = true;
        }
        setCheckBoxVendorLists(ids);
        setVendorAddStatus(true);
        if (influencerAddStatus) setInfluencerAddStatus(false);
      } else {
        for (var i = 0; i < elements.length; i++) {
          elements[i].checked = false;
        }
        setCheckBoxVendorLists([]);
        setVendorAddStatus(false);
        if (influencerAddStatus) setInfluencerAddStatus(false);
      }
    } else {
      if (element.checked) {
        for (var i = 0; i < elements.length; i++) {
          elements[i].checked = true;
        }
        setCheckBoxInfluencerLists(ids);
        setInfluencerAddStatus(true);
        if (vendorAddStatus) setVendorAddStatus(false);
      } else {
        for (var i = 0; i < elements.length; i++) {
          elements[i].checked = false;
        }
        setCheckBoxInfluencerLists([]);
        setInfluencerAddStatus(false);
        if (vendorAddStatus) setVendorAddStatus(false);
      }
    }
  };

  const handleCheckboxClick = (id, status) => {
    let elements = document.getElementsByClassName("multipleStatus");
    let lists = [];
    if (status === "Under Process") {
      for (var i = 0; i < elements.length; i++) {
        if (elements[i].checked) lists = [...lists, elements[i].id];
        else if (!elements[i].checked) lists.filter((list) => list.id !== id);
      }
      if (!toggleTabs) {
        setCheckBoxInfluencerLists(lists);
        setInfluencerAddStatus(true);
        if (vendorAddStatus) setVendorAddStatus(false);
        const element = document.getElementById("influencerSelectAll");
        if (lists.length === 0) element.checked = false;
      } else {
        setCheckBoxVendorLists(lists);
        setVendorAddStatus(true);
        if (influencerAddStatus) setInfluencerAddStatus(false);
        const element = document.getElementById("vendorSelectAll");
        if (lists.length === 0) element.checked = false;
      }
    } else if (status === "Done") {
      for (var i = 0; i < elements.length; i++) {
        if (elements[i].checked) lists = [...lists, elements[i].id];
        else if (!elements[i].checked) lists.filter((list) => list.id !== id);
      }
      if (!toggleTabs) {
        setCheckBoxInfluencerLists(lists);
        setInfluencerAddStatus(true);
        if (vendorAddStatus) setVendorAddStatus(false);
        const element = document.getElementById("influencerSelectAll");
        if (lists.length === 0) element.checked = false;
      } else {
        setCheckBoxVendorLists(lists);
        setVendorAddStatus(true);
        if (influencerAddStatus) setInfluencerAddStatus(false);
        const element = document.getElementById("vendorSelectAll");
        if (lists.length === 0) element.checked = false;
      }
    }
  };

  const handleMultipleStatus = async (status) => {
    await paymentservice.addStatusAtMultiple({
      statusLists: !toggleTabs ? checkBoxInfluencerLists : checkBoxVendorLists,
      payment_Status: status,
    });
    Swal.fire({
      icon: "success",
      title: "Status Updated!",
      showConfirmButton: false,
      timer: 3000,
    });
    window.location.reload();
  };

  const handleCampaignSearch = async (value) => {
    setLoading(true);

    if (value) setSearchCampaign(value);
    else window.location.reload();

    const response = !searchCampaign
      ? campaigns
      : campaigns.filter((item) =>
        item.campaignName[0]
          .toLowerCase()
          .includes(searchCampaign.toLowerCase())
      );

    setCampaigns(response);
    setLoading(false);
  };

  const handleCompanySearch = async (value) => {
    setLoading(true);

    if (value) setSearchCompany(value);
    else window.location.reload();

    const response = !searchCompany
      ? companies
      : companies.filter((item) =>
        item.name[0].toLowerCase().includes(searchCompany.toLowerCase())
      );

    setCompanies(response);
    setLoading(false);
  };

  const handleInfluencerStatusFilter = async (status) => {
    const tableRows = document.getElementsByTagName("tr");
    for (var i = 0; i < tableRows.length; i++) {
      if (tableRows[i].classList.contains("tableActive"))
        tableRows[i].classList.remove("tableActive");
    }

    setDownloadInfluencer(true);
    setDownloadInfluencerStatus(status);

    if (showPaymentDetails) {
      setShowPaymentDetails(false);
    }

    const influencerFilterData = await paymentservice.getSelectedStatus({
      status: status,
      isCompany: false,
    });

    if (status !== "") {
      status = status + "-(" + influencerFilterData.payment.length + ")";
      setFilterInfluencerStatus(status);
    } else {
      status = "New-(" + influencerFilterData.payment.length + ")";
      setFilterInfluencerStatus(status);
    }

    if (influencerFilterData.length < 1) setDownloadInfluencer(false);
    setPaymentData(influencerFilterData.payment);
  };

  const handleCompanyStatusFilter = async (status) => {
    const tableRows = document.getElementsByTagName("tr");
    for (var i = 0; i < tableRows.length; i++) {
      if (tableRows[i].classList.contains("tableActive"))
        tableRows[i].classList.remove("tableActive");
    }

    setDownloadCompany(true);
    setDownloadCompanyStatus(status);

    if (showCompanyPaymentDetails) {
      setShowCompanyPaymentDetails(false);
    }

    const companyFilterData = await paymentservice.getSelectedStatus({
      status: status,
      isCompany: true,
    });

    if (status !== "") {
      status = status + "-(" + companyFilterData.payment.length + ")";
      setFilterCompanyStatus(status);
    } else {
      status = "New-(" + companyFilterData.payment.length + ")";
      setFilterCompanyStatus(status);
    }

    if (companyFilterData.length < 1) setDownloadCompany(false);
    setPaymentCompanyData(companyFilterData.payment);
  };

  const handleCompanyStatus = async (status, statusCode) => {
    setDetailLoading(true);
    if (showCompanyPaymentDetails) {
      setShowCompanyPaymentDetails(false);
    }

    const companyStatusData = await paymentservice.getDataByStatus({
      status: statusCode,
      isCompany: true,
    });

    status = status + " - (" + companyStatusData.length + ")";
    setCompanyStatus(status);

    setPaymentCompanyData(companyStatusData);
    setDetailLoading(false);
  };

  async function handleUpdate(obj) {
    let { name, value } = obj.target;
    const id = value.split("_")[0];
    const status = value.split("_")[1];

    await paymentservice.getUpdatedData(id, status);

    if (status === "Failure") {
      userParams["id"] = id;
      setRemarksModal(true);
    }
  }

  const handlePaymentStatus = async (e) => {
    let { name, value } = e.target;
    payment_Status = {
      payment_status: value,
    };
  };

  const handleUpload = async (id) => {
    const { value: file } = await Swal.fire({
      title: "Select image",
      input: "file",
      inputAttributes: {
        accept: "image/*",
        "aria-label": "Upload Payment Screenshot",
      },
    });

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        Swal.fire({
          title: "Your Uploaded Picture",
          imageUrl: e.target.result,
          imageAlt: "The Uploaded Picture",
        });
      };
      reader.readAsDataURL(file);
      const formData = new FormData();
      formData.append("file", file);
      formData.append("id", id);
      await paymentlinkservice.getUploadScreenshot(formData);
    }
  };

  const uploadGstScreenshot = async (id) => {
    const { value: file } = await Swal.fire({
      title: "Select image",
      input: "file",
      inputAttributes: {
        accept: "image/*",
        "aria-label": "Upload GST Screenshot",
      },
    });

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        Swal.fire({
          title: "Your Uploaded Picture",
          imageUrl: e.target.result,
          imageAlt: "The Uploaded Picture",
        });
      };
      reader.readAsDataURL(file);
      const formData = new FormData();
      formData.append("file", file);
      formData.append("id", id);
      await paymentlinkservice.uploadGstScreenshot(formData);
    }
  };

  const handleDelete = async (id, isCompany) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-danger me-2",
        cancelButton: "btn btn-success me-2",
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert data!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          Swal.fire({
            title: "Submit the Remarks",
            input: "text",
            inputAttributes: {
              autocapitalize: "off",
            },
            showCancelButton: true,
            confirmButtonText: "Submit",
            showLoaderOnConfirm: true,
            preConfirm: async (msg) => {
              if (isCompany) {
                setPaymentCompanyData(
                  paymentCompanyData.filter((item) => item._id !== id)
                );
                if (showCompanyPaymentDetails) {
                  setShowCompanyPaymentDetails(false);
                }
              } else {
                setPaymentData(paymentData.filter((item) => item._id !== id));
                if (showPaymentDetails) {
                  setShowPaymentDetails(false);
                }
              }
              await paymentservice.deleteRemarks({
                id: id,
                remarks: msg,
                isCompany: isCompany,
              });
            },
            allowOutsideClick: () => !Swal.isLoading(),
          }).then((result) => {
            if (result.isConfirmed) {
              swalWithBootstrapButtons.fire(
                "Deleted!",
                "Your file has been deleted.",
                "success"
              );
            }
          });
        }
      });
  };

  const handleFail = async (id, isCompany, payment_Status) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-danger me-2",
        cancelButton: "btn btn-success me-2",
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert data!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, Fail it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          Swal.fire({
            title: "Submit the Remarks",
            input: "text",
            inputAttributes: {
              autocapitalize: "off",
            },
            showCancelButton: true,
            confirmButtonText: "Submit",
            showLoaderOnConfirm: true,
            preConfirm: async (msg) => {
              if (isCompany) {
                setPaymentCompanyData(
                  paymentCompanyData.filter((item) => item._id !== id)
                );
                if (showCompanyPaymentDetails) {
                  setShowCompanyPaymentDetails(false);
                }
              } else {
                setPaymentData(paymentData.filter((item) => item._id !== id));
                if (showPaymentDetails) {
                  setShowPaymentDetails(false);
                }
              }
              await paymentservice.failRemarks({
                id: id,
                remarks: msg,
                isCompany: isCompany,
                payment_Status: payment_Status,
              });
            },
            allowOutsideClick: () => !Swal.isLoading(),
          }).then((result) => {
            if (result.isConfirmed) {
              swalWithBootstrapButtons.fire(
                "Failed!",
                "Your data has been failed.",
                "success"
              );
            }
          });
        }
      });
  };

  const handleGstPay = async (id, isGstPay) => {
    const element = document.getElementById(id);
    if (element.checked) {
      Swal.fire({
        title: "Do you want to pay GST?",
        showDenyButton: true,
        confirmButtonText: "Now",
        denyButtonText: `Later`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          Swal.fire("Done!", "", "success");
          if (!isGstPay)
            await paymentservice.updateIsGstPay({ _id: id.split("-")[0] });
        } else if (result.isDenied) {
          Swal.fire("Please, Pay GST later", "", "info");
          element.checked = false;
        }
      });
    }
  };

  const handleClick = (key) => {
    if (showCompanyPaymentDetails) setShowCompanyPaymentDetails(false);
    if (showPaymentDetails) setShowPaymentDetails(false);
    if (checkBoxInfluencerLists.length > 0) setCheckBoxInfluencerLists([]);
    if (checkBoxVendorLists.length > 0) setCheckBoxVendorLists([]);

    if (key === "1") {
      setToggleCompanyList(false);
      setToggleTabs(false);
      if (downloadCompany) setDownloadCompany(false);
      if (toggleTabs === true) {
        if (paymentData.length > 0 && paymentData[0].payment_Status === "") {
          setDownloadInfluencer(true);
          setInfluencerCount(paymentData.length);
        }
      }
      if (vendorAddStatus) setVendorAddStatus(false);
    } else {
      setToggleCompanyList(true);
      setToggleTabs(true);
      if (downloadInfluencer) setDownloadInfluencer(false);
      if (toggleTabs === false) {
        if (
          paymentCompanyData.length > 0 &&
          paymentCompanyData[0].payment_Status === ""
        ) {
          setDownloadCompany(true);
          setCompanyCount(paymentCompanyData.length);
        }
      }
      if (influencerAddStatus) setInfluencerAddStatus(false);
    }
  };

  async function handleShow(index, id) {
    setDetailLoading(true);
    const tableRows = document.getElementsByTagName("tr");
    for (var i = 0; i < tableRows.length; i++) {
      if (tableRows[i].classList.contains("tableActive"))
        tableRows[i].classList.remove("tableActive");
    }

    const elemnt = document.getElementById(index);
    elemnt.classList.add("tableActive");
    const resp = await paymentservice.getByCampaignId(id);
    setPaymentData(resp);

    const options = document.getElementsByTagName("option");
    var temp = 0;
    for (let j = 0; j < resp.length; j++) {
      const currentStatus = resp[j].status;
      for (let i = temp; i < options.length; i++) {
        const value = options[i].value;
        const finalValue = value.split("_")[1];
        if (finalValue === currentStatus) {
          options[i].setAttribute("selected", "");
          break;
        }
      }
      temp += 5;
    }

    if (showPaymentDetails) {
      setShowPaymentDetails(false);
    }

    if (showCompanyPaymentDetails) {
      setShowCompanyPaymentDetails(false);
    }

    if (toggleCompanyList) {
      setToggleCompanyList(false);
    }
    setDetailLoading(false);
  }

  async function handleCompanyShow(index, name, id) {
    setDetailLoading(true);
    const tableRows = document.getElementsByTagName("tr");
    for (var i = 0; i < tableRows.length; i++) {
      if (tableRows[i].classList.contains("tableActive"))
        tableRows[i].classList.remove("tableActive");
    }

    const elemnt = document.getElementById(index);
    elemnt.classList.add("tableActive");
    const resp = await paymentservice.getByCompanyId(id);
    setPaymentCompanyData(resp);

    const options = document.getElementsByTagName("option");
    var temp = 0;
    for (let j = 0; j < resp.length; j++) {
      const currentStatus = resp[j].status;
      for (let i = temp; i < options.length; i++) {
        const value = options[i].value;
        const finalValue = value.split("_")[1];
        if (finalValue === currentStatus) {
          options[i].setAttribute("selected", "");
          break;
        }
      }
      temp += 5;
    }

    if (showPaymentDetails) {
      setShowPaymentDetails(false);
    }

    if (showCompanyPaymentDetails) {
      setShowCompanyPaymentDetails(false);
    }
    setDetailLoading(false);
  }

  async function handleDownload(id, name, isCompany) {
    const resp = await paymentservice.download({
      id: id,
      name: name,
      isCompany: isCompany,
    });
    const url = process.env.REACT_APP_BASE_URL + "/" + resp.name;
    window.open(url, "_blank", "noopener,noreferrer");
  }

  async function handleClubLinksExport(ids, isCompany) {
    const resp = await paymentservice.exportClubLinksData({
      paymentIds: ids,
      isCompany: isCompany,
    });

    const url = process.env.REACT_APP_BASE_URL + "/" + resp.name;
    window.open(url, "_blank", "noopener,noreferrer");
  }

  async function handleInfluencerExport(status, isCompany) {
    const resp = await paymentservice.exportData({
      status: status,
      isCompany: isCompany,
    });

    const url = process.env.REACT_APP_BASE_URL + "/" + resp.name;
    window.open(url, "_blank", "noopener,noreferrer");
  }

  async function handleCompanyExport(status, isCompany) {
    const resp = await paymentservice.exportData({
      status: status,
      isCompany: isCompany,
    });

    const url = process.env.REACT_APP_BASE_URL + "/" + resp.name;
    window.open(url, "_blank", "noopener,noreferrer");
  }

  const handleCompanyListShow = () => {
    setToggleCompanyList(true);
  };

  const handleView = async (id, index) => {
    setShow(true);
    if (view) setView(false);

    const tableRows = document.getElementsByClassName("tableRow");
    for (var i = 0; i < tableRows.length; i++) {
      if (tableRows[i].classList.contains("tableActive"))
        tableRows[i].classList.remove("tableActive");
    }
    const elemnt = document.getElementById(index);
    elemnt.classList.add("tableActive");

    if (id === "hide") {
      setActivePaymentIndex(-1);
      setShowPaymentDetails(false);
      return;
    }
    setShowPaymentDetails(true);
    setActivePaymentIndex(index);

    const final = await paymentlinkservice.getPaymentDetailsById({ id: id });
    // const final = paymentData.filter((item) => item._id === id);
    setPaymentDetails(final);
  };

  const handleCompanyView = async (id, index) => {
    setShow(true);
    if (view) setView(false);

    const tableRows = document.getElementsByClassName("tableRow");
    for (var i = 0; i < tableRows.length; i++) {
      if (tableRows[i].classList.contains("tableActive"))
        tableRows[i].classList.remove("tableActive");
    }
    const elemnt = document.getElementById(index);
    elemnt.classList.add("tableActive");

    if (id === "hide") {
      setActivePaymentIndex(-1);
      setShowCompanyPaymentDetails(false);
      return;
    }
    setShowCompanyPaymentDetails(true);
    setActivePaymentIndex(index);

    const final = await paymentlinkservice.getPaymentDetailsById({ id: id });
    setPaymentDetails(final);
  };

  const handleRadioButton = async (id, typeOfPayment) => {
    await paymentservice.updateTypeOfPayment({
      id: id,
      typeOfPayment: typeOfPayment,
    });
  };

  const handleSubmit = async (
    id,
    isCompany,
    elm_id,
    payment_Status,
    isGstRequested
  ) => {
    await paymentservice.addPaymentStatus({
      _id: id,
      isCompany: isCompany,
      payment_Status: payment_Status,
      isGstRequested: isGstRequested,
    });

    if (payment_Status === "Done")
      document.getElementById(elm_id).innerHTML = `${payment_Status}(${new Date(
        Date.now()
      ).toLocaleDateString(undefined, {
        day: "numeric",
        month: "numeric",
        year: "numeric",
      })})`;

    Swal.fire({
      icon: "success",
      title: "Updated!",
      showConfirmButton: false,
      timer: 2000,
    });

    window.location.reload();
  };

  const handleLinksView = async (links) => {
    const response = await paymentlinkservice.getClubLinks({
      linkChild: links,
    });
    setViewData(response);
    setView(true);
  };

  const handlePancardSearch = async (value, isCompany) => {
    if (value) {
      const response = await paymentlinkservice.getSearchByPancard({ search: value.toUpperCase(), isCompany: isCompany });
      if (isCompany) setPaymentCompanyData(response);
      else setPaymentData(response);
    } else {
      setPaymentData(initialPaymentData);
      setPaymentCompanyData(initialPaymentCompanyData);
    }
  }

  const getInit = async () => {
    const allCampaings = await paymentservice.getAllByCampaigns();
    const allCompanies = await paymentservice.getAllCampaignsByCompany();
    const allInfluencerStatus =
      await paymentservice.getPaymentInfluencerStatus();
    const allCompanyStatus = await paymentservice.getPaymentCompanyStatus();

    const influencerFilterData = await paymentservice.getSelectedStatus({
      status: "",
      isCompany: false,
    });

    const companyFilterData = await paymentservice.getSelectedStatus({
      status: "",
      isCompany: true,
    });
    
    setPaymentCompanyData(companyFilterData.payment);
    setInitialPaymentCompanyData(companyFilterData.payment);
    setFinancialCompanyPayment(companyFilterData.financialPayment);
    setPaymentData(influencerFilterData.payment);
    setInitialPaymentData(influencerFilterData.payment);
    setFinancialPayment(influencerFilterData.financialPayment);

    if (toggleTabs === false) {
      if (
        influencerFilterData.payment.length > 0 &&
        influencerFilterData.payment[0].payment_Status === ""
      ) {
        setDownloadInfluencer(true);
        setInfluencerCount(influencerFilterData.payment.length);
      }
    }

    setCampaigns(allCampaings);
    setCompanies(allCompanies);
    setPaymentInfluencerStatusData(allInfluencerStatus);
    setPaymentCompanyStatusData(allCompanyStatus);
    setInitialPaymentCompanyStatusData(allCompanyStatus);
    setInitialPaymentInfluencerStatusData(allInfluencerStatus);

    setLoading(false);
    setDetailLoading(false);
  };

  useEffect(() => {
    getInit();
  }, []);

  return (
    <>
      <div className="col-xl-12 p-2">
        <div className="mb-2 d-flex" id="dateSearch">
          <div className="w-25">
            {toggleTabs ? (
              <input
                className="form-control"
                type="search"
                placeholder="Search Vendor"
                aria-label="Search"
                name="search"
                value={searchCompany}
                onChange={(e) => handleCompanySearch(e.target.value)}
                autoComplete="off"
              />
            ) : (
              <input
                className="form-control"
                type="search"
                placeholder="Search Campaign"
                aria-label="Search"
                name="search"
                value={searchCampaign}
                onChange={(e) => handleCampaignSearch(e.target.value)}
                autoComplete="off"
              />
            )}
          </div>
          <div className="ps-2 w-75 d-flex justify-content-between">
            <div className="align-self-center">
              {!toggleTabs &&
                influencerAddStatus &&
                checkBoxInfluencerLists.length > 0 &&
                (filterInfluencerStatus === "" ||
                  filterInfluencerStatus.split("-")[0] === "New") && (
                  <h6>
                    <span
                      className="badge bg-primary"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleMultipleStatus("Under Process")}
                    >
                      Under Process
                    </span>
                  </h6>
                )}
              {!toggleTabs &&
                influencerAddStatus &&
                checkBoxInfluencerLists.length > 0 &&
                filterInfluencerStatus.split("-")[0] === "Under Process" && (
                  <h6>
                    <span
                      className="badge bg-primary"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleMultipleStatus("Done")}
                    >
                      Done
                    </span>
                  </h6>
                )}
              {toggleTabs &&
                vendorAddStatus &&
                checkBoxVendorLists.length > 0 &&
                (filterCompanyStatus === "" ||
                  filterCompanyStatus.split("-")[0] === "New") && (
                  <h6>
                    <span
                      className="badge bg-primary"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleMultipleStatus("Under Process")}
                    >
                      Under Process
                    </span>
                  </h6>
                )}
              {toggleTabs &&
                vendorAddStatus &&
                checkBoxVendorLists.length > 0 &&
                filterCompanyStatus.split("-")[0] === "Under Process" && (
                  <h6>
                    <span
                      className="badge bg-primary"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleMultipleStatus("Done")}
                    >
                      Done
                    </span>
                  </h6>
                )}
              {!toggleTabs && (
                <h6>
                  <span
                    className="badge bg-warning"
                    style={{ cursor: "pointer", color: "#000" }}
                    onClick={() => handleGstRequest(false)}
                  >
                    GST Requests
                    {influencerGstRequestedCount > 0
                      ? " - (" + influencerGstRequestedCount + ")"
                      : ""}
                  </span>
                </h6>
              )}
              {toggleTabs && (
                <h6>
                  <span
                    className="badge bg-warning"
                    style={{ cursor: "pointer", color: "#000" }}
                    onClick={() => handleGstRequest(true)}
                  >
                    GST Requests
                    {vendorGstRequestedCount > 0
                      ? " - (" + vendorGstRequestedCount + ")"
                      : ""}
                  </span>
                </h6>
              )}
            </div>
            <div>
              {toggleTabs ? (
                <input
                  className="form-control"
                  type="search"
                  placeholder="Search Pancard"
                  aria-label="Search"
                  onChange={(e) => handlePancardSearch(e.target.value, true)}
                  autoComplete="off"
                />
              ) : (
                <input
                  className="form-control"
                  type="search"
                  placeholder="Search Pancard"
                  aria-label="Search"
                  onChange={(e) => handlePancardSearch(e.target.value, false)}
                  autoComplete="off"
                />
              )}
            </div>
            <div>
              <div>
                <h6>
                  <span className="badge bg-primary">
                    {toggleTabs ? filterCompanyStatus : filterInfluencerStatus}
                  </span>{" "}
                  <span className="badge bg-primary">
                    {toggleTabs ? companyStatus : influencerStatus}
                  </span>
                </h6>
              </div>
              <div>
                {companyCount > 0 && toggleTabs && (
                  <Badge bg="success" className="me-2 align-text-bottom">
                    New - {companyCount}
                  </Badge>
                )}
                {downloadCompany && (
                  <FontAwesomeIcon
                    onClick={() =>
                      handleCompanyExport(downloadCompanyStatus, true)
                    }
                    icon={faCircleArrowDown}
                    style={{
                      cursor: "pointer",
                      fontSize: "20px",
                      marginRight: "10px",
                    }}
                    className="text-primary"
                    title="Download Vendor"
                  />
                )}
                {influencerCount > 0 && !toggleTabs && (
                  <Badge bg="success" className="me-2 align-text-bottom">
                    New - {influencerCount}
                  </Badge>
                )}
                {downloadInfluencer && (
                  <FontAwesomeIcon
                    onClick={() =>
                      handleInfluencerExport(downloadInfluencerStatus, false)
                    }
                    icon={faCircleArrowDown}
                    style={{
                      cursor: "pointer",
                      fontSize: "20px",
                      marginRight: "10px",
                    }}
                    className="text-primary"
                    title="Download Influencer"
                  />
                )}
                {toggleTabs ? (
                  <>
                    <FontAwesomeIcon
                      icon={faFilter}
                      color={"#0d6efd"}
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      style={{ cursor: "pointer" }}
                      title="Vendor Filter"
                      border
                    />
                    <ul
                      className="dropdown-menu paymentScroll"
                      style={{ height: "630px", overflowY: "auto" }}
                    >
                      <li>
                        <input className="form-control form-control-sm " type="text" placeholder="Status... " onChange={(e) => handleFilterSearchChange("status", e.target.value, true)} aria-label=".form-control-sm example" />
                      </li>
                      {paymentCompanyStatusData.length > 0 ? (
                        paymentCompanyStatusData.map((status, index) => (
                          <li key={index} style={CustomRowStyle}>
                            <a
                              className="dropdown-item"
                              href="#"
                              onClick={() => handleCompanyStatusFilter(status)}
                            >
                              {status}
                            </a>
                          </li>
                        ))
                      ) : (
                        <li className="text-danger text-center">
                          <b>No Data</b>
                        </li>
                      )}
                    </ul>
                  </>
                ) : (
                  <>
                    <FontAwesomeIcon
                      icon={faFilter}
                      color={"#0d6efd"}
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      style={{ cursor: "pointer" }}
                      title="Influencer Filter"
                      border
                    />
                    <ul
                      className="dropdown-menu paymentScroll"
                      style={{ height: "630px", overflowY: "auto" }}
                    >
                      <li>
                        <input className="form-control form-control-sm " type="text" placeholder="Status... " onChange={(e) => handleFilterSearchChange("status", e.target.value, false)} aria-label=".form-control-sm example" />
                      </li>
                      {paymentInfluencerStatusData.length > 0 ? (
                        paymentInfluencerStatusData.map((status, index) => (
                          <>
                            <li key={index} style={CustomRowStyle}>
                              <a
                                className="dropdown-item"
                                href="#"
                                onClick={() => handleInfluencerStatusFilter(status)}
                              >
                                {status}
                              </a>
                            </li>
                          </>
                        ))
                      ) : (
                        <li className="text-danger text-center">
                          <b>No Data</b>
                        </li>
                      )}
                    </ul>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex">
          <div
            className=" px-2 pt-2 pb-4 mb-2 w-25 shadow rounded-3 overflow-auto paymentConf scroll"
            id="tableSection"
            style={{ height: "100vh" }}
          >
            <Tabs
              defaultActiveKey={1}
              id="uncontrolled-tab-example"
              className="mb-3"
              onSelect={handleClick}
              style={{ ...customHeadingStyle, fontSize: "14px" }}
            >
              <Tab eventKey={1} title="Influencer">
                <InfluencerPayment
                  customHeadingStyle={customHeadingStyle}
                  loading={loading}
                  campaigns={campaigns}
                  CustomRowStyle={CustomRowStyle}
                  handleShow={handleShow}
                  handleDownload={handleDownload}
                  CustomFontStyle={CustomFontStyle}
                />
              </Tab>
              <Tab eventKey={2} title="Vendor">
                <CompanyPayment
                  customHeadingStyle={customHeadingStyle}
                  loading={loading}
                  companies={companies}
                  CustomRowStyle={CustomRowStyle}
                  handleCompanyShow={handleCompanyShow}
                  handleDownload={handleDownload}
                  handleCompanyListShow={handleCompanyListShow}
                  CustomFontStyle={CustomFontStyle}
                />
              </Tab>
            </Tabs>
          </div>
          <div className="d-grid w-75" id="tableSection">
            <div>
              {toggleCompanyList && (
                <PaymentCompanyList
                  loading={detailLoading}
                  CustomFontStyle={CustomFontStyle}
                  CustomRowStyle={CustomRowStyle}
                  customBadgeStyle={customBadgeStyle}
                  customHeadingStyle={customHeadingStyle}
                  paymentCompanyData={paymentCompanyData}
                  remarksModal={remarksModal}
                  userParams={userParams}
                  paymentStatus={paymentStatus}
                  handleGstPay={handleGstPay}
                  handleCheckboxClick={handleCheckboxClick}
                  handleSelectAll={handleSelectAll}
                  filterCompanyStatus={filterCompanyStatus}
                  handleUpdate={handleUpdate}
                  handleCompanyView={handleCompanyView}
                  handleSubmit={handleSubmit}
                  handleRadioButton={handleRadioButton}
                  handleDelete={handleDelete}
                  handleFail={handleFail}
                  handleUpload={handleUpload}
                  financialCompanyPayment={financialCompanyPayment}
                  uploadGstScreenshot={uploadGstScreenshot}
                  handleSearchChange={handleSearchChange}
                  initialPaymentCompanyData={initialPaymentCompanyData}
                />
              )}
              {!toggleCompanyList && (
                <PaymentInfluencerList
                  CustomFontStyle={CustomFontStyle}
                  CustomRowStyle={CustomRowStyle}
                  customBadgeStyle={customBadgeStyle}
                  customHeadingStyle={customHeadingStyle}
                  paymentData={paymentData}
                  financialPayment={financialPayment}
                  handleGstPay={handleGstPay}
                  handleCheckboxClick={handleCheckboxClick}
                  handleSelectAll={handleSelectAll}
                  filterInfluencerStatus={filterInfluencerStatus}
                  influencerSelectAll={influencerSelectAll}
                  handleUpdate={handleUpdate}
                  handleView={handleView}
                  remarksModal={remarksModal}
                  userParams={userParams}
                  paymentStatus={paymentStatus}
                  handleSubmit={handleSubmit}
                  handleRadioButton={handleRadioButton}
                  handleDelete={handleDelete}
                  handleFail={handleFail}
                  handleUpload={handleUpload}
                  uploadGstScreenshot={uploadGstScreenshot}
                  loading={detailLoading}
                  handleSearchChange={handleSearchChange}
                  initialPaymentData={initialPaymentData}
                />
              )}
            </div>
            <div>
              {showPaymentDetails && (
                <>
                  <InfluencerPaymentsDetails
                    CustomFontStyle={CustomFontStyle}
                    customHeadingStyle={customHeadingStyle}
                    paymentData={paymentDetails}
                    view={view}
                    show={show}
                    handleClose={handleClose}
                    viewData={viewData}
                    handleView={handleLinksView}
                    handleClubLinksExport={handleClubLinksExport}
                    customStyle={{ fontSize: "11px", fontWeight: "500" }}
                  />
                </>
              )}
            </div>
            <div>
              {showCompanyPaymentDetails && (
                <>
                  <CompanyPaymentsDetails
                    CustomFontStyle={CustomFontStyle}
                    customHeadingStyle={customHeadingStyle}
                    paymentData={paymentDetails}
                    view={view}
                    viewData={viewData}
                    show={show}
                    handleClose={handleClose}
                    handleView={handleLinksView}
                    handleClubLinksExport={handleClubLinksExport}
                    customStyle={{ fontSize: "11px", fontWeight: "500" }}
                  />
                </>
              )}
            </div>
            <br />
            <br />
          </div>
        </div>
      </div>
    </>
  );
}
