import React from "react";

export default function influencerList({ name }) {

  const influencerUrl = `/profile/${name}/`;

  return (
    <>
      <li className="list-group-item border-bottom-0">
        <a href={influencerUrl} className="text-decoration-none">
          {name}
        </a>
      </li>
    </>
  );
}
