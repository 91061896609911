import React from "react";
import "../influencer/css/influencer.css";
import Select from "react-select";
import { useEffect } from "react";
import { useState, useCallback } from "react";
import makeAnimated from "react-select/animated";

const makeAnimatedComp = makeAnimated();

const Brand = ({
  loading,
  customStyle,
  customStyles,
  userData,
  brand,
  handleChange,
  title = "Brand",
  value
}) => {
  const [options, setOptions] = useState([{ value: "", label: "" }]);

  // const handleInputChange = (inputValue, actionMeta) => {
  //   handleNewBrandChange(inputValue);
  //   if (actionMeta.action === "menu-close") {
  //   }
  // };

  useEffect(() => {
    let opt = [];
    brand.map((b) => {
      opt.push({ value: b._id, label: `${b.name} | ${b.category.name}` });
    });
    setOptions(opt);
  }, [brand]);

  return (
    <div className="col" style={{ fontSize: "13px" }}>
      <Select
        styles={customStyles}
        className="scroll "
        name={title.toLowerCase()}
        title={title}
        isLoading={loading}
        components={makeAnimatedComp}
        placeholder={title}
        isMulti={false}
        options={options}
        isClearable={true}
        isSearchable={true}
        value={value}
        onChange={handleChange}
        // onInputChange={handleInputChange}
        // defaultInputValue={["name", "name2"]}
        // defaultMenuIsOpen={["Art"]}
        // defaultValue={"Art"}
        // closeMenuOnSelect={false}
        style={{ backgroundColor: "black" }}
      />
    </div>
  );
};

export default Brand;
