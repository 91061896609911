import { faCreativeCommonsBy } from "@fortawesome/free-brands-svg-icons";
import {
  faListCheck,
  faRupee,
  faUsers,
  faCirclePlus,
  faArrowLeft,
  faHandsClapping,
  faArrowsRotate,
  faCheckDouble,
  faFileCircleExclamation,
  faFileExcel,
  faHouseFloodWaterCircleArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Spinner, Tab, Tabs } from "react-bootstrap";
import IdToDate from "../../../admin/common/IdToDate";
import ShowCampaignData from "./showCampaignData";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import paymentlinkservice from "../../../services/paymentlinkservice";

export default function CampaignInfo({
  loading,
  toggleView,
  setToggleView,
  handleClick,
  campaignDetails,
  customStyle,
  customButtonStyle,
  borderRadiusStyle,
  backgroundColorStyle,
  campaignsInfo,
}) {
  const fontStyle = { fontSize: "11px" };
  const [viewData, setViewData] = useState([]);
  const history = useHistory();

  const handleView = async (id, index) => {
    const tableRows = document.getElementsByTagName("tr");
    for (var i = 0; i < tableRows.length; i++) {
      if (tableRows[i].classList.contains("tableActive"))
        tableRows[i].classList.remove("tableActive");
    }
    const elemnt = document.getElementById(index);
    elemnt.classList.add("tableActive");

    const response = await paymentlinkservice.getPaymentDetailsById({ id: id });
    setViewData(response);
    setToggleView(true);
  };

  const handleLinkGenerate = (camp) => {
    if (camp.paymentLimit === "0") {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Payment Limit Exceeded",
        showConfirmButton: false,
        timer: 2000,
      });
      return;
    }

    history.push({
      pathname: `/admin/${camp.isOfficeExpense ? "expense" : "payment"}/link`,
      state: {
        id: camp.id,
        name: camp.name,
        platform: camp.platform,
        paymentLimit: camp.paymentLimit,
        isOfficeExpense: camp.isOfficeExpense,
      },
    });
  };

  const CategoryFormatting = (categories) => {
    let categoryString = "";
    categories.map((c) => (categoryString += c.value + ", "));
    return categoryString.substring(0, categoryString.length - 2);
  };

  const ShowLabel = (status) => {
    let name = "";
    switch (status) {
      case "P":
        name = "Pending";
        break;
      case "A":
        name = "Applied";
        break;
      case "W":
        name = "Waiting";
        break;
      case "PP":
        name = "Partial Pending";
        break;
      case "S":
        name = "Success";
        break;
      case "F":
        name = "Failed";
        break;
      case "R":
        name = "Rejected";
        break;
      case "isDisable":
        name = "Club Links";
        break;
      default:
        break;
    }
    return name;
  };

  const handleCopyClick = (pathName, isCompany) => {
    const link = `${window.location.protocol}//${window.location.host}/${isCompany ? "company" : "influencer"
      }/payment/${pathName}`;
    navigator.clipboard.writeText(link);
  };

  async function handleDecline(id, payableAmount, rowId) {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-danger me-2",
        cancelButton: "btn btn-success me-2",
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          swalWithBootstrapButtons.fire(
            "Deleted!",
            "Your file has been deleted.",
            "success"
          );

          const elm = document.getElementById(rowId);
          elm.style.display = "none";

          await paymentlinkservice.Declined({
            id: id,
            payableAmount: payableAmount,
          });
        }
      });
  }

  return (
    <>
      <div
        className="col-xl-12 col-lg-12 col-md-12 col-sm-12"
        style={{ marginBottom: "65px" }}
      >
        <nav class="navbar navbar-light ">
          <div>
            <a class="navbar-brand">
              <FontAwesomeIcon
                icon={faArrowLeft}
                size={"sm"}
                onClick={() => history.goBack()}
                style={{ cursor: "pointer" }}
              />
            </a>
            <small className="fs-5">{campaignDetails.name}</small>
          </div>
          <button
            className="btn btn-sm btn-danger"
            onClick={() =>
              (window.location.href = `/admin/payment/campaign/report/${campaignDetails.id}`)
            }
          >
            Report
          </button>
        </nav>
        <div className="d-flex">
          <div
            className="card p-3 me-2"
            style={{ ...borderRadiusStyle, width: "50%" }}
          >
            <div className="card-body">
              <div className="d-flex justify-content-between">
                <div className="d-flex">
                  <FontAwesomeIcon
                    icon={faListCheck}
                    className="p-3 rounded-pill"
                    style={backgroundColorStyle}
                  />
                  &nbsp;&nbsp;
                  <small className="align-self-center mt-1">
                    {campaignDetails.name}
                    <br />
                    <p style={{ ...customStyle, color: "#3C44BC" }}>
                      {campaignDetails.category &&
                        campaignDetails.category.length > 0
                        ? CategoryFormatting(campaignDetails.category)
                        : "NA"}
                    </p>
                  </small>
                </div>
                <div>
                  <IdToDate id={campaignDetails.id} />
                  &nbsp;
                  <FontAwesomeIcon
                    icon={faCirclePlus}
                    style={{
                      ...customStyle,
                      backgroundColor: "#3C44BC",
                      color: "#fff",
                    }}
                    className="btn rounded-pill"
                    onClick={() => handleLinkGenerate(campaignDetails)}
                  />
                </div>
              </div>
              <p
                className="text-muted mt-2 mb-4"
                style={{ ...customStyle, marginLeft: "5%" }}
              >
                {campaignDetails.deliverables}
              </p>
              <div className="d-flex justify-content-between">
                <small
                  className="text-muted align-self-end"
                  style={customStyle}
                >
                  <FontAwesomeIcon
                    icon={faCreativeCommonsBy}
                    style={{ ...customStyle, color: "#3C44BC" }}
                  />
                  &nbsp; {campaignDetails.createdby}
                </small>
                <div>
                  <div className="d-inline-block me-3">
                    <div className="h6 mb-1 text-center" style={customStyle}>
                      {campaignDetails.paymentBudget}{" "}
                      <FontAwesomeIcon
                        icon={faRupee}
                        style={{ ...customStyle, color: "#3C44BC" }}
                      />
                    </div>
                    <small className="text-muted" style={customStyle}>
                      Budget{" "}
                    </small>
                  </div>
                  <div className="d-inline-block me-3">
                    <div className="h6 mb-1 text-center" style={customStyle}>
                      {campaignDetails.paymentLimit}{" "}
                      <FontAwesomeIcon
                        icon={faRupee}
                        style={{ ...customStyle, color: "#3C44BC" }}
                      />
                    </div>
                    <small className="text-danger" style={customStyle}>
                      Limit{" "}
                    </small>
                  </div>
                  <div className="d-inline-block me-3">
                    <div className="h6 mb-1 text-center" style={customStyle}>
                      {campaignDetails.platform
                        ? campaignDetails.platform.map((p) => p.name + " ")
                        : "NA"}
                    </div>
                    <small className="text-muted" style={customStyle}>
                      Platform
                    </small>
                  </div>
                  <div className="d-inline-block">
                    <div className="h6 mb-1 text-center" style={customStyle}>
                      {campaignDetails.brandName}{" "}
                      <FontAwesomeIcon
                        icon={faUsers}
                        style={{ ...customStyle, color: "#3C44BC" }}
                      />
                    </div>
                    <small className="text-muted" style={customStyle}>
                      Brand
                    </small>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex-fill d-grid gap-2">
            <div className="row">
              <div className="col pe-0">
                <div
                  className="card p-3"
                  style={{ ...borderRadiusStyle, cursor: "pointer" }}
                  onClick={() =>
                    (window.location.href = "/admin/payment/status")
                  }
                >
                  <div className="card-body">
                    <div className="d-flex align-items-center justify-content-between">
                      <div>
                        <h6 className="card-title" style={{ color: "#148A9C" }}>
                          {(() => {
                            const filteredCampaigns = campaignsInfo.filter(
                              (campaign) => campaign._id === "P"
                            );
                            if (filteredCampaigns.length > 0) {
                              return filteredCampaigns[0].details.length;
                            } else {
                              return 0;
                            }
                          })()}
                        </h6>
                        <p className="card-text  fw-bold" style={fontStyle}>
                          Pending
                        </p>
                      </div>
                      <div>
                        <p className="fs-5 ms-3" style={{ color: "#148A9C" }}>
                          <FontAwesomeIcon icon={faArrowsRotate} />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col pe-0" style={{ paddingLeft: "8px" }}>
                <div
                  className="card p-3"
                  style={{ ...borderRadiusStyle, cursor: "pointer" }}
                  onClick={() =>
                    (window.location.href = "/admin/payment/status")
                  }
                >
                  <div className="card-body">
                    <div className="d-flex align-items-center justify-content-between">
                      <div>
                        <h6 className="card-title" >
                          {(() => {
                            const filteredCampaigns = campaignsInfo.filter(
                              (campaign) => campaign._id === "A"
                            );
                            if (filteredCampaigns.length > 0) {
                              return filteredCampaigns[0].details.length;
                            } else {
                              return 0;
                            }
                          })()}
                        </h6>
                        <p className="card-text  fw-bold" style={fontStyle}>
                          Applied
                        </p>
                      </div>
                      <div>
                        <p className="fs-5 ms-3" style={{ color: "#0D6EFD" }}>
                          <FontAwesomeIcon icon={faHandsClapping} />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col" style={{ paddingLeft: "8px" }}>
                <div
                  className="card p-3"
                  style={{ ...borderRadiusStyle, cursor: "pointer" }}
                  onClick={() =>
                    (window.location.href = "/admin/payment/status")
                  }
                >
                  <div className="card-body">
                    <div className="d-flex align-items-center justify-content-between">
                      <div>
                        <h6 className="card-title" style={{ color: "#1AD2EE" }}>
                          {(() => {
                            const filteredCampaigns = campaignsInfo.filter(
                              (campaign) => campaign._id === "W"
                            );
                            if (filteredCampaigns.length > 0) {
                              return filteredCampaigns[0].details.length;
                            } else {
                              return 0;
                            }
                          })()}
                        </h6>
                        <p className="card-text  fw-bold" style={fontStyle}>
                          Waiting
                        </p>
                      </div>
                      <div>
                        <p className="fs-5 ms-3" style={{ color: "#1AD2EE" }}>
                          <FontAwesomeIcon
                            icon={faHouseFloodWaterCircleArrowRight}
                          />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col pe-0">
                <div
                  className="card p-3"
                  style={{ ...borderRadiusStyle, cursor: "pointer" }}
                  onClick={() =>
                    (window.location.href = "/admin/payment/status")
                  }
                >
                  <div className="card-body">
                    <div className="d-flex align-items-center justify-content-between">
                      <div>
                        <h6 className="card-title" style={{ color: "#35B669" }}>
                          {(() => {
                            const filteredCampaigns = campaignsInfo.filter(
                              (campaign) => campaign._id === "S"
                            );
                            if (filteredCampaigns.length > 0) {
                              return filteredCampaigns[0].details.length;
                            } else {
                              return 0;
                            }
                          })()}
                        </h6>
                        <p className="card-text  fw-bold" style={fontStyle}>
                          Success
                        </p>
                      </div>
                      <div>
                        <p className="fs-5 ms-3" style={{ color: "#35B669" }}>
                          <FontAwesomeIcon icon={faCheckDouble} />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col pe-0" style={{ paddingLeft: "8px" }}>
                <div
                  className="card p-3"
                  style={{ ...borderRadiusStyle, cursor: "pointer" }}
                  onClick={() =>
                    (window.location.href = "/admin/payment/status")
                  }
                >
                  <div className="card-body">
                    <div className="d-flex align-items-center justify-content-between">
                      <div>
                        <h6 className="card-title" style={{ color: "#4650DD" }}>
                          {(() => {
                            const filteredCampaigns = campaignsInfo.filter(
                              (campaign) => campaign._id === "F"
                            );
                            if (filteredCampaigns.length > 0) {
                              return filteredCampaigns[0].details.length;
                            } else {
                              return 0;
                            }
                          })()}
                        </h6>
                        <p className="card-text  fw-bold" style={fontStyle}>
                          Failed
                        </p>
                      </div>
                      <div>
                        <p className="fs-5 ms-3" style={{ color: "#4650DD" }}>
                          <FontAwesomeIcon icon={faFileCircleExclamation} />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col" style={{ paddingLeft: "8px" }}>
                <div
                  className="card p-3"
                  style={{ ...borderRadiusStyle, cursor: "pointer" }}
                  onClick={() =>
                    (window.location.href = "/admin/payment/status")
                  }
                >
                  <div className="card-body">
                    <div className="d-flex align-items-center justify-content-between">
                      <div>
                        <h6 className="card-title" style={{ color: "#DC3545" }}>
                          {(() => {
                            const filteredCampaigns = campaignsInfo.filter(
                              (campaign) => campaign._id === "R"
                            );
                            if (filteredCampaigns.length > 0) {
                              return filteredCampaigns[0].details.length;
                            } else {
                              return 0;
                            }
                          })()}
                        </h6>
                        <p className="card-text  fw-bold" style={fontStyle}>
                          Rejected
                        </p>
                      </div>
                      <div>
                        <p className="fs-5 ms-3" style={{ color: "#DC3545" }}>
                          <FontAwesomeIcon icon={faFileExcel} />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        {loading ? (
          <center>
            <Spinner animation="border" />
          </center>
        ) : campaignsInfo.length === 0 ? (
          <center>
            <b className="text-danger">No Payments</b>
          </center>
        ) : (
          <Tabs
            defaultActiveKey={1}
            id="uncontrolled-tab-example"
            className="mb-3"
            onSelect={handleClick}
            style={{ fontSize: "14px" }}
          >
            {campaignsInfo.length > 0 &&
              campaignsInfo.map((campaigns, index) => (
                <Tab eventKey={index + 1} title={ShowLabel(campaigns._id)}>
                  <ShowCampaignData
                    loading={loading}
                    viewData={viewData}
                    handleView={handleView}
                    toggleView={toggleView}
                    setToggleView={setToggleView}
                    data={campaigns.details}
                    status={campaigns._id}
                    customButtonStyle={customButtonStyle}
                    customStyle={{ fontSize: "11px", fontWeight: "500" }}
                    handleCopyClick={handleCopyClick}
                    handleDecline={handleDecline}
                  />
                </Tab>
              ))}
          </Tabs>
        )}
      </div>
    </>
  );
}
