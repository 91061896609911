import http from "./httpservices";
import jwtDecode from "jwt-decode";

const apiEndpoint = "auth/login/";
const tokenKey = process.env.REACT_APP_TOKEN_NAME;

export async function login(credentials) {
  const listOfDomains = ["idiotic.media"];

  if (listOfDomains.indexOf(credentials.email.split("@")[1]) < 0) {
    return false;
  }

  try {
    const { data: token } = await http.post(apiEndpoint, credentials);
    const user = jwtDecode(token);

    localStorage.setItem(tokenKey, token);
    localStorage.setItem("username", user.name);
    http.setToken(token);

    return true;
  } catch (error) {
    return false;
  }
}

export function getToken() {
  const token = localStorage.getItem(tokenKey);
  if (token) {
    return token;
  } else {
    return null;
  }
}

export function getLocalStorage(name) {
  const result = localStorage.getItem(name);
  if (result) {
    return result;
  } else {
    return null;
  }
}

export function getLocalStorageObj() {
  return {
    user_id: getLocalStorage("id"),
    token: getLocalStorage(tokenKey),
    profile_id: getLocalStorage("profile_id"),
  };
}

export function getApiPath() {
  return process.env.REACT_APP_API_URL;
}

export default {
  getToken,
  login,
  getApiPath,
  getLocalStorage,
  getLocalStorageObj,
};
