import { Tooltip } from "bootstrap";
import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import useRole from "../../../hooks/useRole";
import Brand from "../../common/brand/brand";
import Campaign from "../../common/campaign/campaign";
import Company from "../../common/company/company";
import "./css/slideMenu.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHandshake,
  faHome,
  faMoneyBill,
  faMoneyCheck,
} from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";

function SlideMenu(props) {
  const role = useRole();
  const customToggle = () => {
    var tooltipTriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="collapse"]')
    );
    tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new Tooltip(tooltipTriggerEl);
    });
  };

  useEffect(() => {
    customToggle();
  }, []);

  const CustomLine = ({ color }) => {
    return (
      <li style={{ color: color, backgroundColor: color, height: "1px" }}></li>
    );
  };

  return null

  return (
    <div
      className="col-xl-1 col-lg-1 col-md-1 col-sm-1 p-1"
      id="sidebar"
      style={{
        backgroundColor: "#0D1224",
        minHeight: "100vh",
        height: "auto",
        position: "sticky",
        top: "56px",
        alignSelf: "flex-start",
      }}
    >
      <ul className="list-unstyled ps-0">
        <li>
          {/* <button
            className="btn btn-toggle align-items-center rounded collapsed text-white"
            data-bs-toggle="collapse"
            data-bs-target="#dashboard-collapse"
            aria-expanded="true"
          >
            Dashboard
          </button> */}
          <div className="collapse show" id="dashboard-collapse">
            <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
              {role !== "Finance" && (
                <>
                  <li>
                    <NavLink
                      className="link-light rounded"
                      exact
                      to="/admin/payment/dashboard/home"
                    >
                      <FontAwesomeIcon
                        icon={faHome}
                        className="me-1 align-self-center"
                      />
                      Home
                    </NavLink>
                  </li>
                  <CustomLine />
                  <li>
                    <NavLink
                      className="link-light rounded"
                      exact
                      to="/admin/payment/dashboard/campaigns/pending"
                    >
                      <FontAwesomeIcon
                        icon={faHandshake}
                        className="me-1 align-self-center"
                      />
                      Camps
                    </NavLink>
                  </li>
                </>
              )}
            </ul>
          </div>
        </li>
        <CustomLine />
        <li>
          {/* <button
            className="btn btn-toggle align-items-center rounded collapsed text-white"
            data-bs-toggle="collapse"
            data-bs-target="#status-collapse"
            aria-expanded="true"
          >
            Status
          </button> */}
          <div className="collapse show" id="status-collapse">
            <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
              <li>
                <NavLink
                  className="link-light rounded"
                  exact
                  to="/admin/payment/status"
                >
                  <FontAwesomeIcon
                    icon={faMoneyCheck}
                    className="me-1 align-self-center"
                  />
                  Status
                </NavLink>
              </li>
            </ul>
          </div>
        </li>
        <CustomLine />
        <li>
          {/* <button
            className="btn btn-toggle align-items-center rounded collapsed text-white"
            data-bs-toggle="collapse"
            data-bs-target="#create-collapse"
            aria-expanded="true"
          >
            Create
          </button> */}
          <div className="collapse show" id="create-collapse">
            <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
              {role["role"] === "Admin" && (
                <>
                  <Brand />
                  <CustomLine />
                  <Campaign />
                  <CustomLine />
                </>
              )}
              <Company />
              <CustomLine />
            </ul>
          </div>
        </li>

        {role["role"] !== "Operation" &&
          role["role"] === "Admin" &&
          role["isSuperUser"] === true && (
            <li>
              {/* <button
                className="btn btn-toggle align-items-center rounded collapsed text-white"
                data-bs-toggle="collapse"
                data-bs-target="#finance-collapse"
                aria-expanded="true"
              >
                Finance
              </button> */}
              <div className="collapse show" id="finance-collapse">
                <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                  {((role["role"] === "Admin" &&
                    role["isSuperUser"] === true) ||
                    role["role"] === "Finance") && (
                    <li>
                      <NavLink
                        className="link-light rounded"
                        exact
                        to="/admin/payment/confirmation"
                      >
                        <FontAwesomeIcon
                          icon={faMoneyBill}
                          className="me-1 align-self-center"
                        />
                        Finance
                      </NavLink>
                    </li>
                  )}
                </ul>
              </div>
            </li>
          )}
      </ul>
    </div>
  );
}

export default SlideMenu;
