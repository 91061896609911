import http from "./httpservices";

export async function getPancardDetails(postdata) {
  const response = await http.post("pancard/getdetails/", postdata);
  return response;
}

export default {
  getPancardDetails,
};
