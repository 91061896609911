import React, { useState, useEffect } from "react";
import categoryservice from "../../services/categoryservice";
import CategoryList from "../common/categoryList/categoryList";
import influencerservice from "../../services/influencerservice";
import InfluencerList from "../common/influencerList/influencerList";
import { Spinner } from "react-bootstrap";
import cityservice from "../../services/cityservice";
import List from "../common/categoryList/list";
import "./css/dashboard.css";

export const Content = ({ inputText }) => {
  const [categoryContent, setCategoryContent] = useState([]);
  const [influencerContent, setInfluencerContent] = useState([]);
  const [loading, setLoading] = useState(true);
  const [foundInfluencers, setFoundInfluencers] = useState([]);
  const [foundCategories, setFoundCategories] = useState([]);

  const [foundCity, setFoundCity] = useState([]);

  const getInit = async () => {
    const responseInfluencerContent =
      await influencerservice.getLimitedInfluencers();

    setInfluencerContent(responseInfluencerContent.slice(1, 6));
    setFoundInfluencers(responseInfluencerContent.slice(1, 6));
    setLoading(false);

    const responceCity = await cityservice.getLimitedCities();
    setFoundCity(responceCity);
    const responseCategoryContent = await categoryservice.getCategories();
    setCategoryContent(responseCategoryContent);
    setFoundCategories(responseCategoryContent.slice(1, 6));
    setLoading(false);
  };

  useEffect(() => {
    getInit();
  }, []);

  useEffect(async () => {
    if (inputText !== "") {
      const influencerByUsername = await influencerservice.findByUserName(
        inputText
      );
      const cityByName = await cityservice.findByName(inputText);
      const categoryByName = await categoryservice.findByName(inputText);
      setFoundInfluencers(influencerByUsername);
      setFoundCategories(categoryByName);
      setFoundCity(cityByName);
    } else {
      setFoundInfluencers(influencerContent);
      setFoundCategories(categoryContent);
      setFoundCity(foundCity);
    }
  }, [inputText]);

  return (
    <>
      <div className="w-50 p-3 ms-5 rounded-bottom" id="searchedItem">
        {loading ? (
          <>
            <h5 className="text-center my-5">Loading... Cities</h5>
            <center>
              <Spinner animation="border" />
            </center>
          </>
        ) : (
          <>
            <div className="categories text-start">
              <p className="fw-bold">Cities</p>
              <ul className="list-group list-group-flush rounded-3">
                {foundCity && foundCity.length > 0 ? (
                  foundCity.map((i, index) => (
                    <List key={index} name={i.name} urlCategory={"city"} />
                  ))
                ) : (
                  <small>No results found!</small>
                )}
              </ul>
            </div>
          </>
        )}
        <br />
        {loading ? (
          <>
            <h5 className="text-center my-5">
              Loading... Categories &amp; Topics
            </h5>
            <center>
              <Spinner animation="border" />
            </center>
          </>
        ) : (
          <>
            <div className="categories text-start">
              <p className="fw-bold">CATEGORIES &amp; TOPICS</p>
              <ul className="list-group list-group-flush rounded-3">
                {foundCategories && foundCategories.length > 0 ? (
                  foundCategories.map((i, index) => (
                    <CategoryList key={index} name={i.name} />
                  ))
                ) : (
                  <small>No results found!</small>
                )}
              </ul>
            </div>
          </>
        )}
        {loading ? (
          <>
            <h5 className="text-center my-5">
              Loading... Influencers Profiles
            </h5>
            <center>
              <Spinner animation="border" />
            </center>
          </>
        ) : (
          <>
            <div className="influencer text-start">
              <p className="fw-bold mt-3">INFLUENCER PROFILES</p>
              <ul className="list-group list-group-flush rounded-3">
                {foundInfluencers && foundInfluencers.length > 0 ? (
                  foundInfluencers.map((i, index) => (
                    <InfluencerList key={index} name={i.username} />
                  ))
                ) : (
                  <small>No results found!</small>
                )}
              </ul>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Content;
