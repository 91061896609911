import React, { useState } from "react";

export default function categoryList({ name }) {

  const categoryUrl = `/categories/${name}`;

  return (
    <>
      <li className="list-group-item border-bottom-0">
        <a href={categoryUrl} className="text-decoration-none">
          {name}
        </a>
        {/* <span className="bg-secondary p-1 rounded-2 ms-2 text-white">
          In Category
        </span> */}
      </li>
    </>
  );
};
