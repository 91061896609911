import React, { useEffect, useState } from "react";
import Header from "../common/header/header";
import ManageRole from "../common/manage/manageRole";
import { Spinner, Tab, Tabs } from "react-bootstrap";
import userservice from "../../services/userservice";
import AdminSideMenu from "../common/adminSideMenu/adminSideMenu";
import "../role/css/role.css";
import Footer from "../common/footer/footer";

export default function Role() {
  const [loading, setLoading] = useState(true);
  const [title, setTitle] = useState("Manage Role");
  const [currentRole, setCurrentRole] = useState("");

  const handleClick = async (id, name) => {
    setTitle(name);
    setLoading(true);
    const resp = await userservice.getCurrentRole(id);
    setCurrentRole(resp);
    setLoading(false);
  };

  const Init = async () => {
    setLoading(false);
  };

  useEffect(() => {
    Init();
  }, []);

  return (
    <>
      <Header title={"Manage Role"} />
      <div className="container-fluid">
        <div className="row">
          <AdminSideMenu handleClick={handleClick} />
          <div className="pt-3 col-xl-10 ">
            <Tabs
              defaultActiveKey="all"
              transition={false}
              id="noanim-tab-example"
              className="mb-3"
              style={{ fontSize: "14px" }}
            >
              <Tab eventKey="all" title={title} className="text-capitalize">
                {loading ? (
                  <center>
                    <Spinner animation="border" />
                  </center>
                ) : !currentRole ? (
                  <div className=" w-25 h-25 mx-auto p-4 rounded-3">
                    <h5 className="text-danger text-center">
                      Please Select User
                    </h5>
                  </div>
                ) : (
                  <div className="row row-cols-1 row-cols-md-3 g-4">
                    <ManageRole
                      id={currentRole._id}
                      currentRole={
                        currentRole && currentRole.role && currentRole.role.name
                          ? currentRole.role.name
                          : "---"
                      }
                    />
                  </div>
                )}
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
