import React from "react";
import Select from "react-select";
import "../influencer/css/influencer.css";

const Gender = ({ handleChange, userData, customStyle, customStyles }) => {
  const options = [
    { value: "M", label: "Male" },
    { value: "F", label: "Female" },
    { value: "O", label: "Other" },
  ];

  return (
    <div className="col" style={customStyle}>
      <Select
        styles={customStyles}
        className="mb-2"
        onChange={handleChange}
        // defaultInputValue={"Gender"}
        placeholder="Gender"
        options={options}
        // isClearable={true}
        value={options.filter((o) => o.value === userData["gender"])[0]}
        isSearchable={false}
        closeMenuOnSelect={true}
      />

      {/* <div className="row">
        <div className="form-check mt-2 ms-1 col" style={customStyle}>
          <input
            className="form-check-input"
            type="radio"
            name="gender"
            id="flexRadioDefault1"
            value="M"
            checked={userData && userData.username && userData.gender && userData.gender === "M"}
            onChange={handleChange}
          />
          <label className="form-check-label" htmlFor="flexRadioDefault1">
            Male
          </label>
        </div>
        <div className="form-check mt-2 ms-1 col" style={customStyle}>
          <input
            className="form-check-input"
            type="radio"
            name="gender"
            id="flexRadioDefault2"
            value="F"
            
            checked={userData && userData.username && userData.gender && userData.gender === "F"}
            onChange={handleChange}
          />
          <label className="form-check-label" htmlFor="flexRadioDefault2">
            Female
          </label>
        </div>
        <div className="form-check mt-2 ms-1 col" style={customStyle}>
          <input
            className="form-check-input"
            type="radio"
            name="gender"
            id="flexRadioDefault3"
            value="O"
            checked={userData && userData.username && userData.gender && userData.gender === "O"}
            onChange={handleChange}
          />
          <label className="form-check-label" htmlFor="flexRadioDefault3">
            Other
          </label>
        </div>
      </div> */}
    </div>
  );
};

export default Gender;
