import { useQuery } from "react-query";
import paymentStatus from "../services/paymentstatuservice";

export const useFetchAllPayments = (filters) => {
  return useQuery({
    queryKey: ["fetchAllPayments", filters],
    queryFn: () => paymentStatus.FetchAllPaymentsStatus(filters),
    staleTime: 1 * 60 * 1000,
  });
};

export default { useFetchAllPayments };
