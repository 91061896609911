import React from "react";
import { Badge } from "react-bootstrap";
import Swal from "sweetalert2";
import paymentlinkservice from "../../../services/paymentlinkservice";

const GSTAmountFormatter = ({ cell, row }) => {
    const customFontStyle = { fontSize: "12px" };

    const handleGstPay = async (id) => {
        const { value: file } = await Swal.fire({
            title: "Select File to Upload",
            input: "file",
            inputAttributes: {
                accept: "pdf/*",
                required: true,
                "aria-label": "Upload GST File",
            },
        });

        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                Swal.fire({
                    title: "Your Uploaded File",
                    imageUrl: e.target.result,
                    imageAlt: "The Uploaded File",
                });
            };
            reader.readAsDataURL(file);
            const formData = new FormData();
            formData.append("file", file);
            formData.append("id", id);
            formData.append("campaign", row.campaign._id);
            if (row.isCompany) formData.append("name", row.company._id);
            else formData.append("name", row.influencer._id);
            formData.append("isCompany", row.isCompany);
            await paymentlinkservice.getUploadGst(formData);
        }
    };

    return cell ? (
        <div>
            <Badge
                bg={row.isGstPay ? "success" : "warning"}
                className={row.isGstPay ? "text-white" : "text-black"}
                style={customFontStyle}
            >
                {`\u20B9${cell}`}
            </Badge>
            <br />
            {/* &nbsp;&nbsp; */}
            {row.gstAmount &&
                row.status === "S" &&
                !row.isGstPay &&
                !row.isGstRequested ? (
                <Badge
                    type="button"
                    title="Pay GST"
                    className="btn btn-primary fw-bold p-1 mt-1"
                    onClick={() => handleGstPay(row._id)}
                    style={customFontStyle}
                >
                    Pay GST
                </Badge>
            ) : (
                ""
            )}
        </div>
    ) : (
        <span style={customFontStyle}>NA</span>
    );
};

export default GSTAmountFormatter;
