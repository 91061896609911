import {
  faCheck,
  faCircleCheck,
  faCircleXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import React, { useState } from "react";
import { Modal, Button, Spinner, Alert } from "react-bootstrap";

function BankPopup({
  paymentInfo,
  handlePaymentClick,
  togglePaymentOption,
  userData,
  customStyle,
  handleChange,
  handlePancardUpload,
  handleAddBankDetails,
  setVisible,
  visible,
  isPancardVerify,
  loadingPancardVerify,
}) {
  const btnClose = () => setVisible(false);
  const [showPanCardError, setShowPanCardError] = useState(false);

  const handlePancardLength = (params) => {
    setShowPanCardError(true);

    handleChange(params);
  };

  return (
    <>
      <Modal show={visible} onHide={btnClose}>
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "#023430" }}>
            Add Bank Details
          </Modal.Title>
        </Modal.Header>
        <form onSubmit={handleAddBankDetails}>
          <Modal.Body>
            <div className="col-xl-12">
              {/* <div className="form-check form-switch d-flex justify-content-center my-3">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="paymentOption"
                  onClick={handlePaymentClick}
                  style={{ cursor: "pointer", fontSize: "13px" }}
                />
                &nbsp;
                <label
                  className="form-check-label"
                  htmlFor="paymentOption"
                  style={{ cursor: "pointer", fontSize: "13px" }}
                >
                  Paytm
                </label>
              </div> */}
              {!togglePaymentOption ? (
                <>
                  <Alert variant="danger" className="text-center fs-6 py-1">
                    The <b>PANCARD</b> has been verified online, and the account name
                    has been updated accordingly.
                  </Alert>
                  <small>
                    <strong>Pan Card Number* </strong>
                  </small>
                  <div>
                    <div className="d-flex mb-3 gap-2 align-items-center">
                      <input
                        type="text"
                        className="form-control customRequired w-50 me-2"
                        id="user_pancard"
                        placeholder="Enter Pan Card Number"
                        name="pancard"
                        value={userData.pancard}
                        style={customStyle}
                        onChange={handlePancardLength}
                        maxLength="10"
                        pattern="[A-Z]{5}[0-9]{4}[A-Z]{1}"
                        title="Enter only '5' Uppercase Characters, '4' Numerical Letters and '1' Uppercase Character"
                        required
                      ></input>
                      {loadingPancardVerify && (
                        <small style={{ color: "red" }}>Loading...</small>
                      )}

                      {showPanCardError &&
                        (isPancardVerify ? (
                          <FontAwesomeIcon icon={faCircleCheck} color="green" />
                        ) : (
                          <FontAwesomeIcon icon={faCircleXmark} color="red" />
                        ))}
                    </div>
                    <input
                      type="file"
                      className="form-control w-50"
                      id="formGroupExampleInput"
                      placeholder="Upload Your Pan Card"
                      onChange={handlePancardUpload}
                      style={customStyle}
                      title="Choose only '.pdf' file to Upload"
                      accept={".pdf"}
                    ></input>
                  </div>
                  <hr />
                  <small>
                    <strong>{parseInt(paymentInfo.payableAmount) > 5000 ? "Pancard Name*" : "Pancard Name"}</strong>
                  </small>
                  <input
                    type="text"
                    className="form-control customRequired"
                    id="user_pancard_name"
                    placeholder="Name as per Pancard"
                    name="pancardName"
                    value={userData.pancardName}
                    style={customStyle}
                    // onChange={handleChange}
                    pattern="[a-zA-Z\s]*"
                    title="Enter only Alphabet Characters"
                    // required={!togglePaymentOption ? true : false}
                    required={parseInt(paymentInfo) > 5000}
                    disabled
                  // onPaste={onPaste}
                  ></input>
                  <hr />
                  <small>
                    <strong>Account Name*</strong>
                  </small>
                  <input
                    type="text"
                    className="form-control customRequired"
                    id="user_account_name"
                    placeholder="Enter Account Name"
                    name="accountName"
                    value={userData.accountName}
                    style={customStyle}
                    onChange={handleChange}
                    pattern="[a-zA-Z\s]*"
                    title="Enter only Alphabet Characters"
                    required
                  ></input>
                  <hr />
                  <small>
                    <strong>Account Number*</strong>
                  </small>
                  <input
                    type="text"
                    className="form-control customRequired"
                    id="user_account_number"
                    placeholder="Enter Account Number"
                    name="accountNo"
                    value={userData.accountNo}
                    style={customStyle}
                    onChange={handleChange}
                    maxLength="20"
                    pattern="[0-9]*"
                    title="Enter only Numerical Letters"
                    required
                  // required={!togglePaymentOption ? true : false}
                  // onPaste={onPaste}
                  ></input>
                  <hr />
                  <small>
                    <strong>IFSC Code*</strong>
                  </small>
                  <input
                    type="text"
                    className="form-control customRequired"
                    id="user_ifsc"
                    placeholder="Enter IFSC Code"
                    name="ifscCode"
                    value={userData.ifscCode}
                    style={customStyle}
                    onChange={handleChange}
                    maxLength="11"
                    pattern="[A-Z]{4}0[A-Z0-9]{6}"
                    title="Enter only '4' Uppercase Alphabet Characters, '0' Numerial Letter and '6' Uppercase Alphanumeric Letters"
                    required
                  // required={!togglePaymentOption ? true : false}
                  // onPaste={onPaste}
                  ></input>
                  <hr />
                </>
              ) : (
                <>
                  <small>
                    <strong>Pan Card Number*</strong>
                  </small>
                  <div className="d-flex justify-content-around">
                    <input
                      type="text"
                      className="form-control customRequired w-50 me-2"
                      id="user_pancard"
                      placeholder="Enter Pan Card Number"
                      name="pancard"
                      value={userData.pancard}
                      style={customStyle}
                      onChange={handleChange}
                      maxLength="10"
                      pattern="[A-Z]{5}[0-9]{4}[A-Z]{1}"
                      title="Enter only '5' Uppercase Characters, '4' Numerical Letters and '1' Uppercase Character"
                      required={togglePaymentOption ? true : false}
                    // onPaste={onPaste}
                    ></input>
                    <input
                      type="file"
                      className="form-control w-50"
                      id="formGroupExampleInput"
                      placeholder="Upload Your Pan Card"
                      onChange={handlePancardUpload}
                      style={customStyle}
                      title="Choose only '.pdf' file to Upload"
                      accept={".pdf"}
                    ></input>
                  </div>
                  <hr />
                  <small>
                    <strong>Paytm Number*</strong>
                  </small>
                  <input
                    type="text"
                    className="form-control"
                    id="user_paytmNumber"
                    placeholder="Enter Paytm Number"
                    name="paytmNumber"
                    value={userData.paytmNumber}
                    style={customStyle}
                    onChange={handleChange}
                    maxLength="10"
                    pattern="[0-9]{10}"
                    title="Enter only Numerical Letters"
                    required={togglePaymentOption ? true : false}
                  // onPaste={onPaste}
                  ></input>
                </>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={btnClose}>
              Close
            </Button>
            <button
              className="btn btn-success"
              type="submit"
              disabled={!isPancardVerify}
            >
              Add
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
}

export default BankPopup;
