export default function removeMediaFromUrl() {
  const authAllow = {
    Operation: [
      "dashboard",
      "influencer",
      "influencers",
      "filter",
      "allCampaign",
      "link",
      "pending",
      "applied",
      "waiting",
      "success",
      "failure",
    ],
    Finance: [
      "confirmation",
      "confirmation#",
    ],
    Admin: ["*"],
    Outreach: ["dashboard", "influencer", "influencers"],
  };

  return authAllow;
}
