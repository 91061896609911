import http from "./httpservices";

export async function getStates() {
  const { data } = await http.get("state/");
  return data;
}

export async function getStatesByName() {
  const { data } = await http.get("state/india");
  return data;
}

export async function getStatesById(countryId) {
  const { data } = await http.post("state/findbyId/", { countryId: countryId });
  return data;
}

export default {
  getStates,
  getStatesById,
  getStatesByName,
};
