import http from "./httpservices";

const endPoint = "emailscraping/";

export async function InstagramUserEmail(scrapingObject) {
  const { data } = await http.post(endPoint, scrapingObject);
  return data;
}

export async function getInstagramUserEmail() {
  const { data } = await http.get(endPoint);
  return data;
}

export async function deleteInstagramUserEmail(params) {
  const { data } = await http.post(`${endPoint}delete`, params);
  return data;
}

export async function PostInfluencerQueue(params) {
  const data = await http.post(`${endPoint}queue`, params);
  return data;
}

export async function InfluencerQueue(params) {
  const { data } = await http.get(`${endPoint}queue`, params);
  return data;
}

export async function InfluencerQueueSingle(params) {
  const { data } = await http.get(`${endPoint}queue/single`, params);
  return data;
}

export async function InfluencerQueueDelete(params) {
  const { data } = await http.post(`${endPoint}queue/delete`, params);
  return data;
}

export default {
  InfluencerQueue,
  PostInfluencerQueue,
  InstagramUserEmail,
  getInstagramUserEmail,
  deleteInstagramUserEmail,
  InfluencerQueueSingle,
  InfluencerQueueDelete,
};
