import http from "./httpservices";

export async function getChannelDetails(url) {
  const { data } = await http.post("yt/getChannelDetails/", url);
  return data;
}

export async function getYTChannelInfo(params) {
  const { data } = await http.post("yt/generate/payment/link", params);
  return data;
}

export default {
  getChannelDetails,
  YTChannelInfo: getYTChannelInfo,
};
