import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAdd,
  faMinusCircle,
  faPlusCircle,
} from "@fortawesome/free-solid-svg-icons";
import companyservice from "../../../services/companyservice";
import VendorCategory from "../select/vendorCategory";
import vendorcategoryservice from "../../../services/vendorcategoryservice";
import vendorservice from "../../../services/vendorservice";
import platformservice from "../../../services/platformservice";

const customStyles = {
  control: (base, state) => ({
    ...base,
    background: localStorage.getItem('theme') === 'dark' ? '#212529' : 'light',
    color: state.isActive ? 'white' : '#212529',
    borderColor: localStorage.getItem('theme') === 'dark' ? '#495057' : 'light',
  }),
  option: (base, state) => ({
    ...base,
    backgroundColor: state.isSelected || localStorage.getItem('theme') === 'dark' ? '#212529' : 'light', // Option background color
    color: state.isSelected || localStorage.getItem('theme') === 'dark' ? 'light' : '#212529', // Option text color
  }),
};

function Company() {
  const btnClose = () => setVisible(false);
  const btnShow = () => setVisible(true);

  const customStyle = { fontSize: "13px" };
  const [visible, setVisible] = useState(false);
  let [params, setParams] = useState({
    name: "",
    category: "",
    number: "",
  });
  const [vendorCategory, setVendorCategory] = useState([]);
  const [platforms, setPlatforms] = useState([]);
  const [newCategory, setNewCategory] = useState("");
  const [socialComponent, setSocialComponent] = useState([
    { platform: "", url: "" },
  ]);

  const handleRemoveComponent = (index) => {
    const list = [...socialComponent];
    list.splice(index, 1);
    setSocialComponent(list);
  };

  const handleComponentChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...socialComponent];
    list[index][name] = value;
    setSocialComponent(list);
  };

  const addSocialComponent = () => {
    setSocialComponent([...socialComponent, { platform: "", url: "" }]);
  };

  const handleNewCategoryChange = (value) => {
    setNewCategory(value);
  };

  const handleCategoryChange = async (e) => {
    if (e.__isNew__) {
      let categoryId = await vendorcategoryservice.addVendorCategory({
        name: e.label,
      });
      setParams((prevState) => ({
        ...prevState,
        ["category"]: categoryId,
      }));
    } else {
      setParams((prevState) => ({
        ...prevState,
        ["category"]: e.value,
      }));
    }
  };

  const handleChange = (e) => {
    let { name, value } = e.target;

    setParams((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let data;
      if (socialComponent[0].platform !== "") {
        data = {
          name: params.name,
          category: params.category,
          number: params.number,
          socialMedia: socialComponent,
        };
      } else {
        data = {
          name: params.name,
          category: params.category,
          number: params.number,
        };
      }
      await companyservice.addCompany(data);
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Vendor Added Successfully!",
        showConfirmButton: false,
        timer: 2500,
      });
      window.location.reload();
    } catch (e) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Vendor Already Exist!",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  const Init = async () => {
    const response = await vendorcategoryservice.getVendorCategories();
    setVendorCategory(response);
    const platforms = await platformservice.getAllPlatform();
    setPlatforms(platforms);
  };

  useEffect(() => {
    Init();
  }, []);

  return (
    <>
      <li className="nav-item" style={{ cursor: "pointer" }}>
        <a
          className="nav-link link-light rounded"
          aria-current="page"
          onClick={btnShow}
          id={"companyModalOpener"}
        >
          <FontAwesomeIcon icon={faAdd} className="me-1 align-self-center" />
          Vendor
        </a>
      </li>
      <Modal show={visible} onHide={btnClose}>
        <Modal.Header closeButton>
          <Modal.Title >Add Vendor</Modal.Title>
        </Modal.Header>
        <form onSubmit={handleSubmit}>
          <Modal.Body>
            <input
              type="text"
              className="form-control"
              placeholder="Vendor*"
              value={params.name}
              name="name"
              onChange={handleChange}
              style={customStyle}
              required
            />
          </Modal.Body>
          <Modal.Body>
            <VendorCategory
              handleChange={handleCategoryChange}
              userData={params}
              category={vendorCategory}
              customStyle={customStyle}
              customStyles={customStyles}
              isMulti={false}
              handleNewCategoryChange={handleNewCategoryChange}
            />
          </Modal.Body>
          <Modal.Body>
            <input
              type="text"
              className="form-control"
              placeholder="Vendor Number"
              value={params.number}
              name="number"
              onChange={handleChange}
              minLength={10}
              maxLength={10}
              pattern="[0-9]*"
              title="Enter only Numerical Letters"
              style={customStyle}
            />
          </Modal.Body>
          {socialComponent &&
            socialComponent.map((social, index) => (
              <Modal.Body className="d-flex">
                <select
                  className="form-select border me-2"
                  aria-label="Default select example"
                  style={{ ...customStyle, width: "35%" }}
                  name="platform"
                  onChange={(e) => handleComponentChange(e, index)}
                >
                  <option selected value="">
                    Choose Platform
                  </option>
                  {platforms.map((platform) => (
                    <option value={platform._id} key={platform._id} style={{ color: localStorage.getItem('theme') === 'dark' ? 'lightgray' : "#212529", backgroundColor: localStorage.getItem('theme') === 'dark' ? '#212529' : "lightgray" }}>
                      {platform.name}
                    </option>
                  ))}
                </select>
                <input
                  type="url"
                  className="form-control"
                  placeholder="Social Media URL"
                  name="url"
                  value={socialComponent.url}
                  onChange={(e) => handleComponentChange(e, index)}
                  style={{ ...customStyle, width: "65%" }}
                />
                {socialComponent.length > 1 && (
                  <div className="buttonWidth">
                    <FontAwesomeIcon
                      className="fs-6 ms-2 mt-2 text-danger"
                      style={{ cursor: "pointer" }}
                      icon={faMinusCircle}
                      onClick={() => handleRemoveComponent(index)}
                      title="Remove"
                    />
                  </div>
                )}
              </Modal.Body>
            ))}
          <Modal.Body>
            <div className="d-flex justify-content-between">
              <div></div>
              <FontAwesomeIcon
                icon={faPlusCircle}
                className="fs-6"
                style={{ cursor: "pointer" }}
                onClick={addSocialComponent}
                title="Add"
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={btnClose}>
              Close
            </Button>
            <button className="btn btn-success" type="submit">
              Add
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
}

export default Company;
