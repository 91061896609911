import { Link } from "react-router-dom";

function influencerFormatter(cell, row) {
  return (
    <>
      <div>
        {cell ? (
          <>
            <div className="d-flex">
              <div>
                {row["profile_pic_url_hd"] && (
                  <>
                    <Link
                      to={{
                        pathname: `https://www.instagram.com/${cell}`,
                      }}
                      target={"_blank"}
                      title={cell}
                      style={{ textDecoration: "none" }}
                    >
                      <img
                        src={
                          process.env.REACT_APP_BASE_URL +
                          row["profile_pic_url_hd"].replace("/media", "")
                        }
                        width={40}
                        height={40}
                        className="rounded-circle border"
                      />
                    </Link>
                  </>
                )}
              </div>
              <div className="ms-2">
                {row.full_name}
                <br />
                <Link
                  to={{
                    pathname: `https://www.instagram.com/${cell}`,
                  }}
                  target={"_blank"}
                  title={cell}
                  style={{ textDecoration: "none" }}
                >
                  {`${cell}`}
                </Link>
              </div>
            </div>
          </>
        ) : row.isCompany ? (
          row.companyName
        ) : (
          "NA"
        )}
      </div>
    </>
  );
}
export default influencerFormatter;
