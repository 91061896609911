import React, { useState, useEffect } from "react";
import jwtDecode from "jwt-decode";
import Header from "../../../../components/common/header/header";
import SildeMenu from "../../../../components/payment/common/slideMenu";
import campaignservice from "../../../../services/campaignservice";
import CampaignPending from "../../../../admin/payments/manage/campaigns/campaignPending";
import Footer from "../../../../components/common/footer/footer";

function PaymentCampaignPending() {
  const id = jwtDecode(
    localStorage.getItem(process.env.REACT_APP_TOKEN_NAME)
  )._id;
  const customStyle = { fontSize: "13px" };
  const borderRadiusStyle = { borderRadius: "20px" };
  const CustomRowStyle = {
    fontSize: "13px",
    fontWeight: "500",
  };
  const [filter, setFilter] = useState("All");
  const [loading, setLoading] = useState(true);
  const [campaigns, setCampaigns] = useState([]);
  const [months, setMonths] = useState([
    {
      name: "All",
      value: "00",
    },
    {
      name: "January",
      value: "01",
    },
    {
      name: "February",
      value: "02",
    },
    {
      name: "March",
      value: "03",
    },
    {
      name: "April",
      value: "04",
    },
    {
      name: "May",
      value: "05",
    },
    {
      name: "June",
      value: "06",
    },
    {
      name: "July",
      value: "07",
    },
    {
      name: "August",
      value: "08",
    },
    {
      name: "September",
      value: "09",
    },
    {
      name: "October",
      value: "10",
    },
    {
      name: "November",
      value: "11",
    },
    {
      name: "December",
      value: "12",
    },
  ]);

  const handleMonthFilter = async (name, value) => {
    if (value === "00") window.location.reload();
    const filteredData = await campaignservice.getCampaignsbyMonth({
      month: value,
      id: id,
    });
    setFilter(name);
    setCampaigns(filteredData);
  };

  const handleChange = async (value) => {
    setLoading(true);
    const response = await campaignservice.getSearchCampaign({
      name: value,
      id: id,
    });
    setCampaigns(response);
    setLoading(false);
  };

  const getInit = async () => {
    setLoading(true);
    const id = jwtDecode(
      localStorage.getItem(process.env.REACT_APP_TOKEN_NAME)
    )._id;
    const campaigns = await campaignservice.getCampaignsByStatus({ id: id });
    setCampaigns(campaigns);
    setLoading(false);
  };

  useEffect(() => {
    getInit();
  }, []);

  return (
    <>
      <Header />
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-mg-12 col-sm-12  py-3 px-4">
            <CampaignPending
              loading={loading}
              handleChange={handleChange}
              campaigns={campaigns}
              months={months}
              setCampaigns={setCampaigns}
              handleMonthFilter={handleMonthFilter}
              customStyle={customStyle}
              CustomRowStyle={CustomRowStyle}
              borderRadiusStyle={borderRadiusStyle}
              filter={filter}
            />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default PaymentCampaignPending;
