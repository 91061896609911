import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import Dropdown from "react-bootstrap/Dropdown";

function Filters({ months, filter, handleMonthFilter }) {
  return (
    <Dropdown>
      <Dropdown.Toggle variant="warning" id="dropdown-basic">
        <b>{filter}</b>{" "}
        <FontAwesomeIcon
          icon={faFilter}
          title="Influencer Filter"
        />
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {months.map((m) => (
          <Dropdown.Item onClick={() => handleMonthFilter(m.name, m.value)}>
            {m.name}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default Filters;
