import { useEffect } from "react";
import emailFormatter from "./formatter/emailFormatter";
import influencerFormatter from "./formatter/influencerFormatter";
import numberFormatter from "./formatter/numberFormatter";
import categoryFormatter from "./formatter/categoryFormatter";

import filterInfluencerservice from "../../../services/filterInfluencerservice";
import locationFormatter from "./formatter/locationFormatter";

function InfluencersColumns({
  handleSetColumns,
  setProfiles,
  setFilterLoading,
  setCount,
}) {
  const baseStyle = {
    style: (cell, row, rowIndex, colIndex) => {
      return {
        fontSize: "12px",
      };
    },
  };

  const headerStyle = {
    headerStyle: (column, colIndex) => {
      return {
        backgroundColor: "#f0f2f5",
        fontSize: "12px",
        fontWeight: "normal",
        verticalAlign: "middle",
        padding: "20px 5px",
      };
    },
  };

  const filterByFullName = (filterVal, data) => {
    if (filterVal) {
      const response = filterInfluencerservice.searchInfluencer(filterVal);
      const p = Promise.resolve(response);
      p.then((v) => {
        setProfiles(v["data"]);
        return v["data"];
      });
    } else {
      const response = filterInfluencerservice.fetchInfluencers();
      const p = Promise.resolve(response);
      p.then((v) => {
        setProfiles(v["data"]);
        setCount(v["count"]);
        return v["data"];
      });
    }
  };

  const filterByState = (filterVal, data) => {
    if (filterVal) {
      const response =
        filterInfluencerservice.searchInfluencerByState(filterVal);
      const p = Promise.resolve(response);
      p.then((v) => {
        setProfiles(v["data"]);
        return v["data"];
      });
    } else {
      const response = filterInfluencerservice.fetchInfluencers();
      const p = Promise.resolve(response);
      p.then((v) => {
        setProfiles(v["data"]);
        setCount(v["count"]);
        return v["data"];
      });
    }
  };

  const filterByCategory = (filterVal, data) => {
    if (filterVal) {
      const response =
        filterInfluencerservice.searchInfluencerByCategory(filterVal);
      const p = Promise.resolve(response);
      p.then((v) => {
        setProfiles(v["data"]);
        return v["data"];
      });
    } else {
      const response = filterInfluencerservice.fetchInfluencers();
      const p = Promise.resolve(response);
      p.then((v) => {
        setProfiles(v["data"]);
        setCount(v["count"]);
        return v["data"];
      });
    }
  };

  let Columns = [
    // {
    //   dataField: "full_name",
    //   text: "Name",
    //   sort: true,
    //   ...baseStyle,
    //   className: "h6",
    //   filter: textFilter({
    //     onFilter: filterByFullName,
    //   }),
    // },
    {
      dataField: "username",
      text: "Influencer",
      sort: true,
      formatter: influencerFormatter,
      ...baseStyle,
      ...headerStyle,

      // filter: textFilter({
      //   onFilter: filterByFullName,
      // }),
    },
    {
      dataField: "number",
      text: "Number",
      hidden: true,
      // formatter: numberFormatter,
      sort: true,
      ...baseStyle,
      ...headerStyle,
      // filter: textFilter(),
    },
    {
      dataField: "gender",
      text: "Gender",
      formatter: numberFormatter,
      sort: true,
      ...baseStyle,
      ...headerStyle,
      // filter: textFilter(),
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
      formatter: emailFormatter,
      ...baseStyle,
      ...headerStyle,
      // filter: textFilter(),
    },
    {
      dataField: "instaFollowers",
      text: "Followers",
      sort: true,
      // headerFormatter: customHeaderFormatter,
      ...baseStyle,
      ...headerStyle,
    },
    {
      dataField: "biography",
      text: "Bio",
      sort: true,
      ...baseStyle,
      ...headerStyle,
      // filter: textFilter({
      //   onFilter: filterByCategory,
      // }),
    },
    {
      dataField: "state",
      text: "Location",
      sort: true,
      ...baseStyle,
      ...headerStyle,
      formatter: locationFormatter
      // filter: textFilter({
      //   onFilter: filterByState,
      // }),
    },
    {
      dataField: "categoryName",
      text: "Category",
      formatter: categoryFormatter,
      sort: true,
      csvExport: false,
      ...baseStyle,
      ...headerStyle,
      // filter: textFilter({
      //   onFilter: filterByCategory,
      // }),
    },
  ];
  useEffect(() => {
    handleSetColumns(Columns);
  }, []);
  return null;
}

export default InfluencersColumns;
