import React, { useState } from "react";
import { Badge, Spinner } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {
  CSVExport,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory from "react-bootstrap-table2-filter";
import InfluencersColumns from "./InfluencersColumns.jsx";
import { useEffect } from "react";
import jwtDecode from "jwt-decode";
import filterInfluencerservice from "../../../services/filterInfluencerservice.js";
import influencerservice from "../../../services/influencerservice.js";
import { text } from "@fortawesome/fontawesome-svg-core";

function InfluencerDataTable({
  loading,
  profiles,
  setProfiles,
  count,
  setCount,
  setLoading,
}) {
  // const { SearchBar, ClearSearchButton } = Search;
  const { ExportCSVButton } = CSVExport;
  const [rowCount, setRowCount] = useState(0);
  const [Columns, setColumns] = useState({});
  const [searchBio, setSearchBio] = useState("");
  const [gender, setGender] = useState("");
  const [location, setLocation] = useState("");
  const [followers, setFollowers] = useState({
    minRange: "",
    maxRange: "",
    influencerSize: "",
  });
  const [filterLoading, setFilterLoading] = useState(false);
  const defaultSorted = [
    {
      dataField: "_id",
      order: "desc",
    },
  ];

  const superUser = jwtDecode(
    localStorage.getItem(process.env.REACT_APP_TOKEN_NAME)
  ).isSuperUser;

  const handleFilter = (filterValue, column) => {
    const filteredResult = profiles.filter((item) => {
      const fieldValue = item[column.dataField].toString();
      return fieldValue.includes(filterValue);
    });
    setProfiles(filteredResult);
  };

  const handleSetColumns = (columns) => {
    setColumns(columns);
  };

  const handleOnDataSizeChange = ({ dataSize }) => {
    setRowCount(dataSize);
  };

  useEffect(() => {
    setRowCount(count);
  }, [count]);

  const handleSearchInBio = async () => {
    setLoading(true);
    const filterByBio = await filterInfluencerservice.filterBio({
      search: searchBio,
    });

    setRowCount(filterByBio.count);
    setProfiles(filterByBio.data);
    setLoading(false);
  };

  const handleFilters = async (gender, filter) => {
    setGender(gender);
    setLoading(true);

    if (gender !== "All" || location !== "" || followers !== "") {
      const responseInfluencer = await filterInfluencerservice.applyFilters({
        gender: gender.charAt(0),
        location: location.charAt(0).toUpperCase() + location.slice(1),
        followers: followers,
      });
      setProfiles(responseInfluencer["data"]);
      setCount(responseInfluencer["count"]);
    } else {
      const responseInfluencer =
        await filterInfluencerservice.fetchInfluencers();
      setProfiles(responseInfluencer["data"]);
      setCount(responseInfluencer["count"]);
    }
    setLoading(false);
  };

  const handleChange = (e) => {
    let { name, value } = e.target;

    setFollowers((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleEnterKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearchInBio();
    }
  };

  const exportWithoutNumber = (csvProps, data) => {
    const exportData = data.map((item) => ({
      username: item.username,
      gender: item.gender,
      email: item.email,
      instaFollowers: item.instaFollowers,
      biography: item.biography,
      state: item.state,
    }));
    csvProps.onExport(exportData, "ExportWithoutNumber.csv");
  };

  const exportAllColumns = (csvProps, data) => {
    const exportData = data.map((item) => ({
      username: item.username,
      number: item.number,
      gender: item.gender,
      email: item.email,
      instaFollowers: item.instaFollowers,
      biography: item.biography,
      state: item.state,
    }));
    csvProps.onExport(exportData, "All_Columns.csv");
  };

  return (
    <>
      <div className="col-xl-5 col-lg-5 col-md-5 col-sm-5 p-3">
        <div className="input-group mb-3">
          <input
            type="text"
            className="form-control form-control-lg w-25"
            placeholder="search in bios"
            onChange={(e) => setSearchBio(e.target.value)}
            onKeyPress={handleEnterKeyPress}
            value={searchBio}
          />
          <button
            className="input-group-text"
            id="basic-addon2"
            onClick={handleSearchInBio}
          >
            search
          </button>
        </div>
      </div>
      <div className="d-flex">
        <div className="dropdown me-3">
          <button
            className="btn btn-dark dropdown-toggle rounded-pill"
            type="button"
            id="gender"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            style={{ fontSize: "11px" }}
          >
            {gender === "" ? "Gender" : gender}
          </button>
          <ul
            className="dropdown-menu"
            aria-labelledby="gender"
            style={{ fontSize: "11px" }}
          >
            {/* <li>
              <a
                className="dropdown-item"
                href="#"
                onClick={() => handleFilters("All", "gender")}
              >
                All
              </a>
            </li> */}
            <li>
              <a
                className="dropdown-item"
                href="#"
                onClick={() => handleFilters("Male", "gender")}
              >
                Male
              </a>
            </li>
            <li>
              <a
                className="dropdown-item"
                href="#"
                onClick={() => handleFilters("Female", "gender")}
              >
                Female
              </a>
            </li>
          </ul>
        </div>
        <div className="dropdown me-3">
          <button
            className="btn btn-dark dropdown-toggle rounded-pill"
            type="button"
            id="location"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            style={{ fontSize: "11px" }}
          >
            {location === "" ? "Location" : location}
          </button>
          <div
            className="dropdown-menu"
            aria-labelledby="location"
            style={{ width: "350%", fontSize: "11px" }}
          >
            <div className="px-3 py-1">
              <p className=" fw-normal">SELECT COUNTRY</p>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="country"
                  id="countryName"
                  checked
                />
                <label className="form-check-label" htmlFor="countryName">
                  India
                </label>
              </div>
            </div>
            <div className="px-3 py-1">
              <p className=" fw-normal">STATE / CITY</p>
              <div className="input-group mb-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Location"
                  aria-label="Location"
                  aria-describedby="button-addon2"
                  name="location"
                  value={location}
                  onChange={(e) => setLocation(e.target.value)}
                  style={{ fontSize: "11px" }}
                />
                <button
                  className="btn btn-dark"
                  type="button"
                  id="button-addon2"
                  onClick={() => handleFilters(gender, "location")}
                  style={{ fontSize: "11px" }}
                >
                  Apply
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="dropdown">
          {/* <form onSubmit={handleFollowersFilter}> */}
          <button
            className="btn btn-dark dropdown-toggle rounded-pill"
            type="button"
            id="followers"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            style={{ fontSize: "11px" }}
          >
            Followers
          </button>
          <div
            className="dropdown-menu"
            aria-labelledby="followers"
            style={{ width: "350%", fontSize: "11px" }}
          >
            <div className="px-3 py-1">
              <p className=" fw-normal">SELECT FOLLOWERS</p>
              <div className="d-flex justify-content-between input-group-sm">
                <input
                  type="text"
                  className="form-control me-2"
                  id="followersMinRange"
                  placeholder="Min"
                  name="minRange"
                  value={followers.minRange}
                  onChange={handleChange}
                  pattern="[0-9]*"
                  title="Enter only Numerical Letters"
                  style={{ fontSize: "11px" }}
                ></input>
                <input
                  type="text"
                  className="form-control"
                  id="followersMaxRange"
                  placeholder="Max"
                  name="maxRange"
                  value={followers.maxRange}
                  onChange={handleChange}
                  pattern="[0-9]*"
                  title="Enter only Numerical Letters"
                  style={{ fontSize: "11px" }}
                ></input>
              </div>
            </div>
            <div className="px-3 py-1 mt-3">
              <p className=" fw-normal">SELECT BY INFLUENCER SIZE</p>
              <div className="d-flex justify-content-between">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="influencerSize"
                    value="2000-10000"
                    id="nano"
                    onChange={handleChange}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="nano"
                    style={{ fontSize: "11px" }}
                  >
                    Nano
                  </label>
                </div>
                <p className="" style={{ fontSize: "11px" }}>
                  2K-10K followers
                </p>
              </div>
              <div className="d-flex justify-content-between">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="influencerSize"
                    id="micro"
                    value="10000-25000"
                    onChange={handleChange}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="micro"
                    style={{ fontSize: "11px" }}
                  >
                    Micro
                  </label>
                </div>
                <p className="" style={{ fontSize: "11px" }}>
                  10K-25K followers
                </p>
              </div>
              <div className="d-flex justify-content-between">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="influencerSize"
                    id="midTier"
                    value="25000-100000"
                    onChange={handleChange}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="midTier"
                    style={{ fontSize: "11px" }}
                  >
                    Mid Tier
                  </label>
                </div>
                <p className="" style={{ fontSize: "11px" }}>
                  25K-100K followers
                </p>
              </div>
              <div className="d-flex justify-content-between">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="influencerSize"
                    id="macro"
                    value="100000-250000"
                    onChange={handleChange}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="macro"
                    style={{ fontSize: "11px" }}
                  >
                    Macro
                  </label>
                </div>
                <p className="" style={{ fontSize: "11px" }}>
                  100K-250K followers
                </p>
              </div>
              <div className="d-flex justify-content-between">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="influencerSize"
                    id="mega"
                    value="250000-1000000"
                    onChange={handleChange}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="mega"
                    style={{ fontSize: "11px" }}
                  >
                    Mega
                  </label>
                </div>
                <p className="" style={{ fontSize: "11px" }}>
                  250K-1M followers
                </p>
              </div>
              <div className="d-flex justify-content-between">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="influencerSize"
                    id="aListers"
                    value="1000000-10000000000"
                    onChange={handleChange}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="aListers"
                    style={{ fontSize: "11px" }}
                  >
                    A-Listers
                  </label>
                </div>
                <p className="" style={{ fontSize: "11px" }}>
                  1M+ followers
                </p>
              </div>
            </div>
            <div className="text-end mb-2 me-3">
              <button
                className="btn btn-primary"
                // type="submit"
                style={{ fontSize: "11px" }}
                onClick={() => handleFilters(gender, "followers")}
              >
                Apply
              </button>
            </div>
          </div>
          {/* </form> */}
        </div>
      </div>
      <br />
      <br />
      <br />
      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
        <InfluencersColumns
          handleSetColumns={handleSetColumns}
          setProfiles={setProfiles}
          setFilterLoading={setFilterLoading}
          setCount={setRowCount}
        />
        <div style={{ fontSize: "12px" }}>
          {loading ? (
            <center className="mt-5">
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </center>
          ) : (
            <ToolkitProvider
              keyField="_id"
              data={profiles}
              columns={Columns}
              search
              remote
              exportCSV
            >
              {(props) => (
                <div>
                  {/* <h3>Input something at below input field:</h3> */}
                  {/* <hr /> */}
                  {/* <MyExportCSV {...props.csvProps} /> */}

                  <div
                    className="d-flex justify-content-end"
                    style={{ marginBottom: "-20px" }}
                  >
                    {/* Export CSV Without Number */}
                    <CSVExport.ExportCSVButton
                      className="btn btn-success"
                      onClick={() =>
                        exportWithoutNumber(props.csvProps, profiles)
                      }
                    >
                      Export to CSV
                    </CSVExport.ExportCSVButton>
                    {/* Export CSV for All Columns */}
                    {superUser && (
                      <CSVExport.ExportCSVButton
                        className="btn btn-success ms-2"
                        onClick={() =>
                          exportAllColumns(props.csvProps, profiles)
                        }
                      >
                        Export with Number
                      </CSVExport.ExportCSVButton>
                    )}
                  </div>

                  {/* <SearchBar {...props.searchProps} className="form-control-sm" /> */}
                  {/* <ClearSearchButton {...props.searchProps} className="btn btn-outline-success ml-2" /> */}
                  {/* <hr /> */}
                  <h6>
                    <Badge pill bg="secondary">
                      {rowCount}
                    </Badge>
                  </h6>
                  <BootstrapTable
                    loading={loading}
                    // bootstrap4
                    keyField="_id"
                    onDataSizeChange={handleOnDataSizeChange}
                    hover
                    // condensed
                    responsive
                    // striped
                    classes="fs-6 table-sm table-borderless"
                    defaultSorted={defaultSorted}
                    {...props.baseProps}
                    filter={filterFactory()}
                    onFilter={handleFilter}
                    // caption={<CaptionElement />}
                    pagination={paginationFactory({ sizePerPage: 100 })}
                    // expandRow={expandRow}
                    filterPosition="top"
                    rowStyle={{ cursor: "pointer" }}
                    // selectRow={{ mode: "checkbox" }}
                    bordered={false}
                  />
                </div>
              )}
            </ToolkitProvider>
          )}
        </div>
      </div>
    </>
  );
}

export default InfluencerDataTable;
