import React, { useEffect, useState } from "react";
// import "bootstrap/dist/css/bootstrap.min.css";
import "./css/login.css";
import Input from "../common/input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Link } from "react-router-dom";
import authService from "../../services/authService";
import { faRightToBracket } from "@fortawesome/free-solid-svg-icons";
import { Redirect } from "react-router-dom";
import axios from "axios";

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [message, setMessage] = useState("Enter a Valid Credentials");
  const [error, setError] = useState(false);

  const loginLogic = async () => {
    const resp = authService.login({
      email: email,
      password: password,
    });

    (await resp) ? (window.location.href = "/dashboard") : setError(true);

    // const splitEmail = email.split("@");
    // const userName = splitEmail[0];
    // const domain = splitEmail[1];

    // if (domain !== "idiotic.media") return false;

    // if (password !== "idioticmedia") return false;

    // localStorage.setItem("username", userName);
  };

  const handleLogin = (e) => {
    e.preventDefault();
    loginLogic();
  };

  const errorStyle = {
    width: "100%",
    marginTop: "0.25rem",
    fontSize: ".875em",
    color: "#dc3545",
  };

  return (
    <>
      <div className="loginForm">
        <form onSubmit={handleLogin}>
          <div className="email">
            <input
              type="email"
              id=""
              placeholder="Business Email (idiotic.media)"
              className="form-control p-3 outline-secondary"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              name="email"
              required
            />
          </div>
          <br />
          <div className="pwd">
            <input
              type="password"
              placeholder="Password"
              className="form-control p-3 outline-secondary"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              id="password"
              name="password"
              required
            />
            {error && <small style={errorStyle}>{message}</small>}
          </div>

          <p className="d-flex justify-content-end pt-3">
            <a href="/" className="text-decoration-none ">
              {/* Forgot password? */}
            </a>
          </p>
          <div className="loginButton">
            <button
              type="submit"
              className="btn btn-primary w-100 bg-danger btn-outline-danger text-white"
            >
              <strong>
                Sign In &nbsp;
                <FontAwesomeIcon icon={faRightToBracket} />
              </strong>
            </button>
          </div>
        </form>
        {/* <p className=" pt-3">or</p>
        <div className="">
          <button
            type="button"
            className="btn btn-primary w-100 bg-danger btn-outline-danger text-white p-3"
            onClick={() => (window.location.href = "/dashboard")}
          >
            <strong>
              Sign In With Google &nbsp;
              <FontAwesomeIcon icon="fa-solid fa-right-to-bracket" />
            </strong>
          </button>
        </div> */}
      </div>
      {/* <hr /> */}
      {/* <p className="d-flex justify-content-between  signup">
        <p>Don't have an account? </p>
        <Link to="/register" className="text-decoration-none text-danger">
          &nbsp;SIGNUP FOR FREE
        </Link>
      </p> */}
    </>
  );
}
