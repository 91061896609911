export default function removeMediaFromUrl(url) {
  if (!url) {
    return "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png";
  }

  if (url && url.search("/media") == 0) {
    url = url.substring(6);
  }

  return process.env.REACT_APP_BASE_URL + url;
}
