import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import "./css/profile.css";
import User from "./user";
import MainContent from "./mainContent";
import Header from "../common/header/header";
import influencerservice from "../../services/influencerservice";
import campaignservice from "../../services/campaignservice";
import { useHistory } from "react-router-dom";
import Footer from "../common/footer/footer";
import paymentlinkservice from "../../services/paymentlinkservice";
import companyservice from "../../services/companyservice";
const CryptoJS = require("crypto-js");

export default function Profile({ match }) {
  const heightStyle = { height: "758px" };

  const [influencer, setInfluencer] = useState({
    categoryName: [],
  });
  const [vendor, setVendor] = useState("");
  const [profileDetails, setProfileDetails] = useState([]);
  const [toggleTabs, setToggleTabs] = useState(false);
  const [loading, setLoading] = useState(true);
  const [overview, setOverview] = useState({});
  const history = useHistory();

  const handleCampaignDetails = async (campaignId) => {
    const _campaign = await campaignservice.getCampaignById({
      id: campaignId,
    });

    history.push({
      pathname: "/admin/payment/campaign",
      state: {
        id: _campaign._id,
        name: _campaign.name,
        category: _campaign.category,
        deliverables: _campaign.deliverables,
        platform: _campaign.platform ? _campaign.platform : "NA",
        brandName: _campaign.brand ? _campaign.brand.name : "NA",
        paymentBudget: _campaign.totalPayment ? _campaign.totalPayment : "NA",
        paymentLimit: _campaign.paymentLimit ? _campaign.paymentLimit : "NA",
        createdby: _campaign.createdby ? _campaign.createdby.fullName : "NA",
      },
    });
  };

  const ShowLabel = (status) => {
    let name = "";
    switch (status) {
      case "P":
        name = "Pending";
        break;
      case "A":
        name = "Applied";
        break;
      case "W":
        name = "Waiting";
        break;
      case "PP":
        name = "Partial Pending";
        break;
      case "S":
        name = "Success";
        break;
      case "F":
        name = "Failed";
        break;
      case "R":
        name = "Rejected";
        break;
      default:
        break;
    }
    return name;
  };

  const handleClick = (key) => {
    if (key === "1") {
      setToggleTabs(false);
    } else {
      setToggleTabs(true);
    }
  };

  const Init = async () => {
    setLoading(true);

    const _influencer = await influencerservice.getInfluencerDetailById(
      match.params["id"]
    );
    setInfluencer(_influencer);

    let isCompany = match.params['isCompany'];
    isCompany = isCompany.replaceAll("xXx", "/");
    const bytes = CryptoJS.AES.decrypt(isCompany, "123");
    isCompany = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    if (isCompany === true) {
      const vendor = await companyservice.getVendorById({ id: match.params['id'] });
      setVendor(vendor);
    }

    const profileDetails = await paymentlinkservice.getProfileDetails({
      id: match.params["id"],
      isCompany: match.params["isCompany"],
    });
    setProfileDetails(profileDetails);

    const overviewDetails = await paymentlinkservice.getProfileOverviewById({
      id: match.params["id"],
      isCompany: match.params["isCompany"],
    });
    setOverview(overviewDetails);

    setLoading(false);
  };

  useEffect(() => {
    Init();
  }, []);

  return (
    <>
      <Header title={"Influencer Profile"} />
      <div className="container-fluid" id="profile">
        <div className="row" style={heightStyle}>
          {loading ? (
            <center>
              {" "}
              <Spinner animation="border" />{" "}
            </center>
          ) : (
            <MainContent
              loading={loading}
              isCompany={match.params["isCompany"]}
              ShowLabel={ShowLabel}
              handleClick={handleClick}
              overview={overview}
              profileDetails={profileDetails}
              setProfileDetails={setProfileDetails}
              handleCampaignDetails={handleCampaignDetails}
              influencer={influencer}
              vendor={vendor}
            />
          )}
        </div>
      </div>
      <Footer />
    </>
  );
}
