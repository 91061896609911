import http from "./httpservices";

export async function generatePaymentLink(paymentObj) {
  const { data } = await http.post("payment/", paymentObj);
  return data;
}

export async function generateCompanyLink(paymentObj) {
  const { data } = await http.post("payment/companylink", paymentObj);
  return data;
}

export async function generateExpenseLink(paymentObj) {
  const { data } = await http.post("payment/expenselink", paymentObj);
  return data;
}

export async function getPaymentLink(obj) {
  try {
    const { data } = await http.post("payment/getlink", obj);
    return data;
  } catch (error) {
    return null;
  }
}

export async function getCompanyPaymentLink(obj) {
  try {
    const { data } = await http.post("payment/getcompanylink", obj);
    return data;
  } catch (error) {
    return null;
  }
}

export async function getPaymentData() {
  const { data } = await http.get("payment/getdata");
  return data;
}

export async function getAllByCampaigns() {
  const { data } = await http.get("payment/getallbycampaigns");
  return data;
}

export async function getAllCampaignsByCompany() {
  const { data } = await http.get("payment/getallcampaignsbycompany");
  return data;
}

export async function getSelectedStatus(filterObj) {
  const { data } = await http.post("/payment/statusfilter", filterObj);
  return data;
}

export async function getDataByStatus(params) {
  const { data } = await http.post("/payment/data/status", params);
  return data;
}

export async function getPaymentInfluencerStatus() {
  const { data } = await http.get("payment/getpaymentinfluencerstatus");
  return data;
}

export async function getPaymentCompanyStatus() {
  const { data } = await http.get("payment/getpaymentcompanystatus");
  return data;
}

export async function getByCampaignId(id) {
  const { data } = await http.post("payment/getbycampaignid", { id: id });
  return data;
}

export async function getByCompanyId(id) {
  const { data } = await http.post("payment/getbycompanyname", { id: id });
  return data;
}

export async function download(object) {
  const { data } = await http.post("payment/download", object);
  return data;
}

export async function exportData(object) {
  const { data } = await http.post("payment/exportdata", object);
  return data;
}

export async function exportClubLinksData(object) {
  const { data } = await http.post("payment/exportclublinks", object);
  return data;
}

export async function getPendingPaymentData(pendingObj) {
  const { data } = await http.post("payment/getpendingdata", pendingObj);
  return data;
}

export async function getPendingCompanyData(id) {
  const { data } = await http.post("payment/getcompanydata/" + id);
  return data;
}

export async function getAppliedPaymentData(appliedObj) {
  const { data } = await http.post("payment/getapplieddata", appliedObj);
  return data;
}

export async function getPaymentByStatus(params) {
  const { data } = await http.post("payment/status", params);
  return data;
}

export async function getSuccessPaymentData(id, role) {
  const { data } = await http.post("payment/getsuccessdata", {
    id: id,
    role: role,
  });
  return data;
}

export async function getRejectedPaymentData(obj) {
  const { data } = await http.post("payment/getrejecteddata", obj);
  return data;
}

export async function getRejectedPaymentDataById(obj) {
  const { data } = await http.post("payment/getrejecteddatabyid", obj);
  return data;
}

export async function getFailedPaymentData(obj) {
  const { data } = await http.post("payment/getfaileddata", obj);
  return data;
}

export async function getFailurePaymentData(id, role) {
  const { data } = await http.post("payment/getfailuredata", {
    id: id,
    role: role,
  });
  return data;
}

export async function getApprovedData(rowId, id) {
  const { data } = await http.post("payment/getapproved", {
    rowId: rowId,
    id: id,
  });
  return data;
}

export async function getApprovedCompanyData(rowId, id) {
  const { data } = await http.post("payment/getcompanyapproved", {
    rowId: rowId,
    id: id,
  });
  return data;
}

export async function Declined(params) {
  const { data } = await http.post("payment/declined", params);
  return data;
}

export async function getUpdatedData(id, payment_Status) {
  const { data } = await http.post("payment/getupdated", {
    id: id,
    payment_Status: payment_Status,
  });
  return data;
}

export async function addPaymentStatus(params) {
  const { data } = await http.post("payment/addstatus", params);
  return data;
}

export async function getCampaignPaymentData(name, id, isCompany, status) {
  const { data } = await http.post("payment/bycampaign", {
    name: name,
    id: id,
    isCompany: isCompany,
    status: status,
  });
  return data;
}

export async function getPaymentLinkCategory() {
  const { data } = await http.post("payment/category");
  return data;
}

export async function getUploadInvoice(formData) {
  const { data } = await http.post("payment/upload", formData);
  return data;
}

export async function getUploadScreenshot(formData) {
  const { data } = await http.post("payment/uploadscreenshot", formData);
  return data;
}

export async function uploadGstScreenshot(formData) {
  const { data } = await http.post("payment/gstscreenshot", formData);
  return data;
}

export async function getUploadGst(formData) {
  const { data } = await http.post("payment/uploadgst", formData);
  return data;
}

export async function getUploadPancard(pancardFormData) {
  const { data } = await http.post("payment/uploadpancard", pancardFormData);
  return data;
}

export async function deleteRemarks(remarks) {
  const { data } = await http.post("payment/deleteremarks", remarks);
  return data;
}

export async function failRemarks(remarks) {
  const { data } = await http.post("payment/failremarks", remarks);
  return data;
}

export async function getSearchData(name, isCompany, id, status) {
  const { data } = await http.post("/payment/searchdata", {
    id: id,
    name: name,
    isCompany: isCompany,
    status: status,
  });
  return data;
}

export async function addInfluencerData(params) {
  const data = await http.post("payment/adddata", params);
  return data;
}

export async function addCompanyData(params) {
  const data = await http.post("payment/addcompanydata", params);
  return data;
}

export async function getPaymentDetailsById(params) {
  const { data } = await http.post("payment/detailbyid", params);
  return data;
}

export async function getInfluencerDetails(params) {
  const { data } = await http.post("payment/influencer/detail", params);
  return data;
}

export async function getVendorDetails(params) {
  const { data } = await http.post("payment/vendor/detail", params);
  return data;
}

export async function updateIsGstPay(params) {
  const { data } = await http.post("payment/gstpay", params);
  return data;
}

export async function addStatusAtMultiple(params) {
  const { data } = await http.post("payment/addmultiplestatus", params);
  return data;
}

export async function getAllSelectedIds(params) {
  const { data } = await http.post("payment/selectedids", params);
  return data;
}

export async function requestGstPay(param) {
  const { data } = await http.post("payment/requestgstpay", param);
  return data;
}

export async function getGstRequest(param) {
  const { data } = await http.post("payment/requestedgst", param);
  return data;
}

export async function getProfileDetails(params) {
  const { data } = await http.post("payment/detail/status", params);
  return data;
}

export async function getProfileOverviewById(params) {
  const { data } = await http.post("payment/overview", params);
  return data;
}

export async function updateTypeOfPayment(params) {
  const { data } = await http.post("payment/typeofpayment", params);
  return data;
}

export async function getClubLinks(params) {
  const { data } = await http.post("payment/links", params);
  return data;
}

export async function getSearchPayments(params) {
  const { data } = await http.post("payment/searchValue", params);
  return data;
}

export async function getSearchByPancard(params) {
  const { data } = await http.post("payment/searchpancard", params);
  return data;
}

export async function generateNewPaymentLink(params) {
  const { data } = await http.post("payment/createnewlink", params);
  return data;
}

export default {
  generatePaymentLink,
  generateCompanyLink,
  generateExpenseLink,
  getPaymentLink,
  getCompanyPaymentLink,
  getPaymentData,
  getPaymentInfluencerStatus,
  getPaymentCompanyStatus,
  getSelectedStatus,
  getDataByStatus,
  getPendingPaymentData,
  getPendingCompanyData,
  getCampaignPaymentData,
  getAppliedPaymentData,
  getPaymentByStatus,
  getSuccessPaymentData,
  getFailurePaymentData,
  getApprovedData,
  getApprovedCompanyData,
  Declined,
  getPaymentLinkCategory,
  getUpdatedData,
  addPaymentStatus,
  getUploadInvoice,
  getUploadScreenshot,
  uploadGstScreenshot,
  getUploadGst,
  getUploadPancard,
  deleteRemarks,
  failRemarks,
  getAllByCampaigns,
  getByCampaignId,
  getByCompanyId,
  download,
  exportData,
  exportClubLinksData,
  getRejectedPaymentData,
  getRejectedPaymentDataById,
  getFailedPaymentData,
  getAllCampaignsByCompany,
  getSearchData,
  addInfluencerData,
  addCompanyData,
  getPaymentDetailsById,
  getInfluencerDetails,
  getVendorDetails,
  updateIsGstPay,
  addStatusAtMultiple,
  getAllSelectedIds,
  requestGstPay,
  getGstRequest,
  getProfileDetails,
  getProfileOverviewById,
  updateTypeOfPayment,
  getClubLinks,
  getSearchPayments,
  getSearchByPancard,
  generateNewPaymentLink
};
