import jwtDecode from "jwt-decode";

export default function removeMediaFromUrl() {
  const id = jwtDecode(
    localStorage.getItem(process.env.REACT_APP_TOKEN_NAME)
  ).role;
  const isSuperUser = jwtDecode(
    localStorage.getItem(process.env.REACT_APP_TOKEN_NAME)
  ).isSuperUser;

  let role = "";
  switch (id) {
    case "62e12146f1cb1843765c65ab":
      role = "Operation";
      break;
    case "62e12146f1cb1843765c65ad":
      role = "Finance";
      break;
    case "62e12146f1cb1843765c65af":
      role = "Admin";
      break;
    case "62e12146f1cb1843765c65b1":
      role = "Outreach";
      break
    case "6492d5001cad552938dff37a":
      role = "FinanceOperation";
      break
  }

  return { role, isSuperUser };
}
