import http from "./httpservices";

export async function getHashTagsQueue() {
  const { data } = await http.get("/hashtagsqueue");
  return data;
}

export async function getHashTagsQueueStatus() {
  const { data } = await http.get("/hashtagsqueue/status");
  return data;
}

export async function hashTagsQueue(name) {
  const { data } = await http.post("/hashtagsqueue", {
    name: name,
  });
  return data;
}

export default {
  getHashTagsQueue,
  hashTagsQueue,
  getHashTagsQueueStatus
};
