import React from "react";
import Placeholder from "react-bootstrap/Placeholder";
import Card from "react-bootstrap/Card";
import Table from "react-bootstrap/Table";
import _ from "lodash";
import { Badge } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons";

function tablePlaceHolder(props) {
  return (
    <div>
      <Table striped hover size="sm" className="mt-2">
        <thead>
          <tr>
            <th className="expand-cell-header" data-row-selection="true">
              <div>
                <span>+</span>
              </div>
            </th>
            <th
              tabIndex="0"
              aria-label="Date sort desc"
              className="sortable h6"
            >
              Date
              <FontAwesomeIcon icon={faArrowDown} size="sm" color="grey" />
              &nbsp;
              <FontAwesomeIcon icon={faArrowUp} size="sm" />
            </th>
            <th tabIndex="0" aria-label="Pay sortable" className="sortable h6">
              Pay&nbsp;
              <FontAwesomeIcon icon={faArrowDown} size="sm" color="grey" />
              &nbsp;
              <FontAwesomeIcon icon={faArrowUp} size="sm" />
            </th>
            <th tabIndex="0" aria-label="Camp sortable" className="sortable h6">
              Camp&nbsp;
              <FontAwesomeIcon icon={faArrowDown} size="sm" color="grey" />
              &nbsp;
              <FontAwesomeIcon icon={faArrowUp} size="sm" />
            </th>
            <th
              tabIndex="0"
              aria-label="Platform sortable"
              className="sortable h6"
            >
              Platform&nbsp;
              <FontAwesomeIcon icon={faArrowDown} size="sm" color="grey" />
              &nbsp;
              <FontAwesomeIcon icon={faArrowUp} size="sm" />
            </th>
            <th
              tabIndex="0"
              aria-label="Inf/Ven sortable"
              className="sortable h6"
            >
              Inf/Ven&nbsp;
              <FontAwesomeIcon icon={faArrowDown} size="sm" color="grey" />
              &nbsp;
              <FontAwesomeIcon icon={faArrowUp} size="sm" />
            </th>
            <th
              tabIndex="0"
              aria-label="Comment sortable"
              className="sortable h6"
            >
              Comment&nbsp;
              <FontAwesomeIcon icon={faArrowDown} size="sm" color="grey" />
              &nbsp;
              <FontAwesomeIcon icon={faArrowUp} size="sm" />
            </th>
            <th tabIndex="0" aria-label="POC sortable" className="sortable h6">
              POC&nbsp;
              <FontAwesomeIcon icon={faArrowDown} size="sm" color="grey" />
              &nbsp;
              <FontAwesomeIcon icon={faArrowUp} size="sm" />
            </th>
            <th
              tabIndex="0"
              aria-label="Amount sortable"
              className="sortable h6"
            >
              Amount&nbsp;
              <FontAwesomeIcon icon={faArrowDown} size="sm" color="grey" />
              &nbsp;
              <FontAwesomeIcon icon={faArrowUp} size="sm" />
            </th>
            <th tabIndex="0" aria-label="GST sortable" className="sortable h6">
              GST&nbsp;
              <FontAwesomeIcon icon={faArrowDown} size="sm" color="grey" />
              &nbsp;
              <FontAwesomeIcon icon={faArrowUp} size="sm" />
            </th>
            <th tabIndex="0" aria-label="% sortable" className="sortable h6">
              %&nbsp;
              <FontAwesomeIcon icon={faArrowDown} size="sm" color="grey" />
              &nbsp;
              <FontAwesomeIcon icon={faArrowUp} size="sm" />
            </th>
            <th
              tabIndex="0"
              aria-label="Payable sortable"
              className="sortable h6"
            >
              Payable&nbsp;
              <FontAwesomeIcon icon={faArrowDown} size="sm" color="grey" />
              &nbsp;
              <FontAwesomeIcon icon={faArrowUp} size="sm" />
            </th>
            <th
              tabIndex="0"
              aria-label="Remarks sortable"
              className="sortable h6"
            >
              Remarks&nbsp;
              <FontAwesomeIcon icon={faArrowDown} size="sm" color="grey" />
              &nbsp;
              <FontAwesomeIcon icon={faArrowUp} size="sm" />
            </th>
            <th tabIndex="0">
              <select
                className="form-control-sm text-cnter"
                style={{ fontSize: "0.575rem" }}
                disabled
              >
                <option value="">Status</option>
              </select>
            </th>
            <th tabIndex="0" className="h6">
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {_.range(1, 30).map((i) => (
            <tr key={i}>
              <td>+</td>
              <td>
                <Badge bg="dark" style={{ fontSize: "10px" }}>
                  00/00/0000
                </Badge>
                <div style={{ width: "90px" }}></div>
              </td>
              <td>
                <Badge bg="info" style={{ fontSize: "10px" }}>
                  XXX
                </Badge>
              </td>
              <td>
                <Placeholder as={Card.Text} animation="glow">
                  <Placeholder xs={10} />
                </Placeholder>
              </td>
              <td>
                <Placeholder
                  as={Card.Text}
                  animation="glow"
                  //
                >
                  <Placeholder xs={6} />
                </Placeholder>
              </td>
              <td>
                <a
                  style={{ textDecoration: "none", fontSize: "12px" }}
                  href="#"
                >
                  xxxxx...
                </a>
              </td>

              <td>
                <Placeholder
                  as={Card.Text}
                  animation="glow"
                  //
                >
                  <Placeholder xs={8} />
                </Placeholder>
              </td>
              <td>
                <Placeholder
                  as={Card.Text}
                  animation="glow"
                  //
                >
                  <Placeholder xs={8} />
                </Placeholder>
              </td>

              <td>
                <Badge bg="success" style={{ fontSize: "10px" }}>
                  {`\u20B9 0000`}
                </Badge>
              </td>

              <td>
                <Badge
                  bg="warning"
                  style={{ fontSize: "10px", color: "black" }}
                >
                  {`\u20B9 0000`}
                </Badge>
              </td>
              <td>
                <Placeholder as={Card.Text} animation="glow">
                  <Placeholder xs={7} />
                </Placeholder>
              </td>
              <td>
                <Badge
                  bg="warning"
                  style={{ fontSize: "10px", color: "black" }}
                >
                  {`\u20B9 0000`}
                </Badge>
              </td>
              <td>
                <Placeholder as={Card.Text} animation="glow">
                  <Placeholder xs={7} />
                </Placeholder>
              </td>
              <td>
                <Badge pill style={{ fontSize: "10px" }}>
                  XXXXXXX
                </Badge>
              </td>
              <td>
                <Placeholder as={Card.Text} animation="glow">
                  <Placeholder xs={7} />
                </Placeholder>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}

export default tablePlaceHolder;
