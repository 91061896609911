import http from "./httpservices";

export async function addNewPaymentDetails(params) {
  const data = await http.post("bankdetails/", params);
  return data;
}

export async function addNewCompanyDetails(params) {
  const { data } = await http.post("bankdetails/companyinfo", params);
  return data;
}

export async function getBankDetails() {
  const { data } = await http.get("bankdetails/");
  return data;
}

export async function getAllInfluencerBankDetails(id) {
  const { data } = await http.post("bankdetails/influencerdetails", { id: id });
  return data;
}

export async function influencerBankDetails(params) {
  const response = await http.post("bankdetails/details", params);
  return response;
}

export async function getAllCompanyBankDetails(id) {
  const { data } = await http.post("bankdetails/companydetails", { id: id });
  return data;
}

export async function activeBankDetails(params) {
  const { data } = await http.post("bankdetails/active", params);
  return data;
}

export default {
  addNewPaymentDetails,
  addNewCompanyDetails,
  getBankDetails,
  getAllInfluencerBankDetails,
  getAllCompanyBankDetails,
  activeBankDetails,
  influencerBankDetails
};
