import { Card, ListGroup } from "react-bootstrap";
import StatusCard from "../common/campaignCard/statusCard";
import IdToDate from "../../admin/common/IdToDate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDollar, faHandshakeSimple, faList, faUser } from "@fortawesome/free-solid-svg-icons";

function ShowOverviewData({ overview, handleCampaignDetails, influencer }) {
  const fontStyle = { fontSize: "12px" };
  return (
    <>
      <div className="col-xl-9">
        <div className="row">
          <div className="col-xl-9">
            <Card>
              <Card.Body>
                <div className="d-flex align-items-center justify-content-between">
                  <div>
                    <h6 className="card-title" style={{ color: "#148A9C" }}>
                      {influencer.username
                        ? "@" + influencer.username
                        : "NA"}
                    </h6>
                    <p className="card-text  fw-bold" style={fontStyle}>
                      {influencer.biography ? influencer.biography : "NA"}
                    </p>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </div>
          <div className="col-xl-3">
            <Card
              style={{ cursor: "pointer" }}
              onClick={() => (window.location.href = "/admin/payment/status")}
            >
              <Card.Body>
                <div className="d-flex align-items-center justify-content-between">
                  <div>
                    <h6 className="card-title" style={{ color: "#148A9C" }}>
                      {overview.totalSuccessAmount
                        ? overview.totalSuccessAmount
                        : "NA"}
                    </h6>
                    <p className="card-text  fw-bold" style={fontStyle}>
                      Success Payments
                    </p>
                  </div>
                  <div>
                    <p className="fs-5" style={{ color: "#148A9C" }}>
                      <FontAwesomeIcon icon={faDollar} />
                    </p>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </div>
        </div>
        <br />
        <div className="row">
          <StatusCard
            stats={overview}
          />
        </div>
      </div>
      <div className="col-xl-3">
        <ListGroup as="ul">
          <ListGroup.Item
            as="li"
            className="d-flex justify-content-between align-items-start active fw-bold"
          >
            Campaigns ({overview.totalCampaignCount})
          </ListGroup.Item>
          {overview.totalCampaigns.length === 0 ? (
            <center className="text-danger">No Data</center>
          ) : (
            overview.totalCampaigns.map((campaign, index) => (
              <ListGroup.Item
                as="li"
                className="d-flex justify-content-between align-items-start"
                style={{ cursor: "pointer" }}
                onClick={() => handleCampaignDetails(campaign._id)}
              >
                <div className="ms-2 me-auto">
                  <div className="fw-bold" style={{ fontSize: "14px" }}>
                    <FontAwesomeIcon icon={faHandshakeSimple} /> {campaign.name}
                  </div>
                  <div style={{ fontSize: "14px" }}>
                    <FontAwesomeIcon icon={faUser} />{" "}
                    {campaign.createdby && campaign.createdby.fullName}
                  </div>
                </div>
                <IdToDate id={campaign._id} />
              </ListGroup.Item>
            ))
          )}
        </ListGroup>
      </div>
    </>
  );
}

export default ShowOverviewData;
