import React from 'react';
import Stats from '../components/stats/stats';

export default function stats() {
  return (
    <>
      <Stats />
    </>
  )
}
