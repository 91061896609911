import { Link } from "react-router-dom";

function numberFormatter(cell, row) {
  return (
    <>
      <div>{cell ? cell.split(",")[0] : "NA"}</div>
    </>
  );
}
export default numberFormatter;
