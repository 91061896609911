import React, { useEffect } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Tooltip } from "bootstrap";
import { NavLink } from "react-router-dom";
import useRole from "../../../hooks/useRole";
import Brand from "../../common/brand/brand";
import Campaign from "../../common/campaign/campaign";
import Company from "../../common/company/company";
// import "./css/slideMenu.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilter,
  faHandshake,
  faHome,
  faMoneyBill,
  faMoneyCheck,
} from "@fortawesome/free-solid-svg-icons";

function SideMenuCanvas({ show, handleClose }) {
  const role = useRole();
  const customToggle = () => {
    var tooltipTriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="collapse"]')
    );
    tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new Tooltip(tooltipTriggerEl);
    });
  };

  useEffect(() => {
    customToggle();
  }, []);

  const CustomLine = ({ color }) => {
    return <li style={{ height: "1px" }}></li>;
  };
  return (
    <Offcanvas show={show} onHide={handleClose} data-bs-theme="dark">
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Media Payout Dashboard</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <div id="sidebar">
          <ul className="list-unstyled ps-0">
            <li>
              <div className="collapse show" id="dashboard-collapse">
                <ul className="btn-toggle-nav list-unstyled fw-normal pb-1">
                  {role !== "Finance" && (
                    <>
                      <li>
                        <NavLink
                          className="link-light rounded"
                          exact
                          to="/admin/payment/dashboard/home"
                        >
                          <FontAwesomeIcon
                            icon={faHome}
                            className="me-1 align-self-center"
                          />
                          Home
                        </NavLink>
                      </li>
                      <CustomLine />
                      <li>
                        <NavLink
                          className="link-light rounded"
                          exact
                          to="/admin/payment/dashboard/campaigns/pending"
                        >
                          <FontAwesomeIcon
                            icon={faHandshake}
                            className="me-1 align-self-center"
                          />
                          Campaigns
                        </NavLink>
                      </li>
                    </>
                  )}
                </ul>
              </div>
            </li>
            <CustomLine />
            <li>
              <div className="collapse show" id="status-collapse">
                <ul className="btn-toggle-nav list-unstyled fw-normal pb-1">
                  <li>
                    <NavLink
                      className="link-light rounded"
                      exact
                      to="/admin/payment/status"
                    >
                      <FontAwesomeIcon
                        icon={faMoneyCheck}
                        className="me-1 align-self-center"
                      />
                      Status
                    </NavLink>
                  </li>
                </ul>
              </div>
            </li>
            <CustomLine />
            <li>
              <div className="collapse show" id="create-collapse">
                <ul className="btn-toggle-nav list-unstyled fw-normal pb-1">
                  {role["role"] === "Admin" && (
                    <>
                      <Brand />
                      <CustomLine />
                      <Campaign />
                      <CustomLine />
                    </>
                  )}
                  <Company />
                  <CustomLine />
                </ul>
              </div>
            </li>

            {role["role"] !== "Operation" &&
              role["role"] === "Admin" &&
              role["isSuperUser"] === true && (
                <li>
                  <div className="collapse show" id="finance-collapse">
                    <ul className="btn-toggle-nav list-unstyled fw-normal pb-1">
                      {((role["role"] === "Admin" &&
                        role["isSuperUser"] === true) ||
                        role["role"] === "Finance") && (
                        <li>
                          <NavLink
                            className="link-light rounded"
                            exact
                            to="/admin/payment/confirmation"
                          >
                            <FontAwesomeIcon
                              icon={faMoneyBill}
                              className="me-1 align-self-center"
                            />
                            Finance
                          </NavLink>
                        </li>
                      )}
                    </ul>
                  </div>
                </li>
              )}

            {/* <li>
              <div className="collapse show" id="finance-collapse">
                <ul className="btn-toggle-nav list-unstyled fw-normal pb-1">
                  <li>
                    <a
                      className="link-light rounded"
                      href="https://meesha.io/filter/influencers"
                      target="_blank"
                    >
                      <FontAwesomeIcon
                        icon={faFilter}
                        className="me-1 align-self-center"
                      />
                      Meesha Filter
                    </a>
                  </li>
                </ul>
              </div>
            </li> */}
          </ul>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
}

export default SideMenuCanvas;
