import React from "react";
import Header from "../../../components/common/header/header";
import emailscrapingservice from "../../../services/emailscrapingservice";
import { useState } from "react";
import { Spinner, Tab, Tabs } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleArrowDown, faTrash } from "@fortawesome/free-solid-svg-icons";
import jwtDecode from "jwt-decode";
import { useEffect } from "react";
import Button from "react-bootstrap/Button";
import Badge from "react-bootstrap/Badge";
import Table from "react-bootstrap/Table";
import Footer from "../../../components/common/footer/footer";
import Swal from "sweetalert2";
import hashtagservice from "../../../services/hashtagservice";
import HashTags from "./hashTags/hashTags";

function EmailScraping() {
  const d = new Date();
  const currentData = d.toLocaleDateString("en-US", {
    day: "numeric",
    month: "numeric",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });

  const customStyle = { fontSize: "13px", fontWeight: "500" };
  const [emailScrapingList, setEmailScrapingList] = useState([]);
  const [influencerQueue, setInfluencerQueue] = useState([]);

  const [listLoading, setListLoading] = useState(true);

  const [name, setName] = useState("");

  const onSubmit = async (e) => {
    e.preventDefault();
    const response = await emailscrapingservice.PostInfluencerQueue({
      userName: name,
    });
    if (response.status === 201) {
      Swal.fire(response.data.title, response.data.msg);
      setName("");
      return;
    }

    setInfluencerQueue([
      {
        userName: name,
      },
      ...influencerQueue,
    ]);
    setName("");
    return;
  };

  const handleDownload = (fileName) => {
    const url = process.env.REACT_APP_BASE_URL + "/emailScraping/" + fileName;
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const handleEmailQueue = async (influencerList) => {
    // const response = await emailscrapingservice.InfluencerQueueSingle();
    // // console.log(response);

    // setEmailScrapingList([
    //   {
    //     userName: response.data.userName,
    //     status: "loading",
    //     totalFollowing: "NA",
    //     // _id: null,
    //     downloadFollowing: 0,
    //     path: "NA",
    //   },
    //   ...influencerList,
    // ]);
    // await emailscrapingservice.InfluencerQueueDelete({ id: response.data._id });

    const emailResponse = await emailscrapingservice.InstagramUserEmail({
      // userName: response.data.userName,
      createdBy: jwtDecode(
        localStorage.getItem(process.env.REACT_APP_TOKEN_NAME)
      )._id,
    });

    return;
  };

  const InIt = async () => {
    setListLoading(true);
    const response = await emailscrapingservice.getInstagramUserEmail();
    const queue = await emailscrapingservice.InfluencerQueue();
    setEmailScrapingList(response);
    setInfluencerQueue(queue.data);
    setListLoading(false);

    if (response.filter((item) => item.status === "loading").length < 3) {
      handleEmailQueue(response);
    }
  };

  const handleDelete = async (id) => {
    await emailscrapingservice.deleteInstagramUserEmail({ id: id });
    window.location.reload();
    // setEmailScrapingList(emailScrapingList.filter((item) => item._id !== id));
  };

  useEffect(async () => {
    InIt();
  }, []);

  return (
    <>
      <Header title={"Email Scraping"} />
      <br />
      <div className="container">
        <div className="row">
          <Tabs
            defaultActiveKey={1}
            id="uncontrolled-tab-example"
            className="mb-3"
          >
            <Tab eventKey={1} title="Email Scraping">
              <form onSubmit={onSubmit}>
                <input
                  type="text"
                  className="form-control mb-3"
                  id="username"
                  placeholder="Instagram Username*"
                  name="instaUsername"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                ></input>
                <button type="submit" className="btn btn-primary">
                  Queue
                </button>
              </form>
              <br />
              <div className="row">
                <div
                  className=" p-3 rounded-3 overflow-auto col-xl-10 mx-auto"
                  id="tableSection"
                >
                  {/* <Table hover size="sm"> */}
                  <Table hover size="sm">
                    <thead>
                      <tr>
                        <th scope="col">
                          <small className="">
                            ({emailScrapingList.length})
                          </small>
                        </th>
                        <th scope="col" className="text-center">
                          <small className="">Date, Time</small>
                        </th>
                        <th scope="col">
                          <small className="">UserName</small>
                        </th>
                        <th scope="col">
                          <small className="">Following</small>
                        </th>
                        <th scope="col">
                          <small className="">Download Following</small>
                        </th>

                        <th scope="col">
                          <small className="">API Hit</small>
                        </th>
                        <th scope="col">
                          <small className="">Status</small>
                        </th>
                        {/* <th scope="col">
                        <small className="">Download</small>
                      </th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {listLoading ? (
                        <tr>
                          <td colSpan={7}>
                            <center>
                              <Spinner animation="border" />
                            </center>
                          </td>
                        </tr>
                      ) : (
                        // paymentPendingData &&
                        emailScrapingList.map((scraping, index) => (
                          <tr key={index}>
                            <td style={customStyle} className="text-center">
                              {index + 1}{" "}
                            </td>
                            <td>
                              <Badge bg="dark">
                                {scraping._id
                                  ? new Date(
                                      parseInt(
                                        scraping._id.substring(0, 8),
                                        16
                                      ) * 1000
                                    ).toLocaleDateString("en-US", {
                                      day: "numeric",
                                      month: "numeric",
                                      year: "numeric",
                                      hour: "2-digit",
                                      minute: "2-digit",
                                    })
                                  : currentData}
                              </Badge>
                            </td>

                            <td
                              style={{ ...customStyle, cursor: "pointer" }}
                              onClick={() =>
                                window.open(
                                  `https://www.instagram.com/${scraping.userName}`,
                                  "_blank"
                                )
                              }
                            >
                              {scraping.userName}
                            </td>
                            <td style={customStyle}>
                              {scraping.totalFollowing}
                            </td>
                            <td style={customStyle} className="w-25">
                              {scraping.downloadFollowing
                                ? scraping.downloadFollowing
                                : "NA"}
                            </td>
                            <td style={customStyle} className="w-25">
                              {scraping.apiHit || 0}
                            </td>
                            <td style={customStyle} className="w-25">
                              {scraping.status === "loading" ? (
                                <>
                                  <Button variant="primary" disabled>
                                    <Spinner
                                      as="span"
                                      animation="border"
                                      size="sm"
                                      role="status"
                                      aria-hidden="true"
                                    />
                                    <span className="visually-hidden">
                                      Loading...
                                    </span>
                                  </Button>{" "}
                                  <FontAwesomeIcon
                                    icon={faTrash}
                                    className="text-danger align-middle"
                                    title="Download"
                                    onClick={() => handleDelete(scraping._id)}
                                    style={{
                                      cursor: "pointer",
                                      fontSize: "16px",
                                    }}
                                  />
                                </>
                              ) : (
                                <Badge bg="success">Success</Badge>
                              )}{" "}
                              {""}
                              {scraping.path === "NA" ? null : (
                                <FontAwesomeIcon
                                  icon={faCircleArrowDown}
                                  className="text-primary align-middle"
                                  title="Download"
                                  onClick={() => handleDownload(scraping.path)}
                                  style={{
                                    cursor: "pointer",
                                    fontSize: "16px",
                                  }}
                                />
                              )}
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </Table>
                </div>
                <div className=" rounded-3 col-xl-2 mx-auto">
                  <h6 className="m-3">Queue ({influencerQueue.length})</h6>
                  <hr />
                  {influencerQueue.length === 0 ? (
                    <p>No data</p>
                  ) : (
                    influencerQueue.map((inf, index) => (
                      <p key={index}>
                        {index + 1}) {inf.userName}
                      </p>
                    ))
                  )}
                </div>
              </div>
            </Tab>
            <Tab eventKey={2} title="Hashtag">
              <HashTags customStyle={customStyle} currentData={currentData} />
            </Tab>
          </Tabs>
        </div>
      </div>
      <br />
      <br />
      <br />
      <Footer />
    </>
  );
}

export default EmailScraping;
