import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import jwtDecode from "jwt-decode";
import CampaignDetails from "../../../components/payment/campaignDetails/campaignInfo";
import SlideMenu from "../../../components/payment/common/slideMenu";
import Header from "../../../components/common/header/header";
import campaignservice from "../../../services/campaignservice";
import Footer from "../../../components/common/footer/footer";

function CampaignInfo(props) {
  if (!props.location.state) window.location.href = "/admin/payment/campaign";
  const campaignDetails = props.location.state;

  const customStyle = { fontSize: "13px" };
  const borderRadiusStyle = { borderRadius: "20px" };
  const customButtonStyle = { fontSize: "20px", cursor: "pointer" };
  const backgroundColorStyle = { color: "#3C44BC", backgroundColor: "#9398E6" };

  const [loading, setLoading] = useState(true);
  const [toggleView, setToggleView] = useState(false);
  const [campaignsInfo, setCampaignsInfo] = useState([]);
  const [toggleTabs, setToggleTabs] = useState(false);

  const admin = jwtDecode(
    localStorage.getItem(process.env.REACT_APP_TOKEN_NAME)
  ).isAdmin;

  const handleClick = (key) => {
    if (key === "1") {
      setToggleTabs(false);
    } else {
      setToggleTabs(true);
    }
  };

  const getInit = async () => {
    setLoading(true);
    const response = await campaignservice.CampaignDetailStatus({
      campaignId: campaignDetails.id,
      userId: jwtDecode(localStorage.getItem(process.env.REACT_APP_TOKEN_NAME))._id
    });
    setCampaignsInfo(response);
    setLoading(false);
  };

  useEffect(() => {
    getInit();
  }, []);

  return (
    <>
      <Header />
      <div className="container-fluid">
        <div className="row">
          <CampaignDetails
            admin={admin}
            loading={loading}
            toggleView={toggleView}
            setToggleView={setToggleView}
            handleClick={handleClick}
            campaignDetails={campaignDetails}
            customStyle={customStyle}
            customButtonStyle={customButtonStyle}
            borderRadiusStyle={borderRadiusStyle}
            backgroundColorStyle={backgroundColorStyle}
            campaignsInfo={campaignsInfo}
          />
        </div>
      </div>
      <Footer />
    </>
  );
}

export default CampaignInfo;
