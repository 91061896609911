import http from "./httpservices";

export async function getUserDetails(url) {
  const response = await http.post("instagram/getuserdetails/", url);

  return response;
}

export default {
  getUserDetails,
};
