import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import userservice from "../../../services/userservice";
import "./css/adminSideMenu.css";

function AdminSideMenu({ handleClick }) {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  const handleChange = async (name) => {
    setLoading(true);
    const response = await userservice.getSearchUser(name);
    setUsers(response);
    setLoading(false);
  };

  const getInit = async () => {
    setLoading(true);
    const pocUsers = await userservice.getStatsUsers();
    setUsers(pocUsers);
    setLoading(false);
  };

  useEffect(() => {
    getInit();
  }, []);

  return (
    <div className="flex-shrink-0 p-3 col-xl-2">
      <input
        className="form-control me-2"
        type="search"
        placeholder="Search User"
        aria-label="Search"
        name="search"
        autoComplete="off"
        onChange={(e) => handleChange(e.target.value)}
      />
      <hr />
      <ul className="list-unstyled ps-0">
        <li className="mb-1">
          <div
            className="collapse show scroll"
            id="home-collapse"
            style={{ height: "660px", overflowY: "auto" }}
          >
            {loading ? (
              <center>
                <Spinner animation="border" />
              </center>
            ) : (
              <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                {/* <li
                  key={"id"}
                  onClick={() => (window.location.href = "/admin/manage/role")}
                >
                  <Link className="link-dark rounded text-capitalize">All</Link>
                </li> */}
                {users.map((user) => (
                  <li key={user._id}>
                    <a
                      href="#"
                      className="rounded text-capitalize"
                      onClick={() => handleClick(user._id, user.fullName)}
                    >
                      {user.fullName}
                    </a>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </li>
      </ul>
    </div>
  );
}

export default AdminSideMenu;
