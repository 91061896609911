import http from "./httpservices";


export async function getTelegramInfo(params) {
  const { data } = await http.post("telegram/generate/payment/link", params);
  return data;
}

export default {
  
  TelegramInfo: getTelegramInfo,
};
