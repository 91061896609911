import React, { useState, useEffect } from "react";
import "./css/dashboard.css";
import Content from "./content";
import Header from "../common/header/header";
import influencerservice from "../../services/influencerservice";
import categoryservice from "../../services/categoryservice";
import Footer from "../common/footer/footer";


export const Search = () => {
  const [searchContent, setSearchContent] = useState(false);
  const [inputText, setInputText] = useState("");
  const [categoryCount, setCategoryCount] = useState([]);
  const [influencerCount, setInfluencerCount] = useState([]);
  
  const openSearch = () => {
    setSearchContent(!searchContent);
  };

  const inputHandler = (e) => {
    const lowerCase = e.target.value.toLowerCase();
    setInputText(lowerCase);
  };

  const getInit = async () => {

    window.location.href = "/admin/payment/dashboard/home";
    // const responseInfluencerContent =
    //   await influencerservice.getInfluencersCount();
    // setInfluencerCount(responseInfluencerContent.count);

    // const responseCategoryContent = await categoryservice.getCategoriesCount();
    // setCategoryCount(responseCategoryContent.count);
  };

  useEffect(() => {
    getInit();
  }, []);

  return (
    <>
      <div className="container-fluid m-0" id="search">
        <div className="row">
          <Header title={"Search Influencers"} />
          <div className="col-xl-12 text-center px-5 py-5" id="section-2">
            <div className="searchBox">
              <h3 className="">
                Search from the{" "}
                <span className="text-danger">Largest Database</span> of
                Influencers
              </h3>
              <small className=" subTitle">
                Access over {influencerCount} <b>Influencers</b> from{" "}
                {categoryCount} <b>Categories</b>
              </small>
              <br />
              <br />
              <div className="d-flex mx-auto w-75" id="searchBox">
                <select
                  className="form-select border rounded-0 rounded-start p-3 filterDropDown"
                  aria-label="Default select example"
                >
                  <option defaultValue>Instagram</option>
                </select>
                <input
                  type="text"
                  className="form-control p-3"
                  aria-label="Text input with dropdown button"
                  placeholder="Type names, city, categories keywords"
                  value={inputText}
                  onClick={openSearch}
                  onChange={inputHandler}
                  onKeyUp={openSearch}
                  onKeyPress={(e) =>
                    e.key === "Enter" &&
                    (window.location.href = `/influencers/${inputText}`)
                  }
                />
              </div>
              {searchContent && <Content inputText={inputText} />}
            </div>
            <div className="mt-4">
              <button
                type="button"
                className="btn btn-danger rounded-pill shadow w-25 searchBtn"
                onClick={() =>
                  (window.location.href = `/filter/influencers/${inputText}`)
                }
              >
                Search
              </button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Search;
