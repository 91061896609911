import { faCircleArrowDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Spinner, Table } from "react-bootstrap";

function CompanyPayment({
  customHeadingStyle,
  loading,
  companies,
  CustomRowStyle,
  handleCompanyShow,
  handleDownload,
  handleCompanyListShow,
  CustomFontStyle,
}) {
  return (
    <>
      <Table hover size="sm">
        <thead>
          <tr>
            <th scope="col">
              <small className="" style={customHeadingStyle}>
                #
              </small>
            </th>
            <th scope="col">
              <small className="" style={customHeadingStyle}>
                Vendor
              </small>
            </th>
            <th scope="col">
              <small className="" style={customHeadingStyle}>
                Count
              </small>
            </th>
            <th scope="col">
              <small className="" style={customHeadingStyle}>
                Category
              </small>
            </th>
            <th scope="col">
              <small className="" style={customHeadingStyle}>
                Download
              </small>
            </th>
          </tr>
        </thead>
        <tbody className="mx-auto my-0">
          {loading ? (
            <tr>
              <td colSpan={7}>
                <center>
                  <Spinner animation="border" />
                </center>
              </td>
            </tr>
          ) : companies.length === 0 ? (
            <tr>
              <td colSpan={7}>
                {" "}
                <center>
                  <b className="text-danger">No Data</b>
                </center>{" "}
              </td>
            </tr>
          ) : (
            companies &&
            companies.map((p, index) => (
              <tr
                key={p._id}
                id={`id_${index}_company`}
                className="align-middle"
                onClick={handleCompanyListShow}
              >
                <td
                  style={CustomRowStyle}
                  className=""
                  onClick={() =>
                    handleCompanyShow(`id_${index}_company`, p.name[0], p._id)
                  }
                >
                  {index + 1}
                </td>
                <td
                  style={CustomRowStyle}
                  className=""
                  onClick={() =>
                    handleCompanyShow(`id_${index}_company`, p.name[0], p._id)
                  }
                >
                  {p.name[0]}
                </td>
                <td
                  style={CustomRowStyle}
                  className=""
                  onClick={() =>
                    handleCompanyShow(`id_${index}_company`, p.name[0], p._id)
                  }
                >
                  {p.count}
                </td>
                <td
                  style={CustomRowStyle}
                  className=""
                  onClick={() =>
                    handleCompanyShow(`id_${index}_company`, p.name[0], p._id)
                  }
                >
                  {p.companyCategory[0]}
                </td>
                <td style={CustomFontStyle} className="text-center">
                  <FontAwesomeIcon
                    icon={faCircleArrowDown}
                    title="Download User Details"
                    className="text-primary"
                    style={{ fontSize: "15px", cursor: "pointer" }}
                    onClick={() => handleDownload(p._id, p.name[0], true)}
                  />
                </td>
              </tr>
            ))
          )}
        </tbody>
      </Table>
    </>
  );
}

export default CompanyPayment;
