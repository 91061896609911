import React, { useEffect } from "react";
import { useState } from "react";
import { Badge, Spinner, Table } from "react-bootstrap";
import hashtagservice from "../../../../services/hashtagservice";
import hashtagqueueservice from "../../../../services/hashtagqueueservice";
import Swal from "sweetalert2";

function HashTags({ customStyle, currentData }) {
  const [hashTagList, setHashTagList] = useState([]);
  const [hashTagQueueList, setHashTagQueueList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");

  const handleClick = (tagName) => {
    window.location.href = `/admin/hashtag/report/${tagName}`;
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const response = await hashtagqueueservice.hashTagsQueue(name);
    if (response.status === 201) {
      Swal.fire(response.data.title, response.data.msg);
      setName("");
      return;
    }

    setHashTagQueueList([
      {
        name: name,
      },
      ...hashTagQueueList,
    ]);

    // setHashTagList([
    //   {
    //     "_id": null,
    //     "name": name,
    //     "count": 0,
    //     "downloadInfluencers": 0,
    //     "apiHit": 0,
    //     "status": "W",

    //   },
    //   ...hashTagList
    // ])
    await InIt();
    setName("");

    return;
  };

  const InIt = async () => {
    setLoading(true);

    const hashTags = await hashtagqueueservice.getHashTagsQueueStatus();
    setHashTagList(hashTags.data);
    const hashTagQueue = await hashtagqueueservice.getHashTagsQueue();
    setHashTagQueueList(hashTagQueue.data);
    setLoading(false);
  };

  useEffect(async () => {
    InIt();
  }, []);

  return (
    <>
      <form onSubmit={onSubmit}>
        <input
          type="text"
          className="form-control mb-3"
          id="hashtag"
          placeholder="Instagram Hashtag*"
          name="instaHashtag"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        ></input>
        <button type="submit" className="btn btn-primary">
          Queue
        </button>
      </form>
      <br />
      <div className="row">
        <div
          className="p-3 rounded-3 shadow-sm overflow-auto col-xl-10 mx-auto"
          id="tableSection"
        >
          <Table hover border size="sm">
            <thead>
              <tr>
                <th scope="col">
                  <small className="">#</small>
                </th>
                <th scope="col">
                  <small className="">TagName</small>
                </th>
                <th scope="col">
                  <small className="">Total</small>
                </th>
                <th scope="col">
                  <small className="">Download</small>
                </th>
                <th scope="col">
                  <small className="">API Hit</small>
                </th>
                <th scope="col" className="text-center">
                  <small className="">Created On</small>
                </th>
                <th scope="col">
                  <small className="">Status</small>
                </th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan={6}>
                    <center>
                      <Spinner animation="border" />
                    </center>
                  </td>
                </tr>
              ) : hashTagList.length === 0 ? (
                <tr>
                  {" "}
                  <td colSpan={7}>No Data</td>
                </tr>
              ) : (
                hashTagList.map((tag, index) => (
                  <tr
                    style={{ cursor: "pointer" }}
                    onClick={() => handleClick(tag.name)}
                  >
                    <td style={customStyle} className="text-center">
                      {index + 1}
                    </td>
                    <td style={{ ...customStyle }} className="w-25">
                      <Badge
                        pill
                        bg="warning"
                        text="dark"
                        style={{ fontSize: "13px" }}
                      >
                        #{tag.name}
                      </Badge>
                    </td>
                    <td style={customStyle}>{tag.count ? tag.count : 0}</td>
                    <td style={customStyle} className="w-25">
                      {tag.downloadInfluencers ? tag.downloadInfluencers : 0}
                    </td>
                    <td style={customStyle} className="w-25">
                      {tag.apiHit ? tag.apiHit : 0}
                    </td>
                    {/* <td style={customStyle} className="w-25">
                      {tag.status}
                    </td> */}
                    <td>
                      <Badge bg="light" text="dark">
                        {tag._id
                          ? new Date(
                              parseInt(tag._id.substring(0, 8), 16) * 1000
                            ).toLocaleDateString("en-US", {
                              day: "numeric",
                              month: "numeric",
                              year: "numeric",
                              hour: "2-digit",
                              minute: "2-digit",
                            })
                          : currentData}
                      </Badge>
                    </td>
                    <td style={customStyle} className="w-25">
                      {tag.status === "W" ? (
                        <Spinner animation="border" size="sm" />
                      ) : (
                        <Badge pill bg="success">
                          Success
                        </Badge>
                      )}
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </Table>
        </div>
        <div className=" shadow-sm rounded-3 col-xl-2 mx-auto">
          <h6 className="m-3">Queue ({hashTagQueueList.length})</h6>
          <hr />
          {hashTagQueueList.length === 0 ? (
            <p>No data</p>
          ) : (
            hashTagQueueList.map((q) => <p key={q.name}>{q.name}</p>)
          )}
        </div>
      </div>
    </>
  );
}

export default HashTags;
