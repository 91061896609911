import { faCircleInfo, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BrandList from "../brand/brandList";
import CompanyList from "../company/companyList";
import Card from "react-bootstrap/Card";
import { Badge, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";



export default function CampaignCard({ stats, vendors, brands, role }) {
  const fontStyle = { fontSize: "12px" };

  const handleCampaignModalOpener = () => {
    document.getElementById("campaignModalOpener").click();
  };

  const handleCompanyModalOpener = () => {
    document.getElementById("companyModalOpener").click();
  };

  const handleBrandModalOpener = () => {
    document.getElementById("brandModalOpener").click();
  };
  const history = useHistory();

  return (
    <>
      <div className="col-xl-4" onClick={() => history.push('/admin/payment/dashboard/campaigns/pending')} style={{cursor: "pointer"}}>
        <Card className="p-3" >
          <Card.Body>
            <div className="d-flex align-items-center justify-content-between">
              <div>
                <h4 className="card-title" style={{ color: "#06616F" }}>
                  <Badge>{stats.stats.totalCampaign}</Badge>
                </h4>
                <p className="card-text  text-uppercase fw-bold">Campaigns</p>
              </div>
              {role["role"] !== "Operation" && (
                <div
                  style={{
                    cursor: "pointer",
                    fontSize: "25px",
                  }}
                  title="Add Campaign"
                >
                  <Button
                    onClick={() =>
                      (window.location.href =
                        "/admin/payment/dashboard/campaigns/pending")
                    }
                  >
                    <FontAwesomeIcon icon={faCircleInfo} />
                  </Button>
                </div>
              )}
            </div>
          </Card.Body>
        </Card>
      </div>
      <div className="col-xl-4" >
        <Card className="p-3">
          <Card.Body>
            <div className="d-flex align-items-center justify-content-between">
              <div>
                <h4 className="card-title">
                  <Badge bg="danger">{stats.stats.totalCompany}</Badge>
                </h4>
                <p className="card-text  text-uppercase fw-bold">Vendors</p>
              </div>
              <div
                style={{
                  fontSize: "25px",
                }}
                title="Add Vendor"
                className="d-flex justify-content-around"
              >
                <CompanyList vendors={vendors} />
              </div>
            </div>
          </Card.Body>
        </Card>
      </div>
      <div className="col-xl-4">
        <Card className="p-3">
          <Card.Body>
            <div className="d-flex align-items-center justify-content-between">
              <div>
                <h4 className="card-title" >
                  <Badge bg="success" >{stats.stats.totalBrand}</Badge>
                </h4>
                <p
                  className="card-text  text-uppercase fw-bold"
                >
                  Brands
                </p>
              </div>
              {role["role"] !== "Operation" ? (
                <div
                  className="rounded-pill d-none d-md-flex d-flex justify-content-around"
                  style={{
                    fontSize: "25px",
                  }}
                  title="Add Brand"
                >
                  <BrandList brands={brands} />
                </div>
              ) : (
                <></>
              )}
            </div>
          </Card.Body>
        </Card>
      </div>
    </>
  );
}
