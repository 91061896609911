import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import jwtDecode from "jwt-decode";
import Swal from "sweetalert2";
import brandservice from "../../../services/brandservice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd } from "@fortawesome/free-solid-svg-icons";

function Brand() {
  const btnClose = () => setVisible(false);
  const btnShow = () => setVisible(true);

  const customStyle = { fontSize: "13px" };
  const [visible, setVisible] = useState(false);
  const [params, setParams] = useState({
    name: "",
  });

  const handleChange = (e) => {
    let { name, value } = e.target;

    setParams((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      params["createdBy"] = jwtDecode(
        localStorage.getItem(process.env.REACT_APP_TOKEN_NAME)
      )._id;
      const res = await brandservice.Brand(params);
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Brand Added Successfully!",
        showConfirmButton: false,
        timer: 2500,
      });
      window.location.reload();
    } catch (e) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Brand Already Exist!",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  return (
    <>
      <li className="nav-item" style={{ cursor: "pointer" }}>
        <a
          className="nav-link link-light rounded"
          aria-current="page"
          onClick={btnShow}
          id={"brandModalOpener"}
        >
          <FontAwesomeIcon icon={faAdd} className="me-1 align-self-center" />
          Brand
        </a>
      </li>
      <Modal show={visible} onHide={btnClose}>
        <Modal.Header closeButton>
          <Modal.Title >Add Brand</Modal.Title>
        </Modal.Header>
        <form onSubmit={handleSubmit}>
          <Modal.Body>
            <input
              type="text"
              className="form-control"
              placeholder="Brand*"
              name="name"
              value={params.name}
              onChange={handleChange}
              style={customStyle}
              required
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={btnClose}>
              Close
            </Button>
            <Button className="btn btn-success" type="submit">
              Add
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
}

export default Brand;
