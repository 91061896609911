import http from "./httpservices";

export async function getCompanies() {
  const { data } = await http.get("company/");
  return data;
}

export async function addCompany(params) {
  const { data } = await http.post("company/addcompany", params);
  return data;
}

export async function getVendorById(params) {
  const { data } = await http.post("company/id", params);
  return data;
}

export default {
  getCompanies,
  addCompany,
  getVendorById
};
