import React, { useEffect } from "react";
import PaymentLink from "../../../components/payment/paymentLink/paymentLink";
import "./css/PaymentStyle.css";
import Header from "../../../components/common/header/header";
import SildeMenu from "../../../components/payment/common/slideMenu";
import Footer from "../../../components/common/footer/footer";
import ExpenseLink from "../../../components/payment/paymentLink/expenseLink";

function Expense(props) {
  if (!props.location.state)
    window.location.href = "/admin/payment/dashboard/campaigns/pending";
  const campaignDetails = props.location.state;

  return (
    <>
      <Header />
      <div className="container-fluid">
        <div className="row">
          <SildeMenu />
          <ExpenseLink campaignDetails={campaignDetails} />
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Expense;
