import React from "react";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { Spinner, Table, Tab, Tabs, Badge } from "react-bootstrap";
import Header from "../../../../components/common/header/header";
import Footer from "../../../../components/common/footer/footer";
import hashtagservice from "../../../../services/hashtagservice";
import IdToDate from "../../../../admin/common/IdToDate";

export default function HashTagReport({ match }) {
  const history = useHistory();
  const [hashtags, setHashtags] = useState([]);
  const [loading, setLoading] = useState(true);
  const tabName = `Influencers (${hashtags.length})`;
  const tagName = match.params["tagname"];

  const getInit = async () => {
    const response = await hashtagservice.getHashTagByTagName({
      hashtag: tagName,
    });
    setHashtags(response["data"]);
    setLoading(false);
  };

  useEffect(() => {
    getInit();
  }, []);

  return (
    <>
      <Header />

      <div className="container-fluid">
        <div className="row">
          <div
            className="col-xl-12 col-lg-12 col-md-12 col-sm-12"
            style={{ marginBottom: "65px" }}
          >
            <nav className="navbar navbar-light">
              <div>
                <a className="navbar-brand">
                  <FontAwesomeIcon
                    icon={faArrowLeft}
                    size={"sm"}
                    onClick={() => history.goBack()}
                    style={{ cursor: "pointer" }}
                  />
                </a>
                <Badge>
                  <small className="fs-5">#{tagName}</small>
                </Badge>
              </div>
            </nav>
            {/* table */}
            <Tabs
              defaultActiveKey={1}
              id="uncontrolled-tab-example"
              className="mb-3 d-flex justify-content-end"
              animation={true}
            >
              <Tab eventKey={1} title={tabName}>
                <div
                  className=" p-2 rounded-3 w-100 mx-auto mt-2"
                  style={{ fontSize: "12px" }}
                >
                  {loading ? (
                    <center>
                      <Spinner animation="border" />
                    </center>
                  ) : hashtags.length === 0 ? (
                    <center>
                      <b className="text-danger">No Data</b>
                    </center>
                  ) : (
                    <Table hover size="sm">
                      <thead>
                        <tr>
                          <th scope="col">
                            <small className="">#</small>
                          </th>

                          <th scope="col">
                            <small className="">Influencer</small>
                          </th>
                          <th scope="col">
                            <small className="">Full Name</small>
                          </th>
                          <th scope="col">
                            <small className="">Like</small>
                          </th>
                          <th scope="col">
                            <small className="">Play</small>
                          </th>
                          <th scope="col">
                            <small className="">Reshare</small>
                          </th>
                          <th scope="col">
                            <small className="">Post</small>
                          </th>
                          <th scope="col">
                            <small className="">Created On</small>
                          </th>
                          <th scope="col">
                            <small className="">Email</small>
                          </th>
                          <th scope="col">
                            <small className="">Number</small>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="mx-auto my-0">
                        {loading ? (
                          <tr>
                            <td colSpan={8}>
                              <center>
                                <Spinner animation="border" />
                              </center>
                            </td>
                          </tr>
                        ) : (
                          hashtags.map((hashtag, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>
                                <b>@{hashtag.username}</b>
                              </td>
                              <td>{hashtag.full_name}</td>
                              <td>{hashtag.like_count}</td>
                              <td>{hashtag.play_count}</td>
                              <td>{hashtag.reshare_count}</td>

                              {hashtag.post ? (
                                <td title={hashtag.post}>
                                  {hashtag.post &&
                                    hashtag.post.substring(0, 50) + "..."}
                                </td>
                              ) : (
                                <td>NA</td>
                              )}
                              <td>
                                <IdToDate id={hashtag._id} />
                              </td>
                              <td>{hashtag.influencer[0]?.email || "NA"}</td>
                              <td>{hashtag.influencer[0]?.number || "NA"}</td>
                            </tr>
                          ))
                        )}
                      </tbody>
                    </Table>
                  )}
                </div>
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
