import React, { useEffect, useState } from "react";
import influencerservice from "../services/influencerservice";
import Card from "../components/common/card/card";
import Header from "../components/common/header/header";
import "../components/influencers/css/influencers.css";
import { Spinner } from "react-bootstrap";
import removeMediaFromUrl from "../hooks/useRemoveMediaFromUrl";
import Footer from "../components/common/footer/footer";

const SearchInfluencers = ({ match }) => {
  const [profiles, setProfiles] = useState([]);
  const [loading, setLoading] = useState(true);

  const getInit = async (name) => {
    const response = await influencerservice.getSearchInfluencers(name);
    setProfiles(response);
    setLoading(false);
  };

  useEffect(() => {
    getInit(match.params["name"]);
  }, []);

  return (
    <>
      <Header title={"Search Influencers"} />
      {loading ? (
        <>
          <h3 className="text-center my-5">Loading... Influencers</h3>
          <center>
            <Spinner animation="border" />
          </center>
        </>
      ) : (
        <>
          <h3 className="text-center my-5">
            List of Influencers ({profiles.length})
          </h3>
          <div className="container mt-3">
            <div className="row row-cols-1 row-cols-md-3 g-4">
              {profiles.map((p, index) => (
                <Card
                key={index}
                  name={p.username}
                  bio={p.biography}
                  path={p.path}
                  image={removeMediaFromUrl(p.profile_pic_url_hd)}
                />
              ))}
            </div>
          </div>
        </>
      )}
      <Footer />
    </>
  );
};

export default SearchInfluencers;
